import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
import { VscClose } from 'react-icons/vsc'
import { Container, Button } from 'cc-web-components'

import MessageList from '@/components/MessageList'

import { getMessageListOfTheActivity } from '@/components/MessageList/helpers'
import { Activity } from '@/services/ApiCv/types/Activity'

type Props = {
  title: ReactNode
  opened: boolean
  activity: any
  onClose: () => void
}

const SidebarMessages: React.FC<Props> = ({ title, opened, ...props }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [opened])

  const handleClickWrapper = useCallback(
    (event: any) => {
      if (!wrapperRef || !wrapperRef.current) return

      if (wrapperRef.current.contains(event.target)) return

      props.onClose()
    },
    [props]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickWrapper)

    return () => {
      document.removeEventListener('mousedown', handleClickWrapper)
    }
  }, [handleClickWrapper])

  if (!opened) return <></>

  const chatMessages = !props.activity
    ? []
    : getMessageListOfTheActivity(props.activity as Activity)

  return (
    <div ref={wrapperRef}>
      <Container
        top={0}
        right={0}
        width="40%"
        zIndex={20}
        height="100%"
        maxWidth={480}
        position="fixed"
        justifyContent="flex-end"
      >
        <Container flexDirection="column" width="100%" backgroundColor="#fff" padding={'0px'}>
          <Container
            margin={'20px 20px 10px 20px'}
            alignItems="center"
            justifyContent="space-between"
          >
            <Container flex={1} height={20}>
              {title}
            </Container>

            <Button variant="link" leftContent={<VscClose size={20} />} onClick={props.onClose} />
          </Container>

          <MessageList xp_number={props.activity?.total_xp || 0} chat={chatMessages} />
        </Container>
      </Container>
    </div>
  )
}

export default SidebarMessages
