import React from 'react';
import {useTheme} from 'styled-components';
import {BiPrinter} from 'react-icons/bi';
import {useTranslation} from 'react-i18next';
import {Button, Card, Container, Text} from 'cc-web-components';

import useDeviceDetection from '@/hooks/IsMobile';
import {Routes} from '@cc/shared';
import {openWindow} from '@cc/utils';

const {STUDENT_CODES} = Routes;

type Props = {
  classroomId?: string;
  totalStudents: number;
  totalStudentsRegistereds: number;
};

const PrintAccessCodes: React.FC<Props> = (props: Props) => {
  const {colors} = useTheme();
  const {t} = useTranslation('cornelsen');
  const {isMobile} = useDeviceDetection();

  function openPagePrint() {
    if (!props.classroomId) return;

    openWindow(`${STUDENT_CODES}/${props.classroomId}`);
  }

  return (
    <Container bg="#fff">
      <Card
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        padding={3}
        overflow="visible"
        overflowX="visible">
        <Text
          fontSize={[6, 6, 7, 7]}
          color={colors.secondary}
          value={`${props.totalStudentsRegistereds} / ${props.totalStudents}`}
          pr={[0, 0, 4]}
          pl={[0, 0, 2]}
        />
        <Container flex={1} flexDirection="column">
          <Text
            color={colors.gray[0]}
            value={t('Classroom.PrintBox.Title')}
            mb={1}
            fontSize={[5, 5, 6, 6]}
          />
          <Text
            color={colors.gray[2]}
            value={t('Classroom.PrintBox.SubTitle')}
            mb={[3, 3, 0, 0]}
            fontSize={16}
          />
        </Container>
        <Button
          type="button"
          variant="solid"
          value={t('Classroom.PrintBox.ButtonText')}
          leftContent={
            <Container mr={2}>
              <BiPrinter size={24} />
            </Container>
          }
          width={260}
          height={[40, 40, 50, 50]}
          onClick={openPagePrint}
          fontSize={[4, 4, 5, 5]}
          fontWeight={400}>
          Zugangscodes drucken
        </Button>
      </Card>
    </Container>
  );
};

export default PrintAccessCodes;
