import Images from '@/assets/images';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Container, Dropdown} from 'cc-web-components';
import {style} from './styles';

interface Props {
  isVisible?: boolean;
  classroomId: string;
  classroomName: string;
  onClassroomSelect?: (index: number, classroomId: string, classroomName: string) => void;
}

export const ClassroomOptions: React.FC<Props> = ({
  classroomId,
  classroomName,
  onClassroomSelect,
  isVisible,
}) => {
  const {t} = useTranslation('cornelsen');

  const FILTERS_ACTION = useMemo(() => {
    return [
      {
        key: 0,
      },
      {
        key: 1,
        img: <img src={Images.ReloadBlue} alt="" width={20} style={{marginRight: '10px'}} />,
        label: t('Home.FilterActions.AssignLicense'),
      },
      {
        key: 2,
        img: (
          <img src={Images.SmallTrash} alt="" width={22} height={24} style={{marginRight: '9px'}} />
        ),
        label: t('Home.FilterActions.Delete'),
      },
    ];
  }, [t]);

  const handleOnSelect = useCallback(
    (_: any, index: number) => {
      onClassroomSelect?.(index, classroomId, classroomName);
    },
    [classroomId, classroomName, onClassroomSelect],
  );

  const renderItem = useCallback(
    (item: any) => (
      <>
        {item.img}
        <span style={style.item}>{item.label}</span>
      </>
    ),
    [],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Container id="top-dropdown" className="header-dropdown" style={style.container}>
      <Dropdown
        items={FILTERS_ACTION}
        renderItem={renderItem}
        handleSelectItem={handleOnSelect}
        selectedIndex={0}
        labelIcon={<img src={Images.MenuLinear} style={style.label} alt="" />}
        isInverted={true}
      />
    </Container>
  );
};
