import {UserType} from './types';

export const EMPTY_STRING = '';

export const CHATCLASS_APPLE_STORE =
  'https://apps.apple.com/us/app/chatclass-cornelsen/id1487598001?l=de&ls=1';

export const CHATCLASS_ANDROID_STORE =
  'https://play.google.com/store/apps/details?id=de.cornelsen.chatclass';

export const ORGANIZATIONS: {[key in UserType]: string} = {
  CORNELSEN: 'Cornelsen Verlag',
  BSP: 'Berliner Schulportal',
};

export const LERNEN_CORNELSEN_APPLE_STORE =
  'https://apps.apple.com/us/app/cornelsen-lernen/id1502080578?l=de&ls=1';

export const LERNEN_CORNELSEN_ANDROID_STORE =
  'https://play.google.com/store/apps/details?id=com.cornelsen.uma';
