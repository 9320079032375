import styled from 'styled-components'

export const TableTaskActivityContainer = styled.div`
  #tableTaskActivity {
    table {
      thead {
        display: none;
      }
    }
  }
`
