import styled, { css } from 'styled-components'

export const StyledScoreIconCount = styled.div<{ color: string }>`
  width: 36px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 9999px;
  background: #ef9a9a;
  color: #660003;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;

  ${(props) =>
    props.color === 'High' &&
    css`
      background: #a5d6a7;
      color: #003b0a;
    `}

  ${(props) =>
    props.color === 'Mid' &&
    css`
      background: #ffe082;
      color: #3d2e00;
    `}
`

export const EmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: #f8f8f8;
  border: 1px dashed #bcafa3;
  padding: 10px;

  font-size: 14px;
  text-align: center;
  line-height: 18px;
  color: #999;
`

export const TooltipContainer = styled.div`
  display: flex;
  background-color: #fff;
  border: 1px solid #eee;
  padding: 16px 8px;
`
