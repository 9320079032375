import camelcaseKeys from 'camelcase-keys'
import Service from './apiConfig'
import { ActivityResponse } from './types/Activity'

const ApiHomePageService = {
  getAllClassrooms: async () => {
    return await Service.get(`/api/classroom/all`)
  },
  getAllCompetencies: async () => {
    const response = await Service.get(`/api/classroom/all/competencies`)

    const data = camelcaseKeys(response.data)

    const graphValues = [
      { name: 'Sprechen', value: data.speakingTotalQuestion },
      { name: 'Aussprache', value: data.pronunciationTotalQuestion },
      { name: 'Vokabeln', value: data.vocabTotalQuestion },
      { name: 'Grammatik', value: data.grammarTotalQuestion },
      { name: 'Hören', value: data.listeningTotalQuestion },
      { name: 'Lesen', value: data.readingTotalQuestion },
    ]

    return { ...data, graphValues }
  },
  getAllVocabularyProblems: async () => {
    const { content, correctness, total_elements } = (
      await Service.get<ActivityResponse>(
        `/api/classroom/all/stats?type=LISTENING&type=DIALOG&type=GRAMMAR&type=VOCABULARY&type=VOCABULARY_TRAINER`
      )
    ).data

    const contentParsed = content.map((activity) => {
      const number_items = activity.items.length
      const number_items_correct = activity.items.filter((item) => item.correct).length

      activity.correct = number_items_correct === number_items
      activity.number_items = number_items
      activity.has_feedback = !!activity.feedbacks.length
      activity.number_items_correct = number_items_correct

      return activity
    })

    return {
      content: contentParsed,
      correctness,
      total_elements,
    }
  },
  // getClassroomColor: async () => {
  //   const classroom = (
  //     await Service.get(`/api/classroom/${classroom_id}`, {
  //       params: {classroom_id},
  //     })
  //   ).data
  // },
  getAllSpeakingAssignments: async () => {
    const { content, correctness, total_elements } = (
      await Service.get<ActivityResponse>(
        `/api/classroom/all/stats?type=SPEAKING&type=AI_SPEAKING&type=PRONUNCIATION&type=PICK_A_PIC`
      )
    ).data

    const contentParsed = content.map((activity) => {
      const number_items = activity.items.length
      const number_items_correct = activity.items.filter((item) => item.correct).length

      activity.correct = number_items_correct === number_items
      activity.number_items = number_items
      activity.has_feedback = !!activity.feedbacks.length
      activity.number_items_correct = number_items_correct

      return activity
    })
    return {
      content: contentParsed,
      correctness,
      total_elements,
    }
  },
}

export default ApiHomePageService
