import {useState, useEffect} from 'react';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {useTheme} from 'styled-components';
import {Store} from 'react-notifications-component';
import {Button, Container, Input, Select, Text} from 'cc-web-components';
import {useTranslation} from 'react-i18next';
import {osName, osVersion, isDesktop, isMobile, isTablet} from 'react-device-detect';

import {AddCircleLinedIcon, SendOutlinedIcon} from '@/components/Icons';

import Api from '@/services/ApiCv';
import Images from '@/assets/images';
import ApiClassroomService from '@/services/ApiCv/ApiClassroomService';
import {Classroom} from '@/services/ApiCv/types/Classroom';

import {FeedbackProps, UserProfileFeedbackType, Props} from './types';
import {
  CheckboxContainer,
  CustomInputFileLabel,
  DroppableWrapper,
  Image,
  PhotoPreview,
  TextareaContainer,
} from './styles';
import {getUser, showAlert, startLoading, stopLoading} from '@/redux/Session';
import {escapeHtml} from '@/utils';

const validatorImprovementSchema = Yup.object().shape({
  userName: Yup.string().required(),
  subject: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }),
  description: Yup.string().required(),
});

const validatorBugSchema = Yup.object().shape({
  userName: Yup.string().required(),
  subject: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }),
  description: Yup.string().required(),
  bookName: Yup.string().required(),
  classroomId: Yup.string().required(),
  classroomName: Yup.string().required(),
});

const Feedback = ({closeRequest}: Props) => {
  const {colors} = useTheme();
  const dispatch = useDispatch();
  const {t} = useTranslation('cornelsen');
  const user = useSelector(getUser);

  const [feedbackOptionIndex, setFeedbackOptionIndex] = useState(-1);
  const [classroomOptionIndex, setClassroomOptionIndex] = useState(-1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const [classrooms, setClassrooms] = useState<Classroom[]>([]);
  const [feedbackData, setFeedbackData] = useState<UserProfileFeedbackType>({
    bookName: '',
    classroomId: '',
    classroomName: '',
    description: '',
    files: null,
    logFiles: false,
    state: '',
    subject: {
      label: '',
      value: '',
    },
    userName: user?.name || '',
  });

  const SELECT_ITEMS = [
    {
      id: 1,
      text: t('UserProfile.Feedback.Sections.Options.Technical'),
      label: 'technical_difficulties',
    },
    {
      id: 2,
      text: t('UserProfile.Feedback.Sections.Options.Suggestions'),
      label: 'suggestions_improvements',
    },
    {
      id: 3,
      text: t('UserProfile.Feedback.Sections.Options.Features'),
      label: 'feature_requirement',
    },
    {id: 4, text: t('UserProfile.Feedback.Sections.Options.General'), label: 'general_feedback'},
    {id: 5, text: t('UserProfile.Feedback.Sections.Options.Others'), label: 'others'},
  ] as FeedbackProps[];

  useEffect(() => {
    if (feedbackOptionIndex !== -1 && feedbackOptionIndex !== 0)
      validatorImprovementSchema.isValid(feedbackData).then(valid => {
        valid ? setButtonActive(true) : setButtonActive(false);
      });

    if (classroomOptionIndex !== -1 && feedbackOptionIndex === 0)
      validatorBugSchema.isValid(feedbackData).then(valid => {
        valid ? setButtonActive(true) : setButtonActive(false);
      });
  }, [feedbackData, feedbackOptionIndex, classroomOptionIndex]);

  useEffect(() => {
    ApiClassroomService.getAll()
      .then(classrooms => {
        setClassrooms(classrooms.filter(item => !item.license?.expired));
      })
      .catch(() => setClassrooms([]));
  }, []);

  useEffect(() => {
    const showFile = () => {
      const photoArea = document.getElementById('photo-area');
      if (!feedbackData.files || !photoArea) return;

      const imgTag = `
            <span>${feedbackData.files[0].filename}</span>
          `;
      photoArea.innerHTML = imgTag;
    };

    showFile();
  }, [feedbackData.files]);

  const handleUploadFile = (event: any) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);
    fileReader.onload = () => {
      const validExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!!event.target.files.length && validExtensions.includes(event.target.files[0].type)) {
        setFeedbackData({
          ...feedbackData,
          files: [
            {
              // @ts-ignore
              content: fileReader.result?.replace(/[data:].+,/, ''),
              filename: escapeHtml(event.target.files[0]?.name),
              type: event.target.files[0]?.type,
            },
          ],
        });
      }
    };
  };

  const dropHandler = (event: any) => {
    event.preventDefault();
    const fileReader = new FileReader();
    const files = event.dataTransfer.files;
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = () => {
      const validExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!!files.length && validExtensions.includes(files[0].type)) {
        setFeedbackData({
          ...feedbackData,
          files: [
            {
              // @ts-ignore
              content: fileReader.result?.replace(/[data:].+,/, ''),
              filename: escapeHtml(files[0]?.name),
              type: event.dataTransfer.files[0]?.type,
            },
          ],
        });
      }
    };

    const dropArea = document.getElementById('drop-file-area');
    if (dropArea) {
      dropArea.classList.remove('active');
    }
  };

  const dragLeaveHandler = (event: any) => {
    event.preventDefault();

    const dropArea = document.getElementById('drop-file-area');
    if (dropArea) {
      dropArea.classList.remove('active');
    }
  };

  const dragOverHandler = (event: any) => {
    event.preventDefault();

    const dropArea = document.getElementById('drop-file-area');
    if (dropArea) {
      dropArea.classList.add('active');
    }
  };

  const onSelectedClassroom = (classroom: Classroom) => {
    if (!classroom) return;

    Api.getClassroom(classroom.id)
      .then(data => {
        if (data) {
          setFeedbackData({
            ...feedbackData,
            classroomId: classroom.id,
            classroomName: classroom.display_name,
            bookName: data?.metadata.book_name,
            state: data?.metadata.location || '',
          });
        }
      })
      .catch(() => {});
  };

  const onSubmit = () => {
    dispatch(startLoading());
    const env = window._env_.REACT_APP_CORNELSEN_URL;
    const environment = env.includes('dev')
      ? 'Development'
      : env.includes('stage')
      ? 'Staging'
      : 'Production';
    const device = isDesktop ? 'Desktop' : isMobile ? 'Mobile' : isTablet ? 'Tablet' : '';
    const os = osName + ' ' + osVersion;
    Api.sendUserProfileFeedback({...feedbackData, environment, device, os})
      .then(() => {
        dispatch(stopLoading());
        showNotification('Erfolgreich!', 'Ihre Nachricht wurde versendet.', 'success');
        setShowConfirmation(true);
      })
      .catch(() => {
        dispatch(stopLoading());
        dispatch(
          showAlert({
            value: 'Something went wrong',
            variant: 'danger',
          }),
        );
      });
  };

  const showNotification = (title: string, message: string, type: any) => {
    Store.addNotification({
      title,
      message,
      type,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  };

  return showConfirmation ? (
    <>
      <Container flexDirection="column" overflowY="auto" height="calc(100vh - 213px)">
        <Container flexDirection="column" alignItems="center" p={3}>
          <Text
            value={t('UserProfile.Feedback.Sections.Confirmation.Title')}
            fontSize={24}
            color={colors.black}
            mb={4}
          />

          <Image src={Images.Ada} />

          <Text
            value={t('UserProfile.Feedback.Sections.Confirmation.Description')}
            fontSize={18}
            textAlign="center"
            color={colors.black}
            my={4}
          />
        </Container>
      </Container>
      <Container p={4} justifyContent="center" backgroundColor={colors.white}>
        <Button
          type="button"
          variant="solid"
          value={t('UserProfile.Feedback.Sections.Confirmation.Actions.Finish')}
          onClick={closeRequest}
          height={40}
          minWidth={170}
          fontSize={20}
          fontWeight={400}
        />
      </Container>
    </>
  ) : (
    <>
      <Container flexDirection="column" overflowY="auto" height="calc(100vh - 213px)">
        <Container
          flexDirection="column"
          alignItems="center"
          backgroundColor={colors.white}
          px={3}
          py={4}>
          <p
            style={{fontSize: 16, color: colors.black, textAlign: 'center'}}
            dangerouslySetInnerHTML={{__html: t('UserProfile.Feedback.Description')}}
          />
        </Container>

        <Container flexDirection="column" p={3}>
          <Select
            items={SELECT_ITEMS}
            renderItem={(item: FeedbackProps) => (
              <Text value={item?.text} fontSize={16} color={colors.black} />
            )}
            onSelectItem={(item: FeedbackProps, index: number) => {
              setButtonActive(false);
              setFeedbackOptionIndex(index);
              setFeedbackData({
                ...feedbackData,
                subject: {
                  label: item.label,
                  value: item.text,
                },
              });
            }}
            selectedIndex={feedbackOptionIndex}
            placeholder={
              <Text
                value={t('UserProfile.Feedback.Sections.Options.Placeholder')}
                fontSize={16}
                color={colors.gray[2]}
              />
            }
          />

          <TextareaContainer>
            <Input
              type="textarea"
              placeholder={t('UserProfile.Feedback.Sections.Description.Placeholder')}
              rows={3}
              style={{
                flex: 1,
                fontSize: 16,
                fontFamily: 'CV_Source_Sans',
                color: colors.black,
                placeholder: colors.gray[2],
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFeedbackData({
                  ...feedbackData,
                  description: event.target.value,
                });
              }}
              mt={3}
            />
          </TextareaContainer>
        </Container>

        <Container flexDirection="column" px={3}>
          <Container>
            <CheckboxContainer>
              <input
                type="checkbox"
                checked={feedbackData.logFiles}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFeedbackData({
                    ...feedbackData,
                    logFiles: e.target.checked,
                  })
                }
              />
              <span className="check"></span>
            </CheckboxContainer>

            <Text size={14} value={t('UserProfile.Feedback.Sections.Log.Title')} ml={2} />
          </Container>

          <Text
            value={t('UserProfile.Feedback.Sections.Log.Subtitle')}
            fontSize={14}
            color={colors.black}
            pr={3}
            ml={28}
          />
        </Container>

        {feedbackOptionIndex === 0 && (
          <Container flexDirection="column" p={3}>
            <Text
              value={t('UserProfile.Feedback.Sections.Class.Title')}
              fontSize={14}
              color={colors.black}
              mt={4}
              mb={2}
            />

            <Select
              items={classrooms}
              renderItem={(item: Classroom) => (
                <Text value={item?.display_name} fontSize={16} color={colors.black} />
              )}
              onSelectItem={(item: Classroom, index: number) => {
                setClassroomOptionIndex(index);
                onSelectedClassroom(item);
              }}
              selectedIndex={classroomOptionIndex}
              placeholder={
                <Text
                  value={t('UserProfile.Feedback.Sections.Class.Placeholder')}
                  fontSize={16}
                  color={colors.gray[2]}
                />
              }
            />
          </Container>
        )}

        <Container position="relative" bottom="8px" flexDirection="column" mt={5} p={3}>
          <Text
            value={t('UserProfile.Feedback.Sections.Screenshot.Title')}
            fontSize={14}
            color={colors.black}
            mb={2}
          />

          <DroppableWrapper
            onDrop={dropHandler}
            onDragOver={dragOverHandler}
            onDragLeave={dragLeaveHandler}>
            <Container id="drop-file-area" flexDirection="column" alignItems="center">
              <CustomInputFileLabel htmlFor="file-upload">
                <AddCircleLinedIcon width={30} />
                <input id="file-upload" type="file" onChange={handleUploadFile} />
              </CustomInputFileLabel>

              <Container flexDirection="column" alignItems="center" mt={10}>
                <Text
                  value={t('UserProfile.Feedback.Sections.Screenshot.Actions.Move')}
                  fontSize={16}
                  color={colors.gray[2]}
                  mb="5px"
                />

                <CustomInputFileLabel htmlFor="file-upload">
                  <Text
                    value={t('UserProfile.Feedback.Sections.Screenshot.Actions.AddFile')}
                    fontSize={14}
                    color={colors.primary}
                  />
                  <input id="file-upload" type="file" onChange={handleUploadFile} />
                </CustomInputFileLabel>
              </Container>
            </Container>
          </DroppableWrapper>

          <PhotoPreview id="photo-area" />
        </Container>
      </Container>

      <Container p={4} justifyContent="center" backgroundColor={colors.white}>
        <Button
          type="button"
          variant="solid"
          value={t('UserProfile.Feedback.Actions.Submit')}
          onClick={onSubmit}
          disabled={!buttonActive}
          height={40}
          fontSize={16}
          fontWeight={400}
          leftContent={
            <Container mr={2}>
              <SendOutlinedIcon width={20} />
            </Container>
          }
          px={3}
        />
      </Container>
    </>
  );
};

export default Feedback;
