import styled, { css } from 'styled-components'

export const BookSelectorList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  list-style: none;
`

export const BookSelectorItem = styled.li<{
  active: boolean
  isDisabled?: boolean
}>`
  width: 60px;
  height: 60px;
  border: 1px solid #b8b8b8;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      border: 2px solid #1a73e8;
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}

  img {
    width: 100%;
    height: 100%;
  }
`
