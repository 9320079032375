import styled from 'styled-components'

import Images from '@/assets/images/product-benefits'

export const Modal = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #fff;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    box-sizing: border-box;
  }
  
  #ToggleButton {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }
`;

export const SubTitle = styled.h2`
  max-width: 620px;
  margin-bottom: 50px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #2B323B;

  strong {
    font-weight: 700;
  }
`

export const PricingTableContainer = styled.div`
  display: flex;
  flex-direction: column;  
  width: 100%;
  margin-bottom: 40px;
  padding: 0 20px;
  box-sizing: border-box;

  @media screen and (min-width: 768px) {

  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }
`

export const PricingTable = styled.div`
  margin-bottom: 90px;

  &:after {
    content: "Alle Preisangaben inkl. MwSt.";
    display: flex;
    justify-content: center;
    margin-top: 35px;
    font-size: 14px;
    color: #999;
  }

  &:nth-child(1) {
    order: -1;

    &:after {
      display: none;
    }
  }

  &:nth-child(2) {
    order: -1;

    &:after {
      display: none;
    }
  }

  &:nth-child(3) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      &:after {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    width: 310px;
    margin: 0 15px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      &:after {
        justify-content: center;
        margin-top: 35px;
      }
    }

    &:nth-child(1) {
      width: 280px;
      margin-top: 100px;
      margin-left: 0;

      &:after {
        display: none;
      }
    }

    &:nth-child(2) {
      order: 0;
    }

    &:nth-child(3) {
      width: 280px;
      margin-top: 100px;
      margin-right: 0;

      &:after {
        display: none;
      }
    }
  }

  label {
    position: absolute;
    top: -11px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 18px;
    margin: 0 auto;
    border-radius: 9px;
    background: #19A79D;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }

  & > div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 30px;
    border: 1px solid #e2e7ea;
    border-top: 4px solid #00ada3;
    box-sizing: border-box;

    section {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      label {
        top: -9px;
        border: 1px solid #fff;
        text-transform: none;
      }
    }

    section:nth-of-type(1) {
      position: relative;
      padding: 45px 0 30px;
      border-top: 1px solid #d9dee6;
      background: #f3f4f6;

      ul {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        max-width: 250px;
        list-style: none;
        box-sizing: border-box;

        li {
          padding-left: 26px;
          background: url(${Images.Checked}) no-repeat left top 5px;
          background-size: 12px 9px;
          font-size: 14px;
          line-height: 20px;

          & + li {
            margin-top: 10px;
          }
        }

        @media screen and (min-width: 768px) {
          grid-template-columns: 1fr 1fr 1fr;
          max-width: none;
          padding: 0 50px;

          li {
            & + li {
              margin-top: 0;
            }
          }
        }

        @media screen and (min-width: 1024px) {
          display: grid;
          grid-template-columns: 1fr;    
          max-width: 250px;
          padding: 0;

          li {
            & + li {
              margin-top: 10px;
            }
          }
        }
      }
    }

    section:nth-of-type(2) {
      padding: 20px 0;
      background: #45d8af;

      label {
        width: 76px;
      }

      ul {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        max-width: 250px;
        list-style: none;
        box-sizing: border-box;

        li {
          padding-left: 26px;
          background: url(${Images.ArrowWhite}) no-repeat left top 5px;
          background-size: 12px 9px;
          font-size: 14px;
          line-height: 20px;
          color: #fff;

          span {
            height: 18px;
            margin-left: 10px;
            padding: 0 6px;
            border-radius: 9px;
            background: rgba(255, 255, 255, .7);
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            color: #19A79D;
          }

          & + li {
            margin-top: 10px;
          }
        }

        @media screen and (min-width: 768px) {
          grid-template-columns: 1fr 1fr 1fr;
          max-width: none;
          padding: 0 50px;

          li {
            & + li {
              margin-top: 0;
            }
          }
        }

        @media screen and (min-width: 1024px) {
          display: grid;
          grid-template-columns: 1fr;    
          max-width: 250px;
          padding: 0;

          li {
            & + li {
              margin-top: 10px;
            }
          }
        }
      }
    }

    section:nth-of-type(3) {
      position: relative;
      padding: 20px 0 50px;
      background: #f3f4f6;

      label {
        width: 100px;
      }

      ul {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        max-width: 250px;
        list-style: none;
        box-sizing: border-box;

        li {
          padding-left: 26px;
          background: url(${Images.ArrowGreen}) no-repeat left top 5px;
          background-size: 12px 9px;
          font-size: 14px;
          line-height: 20px;
          color: #999;

          & + li {
            margin-top: 10px;
          }
        }

        @media screen and (min-width: 768px) {
          grid-template-columns: 1fr 1fr 1fr;
          max-width: none;
          padding: 0 50px;

          li {
            & + li {
              margin-top: 0;
            }
          }
        }

        @media screen and (min-width: 1024px) {
          display: grid;
          grid-template-columns: 1fr;    
          max-width: 250px;
          padding: 0;

          li {
            & + li {
              margin-top: 10px;
            }
          }
        }
      }

      button {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }

  &:nth-child(2) {
    & > div {
      section {
        &:nth-of-type(2) {
          padding: 30px 0;
        }

        &:nth-of-type(3) {
          background: #e7f7f6;

          ul {
            li {
              color: #19A79D;
            }
          }
        }
      }
    }
  }

  &:nth-child(3) {
    & > div {
      border-top: 4px solid #7F52B5;

      label {
        background: #7F52B5;
      }

      section {
        &:nth-of-type(2) {
          background: #ccd5fa;
        }
      }
    }
  }
`

export const PricingTableHeader = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
    padding: 10px 0 50px;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`

export const IconCircle = styled.div`
  position: absolute;
  top: -27px;
  left: 50%;
  transform: translateX(-26px);
  display: flex;
  width: 54px;
  height: 54px;
  background: #fff;
  border-radius: 50%;
`

export const TextContainer = styled.div`
  max-width: none;

  @media screen and (min-width: 1024px) {
    max-width: 250px;
  }
`

export const CloseButton = styled.button`
  z-index: 30;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  border: none;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    top: 30px;
    right: 30px;
  }
`

export const Separator = styled.div`
  display: block;
  width: 100%;
  max-width: 820px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e2e7ea;
`

export const Benefits = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 265px;
  background: #f8f8f8;

  ul {
    display: grid;
    grid-template-columns: 1fr;    
    margin-bottom: 30px;

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      max-width: 760px;
    }

    li {
      margin-bottom: 15px;
      list-style: none;
      font-size: 16px;
      font-weight: 600;
      padding-left: 26px;
          background: url(${Images.Checked}) no-repeat left top 5px;
          background-size: 12px 9px;
    }
  }
`

export const AppBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 457px;
  height: 125px;
  padding-right: 20px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 3px 9px 0 rgba(0,0,0,0.14);

  img {
    margin: 0 20px 0 30px;
    width: 107px;
    height: 107px;
  }
`

export const Adaillu = styled.img`
  position: absolute;
  bottom: 23px;
`
