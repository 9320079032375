import { useCallback, useEffect, useState } from 'react'

type Props = {
  scrollHeight: number
}

const useScrollY = (props: Props = { scrollHeight: 360 }) => {
  const [sticky, setSticky] = useState(false)

  const listenScrollEvent = useCallback(() => {
    if (window.scrollY === 0) {
      setSticky(false)
    }

    if (window.scrollY > props.scrollHeight) {
      setSticky(true)
    }
  }, [props])

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)

    return () => window.removeEventListener('scroll', listenScrollEvent)
  }, [listenScrollEvent])

  return [sticky]
}

export default useScrollY
