import {Container} from 'cc-web-components';
import {useEffect} from 'react';
import {useParams} from 'react-router';
import Storage from '../../services/Storage';

export const AuthPage: React.FC = () => {
  const {jwt} = useParams<{jwt: string}>();

  useEffect(() => {
    if (jwt) {
      Storage.setCvToken(jwt);
    }
  }, [jwt]);

  return <Container justifyContent="space-between">{jwt}</Container>;
};
