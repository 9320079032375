import {ClassroomState} from './types';
import {createAction, createReducer, createSelector} from '@reduxjs/toolkit';

export const INITIAL_STATE: ClassroomState = {
  isShowTabNewTask: true,
};

export const setIsShowTabNewTask = createAction<{isShowTabNewTask: boolean}>(
  'SET_IS_SHOW_TAB_TASK',
);

export default createReducer(INITIAL_STATE, {
  [setIsShowTabNewTask.type]: (state, action): ClassroomState => ({
    ...state,
    ...action.payload,
  }),
});

const rootSelector = (state: any): ClassroomState => state.classroomState;

export const getIsShowTabNewTask = createSelector([rootSelector], state => state || {});

// import { ClassroomContextState } from './types'

// const initialState: ClassroomContextState = {
//   activities: [],
//   getActivities: () => {},
// }

// export const TodosContext = createContext<ClassroomContextState>(initialState)
//
// const TodosProvider: React.FC = ({ children }) => {
//   const [activities, setActivities] = useState<{}[]>(initialState.activities)
//
//   const getActivities = () => {
//     console.log('@getActivities')
//   }
//
//   return (
//     <TodosContext.Provider
//       value={{
//         activities,
//         getActivities,
//       }}
//     >
//       {children}
//     </TodosContext.Provider>
//   )
// }
//
// export default TodosProvider
