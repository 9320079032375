import styled from 'styled-components';
import {DownloadLinedSvg, ExternalLinkSvg} from '@/assets';

export const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;

  input {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 20px;
    height: 20px;

    &:checked ~ .check {
      background: #fff;
      border: 1px solid #000;

      &::after {
        display: block;
      }
    }
  }

  .check {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;

    &:after {
      top: 2px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: solid rgb(26, 115, 232);
      border-radius: 1px;
      border-width: 0 3px 3px 0;
      --webkit-transform: rotate(45deg);
      --ms-transform: rotate(45deg);
      transform: rotate(45deg);

      content: '';
      position: absolute;
      display: none;
    }
  }
`;

export const DownloadIcon = styled(DownloadLinedSvg)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
`;

export const DownloadButton = styled.a`
  position: relative;
  padding: 12px 16px 12px 40px;
  font-size: 16px;
  color: #1a73e8;
  text-decoration: none;
  align-self: flex-start;
  margin-top: 16px;
  margin-bottom: 4px;
`;

export const LinkButton = styled.a`
  position: relative;
  padding: 12px 16px 12px 40px;
  font-size: 16px;
  color: #1a73e8;
  text-decoration: none;
  align-self: flex-start;
`;

export const LinkIcon = styled(ExternalLinkSvg)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
`;
