import Service from './apiConfig'

import { Book, BookDetail } from './types/Book'
import { BookDetail as BookDetails, BookDetailResponse } from './types/BookDetail'

type FilterRequestProps = {
  collection?: string
  subject?: string
  grade?: string
}

const ApiBookService = {
  getBooks: async (filter?: FilterRequestProps): Promise<Book[]> => {
    const books = (await Service.get<Book[]>(`/api/books`, { params: { ...filter } })).data

    books.forEach((book) => {
      if(!book.content.modules.length) return
      
      book.content.modules[0].units.forEach((unit) => {
        unit.todos
          .filter((item) => !item.randomized)
          .forEach((activty) => {
            activty.selected = false
          })
      })
    })

    return books
  },
  getBookById: async (bookdId: string): Promise<BookDetail> => {
    const book = (await Service.get<BookDetail>(`/api/book/${bookdId}`)).data

    book.units.forEach((unit) => {
      unit.sections.forEach((section) => {
        section.activities = section.activities
          .filter((item) => !item.randomized)
          .map((activity) => {
            activity.selected = false

            return activity
          })
      })
    })

    return book
  },

  getBookOfClassroomById: async (classroomId: string, bookdId: string): Promise<BookDetail> => {
    const book = (await Service.get<BookDetail>(`/api/classroom/${classroomId}/book/${bookdId}`))
      .data

    book.units.forEach((unit) => {
      unit.sections.forEach((section) => {
        section.activities = section.activities
          .filter((item) => !item.randomized)
          .map((activity) => {
            activity.selected = false

            return activity
          })
      })
    })

    return book
  },

  getBookDetails: async (bookdId: string): Promise<BookDetailResponse> => {
    const book = (await Service.get<BookDetails>(`/api/book/${bookdId}`)).data

    const unit = !!book.units.length ? book.units[0] : null
    const section = !!unit && unit.sections.length!! ? unit.sections[0] : null

    return {
      book,
      unit,
      section,
    }
  },

  getClassroomBookDetails: async (
    classroomId: string,
    bookdId: string
  ): Promise<BookDetailResponse> => {
    const book = (await Service.get<BookDetails>(`/api/classroom/${classroomId}/book/${bookdId}`))
      .data

    const unit = !!book.units.length ? book.units[0] : null
    const section = !!unit && unit.sections.length!! ? unit.sections[0] : null

    return {
      book,
      unit,
      section,
    }
  },

  changeLockUnit: async (classroomId: string, bookId: string, payload: any) => {
    return Service.put(`/api/classroom/${classroomId}/book/${bookId}/lock-units`, payload)
  },
}

export default ApiBookService
