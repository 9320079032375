import styled from 'styled-components'

export const Card = styled.div`
  border: 1px solid #000;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`

export const CardName = styled.span`
  font-size: 32px;
  height: 60px;
  display: block;
`

export const CardCode = styled.span`
  font-size: 46px;
  line-height: 48px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 32px;
  }
`

export const CardTag = styled.span`
  font-size: 12px;
  color: #888;

  @media (max-width: 768px) {
    font-size: 8px;
  }
`

export const CardPlaceholder = styled.div`
  width: 30%;

  @media (max-width: 768px) {
    width: 40%;
  }

  @media print {
    div {
      font-size: 8px;
    }
  }
`

export const Wrapper = styled.div`
  @page {
    size: A4;
    margin: 2cm;
  }

  .table, .cards {
    page-break-after: always;
  }
`

export const Steps = styled.ul`
  margin-top: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

export const Step = styled.ul`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StepIcon = styled.li`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  list-style-type: none;
  border: 1px solid black;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
  }
`

export const StepText = styled.li`
  font-size: 12px;
  list-style-type: none;

  @media (max-width: 768px) {
    font-size: 8px;
  }
`