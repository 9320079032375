import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { IoMdClose } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { Button, Container, Text } from 'cc-web-components'

import { TrialInfoIcon, TrialShopIcon } from '../Icons'

import { LicenseBarContainer, LicenseBarColumn, TrialDaysBar, RemainingDaysBar } from './styles'
import { License } from '@/redux/Session/types'

export const LICENSE_BAR_MIN_DAYS_TO_SHOW = 30
interface Props {
  license: License
  leftDays: number
  expiredLicense: boolean
  testLicense: boolean
  closeCallback: () => void
  shopCallback: () => void
  moreInfoCallback: () => void
}

interface TextVariations {
  description: string
  expiredDescription: string
  expirationLabel: string
}

const LicenseBar: React.FC<Props> = ({
  license,
  leftDays,
  expiredLicense,
  testLicense,
  closeCallback,
  shopCallback,
  moreInfoCallback,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation('cornelsen')

  const [textVarioations, setTexts] = useState({} as TextVariations)

  useEffect(() => {
    let description
    let expiredDescription
    let expirationLabel
    switch (license.type) {
      case 'CCWAE':
      case 'TESTLICENSE01':
        description = t('Globals.LicenseBar.TestDescription')
        expiredDescription = t('Globals.LicenseBar.ExpiredTestLicense')
        expirationLabel = t('Globals.LicenseBar.DaysExpirationTestText')
        break
      default:
        description = t('Globals.LicenseBar.Description')
        expiredDescription = t('Globals.LicenseBar.ExpiredLicense')
        expirationLabel = t('Globals.LicenseBar.DaysExpirationText')
    }

    setTexts({
      description,
      expiredDescription,
      expirationLabel,
    })
  }, [license, t])

  const activeLicenseContent = (
    <LicenseBarContainer>
      <LicenseBarColumn>
        <TrialInfoIcon />
        <Text value={textVarioations.description} ml="10px" fontSize={16} color="#fff" />
        <Text
          ml={2}
          value={`${leftDays} ${t('Globals.LicenseBar.DaysText')}.`}
          color="#fff"
          fontSize={16}
          fontWeight={600}
        />
      </LicenseBarColumn>
      <LicenseBarColumn>
        <div>
          <Text
            color="#fff"
            value={textVarioations.expirationLabel}
            fontSize={14}
            fontWeight={600}
          />
          <Text
            value={`${LICENSE_BAR_MIN_DAYS_TO_SHOW} ${t('Globals.LicenseBar.DaysText')}`}
            color="#fff"
            fontSize={14}
          />
        </div>
        <TrialDaysBar>
          <RemainingDaysBar totalDays={LICENSE_BAR_MIN_DAYS_TO_SHOW} leftDays={leftDays} />
        </TrialDaysBar>
      </LicenseBarColumn>
      <LicenseBarColumn>
        <Button
          type="button"
          variant="solid"
          value={t('Globals.LicenseBar.ButtonShop')}
          fontSize={18}
          fontWeight={400}
          height={40}
          mr="20px"
          padding="0 20px"
          leftContent={
            <Container mr={2}>
              <TrialShopIcon />
            </Container>
          }
          onClick={shopCallback}
        />
        <IoMdClose size={24} color={colors.white} cursor="pointer" onClick={closeCallback} />
      </LicenseBarColumn>
    </LicenseBarContainer>
  )

  const expiredLicenseContent = (
    <LicenseBarContainer>
      <LicenseBarColumn>
        <TrialInfoIcon />
        <Text value={textVarioations.expiredDescription} ml="10px" fontSize={16} color="#fff" />
      </LicenseBarColumn>
      <LicenseBarColumn>
        <Button
          type="button"
          variant="solid"
          value={t('Globals.LicenseBar.ButtonMoreDetails')}
          fontSize={18}
          fontWeight={400}
          height={40}
          mr="20px"
          padding="0 20px"
          onClick={moreInfoCallback}
        />
        <IoMdClose size={24} color={colors.white} cursor="pointer" onClick={closeCallback} />
      </LicenseBarColumn>
    </LicenseBarContainer>
  )

  return expiredLicense ? expiredLicenseContent : activeLicenseContent
}

export default LicenseBar
