import { createGlobalStyle } from 'styled-components'

import CV_SourceSans_Light from '../assets/fonts/CV_SourceSans_Light.ttf'
import CV_SourceSans_Regular from '../assets/fonts/CV_SourceSans_Regular.ttf'
import CV_SourceSans_Semibold from '../assets/fonts/CV_SourceSans_Semibold.ttf'
import CV_SourceSans_Bold from '../assets/fonts/CV_SourceSans_Bold.ttf'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "CV_Source_Sans";
    src: url(${CV_SourceSans_Light});
    font-weight: 300;
  }

  @font-face {
    font-family: "CV_Source_Sans";
    src: url(${CV_SourceSans_Regular});
    font-weight: 400;
  }

  @font-face {
    font-family: "CV_Source_Sans";
    src: url(${CV_SourceSans_Semibold});
    font-weight: 600;
  }

  @font-face {
    font-family: "CV_Source_Sans";
    src: url(${CV_SourceSans_Bold});
    font-weight: 700;
  }

  * {
    margin: 0;
    padding: 0;
  }

  body, input, button {
    font-family: "CV_Source_Sans";
  }

  aside.emoji-picker-react {
    position: absolute;
  }
`
