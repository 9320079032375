import ReactAudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import {BsPlayFill} from 'react-icons/bs';
import {MdPause} from 'react-icons/md';
import {AudioPlayerWrapper} from './styles';

type Props = {
  src: string;
};

const AudioPlayer: React.FC<Props> = ({src}: Props) => {
  return (
    <AudioPlayerWrapper>
      <ReactAudioPlayer
        src={src}
        preload="metadata"
        timeFormat="mm:ss"
        showJumpControls={false}
        showDownloadProgress={false}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        layout="horizontal-reverse"
        customIcons={{
          play: <BsPlayFill />,
          pause: <MdPause />,
        }}
      />
    </AudioPlayerWrapper>
  );
};

export default AudioPlayer;
