import {getIsHideFeaturesUser} from '@/redux/Session';
import {DemoBox} from '@cc/components';
import React from 'react';
import {useSelector} from 'react-redux';
import {Container} from 'cc-web-components';

export const Features: React.FC = () => {
  const IsShowFeaturesUser = !useSelector(getIsHideFeaturesUser);

  if (!IsShowFeaturesUser) {
    return null;
  }

  return (
    <Container alignItems="center" justifyContent="space-between" marginTop="30px">
      <DemoBox />
    </Container>
  );
};
