import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    z-index: 50;

    width: 100%;
    height: 100vh;

    background: #fff;
    padding: 15px;

    font-size: ${(props) => props.theme.fontSizes[2]}px;
    color: ${(props) => props.theme.colors.black};

`;