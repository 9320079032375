import React, {ReactNode, useMemo} from 'react';
import {StyledToggleButton, StyledToggleContainer, StyledToggleButtonContainer} from './styles';
import {Props} from './types';

export const ToggleButton: React.FC<Props> = ({
  values,
  onToggle,
  activeIndex,
  ...othersProps
}: Props) => {
  const options = useMemo(
    () =>
      values.map((item: ReactNode, index: number) => (
        <StyledToggleButtonContainer active={activeIndex === index} key={'toggleButton_' + index}>
          <StyledToggleButton onClick={() => onToggle(index)}>{item}</StyledToggleButton>
        </StyledToggleButtonContainer>
      )),
    [activeIndex, onToggle, values],
  );

  return <StyledToggleContainer {...othersProps}>{options}</StyledToggleContainer>;
};
