import Service from './apiConfig'
import { BookServiceResponse, Books } from './types/Books'

export type SingleBookRequestParam = {
  id: string
}

export type BookActivityDetailsRequestProps = {
  id: string
  type: string
}

type FilterRequestProps = {
  collection?: string
  subject?: string
  grade?: string
}

const ApiHomePageService = {
  getAllBooks: async (filter?: FilterRequestProps) => {
    const books = (await Service.get<BookServiceResponse>(`/api/books`, { params: { ...filter } }))
      .data
    const data: BookServiceResponse = []

    const sortedBooks = books.sort((a: any, b: any) => {
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0
    })

    sortedBooks.forEach((item) => {
      const id = item.id
      const name = item.name
      const configuration = item.configuration
      const content = item.content
      const calendar = item.calendar

      data.push({ id, name, configuration, content, calendar })
    })
    return data
  },
  getBookTasksByPage: async (params: BookActivityDetailsRequestProps) => {
    const { type, id } = params

    return (
      await Service.get(`/api/book/activity/${id}`, {
        params: { type },
      })
    ).data
  },
  getBookById: async (params: SingleBookRequestParam) => {
    const book = (await Service.get<Books>(`/api/book/${params.id}`)).data

    return book
  },
}

export default ApiHomePageService
