import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Route, Routes, useLocation} from 'react-router-dom';

import {
  HomePage,
  HealthPage,
  AuthPage,
  NewBooksPage,
  BooksContentPage,
  NewClassroomPage,
  DownloadPage,
  InformationPage,
  LogoutPage,
  PrintPage,
  SingleBookPage,
  StudentDetailsPage,
  StudentsPrintPage,
  LoginBspScreen,
  InstructionsBspPage,
  InstructionsPage,
} from '@cc/pages';
import {AssignActivityProvider} from '@/redux/AssignActivity';
import {setChannel, setIsAvvConfirmed} from '@/redux/Session';
import useQueryParams from '@/hooks/QueryParams';
import {Additions, PrivateRoute, UserMenu} from './components';
import {useIsAuthenticated} from './hooks';
import {Routes as AppRoutes} from '@cc/shared';
import {Loader} from '@cc/components';
import ApiUserService from '@/services/ApiCv/ApiUserService';

declare global {
  interface Window {
    _trbo: any;
  }
}

const {
  HEALTH,
  NEW_CLASSROOM,
  BOOK,
  NEW_BOOKS,
  LOGGED_OUT,
  CLASSROOM,
  STUDENT_SCORE,
  DOWNLOAD,
  IMPRINT,
  STUDENT_CODES,
  LOGIN_BSP,
  INSTRUCTIONS_BSP,
  TUTORIAL,
  WILDCARD,
} = AppRoutes;

export const Navigation: React.FC = () => {
  const dispatch = useDispatch();
  const QueryParams = useQueryParams();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  useEffect(() => {
    if (typeof window._trbo !== 'undefined' && window._trbo.app && window._trbo.app.reloadApi) {
      window._trbo.app.reloadApi(true, true);
    }
  }, [location]);

  useEffect(() => {
    dispatch(setChannel({channel: QueryParams.get('channel')}));
  }, [dispatch, QueryParams]);

  useEffect(() => {
    if (isAuthenticated) {
      ApiUserService.getIsAvvConfirmed()
        .then(data => {
          dispatch(setIsAvvConfirmed(data));
        })
        .catch(err => {
          const errorData = err.response?.data;
          if (errorData.status === 'AVV_NOT_SET') {
            dispatch(setIsAvvConfirmed({value: false}));
          }
        });
    }
  }, [dispatch, isAuthenticated]);

  if (isAuthenticated === undefined) {
    return <Loader isVisible={true} />;
  }

  return (
    <>
      <AssignActivityProvider>
        <UserMenu isAuthenticated={isAuthenticated} />
        <Routes>
          <Route path={HEALTH} element={<HealthPage />} />
          <Route path={LOGGED_OUT} element={<LogoutPage />} />
          <Route path={DOWNLOAD} element={<DownloadPage />} />
          <Route path={LOGIN_BSP} element={<LoginBspScreen />} />
          <Route
            path={INSTRUCTIONS_BSP}
            element={<InstructionsBspPage isAuthenticated={isAuthenticated} />}
          />
          <Route path={TUTORIAL} element={<InstructionsPage isAuthenticated={isAuthenticated} />} />
          <Route path="/" element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/c/auth/:jwt" element={<AuthPage />} />
            <Route path={`${NEW_CLASSROOM}/:classroomId`} element={<NewClassroomPage />} />
            <Route path={`${BOOK}/:id`} element={<SingleBookPage />} />
            <Route path={`${NEW_BOOKS}/:id`} element={<BooksContentPage />} />
            <Route path={NEW_BOOKS} element={<NewBooksPage />} />
            <Route
              path={`${CLASSROOM}/:classroomId/student/:studentId?`}
              element={<StudentDetailsPage />}
            />
            <Route path={STUDENT_SCORE} element={<InformationPage />} />
            <Route path={IMPRINT} element={<PrintPage />} />
            <Route path={`${STUDENT_CODES}/:classroomId`} element={<StudentsPrintPage />} />
            <Route path={WILDCARD} element={<HomePage />} />
          </Route>
        </Routes>
      </AssignActivityProvider>
      <Additions />
    </>
  );
};
