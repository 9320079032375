import React from 'react'
import { Container, Switch, Text } from 'cc-web-components'
import { useTranslation } from 'react-i18next'

import { useCreateClassroom } from '../../Context'

type Props = {
  setButtonActive: (buttonActive: boolean) => void
}

const StepThree = ({ setButtonActive }: Props) => {
  const { t } = useTranslation('cornelsen')
  const { createClassroomData, setCreateClassroomData } = useCreateClassroom()

  const handleFeedbackSwitch = (event: any) => {
    setCreateClassroomData({
      ...createClassroomData,
      feedback: event.target.checked,
    })
  }

  // const handleQuizSwitch = (event: any) => {
  //   setCreateClassroomData({
  //     ...createClassroomData,
  //     quiz: event.target.checked,
  //   })
  // }

  return (
    <Container flexDirection="column">
      <Container flexDirection="column" background="#fff" padding={3} pt={4} pb={4} mb={2}>
        <Text
          mb={3}
          size={16}
          fontWeight={600}
          value={t('AssignActivity.Tabs.NewClass.Step3.FeedbackTitle')}
        />
        <Text
          mb={3}
          size={16}
          fontWeight={400}
          value={t('AssignActivity.Tabs.NewClass.Step3.FeedbackDescription')}
        />
        <Container flexDirection="row" alignItems="center" justifyContent="space-between">
          <Text
            mb={2}
            size={14}
            fontWeight={400}
            value={t('AssignActivity.Tabs.NewClass.Step3.FeedbackLabel')}
            color="#1a73e8"
          />
          <Switch onChange={handleFeedbackSwitch} checked={createClassroomData.feedback} />
        </Container>
      </Container>

      {/* <Container flexDirection="column" background="#fff" padding={3} pt={4} pb={4}>
        <Text
          mb={3}
          size={16}
          fontWeight={600}
          value={t('AssignActivity.Tabs.NewClass.Step3.QuizTitle')}
        />
        <Text
          mb={3}
          size={16}
          fontWeight={400}
          value={t('AssignActivity.Tabs.NewClass.Step3.QuizDescription')}
        />
        <Container flexDirection="row" alignItems="center" justifyContent="space-between">
          <Text
            mb={2}
            size={14}
            fontWeight={400}
            value={t('AssignActivity.Tabs.NewClass.Step3.QuizLabel')}
            color="#1a73e8"
          />
          <Switch onChange={handleQuizSwitch} checked={createClassroomData.quiz} />
        </Container>
      </Container> */}
    </Container>
  )
}

export default StepThree
