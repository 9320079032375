import React, { useRef, useState } from 'react'
import Picker from 'emoji-picker-react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, Container, Input } from 'cc-web-components'
import { useOutsideClick } from '@/hooks/OutsideClick'

import Helpers from '@/helper'
import useDeviceDetection from '@/hooks/IsMobile'
import { HiOutlineEmojiHappy } from 'react-icons/hi'
import { SendOutlinedIcon, UserIcon } from '@/components/Icons'
import { PickerWrapper } from './styles'

type Props = {
  onSubmit: (text: string) => void
  buttonsWords?: { value: string }[]
  clearAfterSend?: boolean
  hasFeedbackError?: boolean
  feedbackErrorText?: string
}

const FormText: React.FC<Props> = ({
  onSubmit,
  buttonsWords,
  clearAfterSend,
  hasFeedbackError,
  feedbackErrorText,
}: Props) => {
  const { t } = useTranslation('cornelsen')
  const { colors } = useTheme()
  const { isMobile } = useDeviceDetection()
  const emojiPickerRef = useRef<any>()

  const [commentText, setCommentText] = useState('')
  const [isShowEmojies, setShowEmojies] = useState(false)

  useOutsideClick(emojiPickerRef, () => {
    if (isShowEmojies) setShowEmojies(false)
  })

  const onChangeComment = (text: string) => {
    setCommentText((prev) => prev + ' ' + text)
  }

  const handleSubmitComment = async () => {
    if (hasFeedbackError && feedbackErrorText) {
      Helpers.showNotification(t('Globals.Notification.Error.Title'), feedbackErrorText, 'danger')

      return
    }

    onSubmit(commentText)

    if (clearAfterSend === true) {
      setCommentText('')
    }
  }

  const RenderButtonWords = () => (
    <Container flex={1} alignItems="center" flexDirection={isMobile ? 'column' : 'row'}>
      {!!buttonsWords &&
        buttonsWords.map((item, index) => {
          return (
            <Container key={index} mb={[2, 2, 0, 0]}>
              <Button
                value={item.value}
                variant="link"
                onClick={() => onChangeComment(item.value)}
                fontSize={14}
                marginRight={10}
                style={{
                  height: 35,
                  fontSize: 13,
                  color: '#999',
                  fontWeight: 500,
                  border: '1px solid #ddd',
                }}
              />
            </Container>
          )
        })}
    </Container>
  )

  return (
    <Container
      flex={1}
      paddingTop={3}
      flexDirection="column"
      borderTop="1px solid #eee"
      marginTop={2}
    >
      <Container
        my={3}
        display="flex"
        position="relative"
        alignItems="center"
        borderBottom="1px solid #ddd"
        flexDirection="row"
        paddingBottom={4}
      >
        <UserIcon />

        <Input
          flex={1}
          marginLeft={3}
          backgroundColor="#e8effd"
          border="none"
          style={{ borderRadius: 25, flex: 1, backgroundColor: '#e8effd', border: 'none' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCommentText(event.target.value)
          }}
          placeholder={t('Globals.FormFeedback.Comment.ToStartText')}
          value={commentText}
          sideContent={
            <Button
              variant="icon"
              style={{ backgroundColor: 'transparent', border: 'none' }}
              value={<HiOutlineEmojiHappy size={20} color={colors.gray[2]} />}
              onClick={() => setShowEmojies((prev) => !prev)}
            />
          }
        />

        {isShowEmojies && (
          <PickerWrapper ref={emojiPickerRef}>
            <Picker
              native
              disableSearchBar={true}
              disableSkinTonePicker={true}
              groupVisibility={{
                flags: false,
              }}
              onEmojiClick={(_, { emoji }) => {
                onChangeComment(emoji)

                setShowEmojies((prev) => !prev)
              }}
            />
          </PickerWrapper>
        )}
      </Container>

      <Container
        my={[0, 0, 3, 3]}
        alignItems="center"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <RenderButtonWords />

        <Button
          value={t('Globals.FormFeedback.ButtonSendText')}
          variant="solid-min"
          onClick={handleSubmitComment}
          height={44}
          mt={[2, 2, 0, 0]}
          fontSize={16}
          fontWeight={400}
          leftContent={
            <Container mr={2}>
              <SendOutlinedIcon width={20} />
            </Container>
          }
        />
      </Container>
    </Container>
  )
}

export default FormText
