import React from 'react';
import {useTheme} from 'styled-components';
import {Button, Card, Container, Page, Text} from 'cc-web-components';

import Footer from '@/components/Footer';

import BookCoverImage from '@/assets/images/ico_info_lined.png';
import {BookCover} from './styles';
import BgBlue from '@/assets/images/bg-blue.png';

const titleStyle = {
  fontSize: '18px',
  color: '#00aa66',
  marginTop: '32px',
  fontWeight: '600',
};

export const InformationPage: React.FC = () => {
  const {colors} = useTheme();

  const onBackPage = () => {
    window.history.back();
  };

  const renderHeader = () => (
    <Container alignItems="center" width="100%">
      <Button
        color={colors.black}
        value="ChatClass Allgemein"
        onClick={() => {}}
        variant="tab"
        fontWeight="600"
        m="auto"
      />
    </Container>
  );

  return (
    <Page
      avatar={<BookCover src={BookCoverImage} />}
      onClickBack={onBackPage}
      title="Hilfebereich"
      titleSize={40}
      subTitle="Student Health Score"
      headerContent={renderHeader()}
      backgroundImage={BgBlue}
      footerContent={<Footer />}>
      <Container flexDirection="column" mb={4} width="100%">
        <Text
          size={24}
          value="Student Health Score"
          fontWeight={600}
          mt={[2, 2, 4, 4]}
          mb={4}
          ml={[2, 2, 3, 3]}
        />
        <Card flexDirection="column">
          <Container flexDirection="column" ml={[1, 1, 4, 4]} mr={[1, 1, 4, 4]} mb={[4, 4, 5, 5]}>
            <Text value="Was macht eine-/n Schüler-/in „healthy“" style={titleStyle} />

            <Text
              value="Was ist der Student Health Score (SHS)?"
              size={16}
              fontWeight={600}
              mt={3}
            />
            <Text
              size={16}
              lineHeight="22px"
              value="Ein SHS zeigt, welche Schüler/-in besondere Aufmerksamkeit benötigen - bevor sich ihre Leistung deutlich verschlechtert. Der Student Health Score ergibt sich aus einer Anzahl von Metriken und ist bezogen auf einen Zeitraum von 7 Tagen."
            />

            <Text
              value="Wie funktioniert der Student Health Score?"
              size={16}
              fontWeight={600}
              mt={3}
            />
            <Text
              size={16}
              lineHeight="22px"
              value="Der SHS liest eine Vielzahl von Kompetenzen, die den Lernprozess der Schüler/-in reflektieren. Durch den SHS wird aufgezeigt, in welcher Kompetenz die Schüler/-in positiv oder negativ performen. Die Anzahl der Metriken, auf welchen der Student Health Score basiert, haben sich als starke Indikatoren für den Wert von Chat Class erwiesen."
            />

            <Text value="Key Metriken" style={titleStyle} />

            <Text
              value="Welche Kompetenzen & Fähigkeiten spiegelt der Student Health Score wieder?"
              size={16}
              fontWeight={600}
              mt={3}
            />
            <Text
              size={16}
              lineHeight="22px"
              value='Die wichtigste Metrik des SHS ist die "Korrektheit". Diese ergibt sich aus der Zahl der gelösten Aufgaben und der Korrektheit der Antworten. Je korrekter, desto besser. Jede/-r Schüler/-in ist individuell, so ist auch sein Lernansatz, daher geben wir dieser Metrik eine Dynamik und passen sie dem Lernverhalten der oder die Schüler/-in an durch eine Levelvergabe.'
            />

            <Text
              value='Neben der "Korrektheit" Metrik gibt es noch die folgenden Metriken:'
              size={16}
              fontWeight={600}
              mt={3}
              style={{borderBottom: '1px solid', width: 'max-content'}}
            />

            <p style={{fontSize: '16px', marginTop: '16px'}}>
              <strong>Aktivität</strong> - Wie viele Übungen die Schüler/-in machen.
            </p>
            <p style={{fontSize: '16px'}}>
              <strong>Regelmäßigkeit</strong> - Wie oft setzt sich ein/-e Schüler/-in über einen
              festgelegten Zeitraum auf sinnvolle Weise mit ChatClass auseinander.
            </p>
            <p style={{fontSize: '16px'}}>
              <strong>Berührungspunkte (Touch-points)</strong> - Wie oft nehmen die Schüler/-in am
              Quiz teil oder beteiligen sich beim Peer-Feedback.
            </p>

            <Text value="Vergabe der Level" style={titleStyle} />

            <Text
              value="Was bedeutet die Levelvergabe im Student Health Score?"
              size={16}
              fontWeight={600}
              mt={3}
            />
            <Text
              size={16}
              lineHeight="22px"
              value="Die Schülerinnen und Schüler haben unterschiedliche Motivationsniveaus, unterschiedliche Einstellungen zum Lernen und unterschiedliche Reaktionen auf bestimmte Unterrichtspraktiken."
            />
            <Text
              value="Mit dem SHS möchten wir die unterschiedlichen Lernbedürfnisse aller Ihrer Schüler/-innen berücksichtigen."
              size={16}
              fontWeight={600}
            />

            <Text
              value="Die Lehrerinnen und Lehrer können Ihren Schüler/-innen ein Lernstand-Level zuweisen und der SHS wird sich dynamisch diesem Level anpassen. Eine Punktzahl und Farbe erzählen nur die halbe Geschichte über die Performance der Schüler/-in. Ein Schüler/-in mit einem Score von 5,5 bedeutet, dass er gelb ist. Wenn sein Score  in der Vorwoche jedoch 4,5 betrug, ist dies eine gute Entwicklung."
              size={16}
              mt={3}
            />

            <Text value="Differenzierung der Level" style={titleStyle} />

            <Text
              value="Welche Level kann der Student Health Score vergeben?"
              size={16}
              fontWeight={600}
              mt={3}
            />
            <Text
              size={16}
              lineHeight="22px"
              value="Die Schüler/-innen sind in ihren Leistungen sehr unterschiedlich und dies sollte sich auch durch Niveau-Stufen (Level) widerspiegeln. So bekommt jeder Schüler/-in die Möglichkeit, einen Student Health Score in Bezug auf seinen eigenen Leistungsrahmen zu erhalten."
            />

            <p style={{fontSize: '16px', marginTop: '16px'}}>
              <strong>Beginner</strong> - Elementare Sprachverwendung (Grundlegende
              Sprachkenntnisse)
            </p>
            <p style={{fontSize: '16px'}}>
              <strong>Intermediate</strong> - Selbstständige Sprachverwendung (Fortgeschrittene
              Sprachkenntnisse)
            </p>
            <p style={{fontSize: '16px'}}>
              <strong>Advanced</strong> - Kompetente Sprachverwendung (Fachkundige Sprachkenntnisse)
            </p>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};
