import React, { useState } from 'react'
import { Table, Button, Container, List } from 'cc-web-components'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'

type Props = {
  data?: any[]
}

const TableBooks: React.FC<Props> = ({ data }: Props) => {
  const [activeContentIndex, setActiveContentIndex] = useState(-1)
  const todos = data?.map((item) => item.todos)

  const openExtraContent = (index: number) => {
    if (activeContentIndex === index) {
      setActiveContentIndex(-1)
    } else {
      setActiveContentIndex(index)
    }
  }

  const renderList = (pages: string, title: string) => {
    return (
      <Container>
        <>Seite {pages}</>
        <>{title}</>
      </Container>
    )
  }

  const renderExtraContent = (index: number) => {
    if (todos) {
      const currentTodo = index < 0 ? todos[0] : todos[index]
      return (
        <List
          items={currentTodo}
          m={3}
          renderItem={(item: any) => renderList(item.pages, item.title)}
        />
      )
    }
  }

  return (
    <Container>
      <Table
        columns={[
          {
            renderItem: (item: any, index: number) => {
              return (
                <Container width={200}>
                  <Button
                    variant="link"
                    leftContent={
                      activeContentIndex === index ? (
                        <IoChevronUp size={32} />
                      ) : (
                        <IoChevronDown size={32} />
                      )
                    }
                    onClick={() => openExtraContent(index)}
                  />
                  {item.name}
                </Container>
              )
            },
            width: 300,
          },
        ]}
        data={data}
        m={3}
        indexOpenContent={activeContentIndex}
        renderExtraContent={() => renderExtraContent(activeContentIndex)}
      />
    </Container>
  )
}

export default TableBooks
