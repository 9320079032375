import React, {ChangeEvent, useEffect, useState, useRef} from 'react';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Container, Text, Select, Input, InputCheckbox} from 'cc-web-components';
import debounce from 'lodash.debounce';

import {Student} from '@/services/ApiCv/types/Classroom';
import {Classroom} from '@/services/ApiCv/types/Classroom';
import ApiClassroomService from '@/services/ApiCv/ApiClassroomService';
import {useAssignActivityContext} from '@/redux/AssignActivity';

type Props = {
  classId: string;
  studentId?: string;
  setButtonActive: (buttonActive: boolean) => void;
};

const TASK_NAME_LENGHT = 80;
const TASK_INFO_LENGHT = 160;

const StepTwo: React.FC<Props> = ({classId, studentId, setButtonActive}: Props) => {
  const {t} = useTranslation('cornelsen');
  const {colors} = useTheme();
  const [{playlist, playlistBookId}, {updatePayloadForm}] = useAssignActivityContext();

  const [students, setStudents] = useState<Student[]>([]);
  const [classrooms, setClassrooms] = useState<Classroom[]>([]);
  const [listName, setListName] = useState(() => {
    if (!!playlist) {
      return playlist.info.name;
    }

    return 'Vokabeltest';
  });
  const [taskInfos, setTaskInfos] = useState(() => {
    if (!!playlist) {
      return playlist.info.teacher_message;
    }

    return 'Viel Erfolg!';
  });
  const [classroomIndex, setClassroomIndex] = useState(-1);
  const [allStudentsSelected, setAllStudentsSelected] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState(0);
  const [matchedIndex, setMatchedIndex] = useState(-1);

  const debouceChangeNamePayload = useRef(
    debounce((payload: any) => updatePayloadForm(payload), 500),
  ).current;

  const debouceChangePayload = useRef(
    debounce((payload: any) => updatePayloadForm(payload), 500),
  ).current;

  useEffect(() => {
    if (!playlistBookId) return;
    ApiClassroomService.getAll()
      .then(classrooms => {
        setClassrooms(
          classrooms.filter(item => !item.license?.expired && item.course_id === playlistBookId),
        );
        setClassroomIndex(-1);
        setStudents([]);
        setSelectedStudents(0);
      })
      .catch(() => setClassrooms([]));
  }, [playlistBookId]);

  useEffect(() => {
    if (!!classrooms.length && matchedIndex >= 0) {
      setClassroomIndex(matchedIndex);

      ApiClassroomService.getStudentsById(classrooms[matchedIndex].id)
        .then(students => {
          if (!!playlist) {
            const studentdIds = playlist.students.map(item => item.user.id);

            students.forEach(item => {
              item.student.selected = studentdIds.includes(item.student.id);
            });
            setStudents(students.filter(item => !!item.student.id));
          } else {
            setStudents(
              students
                .filter(item => !!item.student.id)
                .map(item => ({...item, student: {...item.student, selected: true}})),
            );
          }
        })
        .catch(() => setStudents([]));
    }
  }, [classrooms, matchedIndex, playlist, studentId]);

  useEffect(() => {
    if (!!playlist) {
      return;
    }

    if (!classrooms.length || !classId) return;

    const findedIndex = classrooms.findIndex(item => item.id === classId);

    if (findedIndex < 0) return;

    const classroom_id = classrooms[findedIndex].id;
    setMatchedIndex(findedIndex);
    updatePayloadForm({classroom_id});
  }, [classrooms, classId, playlist, updatePayloadForm]);

  useEffect(() => {
    if (!!students.length) {
      const studentsSelecteds = students.filter(item => item.student.selected);

      const isAllStudentsSelected = students.length === studentsSelecteds.length;

      const studentsId = studentsSelecteds
        .filter(item => !!item.student.id)
        .map(item => item.student.id);

      updatePayloadForm({student_ids: studentsId});

      setAllStudentsSelected(isAllStudentsSelected);
    }
  }, [students, updatePayloadForm]);

  useEffect(() => {
    if (!playlist || !classrooms.length) {
      return;
    }

    const classroomIndex = classrooms.findIndex(item => item.id === playlist.info.classroom_id);
    setMatchedIndex(classroomIndex);
  }, [playlist, classrooms]);

  useEffect(() => {
    const hasAnyStudentSelecteds = !!students.filter(item => item.student.selected).length;

    if (hasAnyStudentSelecteds && listName !== '') {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }

    if (!listName) {
      setButtonActive(false);
    }
  }, [listName, setButtonActive, students]);

  const onSelectClassroom = (index: number, classroom: Classroom) => {
    const {id} = classroom;

    setClassroomIndex(index);

    updatePayloadForm({classroom_id: id});

    ApiClassroomService.getStudentsById(classroom.id)
      .then(students =>
        setStudents(
          students
            .filter(item => !!item.student.id)
            .map(item => ({
              ...item,
              student: {
                ...item.student,
                selected: true,
              },
            })),
        ),
      )
      .catch(() => setStudents([]));
  };

  const onSelectStudent = (index: number, checked: boolean) => {
    const data = [...students];

    data[index].student.selected = checked;

    setStudents(data);

    if (checked) setSelectedStudents(selectedStudents + 1);
    else setSelectedStudents(selectedStudents - 1);
  };

  const onSelectAllStudents = (checked: boolean) => {
    setStudents(students =>
      students.map(item => {
        item.student.selected = checked;

        return item;
      }),
    );

    setAllStudentsSelected(checked);
  };

  const alphabeticSort = (a: Student, b: Student) => {
    const nameA = a.student.name;
    const nameB = b.student.name;
    return nameA.localeCompare(nameB, undefined, {sensitivity: 'base', numeric: true});
  };

  const RenderStudents = () => (
    <Container paddingLeft={4} paddingRight={3} flexDirection="column">
      {students.sort(alphabeticSort).map((item, index) => {
        return item.student.id ? (
          <Container
            key={index}
            alignItems="center"
            flexDirection="row"
            borderBottom={'1px solid #c5c5c5'}
            paddingTop={2}
            paddingBottom={2}>
            {item.student.emoji}

            <Container flex={1} marginLeft={2} flexDirection="column">
              <Text value={item.student.name} />

              <Text
                size={12}
                color={colors.gray[2]}
                value={t(`Globals.Types.StudentLevel.${item.student.level}`)}
              />
            </Container>

            <InputCheckbox
              checked={item.student.selected}
              disabled={false}
              onChange={checked => onSelectStudent(index, checked)}
            />
          </Container>
        ) : null;
      })}
    </Container>
  );

  const onChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;

    setListName(value);

    debouceChangeNamePayload({name: value});
  };

  const onChangeTaskInfo = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = event.target;

    if (value.length <= TASK_INFO_LENGHT) {
      setTaskInfos(value);

      debouceChangePayload({teacher_message: value});
    }
  };

  const getActiveStudentLength = () => {
    let length = 0;

    for (const item of students) {
      if (item.student.id) {
        length = length += 1;
      }
    }

    return length;
  };

  return (
    <Container flex={1} flexDirection="column">
      <Container padding={3} flexDirection="column">
        <Text
          mb={2}
          size={16}
          fontWeight={600}
          value={t('AssignActivity.Tabs.NewTask.Step2.SectionTaskTitle')}
        />

        <Container pl={1} mb={2} flexDirection="column">
          <Text
            mt={2}
            mb={2}
            size={14}
            value={t('AssignActivity.Tabs.NewTask.Step2.FieldClass')}
            color={colors.gray[1]}
          />

          <Select
            items={classrooms}
            renderItem={(item: Classroom) => item?.display_name}
            onSelectItem={(classroom: Classroom, index: number) =>
              onSelectClassroom(index, classroom)
            }
            placeholder={t('AssignActivity.Tabs.NewTask.Step2.FieldClassPlaceholder')}
            selectedIndex={classroomIndex}
            isDisabled={!!classId}
          />
        </Container>

        <Container pl={1} mb={2} flexDirection="column">
          <Container mt={1} mb={2} alignItems="center" justifyContent="space-between">
            <Text
              fontWeight={600}
              size={16}
              value={t('AssignActivity.Tabs.NewTask.Step2.FieldListName')}
            />

            <Text
              size={12}
              value={`${listName?.length || 0} / ${TASK_NAME_LENGHT}`}
              color={colors.gray[2]}
            />
          </Container>

          <Input
            style={{borderRadius: 4}}
            value={listName}
            onChange={onChangeTitle}
            placeholder={t('AssignActivity.Tabs.NewTask.Step2.FieldListNamePlaceholder')}
            maxLength={80}
          />
        </Container>

        <Container pl={1} flexDirection="column">
          <Container mt={1} mb={2} alignItems="center" justifyContent="space-between">
            <Text value={t('AssignActivity.Tabs.NewTask.Step2.FieldInformation')} />

            <Text
              size={12}
              value={`${taskInfos?.length || 0} / ${TASK_INFO_LENGHT}`}
              color={colors.gray[2]}
            />
          </Container>

          <textarea
            style={{
              border: '1px solid #ddd',
              borderRadius: 4,
              padding: '16px 20px',
              color: '#333',
              fontSize: '14px',
              fontFamily: 'CV_Source_Sans',
              resize: 'vertical',
            }}
            value={taskInfos}
            onChange={onChangeTaskInfo}
            rows={3}
          />
        </Container>
      </Container>

      <Container
        pl={3}
        pr={3}
        minHeight={48}
        backgroundColor="#f7f7f7"
        alignItems="center"
        justifyContent="space-between">
        <Text
          size={16}
          fontWeight={600}
          value={`${t(
            'AssignActivity.Tabs.NewTask.Step2.SectionStudents',
          )} (${getActiveStudentLength()})`}
        />

        <Container flex={1} alignItems="center" justifyContent="flex-end">
          <InputCheckbox
            labelLeft={
              <Text
                size={12}
                marginRight={10}
                value={t('AssignActivity.Tabs.NewTask.Step2.FieldSelectAllText')}
              />
            }
            checked={allStudentsSelected}
            disabled={!students.length}
            onChange={checked => onSelectAllStudents(checked)}
          />
        </Container>
      </Container>

      {!!students.length && <RenderStudents />}
    </Container>
  );
};

export default StepTwo;
