import {Container, Text} from 'cc-web-components';
import React from 'react';

import {StyledScoreIconCount} from './styles';
import {Props} from './types';

const ScoreIconCount: React.FC<Props> = ({color, score}) => (
  <Container justifyContent="center" alignItems="center" style={{gap: '4px'}}>
    <StyledScoreIconCount color={color}>
      {color === 'green' && 'High'}
      {color === 'yellow' && 'Mid'}
      {color === 'red' && 'Low'}
    </StyledScoreIconCount>

    {(score || score === 0) && <Text color="#003B0A" value={score} fontWeight={600} />}
  </Container>
);

export default ScoreIconCount;
