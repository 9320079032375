import React from 'react'

import { StyledContainer, StyledSeparator } from '../styles'

type SeparatorsIntervalProps = {
  rotation: number
  circleRatio: number
  numberOfSeparators: number
}

const SeparatorsInterval: React.FC<SeparatorsIntervalProps> = ({
  rotation,
  circleRatio,
  numberOfSeparators,
}: SeparatorsIntervalProps) => {
  const itemsOfSeparators = Array.from(Array(numberOfSeparators + 1).keys())

  return (
    <>
      {itemsOfSeparators.map((value) => {
        const rotate = (circleRatio / numberOfSeparators) * value + rotation

        return (
          <StyledContainer key={value} rotate={rotate}>
            <StyledSeparator />
          </StyledContainer>
        )
      })}
    </>
  )
}

export default SeparatorsInterval
