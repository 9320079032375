import React from 'react'

import { Container, Button } from 'cc-web-components'
import { VscArrowRight } from 'react-icons/vsc'

type Props = {
  showIconNext?: boolean
  onClickButtonNext: () => void
  onClickButtonBack: () => void
  buttonNextValue: string
  buttonBackValue: string
  buttonNextDisabled: boolean
}

const Actions: React.FC<Props> = (props: Props) => {
  return (
    <Container
      paddingTop={4}
      paddingBottom={3}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Button
        fontSize={16}
        fontWeight={500}
        minWidth={170}
        type="button"
        variant="solid"
        value={props.buttonNextValue}
        onClick={props.onClickButtonNext}
        leftContent={
          props.showIconNext !== false && (
            <Container marginRight={2}>
              <VscArrowRight size={19} />
            </Container>
          )
        }
        style={{ opacity: props.buttonNextDisabled ? 0.5 : 1 }}
        disabled={props.buttonNextDisabled}
      />

      <Button
        type="button"
        value={props.buttonBackValue}
        minWidth={170}
        variant="link"
        onClick={props.onClickButtonBack}
        fontSize={16}
        marginTop={3}
        fontWeight={500}
        style={{
          backgroundColor: '#FFFFFF',
          boxShadow: '0 0 0 1px rgba(0,0,0,0.05), 0 3px 7px 0 rgba(0,0,0,0.10)',
          borderRadius: '20px',
        }}
      />
    </Container>
  )
}

export default Actions
