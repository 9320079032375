import styled, { css } from 'styled-components';

export const ColorSelectorList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
`;

export const ColorSelectorItem = styled.li<{
  active: boolean;
}>`
  width: 60px;
  height: 60px;
  margin: 4px;
  box-shadow: 0px 3px 7px rgb(0, 0, 0,.1);
  cursor: pointer;

  ${props => props.active && css`
    margin: 0;
    border: 2px solid #1A73E8;
  `}

  img {
    width: 100%;
    height: 100%;
  }
`
