import {Text} from '@cc/components';
import styled from '@emotion/styled';

export const CustomText = styled(Text)`
  flex: 1;
  display: flex;
  justify-content: center;
  ${({theme}) => theme.typography.mediumHeading050};
  font-weight: 700;
`;
