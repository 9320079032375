import React, {useState} from 'react';
import {useTheme} from 'styled-components';
import {IoMdClose} from 'react-icons/io';
import {Container, Hr, Text} from 'cc-web-components';
import {IoInformationCircleOutline} from 'react-icons/io5';
import {useTranslation} from 'react-i18next';

import useDeviceDetection from '@/hooks/IsMobile';

import {
  InfoBoxContainer,
  InfoBoxContent,
  InfoBoxIcon,
  InfoBoxClose,
  InfoBoxBackdrop,
} from './styles';
import {WorkSpokenIcon} from '@/components/Icons';

const PronunciationScore: React.FC = () => {
  const {t} = useTranslation('cornelsen');
  const {colors} = useTheme();
  const {isMobile} = useDeviceDetection();

  const [isOpen, setOpen] = useState(false);

  return (
    <InfoBoxContainer>
      <InfoBoxIcon>
        <IoInformationCircleOutline
          onClick={event => {
            event.stopPropagation();
            setOpen(true);
          }}
          onMouseEnter={() => !isMobile && setOpen(true)}
          onMouseLeave={() => !isMobile && setOpen(false)}
          color={colors.primary}
          size={20}
          width={15}
          tabIndex={0}
        />
      </InfoBoxIcon>
      <InfoBoxContent isOpen={isOpen}>
        <InfoBoxClose
          onClick={event => {
            event.stopPropagation();
            setOpen(false);
          }}
        >
          <IoMdClose size={24} color={colors.gray[2]} />
        </InfoBoxClose>
        {
          <>
            <Container alignItems="center">
              <WorkSpokenIcon width={20} />
              <Text
                size={16}
                fontWeight={600}
                value={t('Globals.PronunciationScore.InfoText.PronunciationPracticeTitle')}
              />
            </Container>

            <p
              style={{marginBottom: '8px'}}
              dangerouslySetInnerHTML={{
                __html: t('Globals.PronunciationScore.InfoText.PronunciationPracticeText'),
              }}
            />

            <Hr />

            <Text
              size={16}
              fontWeight={600}
              value={t('Globals.PronunciationScore.InfoText.WordAccuracyTitle')}
              mt={2}
            />
            <Text
              size={16}
              value={t('Globals.PronunciationScore.InfoText.WordAccuracyText')}
              mb={2}
            />

            <Hr />

            <Text
              size={16}
              fontWeight={600}
              value={t('Globals.PronunciationScore.InfoText.FluencyTitle')}
              mt={2}
            />
            <Text size={16} value={t('Globals.PronunciationScore.InfoText.FluencyText')} mb={2} />

            <Hr />

            <Text
              size={16}
              fontWeight={600}
              value={t('Globals.PronunciationScore.InfoText.CompletenessTitle')}
              mt={2}
            />
            <Text size={16} value={t('Globals.PronunciationScore.InfoText.CompletenessText')} />
          </>
        }
      </InfoBoxContent>
      <InfoBoxBackdrop
        isOpen={isOpen}
        onClick={event => {
          event.stopPropagation();
          setOpen(false);
        }}
      />
    </InfoBoxContainer>
  );
};

export default PronunciationScore;
