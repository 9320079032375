import styled from 'styled-components'

export const UserIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: rgb(171, 128, 197);

  @media (max-width: 425px) {
     margin-left: -20px;
  }
  `
  