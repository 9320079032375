import React, {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {Routes} from '@cc/shared';
import {openExternalLink} from '@cc/utils';

const {LOGIN} = Routes;

interface Props {
  isAuthenticated?: boolean;
}

const redirectToLogin = () => {
  openExternalLink(window._env_.REACT_APP_CORNELSEN_URL + LOGIN);
};

export const PrivateRoute: React.FC<Props> = ({isAuthenticated}) => {
  useEffect(() => {
    if (isAuthenticated === false) {
      redirectToLogin();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  return <Outlet />;
};
