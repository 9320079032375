import styled from 'styled-components';

export const Container = styled.div`
  padding: 28px;
`;

export const Book = styled.div`
  cursor: pointer;
  border: 1px solid #b8b8b8;
  img {
    width: 100%;
    height: 100%;
  }
`;
