import React from 'react';
import {Container, Header, InstructionsContainer, Image} from './styles';
import {SmartBanner} from './components';
import {LoginSvg, CheckSvg} from '@cc/assets';
import {useTranslation} from 'react-i18next';
import {StoreInfo} from '@/components/StoreInfo';
import {Instruction} from '@/components/Instruction';
import ChatclassLogo from '@/assets/mobile/icon-4x.png';

const instructions = [
  {
    preText: '1.',
    tKey: 'download',
    icon: <Image src={ChatclassLogo} />,
    iconWithoutWrapper: true,
    bottomContent: <StoreInfo showIcon={false} app="chatclass" />,
    addShadow: true,
  },
  {
    preText: '2.',
    tKey: 'login',
    icon: <LoginSvg />,
  },
  {
    preText: '3.',
    tKey: 'use',
    icon: <CheckSvg />,
  },
];

interface Props {
  isAuthenticated?: boolean;
}

export const InstructionsBspPage: React.FC<Props> = ({isAuthenticated}) => {
  const {t} = useTranslation('instructionsBsp');

  return (
    <Container isAuthenticated={isAuthenticated}>
      <SmartBanner />
      <Header content={t('title')} />
      <InstructionsContainer>
        {instructions.map(({icon, preText, tKey, bottomContent, iconWithoutWrapper, addShadow}) => (
          <Instruction
            icon={icon}
            key={preText}
            text={t(tKey)}
            preText={preText}
            bottomContent={bottomContent}
            iconWithoutWrapper={iconWithoutWrapper}
            addShadow={addShadow}
          />
        ))}
      </InstructionsContainer>
    </Container>
  );
};
