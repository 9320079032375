// import {selectIsFeatureAvailable} from '@/redux/Rights';
import React, {useEffect, useMemo, useState} from 'react';
// import {useSelector} from 'react-redux';
// import {CustomText} from './styles';
import {Select} from 'cc-web-components';
import {useTranslation} from 'react-i18next';
import {useCreateClassroom} from '../../../../Context';
// import {EMPTY_STRING} from '@cc/shared';

export const TeacherStatus: React.FC = () => {
  const {t} = useTranslation('cornelsen');
  const [selectIndex, setSelectIndex] = useState(-1);
  const {createClassroomData, setCreateClassroomData} = useCreateClassroom();
  // const canUpdate = useSelector(selectIsFeatureAvailable('CLASSROOM', 'U'));
  const TEACHER_TITLES = useMemo(
    () => [
      t('AssignActivity.Tabs.NewClass.Step1.TeacherTitle.Herr'),
      t('AssignActivity.Tabs.NewClass.Step1.TeacherTitle.Frau'),
    ],
    [t],
  );

  useEffect(() => {
    const index = TEACHER_TITLES.findIndex(title => title === createClassroomData.teacher_title);

    if (index !== -1) {
      setSelectIndex(index);
      setCreateClassroomData({
        ...createClassroomData,
        teacher_title: TEACHER_TITLES[index],
      });
      return;
    }

    const teacherName = localStorage.getItem('teacherName');
    const teacherTitle = localStorage.getItem('teacherTitle');
    const teacherIndex = TEACHER_TITLES.findIndex(title => title === teacherTitle);

    setCreateClassroomData({
      ...createClassroomData,
      teacher_name:
        createClassroomData.teacher_name ||
        (teacherName && !createClassroomData.teacher_name ? teacherName : ''),
      teacher_title: teacherTitle && teacherIndex !== -1 ? TEACHER_TITLES[teacherIndex] : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createClassroomData.teacher_title]);

  const handleTeacherTitle = (item: string, index: number) => {
    setSelectIndex(index);
    setCreateClassroomData({
      ...createClassroomData,
      teacher_title: item,
    });
    localStorage.setItem('teacherTitle', item);
  };

  // if (canUpdate) {
  return (
    <Select
      mt={2}
      placeholder={''}
      items={TEACHER_TITLES}
      selectedIndex={selectIndex}
      renderItem={(item: string) => item}
      onSelectItem={(item: string, index: number) => handleTeacherTitle(item, index)}
    />
  );
  // }

  // return <CustomText content={TEACHER_TITLES[selectIndex] || EMPTY_STRING} />;
};
