import {Container} from 'cc-web-components';
import styled from 'styled-components';
import {BulbSvg} from '@/assets';

export const NewsContainer = styled(Container)`
  cursor: pointer;
  :focus {
    outline: 3px dotted #263238;
    border-radius: 25px;
  }
  :hover,
  :active {
    outline: none;
    svg,
    div {
      color: #014fb7;
    }
  }
  position: relative;
  padding: 12px 16px 12px 40px;
  margin-right: -16px;
`;

export const BulbSvgStyled = styled(BulbSvg)`
  color: #0163e5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
`;
