import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Text, Hr } from 'cc-web-components'

import Images from '@/assets/images'

const LicenseEmpty: React.FC = () => {
  const { t } = useTranslation('cornelsen')

  return (
    <Container
      flex={1}
      padding={25}
      alignItems="center"
      flexDirection="column"
      backgroundColor="rgb(248 248 248)"
    >
      <Container marginBottom={41} marginTop={15}>
        <img src={Images.Robot2} alt="" />
      </Container>

      <Text
        value={t('AssignActivity.Tabs.NewClass.StepLicenseEmpty.Title')}
        fontSize={24}
        fontWeight={500}
      />

      <Text
        value={t('AssignActivity.Tabs.NewClass.StepLicenseEmpty.SubTitle')}
        fontSize={18}
        textAlign="center"
        marginTop={10}
        fontWeight={400}
      />

      <Container marginTop={61} marginBottom={33} width={'100%'}>
        <Hr color="rgb(229 229 229)" />
      </Container>

      <img src={Images.IdeaBlue} alt="" />

      <Text
        color="#888"
        value={t('AssignActivity.Tabs.NewClass.StepLicenseEmpty.Description')}
        fontSize={16}
        textAlign="center"
        marginTop={16}
        fontWeight={400}
      />
    </Container>
  )
}

export default LicenseEmpty
