import { createAction, createReducer, createSelector } from '@reduxjs/toolkit'
import { ActivityState, Activity, Filter, Collection } from './types'

/** Initial state */
export const INITIAL_STATE: ActivityState = {
  activity: undefined,
  activities: [],
  filterActivities: {
    collectionIndex: 0,
    subjectIndex: 0,
    courseIndex: 0,
  },
  collections: []
}

/** Action creators */
export const activitiesSuccess = createAction<{ activities: Activity[] }>('ACTIVITIES_SUCCESS')
export const selectActivity = createAction<{ activity?: Activity }>('SELECT_ACTIVITY')
export const activitySuccess = createAction<{ activity: Activity }>('ACTIVITY_SUCCESS')
export const applyFilter = createAction<{ filterActivities: Filter }>('APPLY_FILTER')
export const collectionsSuccess = createAction<{ collections: Collection[] }>('COLEECTIONS_SUCCESS')

/** Reducer */
export default createReducer(INITIAL_STATE, {
  [activitiesSuccess.type]: (state, action): ActivityState => ({
    ...state,
    ...action.payload,
    activity: undefined,
  }),
  [activitySuccess.type]: (state, action): ActivityState => ({
    ...state,
    activities: [...state.activities, action.activity],
    activity: undefined,
  }),
  [selectActivity.type]: (state, action): ActivityState => ({
    ...state,
    ...action.payload,
  }),
  [applyFilter.type]: (state, action): ActivityState => ({
    ...state,
    ...action.payload,
  }),  
  [collectionsSuccess.type]: (state, action): ActivityState => ({
    ...state,
    ...action.payload,
  }), 
})

/** Selectors */
const rootSelector = (state: any): ActivityState => state.activity


export const getActivities = createSelector([rootSelector], (state) => state?.activities || [])

export const getActivity = createSelector([rootSelector], (state) => state.activity)

export const getFilter = createSelector([rootSelector], (state) => state.filterActivities)

export const getCollections = createSelector([rootSelector], (state) => state?.collections || [])
