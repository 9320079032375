import axios from 'axios';
import Storage from '../Storage';

const Service = axios.create({
  timeout: 20000,
  responseType: 'json',
  baseURL: window._env_.REACT_APP_URL_API_CHATPLATFORM,
});

Service.interceptors.request.use(async config => {
  const requestConfig = config;
  let token = await Storage.getCvToken();

  if (token && token.charAt(0) === '=') {
    token = token.substring(1);
  }

  requestConfig.headers['Access-Control-Allow-Origin'] = '*';
  requestConfig.headers.authorization = token;

  return requestConfig;
});

export default Service;
