import { format } from 'date-fns'

import Helpers from '../../helper'
import Service from '../../services/ApiCv/apiConfig'
import { HealthScoreRequest, HealthScoreResponse, HealthScoreGraphResponse } from './types'

const ApiStudent = {
  getStudentHealtScore: async (payload: HealthScoreRequest) => {
    const response = (
      await Service.get<HealthScoreResponse>(`/api/student/${payload.studentId}/healthScore`, {
        params: { classroomId: payload.classroomId, ...payload.filter },
      })
    ).data

    const indicator = Helpers.getMetaHealthScore(response.health_score.current)

    const activities_score = response.activity_score * 100
    const frequency_score = response.regularity_score * 100

    response.meta = {
      vocab_total_score: response.vocab_total_score.toFixed(0),
      activities_total_score: response.activities.current.toFixed(2),
      speaking_total_score: response.speaking_total_score.toFixed(0),
      listening_total_score: response.listening_total_score.toFixed(0),
      activities_score: activities_score > 100 ? '100' : activities_score.toFixed(0),
      activities_diff_score: response.activities.diff.toFixed(2),
      activities_diff_score_up: response.activities.diff > 0,
      frequency_score: frequency_score > 100 ? '100' : frequency_score.toFixed(0),
      frequency_diff_score: response.frequency.diff.toFixed(2),
      frequency_diff_score_up: response.frequency.diff > 0,
      classroom_average_score: response.classroom_average.toFixed(2),
      pronunciation_accuracy_score: (response.pronunciation_accuracy_score * 100).toFixed(0),
      pronunciation_completeness_score: (response.pronunciation_completeness_score * 100).toFixed(
        0
      ),
      pronunciation_fluency_score: (response.pronunciation_fluency_score * 100).toFixed(0),
      pronunciation_score: (response.pronunciation_score * 100).toFixed(0),
      indicator,
    }

    return response
  },

  getStudentHealtScoreGraph: async (payload: HealthScoreRequest) => {
    try {
      const response = (
        await Service.get<HealthScoreGraphResponse[]>(
          `/api/student/${payload.studentId}/healthScoreByDays`,
          {
            params: { classroomId: payload.classroomId, ...payload.filter },
          }
        )
      ).data

      response.forEach((item) => {
        item.date = format(new Date(item.start_date), 'yyyy-MM-dd')
        item.date_display = format(new Date(item.start_date), 'dd.MM.yyyy')

        item.vocab_total_score = Number(item.vocab_total_score.toFixed(2))
        item.speaking_total_score = Number(item.speaking_total_score.toFixed(2))
        item.listening_total_score = Number(item.listening_total_score.toFixed(2))
      })

      return response
    } catch (err) {
      return []
    }
  },
}

export default ApiStudent
