import {
  ActivityMessageHtml,
  ActivityItemMessages,
  ActivityMessageAudio,
} from '@/services/ApiCv/types/Activity'
import {
  Chat,
  ChatMessage,
  ChatMessageFromKeys,
  ChatMessageTypeKeys,
} from '@/components/MessageList/types'

export function convertActivityBookItemsToMessageList(
  baseMessages: ActivityItemMessages[]
): Chat[] {
  const messages = [] as Chat[]
  for (let item of baseMessages) {
    const chat = { index: item.index, correct_text: item.correct_text, messages: []} as Chat

    const messagesFromBot = item.messages.filter((item) => item.from_bot)

    for (let messageBot of messagesFromBot) {
      const data = {
        from: ChatMessageFromKeys.BOT,
      } as ChatMessage

      const messageHtml = messageBot.message as ActivityMessageHtml
      const messageMedia = messageBot.message as ActivityMessageAudio

      if (!!messageHtml.html) {
        data.type = ChatMessageTypeKeys.HTML
        data.content = messageHtml.html
        data.options = messageBot.options

        chat.messages.push(data)
      }
      if (!!messageMedia.media) {
        if (messageMedia.media.type === ChatMessageTypeKeys.AUDIO) {
          data.type = ChatMessageTypeKeys.AUDIO
          data.content = messageMedia.media.source_url
          data.options = messageBot.options
          data.metadata = {
            duration: messageMedia.media.metadata.duration,
          }

          chat.messages.push(data)
        }
        if (messageMedia.media.type === ChatMessageTypeKeys.IMAGE) {
          data.type = ChatMessageTypeKeys.IMAGE
          data.content = messageMedia.media.source_url
          data.options = messageBot.options

          chat.messages.push(data)
        }
      }
    }

    messages.push(chat)
  }

  return messages
}
