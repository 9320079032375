import React from 'react';
import styled, {useTheme} from 'styled-components';
import {Page, Card, Text} from 'cc-web-components';

import Footer from '../../components/Footer';

const Wrapper = styled.div`
  max-width: 463px;
  padding: 40px;
  font-size: 18px;
  font-weight: 400;
  color: rgb(33, 37, 41);
  line-height: 28px;
`;

export const PrintPage: React.FC = () => {
  const {colors} = useTheme();

  const RenderContentTexts = () => (
    <Wrapper>
      <Text value="Impressum" fontSize={28} color={colors.primary} /> <br />
      <Text value="Fragen zu ChatClass?" fontSize={18} fontWeight={700} />
      Schreiben Sie einfach eine E-Mail an <br />
      cornelsen-online@cornelsen.de <br />
      oder rufen Sie uns an unter +49 (030)897 85-622.
      <br />
      <br />
      Cornelsen Verlag GmbH
      <br />
      Mecklenburgische Straße 53
      <br />
      14197 Berlin
      <br />
      Telefon: (030) 897 85-0
      <br />
      Telefax: (030) 897 85-578
      <br />
      E-Mail: service@cornelsen.de
      <br />
      <br />
      <Text value="Servicehotline" fontSize={18} fontWeight={700} />
      0800 12 120 20 (kostenlos aus dem deutschen Festnetz)
      <br />
      +49 (0) 30 897 85-640 (für Anrufe aus dem Mobilfunknetz und
      <br />
      dem Ausland)
      <br />
      Mo.-Fr. von 8 bis 18 Uhr
      <br />
      <br />
      Handelsregisternummer:
      <br />
      HRB 149993B Amtsgericht Charlottenburg
      <br />
      Steuer-ID (Steuernummer):
      <br />
      29/011/60384
      <br />
      Umsatzsteuer-ID:
      <br />
      DE143872392
      <br />
      GLN/ ILN:
      <br />
      40 32357 00000 2
      <br />
      <br />
      <Text value="Geschäftsführung" fontSize={18} fontWeight={700} />
      Meeuwis van Arkel
      <br />
      Martina Fiddrich
      <br />
      Mark van Mierle (Vorsitz)
      <br />
      Georg Müller-Loeffelholz
      <br />
      Frank Thalhofer
      <br />
      Dennis Zentgraf
      <br />
      <br />
      <Text value="Inhaltliche Verantwortung" fontSize={18} fontWeight={700} />
      Verantwortlicher im Sinne des § 18 MStV
      <br />
      Meeuwis van Arkel
      <br />
      Martina Fiddrich
      <br />
      Mark van Mierle (Vorsitz)
      <br />
      Georg Müller-Loeffelholz
      <br />
      Frank Thalhofer
      <br />
      Dennis Zentgraf
      <br />
      <br />
      Programmierung
      <br />
      Edusim
    </Wrapper>
  );

  return (
    <Page headerContent={<></>} hideBar footerContent={<Footer />}>
      <Card justifyContent="center">
        <RenderContentTexts />
      </Card>
    </Page>
  );
};
