import React, {useEffect, useState} from 'react';
import {useTheme} from 'styled-components';
import {FaCircle} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Container, Card, Text} from 'cc-web-components';

import useDeviceDetection from '@/hooks/IsMobile';
import {startLoading, stopLoading} from '@/redux/Session';

import ApiStudent from '../service';
import {FilterTime, HealthScoreResponse} from '../types';
import {Wrapper} from './styles';

import GraphArea from './GraphArea';
import ProgressBar from './ProgressBar';
import ProgressCircle from './ProgressCircle';
import HealthScore from '@/components/InfoBox/HealthScore';

interface Props {
  activedTimeFilter: number;
  filterTime: FilterTime | undefined;
  hasChangedLevel?: string;
  onChangeLevel: () => void;
}

const StudyProgress: React.FC<Props> = ({
  activedTimeFilter,
  filterTime,
  hasChangedLevel,
  onChangeLevel,
}: Props) => {
  const {t} = useTranslation('cornelsen');
  const dispatch = useDispatch();
  const {colors} = useTheme();
  const {isMobile} = useDeviceDetection();
  const {classroomId, studentId} = useParams<{classroomId: string; studentId: string}>();

  const [healthScore, setHealtScore] = useState<HealthScoreResponse>();

  useEffect(() => {
    if (!filterTime || !studentId || !classroomId) return;
    (async () => {
      try {
        dispatch(startLoading());

        const response = await ApiStudent.getStudentHealtScore({
          studentId,
          classroomId,
          filter: filterTime,
        });

        setHealtScore(response);

        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
      }
    })();
  }, [dispatch, filterTime, classroomId, studentId, hasChangedLevel]);

  const HeaderCardHealtScore = () => {
    return (
      <Container justifyContent="space-between">
        <Container>
          <span>{t('Student.TabProgress.SectionHealthScore.CardTitle')}</span>
          <HealthScore />
        </Container>
      </Container>
    );
  };

  const HeaderCardGraph = () => {
    return (
      <Container justifyContent="space-between">
        <Container>
          <span>{t('Student.TabProgress.SectionCompetency.CardTitle')}</span>
        </Container>

        <Container>
          <Container alignItems="center">
            <FaCircle fontSize={8} color="rgb(0 170 170)" />

            <Text
              ml={2}
              size={14}
              color="grey"
              value={t('Student.TabProgress.SectionCompetency.Legend.Vocab')}
              fontWeight={500}
            />
          </Container>
          <Container alignItems="center" ml={4}>
            <FaCircle fontSize={8} color="rgb(171 128 197)" />

            <Text
              ml={2}
              size={14}
              color="grey"
              value={t('Student.TabProgress.SectionCompetency.Legend.Speak')}
              fontWeight={500}
            />
          </Container>
          <Container alignItems="center" ml={4}>
            <FaCircle fontSize={8} color="rgb(240 110 0)" />

            <Text
              ml={2}
              size={14}
              color="grey"
              value={t('Student.TabProgress.SectionCompetency.Legend.Listen')}
              fontWeight={500}
            />
          </Container>
        </Container>
      </Container>
    );
  };

  if (!healthScore) {
    return null;
  }

  return (
    <Container flexDirection={isMobile ? 'column' : 'column'} justifyContent="space-between">
      <Wrapper>
        <Card mb={30} title={<HeaderCardHealtScore />}>
          <Container flex={1} flexDirection={isMobile ? 'column-reverse' : 'row'}>
            <Container
              flex={0.6}
              minHeight={410}
              borderRight={isMobile ? '0px solid #eee' : '1px solid #eee'}
              flexDirection="column">
              <Container flexDirection="column">
                <Container style={{cursor: 'pointer'}} onClick={onChangeLevel}>
                  <Text
                    size={16}
                    color={colors.primary}
                    value={`${t('Student.TabProgress.SectionHealthScore.LevelText')} ${t(
                      `Globals.Types.StudentLevel.${healthScore.level}`,
                    )}`}
                    margin={10}
                  />
                </Container>

                <ProgressCircle healthScore={healthScore} />

                <Container flexDirection="column" marginLeft={10}>
                  <Text
                    size={16}
                    color={colors.black}
                    value={t('Student.TabProgress.SectionHealthScore.ClassValueText')}
                    marginBottom={1}
                  />

                  <Text
                    size={16}
                    color={colors.gray[2]}
                    value={`⌀ ${healthScore.meta.classroom_average_score}`}
                  />
                </Container>
              </Container>
            </Container>

            <Container
              flex={0.4}
              padding={isMobile ? `10px 10px 30px 10px` : `10px 10px 10px 20px`}
              borderBottom={isMobile ? '1px solid #eee' : '0px solid #eee'}>
              <ProgressBar healthScore={healthScore} />
            </Container>
          </Container>
        </Card>
      </Wrapper>
      <Container>
        <Card title={<HeaderCardGraph />}>
          <GraphArea
            classroomId={classroomId}
            studentId={studentId}
            filterDate={filterTime}
            activedTimeFilter={activedTimeFilter}
          />
        </Card>
      </Container>
    </Container>
  );
};

export default StudyProgress;
