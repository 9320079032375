import React from 'react';
import {Container, Header, InstructionsContainer} from './styles';
import {useTranslation} from 'react-i18next';
import {Instruction} from '@/components/Instruction';
import {StoreInfo} from '@/components/StoreInfo';

const instructions = [
  {
    preText: '1.',
    tKey: 'downloadChatclass',
    bottomContent: <StoreInfo showIcon={true} app="chatclass" />,
  },
  {
    preText: '2.',
    tKey: 'downloadClapp',

    bottomContent: <StoreInfo showIcon={true} app="cornelsen-lernen" />,
  },
  {
    preText: '3.',
    tKey: 'login',
  },
  {
    preText: '4.',
    tKey: 'use',
  },
];

interface Props {
  isAuthenticated?: boolean;
}

export const InstructionsPage: React.FC<Props> = ({isAuthenticated}) => {
  const {t} = useTranslation('tutorial');

  return (
    <Container isAuthenticated={isAuthenticated}>
      <Header content={t('title')} />
      <InstructionsContainer>
        {instructions.map(({preText, tKey, bottomContent}) => (
          <Instruction
            key={preText}
            text={t(tKey)}
            preText={preText}
            bottomContent={bottomContent}
          />
        ))}
      </InstructionsContainer>
    </Container>
  );
};
