import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { Button, Container, Modal, Text, ProgressBar } from 'cc-web-components'

import Images from '@/assets/images'
import useDeviceDetection from '@/hooks/IsMobile'
import { CartWhiteIcon, CloseBlueIcon } from '@/components/Icons'

import { BubbleContainer } from './styles'

import { Survey } from '@/services/ApiCv/types/Survey'
import { License } from '@/redux/Session/types'

interface ParsedSurvey {
  totalUsers: number
  totalSurveys: number
  yesCount: number
  yesPercent: string
  noCount: number
  noPercent: string
}

type Props = {
  okayCallback: () => void
  shopCallback: () => void
  license: License
  survey: Survey
}

const ModalLicenseExpired: React.FC<Props> = ({
  okayCallback,
  shopCallback,
  license,
  survey,
}: Props) => {
  const { colors } = useTheme()
  const { isMobile } = useDeviceDetection()
  const [parsedSurvey, setParsedSurvey] = useState<ParsedSurvey>({
    totalUsers: 0,
    totalSurveys: 0,
    yesCount: 0,
    yesPercent: '0',
    noCount: 0,
    noPercent: '0',
  })
  const [parsedLicenseExpiredDays, setParsedLicenseExpiredDays] = useState(0)
  const [licenseTitle, setLicenseTitle] = useState('')

  useEffect(() => {
    if (typeof survey.answers !== 'undefined' && survey.answers.length > 0) {
      const yesIndex = survey.answers.findIndex((item) => item.option === 0)
      const noIndex = survey.answers.findIndex((item) => item.option === 1)

      setParsedSurvey({
        totalUsers: survey.total_users || 0,
        totalSurveys: survey.total_surveys || 0,
        yesCount: survey.answers[yesIndex]?.count || 0,
        yesPercent: survey.answers[yesIndex]
          ? Number((survey.answers[yesIndex]?.count / survey.total_surveys) * 100).toFixed(0)
          : '0',
        noCount: survey.answers[noIndex]?.count || 0,
        noPercent: survey.answers[noIndex]
          ? Number((survey.answers[noIndex]?.count / survey.total_surveys) * 100).toFixed(0)
          : '0',
      })
    } else {
      setParsedSurvey({
        ...parsedSurvey,
        totalUsers: survey.total_users || 0,
        totalSurveys: survey.total_surveys || 0,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey])

  useEffect(() => {
    let title
    switch (license.type) {
      case 'CCWAE':
      case 'TESTLICENSE01':
        title = 'Testlizenz'
        break
      case 'CCWAL':
      case 'CCPPK':
        title = 'Klassenlizenz'
        break
      case 'CCWAS':
      case 'CCPPS':
        title = 'Schullizenz'
        break
      default:
        title = ''
    }

    setLicenseTitle(title)

    const deleteDataDaysCount = 90 - Math.abs(license.days_to_expire)
    setParsedLicenseExpiredDays(deleteDataDaysCount >= 0 ? deleteDataDaysCount : 0)
  }, [license])

  return (
    <Modal show={true} padding="0" width={isMobile ? '90%' : '770px'}>
      <Container
        width="100%"
        maxWidth={isMobile ? '100%' : '770px'}
        flexDirection="column"
        overflowX="auto"
        maxHeight="90vh"
        display="block"
      >
        <Container flex={1} justifyContent="space-between">
          <Container flexDirection="column" padding="25px 30px">
            <Text
              value={`${licenseTitle} abgelaufen!`}
              color="rgb(36 42 46)"
              fontWeight={600}
              fontSize={24}
            />

            <Text
              value={
                <span
                  dangerouslySetInnerHTML={{
                    __html: `Ihre ChatClass ${licenseTitle} ist abgelaufen.<br />Wenn Sie die ChatClass-Funktionen weiterhin<br />nutzen möchten, erwerben Sie eine Lizenz.`,
                  }}
                />
              }
              color="rgb(36 42 46)"
              fontSize={20}
              marginTop={20}
            />
          </Container>

          {!isMobile && (
            <Container position="relative" width="40%" height="200px">
              <Container position="absolute" marginTop={30}>
                <img src={Images.Robot3} alt="" />
              </Container>
            </Container>
          )}
        </Container>

        <Container
          backgroundColor="#EBEFF7"
          minHeight={240}
          padding={36}
          marginTop={20}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Container flex={0.5} flexDirection="column">
            <Container alignItems="center">
              <img src={Images.InfoIconBlack} alt="" />

              <Text value="Hinweis" fontSize={18} fontWeight={'bold'} marginLeft={2} />
            </Container>

            <Container flexDirection="column" marginTop={10}>
              <Text
                value="Ihre Schüler/-innen können nun nicht mehr weiter mit der ChatClass App üben."
                fontSize={18}
              />

              <Text
                value={
                  <>
                    In <b>{parsedLicenseExpiredDays} Tagen</b> werden Ihre angelegten Klassen und
                    die dazugehörigen Lernstände automatisch permanent gelöscht.
                  </>
                }
                fontSize={18}
                marginTop={15}
              />
            </Container>
          </Container>
          {isMobile ? (
            <Container
              width={'100%'}
              height="1px"
              marginTop={30}
              marginBottom={30}
              backgroundColor="#C4CBD4"
            />
          ) : (
            <Container
              width="1px"
              height="inheiret"
              marginLeft={20}
              marginRight={35}
              backgroundColor="#C4CBD4"
            />
          )}

          <Container flex={0.5} flexDirection="column">
            <Container flexDirection="column">
              <Text value="Feedback Ihrer Schüler/-innen" fontSize={18} fontWeight={'bold'} />

              <Text
                value="Hat das Üben mit ChatClass gefallen?"
                color="rgb(36 42 46)"
                fontSize={18}
                marginTop={10}
              />
            </Container>

            <BubbleContainer>
              <Container height="10px" />

              <ProgressBar
                value={parsedSurvey.yesPercent}
                color="green"
                width="100%"
                title={
                  <Text
                    size={16}
                    fontSize={13}
                    value={
                      <>
                        <b style={{ marginRight: 15, fontWeight: 600, fontSize: 16 }}>Ja</b>

                        <span style={{ color: 'gray', marginLeft: 14, fontSize: 15 }}>
                          {parsedSurvey.yesCount} votes
                        </span>
                      </>
                    }
                  />
                }
                rightContent={
                  <Text
                    size={16}
                    fontSize={13}
                    value={`${parsedSurvey.yesPercent} %`}
                    color="rgb(36 42 46)"
                  />
                }
              />

              <ProgressBar
                value={parsedSurvey.noPercent}
                color="red"
                width="100%"
                title={
                  <Text
                    size={16}
                    fontSize={13}
                    value={
                      <>
                        <b style={{ marginRight: 15, fontWeight: 600, fontSize: 16 }}>Nein</b>

                        <span style={{ color: 'gray', fontSize: 15 }}>
                          {parsedSurvey.noCount} votes
                        </span>
                      </>
                    }
                  />
                }
                marginTop={15}
                rightContent={
                  <Text
                    size={16}
                    fontSize={13}
                    value={`${parsedSurvey.noPercent} %`}
                    color="rgb(36 42 46)"
                  />
                }
              />

              <Container height="20px" />
            </BubbleContainer>

            <Container paddingLeft={2}>
              <div style={{ fontSize: 14, color: 'rgb(36 42 46)' }}>
                <b>{parsedSurvey.totalSurveys}</b> votes von
              </div>

              <div style={{ fontSize: 14, color: 'rgb(36 42 46)', marginLeft: 7 }}>
                <b>{parsedSurvey.totalUsers}</b> Schüler/-innen
              </div>
            </Container>
          </Container>
        </Container>

        <Container minHeight={109} alignItems="center" justifyContent="center">
          <Button
            variant="outline"
            value={<Text value="Okay" color={colors.primary} fontWeight={300} fontSize={20} />}
            minWidth={80}
            height={50}
            marginRight={15}
            leftContent={<CloseBlueIcon />}
            onClick={okayCallback}
          />

          <Button
            height={49}
            minWidth={189}
            variant="solid"
            value={
              <Text
                value="Lizenz kaufen"
                color="#fff"
                fontSize={20}
                marginLeft={1}
                fontWeight={300}
              />
            }
            leftContent={<CartWhiteIcon />}
            onClick={shopCallback}
          />
        </Container>
      </Container>
    </Modal>
  )
}

export default ModalLicenseExpired
