import React from 'react'
import { Table, Container, Text, Button } from 'cc-web-components'

import {
  MicroIcon,
  BlueBookIcon,
  BookPileIcon,
  EyeFilledPrimaryIcon,
  EyeOutlinePrimaryIcon,
  WorkSpokenIcon,
} from '@/components/Icons'

import { TaskProps } from '../types'

import { TableTaskActivityContainer } from './styles'

type Props = {
  tasks: TaskProps[]
  onClickTask?: (item: TaskProps) => void
  taskIdActived?: string
}

const TableTasks: React.FC<Props> = (props: Props) => {
  return (
    <TableTaskActivityContainer>
      <Table
        id="tableTaskActivity"
        data={props.tasks}
        header={null}
        columns={[
          {
            header: null,
            renderItem: () => <></>,
          },
          // {
          //   header: null,
          //   renderItem: (item: TaskProps) => <Text size={16} value={item.seite_number} />,
          // },
          {
            header: null,
            renderItem: (item: TaskProps) => (
              <Container alignItems="center">
                {item.task_type === 'AI_SPEAKING' && <MicroIcon width={20} />}

                {item.task_type === 'LISTENING' && <>👂</>}

                {item.task_type === 'DIALOG' && <>📖</>}

                {item.task_type === 'PRONUNCIATION' && <WorkSpokenIcon width={20} />}

                {item.task_type === 'GRAMMAR' && <BookPileIcon width={30} />}

                {item.task_type === 'VOCABULARY_TRAINER' && <BlueBookIcon width={20} />}

                <Text size={16} marginLeft={2} value={item.task_title} />
              </Container>
            ),
          },
          {
            header: null,
            renderItem: (item: TaskProps) => (
              <Text size={16} fontWeight={600} value={`${item.group_percent} %`} />
            ),
          },
          {
            header: null,
            renderItem: (item: TaskProps) => (
              <Container alignItems="center">
                <Container width={22} />

                <Text
                  marginTop={1}
                  marginLeft={1}
                  value={`${item.total_students_ok} / ${item.total_students}`}
                />
              </Container>
            ),
          },
          {
            align: 'right',
            header: null,
            renderItem: (item: TaskProps) => (
              <Container justifyContent="flex-end">
                <Button
                  height="100%"
                  variant="link"
                  leftContent={
                    props.taskIdActived === item.task_title ? (
                      <EyeFilledPrimaryIcon width={24} />
                    ) : (
                      <EyeOutlinePrimaryIcon width={24} />
                    )
                  }
                  onClick={() => !!props.onClickTask && props.onClickTask(item)}
                />
              </Container>
            ),
          },
        ]}
      />
    </TableTaskActivityContainer>
  )
}

export default TableTasks
