import React, {useEffect, useState} from 'react';
import lodashOrdeby from 'lodash.orderby';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {BiCaretUp, BiCaretDown} from 'react-icons/bi';
import {Container, Table, Text} from 'cc-web-components';

import useDeviceDetection from '@/hooks/IsMobile';
import {CrossRedIcon, CheckGreenIcon} from '@/components/Icons';

import {PlaylistStudentProps} from '../types';
import {TableColumnHeaderSortable} from '../styles';

import {StudentLink} from './styles';
import {Routes} from '@cc/shared';

const {CLASSROOM} = Routes;

enum SortConfigDirectionkeys {
  ASC = 'asc',
  DESC = 'desc',
}

type SortConfigDirection = SortConfigDirectionkeys;

type SortConfig = {
  key: null | keyof PlaylistStudentProps;
  direction: null | SortConfigDirection;
};

type Props = {
  goal: number;
  students: PlaylistStudentProps[];
  playlistId: string;
  correctness: string;
};

const TabStudents: React.FC<Props> = (props: Props) => {
  const {t} = useTranslation('cornelsen');
  const {colors} = useTheme();
  const {isMobile} = useDeviceDetection();

  const [students, setStudents] = useState<PlaylistStudentProps[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: null,
    direction: null,
  });

  useEffect(() => {
    if (!sortConfig.key || !sortConfig.direction) {
      setStudents(props.students);

      return;
    }

    setStudents(lodashOrdeby(props.students, sortConfig.key, sortConfig.direction));
  }, [props.students, sortConfig]);

  const requestSort = (key: keyof PlaylistStudentProps) => {
    let direction: SortConfigDirection = SortConfigDirectionkeys.ASC;

    if (sortConfig.direction === SortConfigDirectionkeys.ASC) {
      direction = SortConfigDirectionkeys.DESC;
    }

    setSortConfig({key, direction});
  };

  const renderColumnSortable = (columnName: keyof PlaylistStudentProps, children: any) => {
    const getIconsOrdered = () => {
      let opacityIconUp = 0.5;
      let opacityIconDown = 0.5;

      if (!!sortConfig.key && sortConfig.key === columnName) {
        opacityIconUp = sortConfig.direction === SortConfigDirectionkeys.ASC ? 1 : 0.5;
        opacityIconDown = sortConfig.direction === SortConfigDirectionkeys.DESC ? 1 : 0.5;
      }

      return (
        <Container flexDirection="column" marginRight={1}>
          <Container marginBottom={-2}>
            <BiCaretUp color={colors.primary} style={{opacity: opacityIconUp}} />
          </Container>

          <BiCaretDown color={colors.primary} style={{opacity: opacityIconDown}} />
        </Container>
      );
    };

    return (
      <TableColumnHeaderSortable onClick={() => requestSort(columnName)}>
        <Container flexDirection="column" marginRight={isMobile ? 0 : 1}>
          {getIconsOrdered()}
        </Container>

        {children}
      </TableColumnHeaderSortable>
    );
  };

  return (
    <Table
      id="tableTaskStudents"
      flex={1}
      data={students}
      columns={[
        {
          header: renderColumnSortable(
            'name',
            <>
              {t('Classroom.TabTasks.TableStudentTasks.Header.Student')} ({students.length})
            </>,
          ),
          renderItem: (item: PlaylistStudentProps) => {
            let linkTo = `${CLASSROOM}/${item.classroom_id}/student/${item.id}?redirectTo=tasks`;

            return (
              <Container paddingLeft={3} alignItems="center">
                <StudentLink to={linkTo}>
                  {item.emoji}

                  <Text
                    size={16}
                    fontWeight={600}
                    marginLeft={2}
                    color={colors.primary}
                    value={`${item.name}`}
                  />
                </StudentLink>
              </Container>
            );
          },
        },
        {
          header: null,
          renderItem: (item: PlaylistStudentProps) => (
            <Text
              size={16}
              color={item.status_color}
              value={t(`Globals.Types.Status.${item.status_translated}`)}
            />
          ),
        },
        {
          header: renderColumnSortable(
            'status_number',
            <>{t('Classroom.TabTasks.TableStudentTasks.Header.Status')}</>,
          ),
          renderItem: (item: PlaylistStudentProps) => (
            <Text size={16} fontWeight={600} value={`${item.status_number} %`} />
          ),
        },
        {
          header: null,
          renderItem: (item: PlaylistStudentProps) => (
            <Container alignItems="center" paddingLeft={1}>
              {item.all_activities_ok ? <CheckGreenIcon /> : <CrossRedIcon />}

              <Text
                marginLeft={2}
                value={`${item.total_activities_ok} / ${item.total_activities}`}
              />
            </Container>
          ),
        },
      ]}
      footer={
        <Container padding="10px 17px">
          <Container flexDirection="row">
            <Container marginRight={3} alignItems="flex-end">
              <Text
                value={t('Classroom.TabTasks.TableStudentTasks.Footer.CorrectnessText')}
                marginRight={1}
              />

              <Text
                color={colors.green}
                value={`${props.correctness} %`}
                fontWeight={600}
                fontSize={16}
              />
            </Container>

            {props.goal ? (
              <Container alignItems="flex-end">
                <Text
                  value={t('Classroom.TabTasks.TableStudentTasks.Footer.GoalText')}
                  marginRight={1}
                />

                <Text value={`${props.goal} %`} fontWeight={600} fontSize={16} />
              </Container>
            ) : (
              <></>
            )}
          </Container>
        </Container>
      }
    />
  );
};

export default TabStudents;
