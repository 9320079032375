import {updateManifest} from '@cc/utils';
import {useLayoutEffect} from 'react';

const MANIFEST_ID = '#app-manifest';
const ANDROID_MANIFEST = '/deeplinks/android-web-manifest.json'; //! Path inside public folder.
const APP_MANIFEST = '/manifest_cornelsen.json';

export const useAndroidInstallPrompt = () => {
  useLayoutEffect(() => {
    updateManifest(ANDROID_MANIFEST, MANIFEST_ID);

    return () => {
      updateManifest(APP_MANIFEST, MANIFEST_ID);
    };
  }, []);
};
