import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Card, Grid, Page, Text} from 'cc-web-components';
import {Book, Container} from './styles';
import {useNavigate} from 'react-router-dom';
import Footer from '../../components/Footer';
import {startLoading, stopLoading} from '@/redux/Session';
import ApiBookService from '@/services/ApiCv/ApiBookService';
import {BookServiceResponse} from '@/services/ApiCv/types/Books';
import Storage from '@/services/Storage';
import {Routes} from '@cc/shared';

const {BOOK} = Routes;

export const NewBooksPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation('cornelsen');
  const [books, setBooks] = useState<BookServiceResponse>();

  useEffect(() => {
    const banner = document.getElementById('banner') as HTMLDivElement;
    if (banner) {
      banner.style['display'] = 'flex';
    }
  });

  useEffect(() => {
    dispatch(startLoading());
    ApiBookService['getAllBooks']()
      .then(data => {
        const books = data.filter(
          x => x.configuration.features.filter((c: any) => c === 'PUBLIC_BOOK').length > 0,
        );
        setBooks(books);
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  }, [dispatch]);

  function handleNavigateBookDetails(bookId: string) {
    Storage.removeClassroom();

    navigate(`${BOOK}/${bookId}`);
  }

  function handleCoverUrl(cover_url: string) {
    if (cover_url?.substring(0, 7) === '/assets')
      return 'https://chatclass.cornelsen.de' + cover_url;

    return cover_url;
  }

  return (
    <Page headerContent={<></>} footerContent={<Footer />}>
      <Card>
        <Container>
          <Grid gap={12} mobileCols={1} desktopCols={1}>
            <Text size={20} value={t('Books.Title')} mb={3} isBold={true} />

            <Text size={20} value={t('Books.SubTitle')} />

            <Grid gap={26} mobileCols={2} desktopCols={6}>
              {books?.map(book => (
                <Book key={book.id} onClick={() => handleNavigateBookDetails(book.id)}>
                  <img src={handleCoverUrl(book.configuration.cover_url)} alt={book.name} />
                </Book>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Card>
    </Page>
  );
};
