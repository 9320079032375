import {useEffect, useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Container, Grid, Text} from 'cc-web-components';
import QRCode from 'react-qr-code';

import Api from '@/services/ApiCv';
import useDeviceDetection from '@/hooks/IsMobile';
import {startLoading, stopLoading} from '@/redux/Session';
import {ClassroomStudentRow, Classroom} from '@/services/ApiCv/types';
import {
  Card,
  CardCode,
  CardContent,
  CardName,
  CardPlaceholder,
  CardTag,
  StepIcon,
  Steps,
  Step,
  StepText,
  Wrapper,
} from './styles';
import {Routes} from '@/shared';

const {DOWNLOAD} = Routes;

export const StudentsPrintPage: React.FC = () => {
  const dispatch = useDispatch();
  const {isMobile} = useDeviceDetection();
  const {classroomId} = useParams<{classroomId: string}>();
  const [classroom, setClassroom] = useState<Classroom>();
  const [studentsTable, setStudentsTable] = useState<Array<ClassroomStudentRow[]>>([]);
  const [studentsCodes, setStudentsCodes] = useState<Array<ClassroomStudentRow[]>>([]);

  const paginate = useCallback((arr: Array<any>, size: number) => {
    return arr.reduce((acc, val, i) => {
      let idx = Math.floor(i / size);
      let page = acc[idx] || (acc[idx] = []);
      page.push(val);

      return acc;
    }, []);
  }, []);

  useEffect(() => {
    if (!classroomId) {
      return;
    }

    dispatch(startLoading());

    Api.getClassroom(classroomId)
      .then(data => {
        setClassroom(data);
      })
      .catch(() => {
        setClassroom(undefined);
      });

    Api.getClassroomStudents(classroomId, {startDate: '', endDate: ''})
      .then(students => {
        setStudentsTable(paginate(students, 50));
        setStudentsCodes(paginate(students, 8));
        if (students?.length) setTimeout(() => window.print(), 2500);
      })
      .catch(() => {
        dispatch(stopLoading());
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  }, [classroomId, dispatch, paginate]);

  return (
    <Wrapper>
      <Container style={{display: 'block'}}>
        {!!classroom && (
          <Container mb={2} borderBottom="1px solid black" justifyContent="space-between">
            <Text fontSize={16} value={`${classroom?.displayName} Zugangscodes`} />
            <Text fontSize={16} value="ChatClass" />
          </Container>
        )}

        <Container style={{display: 'block'}}>
          {studentsTable?.map((students, tableIndex) => {
            return (
              <Grid
                key={tableIndex}
                gap={16}
                desktopCols={2}
                mobileCols={2}
                m={3}
                className="table">
                {students?.map((student, studentIndex) => (
                  <Container
                    key={studentIndex}
                    borderBottom="1px solid black"
                    justifyContent="space-between">
                    <Text fontSize={12} value={student.student_name} />
                    <Text fontSize={12} fontWeight="bold" value={student.student_code} />
                  </Container>
                ))}
              </Grid>
            );
          })}
        </Container>

        <Container style={{display: 'block'}}>
          {studentsCodes?.map((students, codesIndex) => {
            return (
              <Grid
                key={codesIndex}
                gap={48}
                desktopCols={2}
                mobileCols={2}
                m={3}
                className="cards">
                {students?.map((student, studentIndex) => (
                  <>
                    <Container key={studentIndex} flexDirection="column">
                      <Card>
                        <CardContent>
                          <CardName>{student.student_name}</CardName>
                          <Container flexDirection="column">
                            <CardCode>#{student.student_code}</CardCode>
                            <CardTag>Dein ChatClass Code</CardTag>
                          </Container>
                        </CardContent>

                        <CardPlaceholder>
                          <QRCode
                            viewBox={`0 0 256 256`}
                            style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                            value={'https://app.chatclass.cornelsen.de' + DOWNLOAD}
                          />
                          <Text
                            mt={2}
                            fontSize={isMobile ? '8px' : '14px'}
                            color="#888"
                            value="Scanne den QR Code, um ChatClass herunterzuladen."
                          />
                        </CardPlaceholder>
                      </Card>

                      <Steps>
                        <Step>
                          <StepIcon>1</StepIcon>
                          <StepText>App laden</StepText>
                        </Step>

                        <Step>
                          <StepIcon>2</StepIcon>
                          <StepText>Code eingeben</StepText>
                        </Step>

                        <Step>
                          <StepIcon>3</StepIcon>
                          <StepText>Übung auswählen & los geht's!</StepText>
                        </Step>
                      </Steps>
                    </Container>
                  </>
                ))}
              </Grid>
            );
          })}
        </Container>
      </Container>
    </Wrapper>
  );
};

