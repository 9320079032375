import React, {useState} from 'react';
import {useTheme} from 'styled-components';
import {Button, Container, Text} from 'cc-web-components';
import {BsFillCaretRightFill, BsCaretDownFill} from 'react-icons/bs';

import ApiBookService from '@/services/ApiCv/ApiBooksService';
import useDeviceDetection from '@/hooks/IsMobile';
import {LockOpenLinedIcon, LockClosedFilledIcon} from '@/components/Icons';
import {BookDetail, BookDetailUnit, BookDetailSection} from '@/services/ApiCv/types/BookDetail';

import {Wrapper, ItemHeader, ItemContent, ItemSection, ItemSectionCount} from './styles';

type Props = {
  book: BookDetail;
  bookId?: string;
  sticky: boolean;
  bookUnit: BookDetailUnit | undefined;
  classroomId: string | undefined;
  bookSection: BookDetailSection | undefined;
  isOriginRedirectBooks: boolean;
  isHiddenActivitiesMobile: boolean;
  isLicenseExpired?: boolean;
  onChangeUnit: (unit: BookDetailUnit) => void;
  onChangeSection: (section: BookDetailSection, index: number) => void;
};

const Sidebar: React.FC<Props> = ({
  book,
  bookId,
  bookSection,
  bookUnit,
  classroomId,
  isHiddenActivitiesMobile,
  isOriginRedirectBooks,
  onChangeSection,
  onChangeUnit,
  sticky,
  isLicenseExpired,
}: Props) => {
  const {colors} = useTheme();
  const {isMobile} = useDeviceDetection();
  const [units, setUnits] = useState<BookDetailUnit[]>(() => {
    return book.units;
  });

  const onSelectUnit = (unit: BookDetailUnit | undefined) => {
    !!unit && onChangeUnit(unit);
  };

  const onSelectSection = (section: BookDetailSection, index: number) => {
    onChangeSection(section, index);
  };

  const onChangeLockUnit = (unitId: string) => {
    if (!classroomId) return;

    const data = [...units];

    const findIndex = data.findIndex(item => item.id === unitId);

    const locked = !data[findIndex].locked;

    data[findIndex].locked = locked;

    setUnits(data);

    const payload = {
      lock: locked ? [unitId] : [],
      unlock: locked ? [] : [unitId],
    };
    if (bookId) {
      ApiBookService.changeLockUnit(classroomId, bookId, payload).then(res => {
        console.log(res);
      });
    }
  };

  if (!book) {
    return <></>;
  }

  if (isMobile && !isHiddenActivitiesMobile) {
    return (
      <Wrapper>
        <Container flexDirection="column" position="fixed" width="100%" top={110} zIndex={10}>
          <ItemHeader opened={false} onClick={() => onSelectUnit(bookUnit)}>
            <BsFillCaretRightFill color={colors.primary} />

            <Text size={16} marginLeft={3} fontWeight={600} value={bookUnit?.name} />
            <Text size={16} marginLeft={3} value={bookUnit?.subtitle} />
          </ItemHeader>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {book.units.map(unit => {
        const isItemIndexRender = unit.id === bookUnit?.id;

        return (
          <Container flexDirection="column" key={unit.id}>
            <ItemHeader
              sticky={sticky}
              opened={isItemIndexRender}
              onClick={() => onSelectUnit(unit)}
            >
              <Container flex={1} alignItems="center">
                {isItemIndexRender ? (
                  <BsCaretDownFill color={colors.primary} />
                ) : (
                  <BsFillCaretRightFill color={colors.primary} />
                )}

                <Container flexDirection="column">
                  <Text
                    size={16}
                    marginLeft={3}
                    fontWeight={600}
                    value={unit.name}
                    color={isItemIndexRender ? colors.primary : colors.black}
                  />
                  <Text
                    size={16}
                    marginLeft={3}
                    value={unit.subtitle}
                    color={isItemIndexRender ? colors.primary : colors.black}
                  />
                </Container>
              </Container>

              {!isOriginRedirectBooks && !isLicenseExpired && (
                <>
                  <Button
                    variant="link"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      onChangeLockUnit(unit.id);
                      event.stopPropagation();
                    }}
                    leftContent={!unit.locked ? <LockOpenLinedIcon /> : <LockClosedFilledIcon />}
                  />
                </>
              )}
            </ItemHeader>

            {isItemIndexRender && (
              <ItemContent>
                {unit.sections.map((section, index) => (
                  <ItemSection
                    key={section.section_name}
                    onClick={() => onSelectSection(section, index)}
                    opened={section.section_name === bookSection?.section_name}
                  >
                    <Text
                      size={16}
                      color={
                        section.section_name === bookSection?.section_name
                          ? colors.primary
                          : colors.black
                      }
                      value={section.section_name}
                    />

                    <ItemSectionCount opened={section.section_name === bookSection?.section_name}>
                      <Text
                        size={16}
                        color={colors.primary}
                        value={section.activities.length}
                        fontWeight={600}
                      />
                    </ItemSectionCount>
                  </ItemSection>
                ))}
              </ItemContent>
            )}
          </Container>
        );
      })}
    </Wrapper>
  );
};

export default Sidebar;
