import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Container } from 'cc-web-components'

import ApiLicenseService, { LicenseType } from '@/services/ApiCv/ApiLicenseService'
import { getLicense, startLoading, stopLoading, toggleShowModalPricingTable } from '@/redux/Session'

import Actions from './Components/Actions'
import StepLicenseEmpty from './Steps/LicenseEmpty'
import StepLicenseSelect from './Steps/LicenseSelect'
import { useAssignActivityContext } from '@/redux/AssignActivity'

type TabProps = {
  onClose: () => void
}

const TabNewClass: React.FC<TabProps> = ({ onClose }: TabProps) => {
  const { t } = useTranslation('cornelsen')
  const dispatch = useDispatch()
  const license = useSelector(getLicense)

  const [licenses, setLicenses] = useState<LicenseType[]>([])
  const [buttonClickable, setButtonClickable] = useState(false)
  const [licenseId, setLicenseId] = useState('')
  const [{ classroomId }] = useAssignActivityContext()

  useEffect(() => {
    ApiLicenseService.getAvailableLicense().then((licenses) => {
      setLicenses(licenses.filter((item) => !item.expired))
    })

    // eslint-disable-next-line
  }, [])

  const clickedEmptyLicenseButtonNext = () => {
    dispatch(toggleShowModalPricingTable({ visible: true }))
  }

  const onSubmitForm = () => {
    const params = {
      licenseId: licenseId,
      classroomId: classroomId,
    }

    dispatch(startLoading())

    ApiLicenseService.postReactiveLicense(params)
      .then(() => {
        dispatch(stopLoading())

        window.location.reload()
      })
      .catch(() => {
        dispatch(stopLoading())
      })
  }

  const updateSelectedLicense = (licenseId: string) => {
    setLicenseId(licenseId)
  }

  if (license?.type === '' || !licenses.length) {
    return (
      <Container height={'calc(100vh - 118px - 20px)'} overflowY="auto" flexDirection="column">
        <StepLicenseEmpty />

        <Actions
          showIconNext={false}
          buttonNextValue={t('AssignActivity.Tabs.NewClass.StepLicenseEmpty.ButtonSubmitText')}
          buttonBackValue={t('AssignActivity.Tabs.NewClass.ButtonCancelText')}
          onClickButtonNext={clickedEmptyLicenseButtonNext}
          onClickButtonBack={onClose}
          buttonNextDisabled={false}
        />
      </Container>
    )
  }

  return (
    <Container height={'calc(100vh - 118px - 20px)'} overflowY="auto" flexDirection="column">
      <StepLicenseSelect
        licenses={licenses}
        setButtonActive={setButtonClickable}
        updateSelectedLicense={updateSelectedLicense}
      />

      <Actions
        buttonNextValue={t(
          'AssignActivity.Tabs.NewClass.StepLicenseSelect.ButtonSubmitTextExpiredLicense'
        )}
        buttonBackValue={t('AssignActivity.Tabs.NewClass.ButtonCancelText')}
        onClickButtonNext={onSubmitForm}
        onClickButtonBack={onClose}
        buttonNextDisabled={!buttonClickable}
      />
    </Container>
  )
}

export default TabNewClass
