import styled from 'styled-components';

export const EmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: #f8f8f8;
  border: 1px dashed #bcafa3;
  padding: 10px;

  font-size: 14px;
  text-align: center;
  line-height: 18px;
  color: #999;
`;

export const TooltipContainer = styled.div`
  display: flex;
  background-color: #fff;
  border: 1px solid #eee;
  padding: 16px 8px;
`;
