import {UserType} from '@cc/shared';

type NO_RIGHT = '';
type ONE_RIGHT = 'C' | 'R' | 'U' | 'D';
type TWO_RIGHTS = 'CR' | 'CU' | 'CD' | 'RU' | 'RD' | 'UD';
type THREE_RIGHTS = 'CRU' | 'CRD' | 'CUD' | 'RUD';
type ALL_RIGHTS = 'CRUD';
export type RIGHTS = NO_RIGHT | ONE_RIGHT | TWO_RIGHTS | THREE_RIGHTS | ALL_RIGHTS;

export type BLACKLISTED_FEATURES = {[key in FEATURES]?: RIGHTS};
type BLACKLIST = {[key in UserType]: BLACKLISTED_FEATURES | undefined};

export type FEATURES = 'CLASSROOM' | 'STUDENT_CODE' | 'STUDENT';

//! Provide blocked actions.
const BSP_BLACKLIST_CONFIG: BLACKLISTED_FEATURES = {
  CLASSROOM: 'CUD',
  STUDENT: 'CUD',
  STUDENT_CODE: 'CRUD',
};

export const FEATURES_BLACKLIST_CONFIG: BLACKLIST = {
  CORNELSEN: undefined,
  BSP: BSP_BLACKLIST_CONFIG,
};
