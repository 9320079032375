import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Container, Page, Text} from 'cc-web-components';

import Storage from '@/services/Storage';
import Helpers from '@/helper';
import BookCover from '@/components/BookCover';
import useScrollY from '@/hooks/scrolly';
import ApiBookService from '@/services/ApiCv/ApiBooksService';
import useDeviceDetection from '@/hooks/IsMobile';
import {getLicense, startLoading, stopLoading} from '@/redux/Session';
import {BookDetail, BookDetailUnit, BookDetailSection} from '@/services/ApiCv/types/BookDetail';
import Images from '@/assets/images';
import Header from './Header';
import Sidebar from './Sidebar';
import Activities from './Activities';

import {
  Wrapper,
  HeaderFixedMobile,
  SidebarContainer,
  Content,
  HeaderContainer,
  ContentContainer,
  PageWrapper,
} from './styles';
import Banner from '@/components/Banner';
import BgBlue from '@/assets/images/bg-blue.png';

type ClassroomStorageProps = {
  book_id: string;
  book_name: string;
  classroom_id: string;
  classroom_name: string;
  classroom_color: string;
};

export const SingleBookPage: React.FC = () => {
  const {t} = useTranslation('cornelsen');
  const {id} = useParams<{id: string}>();
  const dispatch = useDispatch();
  const {isMobile} = useDeviceDetection();
  const license = useSelector(getLicense);

  const [isHiddenActivitiesMobile, setHiddenActivitiesMobile] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  const [book, setBook] = useState<BookDetail>();
  const [bookUnit, setBookUnit] = useState<BookDetailUnit | undefined>();
  const [bookSection, setBookSection] = useState<BookDetailSection | undefined>();
  const [classroomStorage, setClassroomStorage] = useState<ClassroomStorageProps>();

  const [sticky] = useScrollY({scrollHeight: 330});

  const onBackPage = () => {
    window.history.back();
  };

  const sortSectionActivitiviesByType = (section: BookDetailSection) => {
    if (section) {
      section.activities.sort(a => {
        if (a.type === 'AI_SPEAKING') {
          return -1;
        }

        if (a.type !== 'AI_SPEAKING') {
          return 1;
        }

        return 0;
      });
      return section;
    }
  };

  useEffect(() => {
    setHiddenActivitiesMobile(isMobile);
  }, [isMobile]);

  useEffect(() => {
    const isBookUncompleted = book?.units.some(unity => !unity.sections.length);
    setShowBanner(!!isBookUncompleted);
  }, [book?.units]);

  useEffect(() => {
    const onStartActivities = (book: BookDetail) => {
      if (!book || !book.units.length) return;
      if (!book.units[0].sections.length) return;

      const sortedSection = sortSectionActivitiviesByType(book.units[0].sections[0]);

      setBookUnit(book.units[0]);
      setBookSection(sortedSection);
    };

    const fetchData = async () => {
      dispatch(startLoading());

      const classroom = await Storage.getClassroom();

      if (id === undefined) {
        return;
      }

      if (!!classroom) {
        setClassroomStorage(classroom);

        const response = await ApiBookService.getClassroomBookDetails(classroom.classroom_id, id);

        setBook(response.book);

        onStartActivities(response.book);

        dispatch(stopLoading());

        return;
      }

      const response = await ApiBookService.getBookDetails(id);
      setBook(response.book);

      onStartActivities(response.book);

      dispatch(stopLoading());
    };

    try {
      fetchData();
    } catch (err) {
      setClassroomStorage(undefined);

      dispatch(stopLoading());
    }
  }, [dispatch, id]);

  const HeaderMeta = useMemo(() => {
    if (!book || !bookUnit || !bookSection) {
      return {prevDisabled: false, nextDisabled: false};
    }

    const sectionIndex = bookUnit.sections.findIndex(
      ({section_name}) => section_name === bookSection.section_name,
    );

    return {
      prevDisabled: sectionIndex === 0,
      nextDisabled: sectionIndex === bookUnit.sections.length - 1,
    };
  }, [book, bookUnit, bookSection]);

  const classroomMetaColor = useMemo(() => {
    return Helpers.getMetaColors(classroomStorage?.classroom_color || 'blue');
  }, [classroomStorage]);

  const onChangeUnit = (unit: BookDetailUnit) => {
    if (isMobile && !isHiddenActivitiesMobile) {
      setHiddenActivitiesMobile(true);
      setBookUnit(unit);
    } else {
      setBookUnit(bookUnit?.id === unit.id ? undefined : unit);
    }
  };

  const onChangeSection = (section: BookDetailSection, index: number) => {
    const sortedSection = sortSectionActivitiviesByType(section);
    setBookSection(sortedSection);
    setHiddenActivitiesMobile(false);
  };

  const onHandlePrev = () => {
    if (!bookUnit || !bookSection) return;

    const sectionIndex = bookUnit.sections.findIndex(
      ({section_name}) => section_name === bookSection.section_name,
    );

    const section = bookUnit.sections[sectionIndex - 1];
    const sortedSection = sortSectionActivitiviesByType(section);

    setBookSection(sortedSection);
  };

  const onHandleNext = () => {
    if (!bookUnit || !bookSection) return;

    const sectionIndex = bookUnit.sections.findIndex(
      ({section_name}) => section_name === bookSection.section_name,
    );

    const section = bookUnit.sections[sectionIndex + 1];
    const sortedSection = sortSectionActivitiviesByType(section);

    setBookSection(sortedSection);
  };

  const getHeaderTitle = () => {
    if (book?.book_family === 'neutral_content' && !!book?.pages_config) {
      const sectionOutcome = book.pages_config.metadata.learningOutcomes.find(
        ({pages}) => `${book.pages_config.page_prefix} ${pages}` === bookSection?.section_name,
      );

      return sectionOutcome?.text || '';
    }

    return bookSection?.section_name || '';
  };

  const getBackgroundImage = () => {
    if (book?.book_family === 'neutral_content') {
      return Images.BgNeutralContent;
    }

    return classroomStorage ? classroomMetaColor.background : BgBlue;
  };

  if (!book) {
    return <></>;
  }

  const [pageTitle, pageSubTitle] = (() => {
    if (!classroomStorage) {
      return [
        book?.name,
        book.total_activity_items
          ? t('Book.ActivitiesItemNumberText', {
              activities: book.total_activities,
              items: book.total_activity_items,
            })
          : t('Book.ActivitiesNumberText', {activities: book?.total_activities}),
      ];
    }

    return [classroomStorage.classroom_name, classroomStorage.book_name];
  })();

  return (
    <PageWrapper isNeutralContent={book?.book_family === 'neutral_content'}>
      <Page
        variant="full"
        stickyBar={true}
        title={pageTitle}
        avatar={<BookCover src={book?.book_cover_url} alt={pageTitle} />}
        subTitle={pageSubTitle}
        backgroundImage={getBackgroundImage()}
        onClickBack={onBackPage}
        headerContent={isMobile ? null : <></>}
        footerContent={<></>}
        barContainerStyle={{display: 'none'}}>
        {isMobile && (
          <HeaderFixedMobile bgImage={BgBlue}>
            <Text size={20} fontWeight={600} value={pageTitle} color="white" />

            <Text size={14} value={pageSubTitle} color="white" marginLeft={15} />
          </HeaderFixedMobile>
        )}

        <Wrapper>
          <SidebarContainer>
            <Sidebar
              book={book}
              bookId={id}
              sticky={sticky}
              bookUnit={bookUnit}
              bookSection={bookSection}
              classroomId={classroomStorage?.classroom_id}
              onChangeUnit={onChangeUnit}
              onChangeSection={onChangeSection}
              isOriginRedirectBooks={!classroomStorage}
              isHiddenActivitiesMobile={isHiddenActivitiesMobile}
              isLicenseExpired={license?.expired === true}
            />
          </SidebarContainer>
          <Content isHidden={isHiddenActivitiesMobile}>
            <HeaderContainer sticky={sticky}>
              {bookSection && (
                <Header
                  title={getHeaderTitle()}
                  onPrev={onHandlePrev}
                  onNext={onHandleNext}
                  prevDisabled={HeaderMeta.prevDisabled}
                  nextDisabled={HeaderMeta.nextDisabled}
                />
              )}
            </HeaderContainer>
            <ContentContainer>
              {!!bookSection && !!bookSection.activities.length && (
                <Activities
                  activities={bookSection.activities}
                  classroomStorage={classroomStorage}
                  bookUnit={bookUnit}
                  bookSection={bookSection}
                  isOriginRedirectBooks={!classroomStorage}
                  isLicenseExpired={license?.expired === true}
                />
              )}
            </ContentContainer>
          </Content>
        </Wrapper>

        {showBanner && (
          <Container width="100%" style={{position: 'absolute', bottom: '0'}}>
            <Banner
              title="Dies ist nur eine Vorschau."
              description="Das Lehrwerk wird Ende Juni 2023 erscheinen."
              closeCallback={() => setShowBanner(false)}
            />
          </Container>
        )}
      </Page>
    </PageWrapper>
  );
};
