import {Theme} from '@emotion/react';
import {typography} from '@cornelsen/cvds-tokens/product/react-native/typography';
import {mq, DEVICES} from '../breakpoints';

//! Always use 'medium*' font style for fontStyle parameter.
export const getFontStyle = (theme: Theme, fontStyle: keyof typeof typography) => {
  const mobileStyle = ('small' + fontStyle.split('medium')[1]) as keyof typeof typography;

  return `${theme.typography[fontStyle]};
          ${mq[DEVICES.MOBILE]} {
            ${theme.typography[mobileStyle]};
          }
  `;
};
