import React, {StrictMode, useEffect} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from 'cc-web-components';
import {Navigation} from '@cc/navigation';
import {TranslationProvider} from '@/redux/Translation';
import store from './redux';
import {AppTheme as theme, CornelsenTheme as EduSimTheme} from '@cc/theme';
import {GlobalStyle} from './theme/GlobalStyle';
import {createRoot} from 'react-dom/client';
import {ThemeProvider as EmotionThemeProvider} from '@emotion/react';

const App: React.FC = () => {
  useEffect(() => {
    if (window._env_.REACT_APP_CORNELSEN_URL.includes('dev')) {
      document.title = 'CC Dev';
    } else if (window._env_.REACT_APP_CORNELSEN_URL.includes('stage')) {
      document.title = 'CC Stage';
    }
  }, []);

  return (
    <StrictMode>
      <Provider store={store}>
        <TranslationProvider>
          <ThemeProvider theme={EduSimTheme}>
            <EmotionThemeProvider theme={theme}>
              <GlobalStyle />
              <BrowserRouter>
                <Navigation />
              </BrowserRouter>
            </EmotionThemeProvider>
          </ThemeProvider>
        </TranslationProvider>
      </Provider>
    </StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
