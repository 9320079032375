import React from 'react';
import {DotsContainer, LoadingContainer} from './styles';

type Props = {
  isVisible?: boolean;
};

export const Loader: React.FC<Props> = ({isVisible}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <LoadingContainer>
      <DotsContainer>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </DotsContainer>
    </LoadingContainer>
  );
};
