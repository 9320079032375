import {UserType} from '@cc/shared';
import {User} from '@/redux/Session/types';
import jwt_decode from 'jwt-decode';
import {JWT} from '../types';

type ReturnType = {
  user: User;
  userType: UserType;
};

export const extractUser = (token: string): ReturnType => {
  const {
    attributes: {user_name, data},
    sub,
    authorizedClientRegistrationId,
  } = jwt_decode<JWT>(token);

  if (authorizedClientRegistrationId === 'bsp') {
    return getBspUser(sub, token, data?.firstName, data?.lastName);
  }

  return getCornelsenUser(sub, token, user_name);
};

const getBspUser = (
  sub: string,
  token: string,
  firstName?: string,
  lastName?: string,
): ReturnType => {
  return {
    user: {
      id: sub,
      name: `${firstName} ${lastName}`,
      token,
      email: '',
      profile: 'teacher-bsp',
      collections: [{code: 'cornelsen', label: 'Cornelsen', subjects: []}],
    },
    userType: 'BSP',
  };
};

const getCornelsenUser = (sub: string, token: string, user_name?: string): ReturnType => {
  return {
    user: {
      id: sub,
      name: user_name,
      token,
      email: '',
      profile: 'teacher-cv',
      collections: [{code: 'cornelsen', label: 'Cornelsen', subjects: []}],
    },
    userType: 'CORNELSEN',
  };
};
