import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';

import ApiBookService from '@/services/ApiCv/ApiBookService';
import {Book, ClassroomData, Collection} from '../types';

interface CreateClassroomContextProps {
  createClassroomData: ClassroomData;
  setCreateClassroomData: Dispatch<SetStateAction<ClassroomData>>;

  collections: Collection[];
  setCollections: Dispatch<SetStateAction<Collection[]>>;
  activeCollection: number;
  setActiveCollection: Dispatch<SetStateAction<number>>;

  books: Book[];
  setBooks: Dispatch<SetStateAction<Book[]>>;
  activeBook: number;
  setActiveBook: Dispatch<SetStateAction<number>>;

  changePayload: (data: any) => void;
}

const CreateClassroomContext = createContext<CreateClassroomContextProps | null>(null);

interface Props {
  children?: React.ReactNode;
}

const CreateClassroomProvider: React.FC<Props> = ({children}) => {
  const [createClassroomData, setCreateClassroomData] = useState<ClassroomData>({
    name: '',
    color: '',
    book: '',
    course_id: '',
    feedback: false,
    quiz: false,
  });

  const [collections, setCollections] = useState<Collection[]>([]);
  const [activeCollection, setActiveCollection] = useState<number>(-1);

  const [books, setBooks] = useState([] as Book[]);
  const [activeBook, setActiveBook] = useState<number>(0);

  useEffect(() => {
    async function loadCollections(): Promise<void> {
      ApiBookService['getAllBooks']().then(data => {
        const groupedCollections = data
          .filter(item => !item.content.modules[0].units.some(unity => !unity.todos.length))
          .sort((a, b) => {
            const firstDate = new Date(
              a.calendar.items.find(item => item.type === 'COURSE_START')?.time ?? 0,
            ).getTime();
            const secondDate = new Date(
              b.calendar.items.find(item => item.type === 'COURSE_START')?.time ?? 0,
            ).getTime();

            return firstDate - secondDate;
          })
          .reduce((accumulator: any, currentData: any) => {
            accumulator[currentData.configuration.book_family] = [
              ...(accumulator[currentData.configuration.book_family] || []),
              {name: currentData.name, id: currentData.id},
            ];

            return accumulator;
          }, {});

        const collectionsIndex = [
          'lighthouse',
          'access',
          'ahead',
          'focus',
          'headlight',
          'highlight',
          'neutral_content',
        ];

        const newCollections = Object.keys(groupedCollections)
          .map((key: any) => {
            return {name: key, books: groupedCollections[key]};
          })
          .sort((a, b) => {
            const aIndex = collectionsIndex.findIndex(collection => collection === a.name);
            const bIndex = collectionsIndex.findIndex(collection => collection === b.name);
            return aIndex - bIndex;
          });

        setCollections(newCollections);
      });
    }

    loadCollections();
  }, []);

  useEffect(() => {
    activeCollection > -1 &&
      collections.length > 0 &&
      setBooks(collections[activeCollection].books);
  }, [activeCollection, collections]);

  const changePayload = useCallback(
    (payload: any) => {
      setCreateClassroomData({...createClassroomData, ...payload});
    },
    [createClassroomData],
  );

  return (
    <CreateClassroomContext.Provider
      value={{
        createClassroomData,
        setCreateClassroomData,

        collections,
        setCollections,
        activeCollection,
        setActiveCollection,

        books,
        setBooks,
        activeBook,
        setActiveBook,

        changePayload,
      }}
    >
      {children}
    </CreateClassroomContext.Provider>
  );
};

function useCreateClassroom(): CreateClassroomContextProps {
  const context = useContext(CreateClassroomContext);

  if (!context) {
    throw new Error(`useCreateClassroom must be used within CreateClassroomProvider`);
  }

  return context;
}

export {CreateClassroomProvider, useCreateClassroom};
