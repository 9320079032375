import React, {ReactNode} from 'react';
import {Container, Text} from 'cc-web-components';

import {UserDefaultIcon} from '@/components/Icons';
import useDeviceDetection from '@/hooks/IsMobile';

interface Props {
  emoji?: ReactNode;
}

const Avatar: React.FC<Props> = ({emoji}: Props) => {
  const {isMobile} = useDeviceDetection();

  return (
    <Container
      height={isMobile ? 85 : 140}
      width={isMobile ? 85 : 140}
      background="rgba(255, 255, 255, 0.5)"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%">
      <Container
        height={isMobile ? 65 : 115}
        width={isMobile ? 65 : 115}
        background="#fff"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        border="1px solid #ddd">
        {emoji ? <Text size={32} value={emoji} /> : <UserDefaultIcon />}
      </Container>
    </Container>
  );
};

export default Avatar;
