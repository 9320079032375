import React from 'react';
import {Input} from 'cc-web-components';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectIsFeatureAvailable} from '@/redux/Rights';
import {CustomText, style} from './styles';
import {EMPTY_STRING} from '@cc/shared';

const MARGIN_TOP = 2;

interface Props {
  onChange?: (event: any) => void;
  value?: string;
  translationKey?: string;
}

export const CustomInput: React.FC<Props> = ({onChange, value, translationKey}) => {
  const {t} = useTranslation('cornelsen');
  const canUpdate = useSelector(selectIsFeatureAvailable('CLASSROOM', 'U'));

  if (canUpdate) {
    return (
      <Input
        style={style}
        value={value}
        mt={MARGIN_TOP}
        onChange={onChange}
        placeholder={translationKey ? t(translationKey) : EMPTY_STRING}
      />
    );
  }

  return <CustomText content={value} />;
};
