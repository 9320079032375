import {CHATCLASS_ANDROID_STORE, CHATCLASS_APPLE_STORE} from '@cc/shared';
import {detectPlatform} from '@cc/utils';
import {useLayoutEffect} from 'react';

export const useRedirectionToMobileStore = () => {
  useLayoutEffect(() => {
    const platform = detectPlatform();

    if (platform === 'ios') {
      window.location.href = CHATCLASS_APPLE_STORE;
    }

    if (platform === 'android') {
      window.location.href = CHATCLASS_ANDROID_STORE;
    }
  }, []);
};
