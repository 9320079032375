import Adaillu from './adaillu.png';
import AdaCelebrate from './ada-celebrate.png';
import ArrowGreen from './arrow-green.svg';
import ArrowWhite from './arrow-white.svg';
import AppIcon from './app-icon.png';
import AppIconShadow from './app-icon-with-shadow.png';
import Checked from './checked.svg';
import Class from './class.svg';
import CloseBlue from './close-blue.svg';
import Feedback from './feedback.png';
import HealthScore from './health-score.png';
import Info from './info.svg';
import PeerFeedback from './peer-feedback.png';
import PrivateMode from './private-mode.png';
import Reports from './reports.svg';
import School from './school.svg';
import Speaking from './speaking.svg';
import Tasks from './tasks.png';
import Test from './test.svg';
import UnitLock from './unit-lock.svg';
import Vocabulary from './vocabulary.svg';
import Quiz from './quiz.png';

const ProductBenefitsImages = {
  Adaillu,
  AdaCelebrate,
  ArrowGreen,
  ArrowWhite,
  AppIcon,
  AppIconShadow,
  Checked,
  Class,
  CloseBlue,
  Feedback,
  HealthScore,
  Info,
  PeerFeedback,
  PrivateMode,
  Reports,
  School,
  Speaking,
  Tasks,
  Test,
  UnitLock,
  Vocabulary,
  Quiz,
};

export default ProductBenefitsImages;
