/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import useDeviceDetection from '@/hooks/IsMobile';
import {getLicense} from '@/redux/Session';
import {useAssignActivityContext} from '@/redux/AssignActivity';

import {Wrapper, Content} from './styles';

import Tabs, {TabsTypes} from './Tabs';
import TabNewTask from './TabNewTask';
import TabNewClass from './TabNewClass';
import TabAssignLicense from './TabAssignLicense';
import {CreateClassroomProvider} from './TabNewClass/Context';

import '@reach/slider/styles.css';
import {getIsShowTabNewTask} from '@/redux/ClassRoom';
import {Routes} from '@cc/shared';
import {selectIsFeatureAvailable} from '@/redux/Rights';

const {NEW_BOOKS} = Routes;

const AssignActivities: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const {isMobile} = useDeviceDetection();
  const license = useSelector(getLicense);
  const [
    {onOpened, onOpenedAssignLicense, isEditClass, isActivateClass},
    {toggleVisibleForm, toggleIsEditClass, toggleIsActivateClass, ...dispatchPlaylist},
  ] = useAssignActivityContext();
  const [tabActived, setTabActived] = useState(TabsTypes.NEW_TASK);
  const {isShowTabNewTask} = useSelector(getIsShowTabNewTask);
  const canCreateClassroom = useSelector(selectIsFeatureAvailable('CLASSROOM', 'C'));
  const hideTaskTab =
    location.pathname === '/' ||
    location.pathname === '/c/' ||
    location.pathname.includes('new-books') ||
    location.pathname === '/imprint';
  const handleClickWrapper = useCallback(
    (event: any) => {
      if (!wrapperRef || !wrapperRef.current) return;

      if (wrapperRef.current.contains(event.target)) return;

      isEditClass && toggleIsEditClass();
      isActivateClass && toggleIsActivateClass();
      toggleVisibleForm();
    },
    [toggleVisibleForm, isEditClass, isActivateClass],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickWrapper);

    return () => {
      document.removeEventListener('mousedown', handleClickWrapper);
    };
  }, [handleClickWrapper]);

  useEffect(() => {
    if (onOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';

      dispatchPlaylist.clearPayloadForm();

      dispatchPlaylist.clearPlaylistForm();

      dispatchPlaylist.toggleErrorSteps(undefined);

      dispatchPlaylist.toggleErrorStepThree(false);
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [onOpened]);

  useEffect(() => {
    if (onOpenedAssignLicense) {
      setTabActived(TabsTypes.ASSIGN_LICENSE);
      return;
    }

    if (isEditClass || hideTaskTab) {
      setTabActived(TabsTypes.NEW_CLASS);
      return;
    }

    const isNewClassTab = ['/', NEW_BOOKS].includes(location.pathname);
    const canActiveClassTab = !isShowTabNewTask || isActivateClass || isNewClassTab;

    if (canActiveClassTab && canCreateClassroom) {
      setTabActived(TabsTypes.NEW_CLASS);
      return;
    }

    setTabActived(TabsTypes.NEW_TASK);
  }, [isShowTabNewTask, isEditClass, isActivateClass, onOpenedAssignLicense, onOpened]);

  if (!onOpened) {
    return <></>;
  }

  return (
    <Wrapper>
      <Content ref={wrapperRef} isMobile={isMobile}>
        <Tabs
          indexActived={tabActived}
          onChange={index => setTabActived(index)}
          isLicenseExpired={license?.expired === true}
        />
        {tabActived === TabsTypes.NEW_TASK && (
          <CreateClassroomProvider>
            <TabNewTask
              onClose={() => {
                isActivateClass && toggleIsActivateClass();
                toggleVisibleForm();
              }}
            />
          </CreateClassroomProvider>
        )}

        {tabActived === TabsTypes.NEW_CLASS && (
          <CreateClassroomProvider>
            <TabNewClass
              onClose={() => {
                isActivateClass && toggleIsActivateClass();
                isEditClass && toggleIsEditClass();
                toggleVisibleForm();
              }}
            />
          </CreateClassroomProvider>
        )}

        {tabActived === TabsTypes.ASSIGN_LICENSE && (
          <TabAssignLicense onClose={toggleVisibleForm} />
        )}
      </Content>
    </Wrapper>
  );
};

export default AssignActivities;
