import {Classroom} from '@/services/ApiCv/types';
import Images from '@/assets/images';

type MetaColors = {
  background: string;
  highlightColor: string;
};

export const getHeaderBackground = (metaColors: MetaColors, classroom?: Classroom) => {
  if (classroom?.metadata.inactive) {
    return Images.InactiveColor;
  }

  return !!classroom?.license?.expired ? Images.GreyColor : metaColors?.background;
};
