import React from 'react';
import {Container} from './styles';

interface Props {
  content?: string;
  children?: React.ReactNode;
}

export const Text: React.FC<Props> = ({content, children, ...props}) => {
  return <Container {...props}>{content || children}</Container>;
};
