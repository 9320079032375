import {getUser, loginSuccess} from '@/redux/Session';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getIsTokenExpired, getToken, extractUser, getIdFromToken} from '../utils';
import Storage from '@/services/Storage';
import {setConfig} from '@/redux/Rights';
import {useLogout} from '@cc/hooks';

export const useIsAuthenticated = () => {
  const dispatch = useDispatch();
  const {logout: clearData} = useLogout();
  const previousUser = useSelector(getUser);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);

  const checkPreviousUser = useCallback(
    (newUserId?: string) => {
      if (!previousUser?.id || newUserId === previousUser.id) {
        return;
      }

      clearData();
    },
    [clearData, previousUser?.id],
  );

  const onLoginSuccess = useCallback(
    (token: string) => {
      const {user, userType} = extractUser(token);

      dispatch(loginSuccess({user}));
      dispatch(setConfig(userType));
      setIsAuthenticated(true);
    },
    [dispatch],
  );

  const checkStorage = useCallback(async () => {
    const token = await Storage.getCvToken();
    const isExpired = getIsTokenExpired(token);

    if (!token || isExpired) {
      clearData();
      setIsAuthenticated(false);
      return;
    }

    onLoginSuccess(token);
  }, [clearData, onLoginSuccess]);

  const handleAuth = useCallback(() => {
    const token = getToken('jwt');

    if (token) {
      const incomingUserId = getIdFromToken(token);
      checkPreviousUser(incomingUserId);
    }

    const isExpired = getIsTokenExpired(token);
    if (isExpired) {
      checkStorage();
      return;
    }

    Storage.setCvToken(token);
    onLoginSuccess(token);
  }, [checkPreviousUser, checkStorage, onLoginSuccess]);

  useEffect(() => {
    handleAuth();
  }, [handleAuth]);

  return isAuthenticated;
};
