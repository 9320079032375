import styled from 'styled-components'

export const DroppableWrapper = styled.div`
  padding: 16px;
  border: 2px dashed #eee;
  border-radius: 4px;

  div.active {
    opacity: 0.5;
  }
`

export const CustomInputFileLabel = styled.label`
  cursor: pointer;

  input {
    display: none;
  }
`
export const Image = styled.img`
  width: 200px;
  height: 200px;
`
export const TextareaContainer = styled.div`
  display: flex;

  textarea::placeholder {
    color: #999;
  }
`

export const PhotoPreview = styled.div`
  display: flex;
  align-items: center;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin: 8px 8px 0;

  font-size: 14px;
  font-family: 'CV_Source_San', sans-serif;

  img {
    width: 75px;
    height: 75px;
    border-radius: 4px;
  }
`

export const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;

  input {
    position: absolute;
    top: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .check {
      background: #fff;
      border: 1px solid #000;

      &::after {
        display: block;
      }
    }
  }

  .check {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;

    &:after {
      top: 2px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: solid rgb(26, 115, 232);
      border-radius: 1px;
      border-width: 0 3px 3px 0;
      --webkit-transform: rotate(45deg);
      --ms-transform: rotate(45deg);
      transform: rotate(45deg);

      content: '';
      position: absolute;
      display: none;
    }
  }
`
