import {useEffect} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {Card, Container, Text} from 'cc-web-components';
import {Wrapper} from './styles';
import {useTheme} from 'styled-components';
import {useLogout} from '@cc/hooks';
import {Routes} from '@cc/shared';
import {useSelector} from 'react-redux';
import {selectUserType} from '@/redux/Rights';

const {LOGIN, LOGIN_BSP} = Routes;

export const LogoutPage: React.FC = () => {
  const {colors} = useTheme();
  const {logout} = useLogout();
  const {t} = useTranslation('logout');
  const userType = useSelector(selectUserType);

  useEffect(() => {
    logout();
  }, [logout]);

  const redirectToLogin = () => {
    const loginPath = userType === 'BSP' ? LOGIN_BSP : LOGIN;
    window.location.href = window.location.origin + loginPath;
  };

  return (
    <Wrapper>
      <Container>
        <Card>
          <Text
            size={16}
            value={
              <Trans t={t} i18nKey="Text" values={{link: 'hier'}}>
                <span
                  style={{cursor: 'pointer', color: colors.primary}}
                  onClick={redirectToLogin}
                />
              </Trans>
            }
          />
        </Card>
      </Container>
    </Wrapper>
  );
};
