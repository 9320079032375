import styled from 'styled-components'

export const LoadingContainer = styled.div`
 position: fixed;
  z-index: 40;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 10px 15px;
  background: rgba(34, 34, 34, 0.4);
`

export const DotsContainer = styled.div`
  position: absolute;
  left: calc(50% - 37.5px);
  top: calc(50% - 37.5px);
  width: 75px;
  height: 75px;

 div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #1A73E8;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

`