import {useRef, useEffect, useMemo} from 'react';
import {useTheme} from 'styled-components';
import {Container, Text, Table, Modal, Button} from 'cc-web-components';
import useDeviceDetection from '@/hooks/IsMobile';
import {ModalInnerContainer, TableTaskActivityContainer, style} from './styles';

type Data = {
  firstColumn: string;
  secondColumn: string;
};

const data: Data[] = [
  {
    firstColumn: 'Unterauftragnehmer (Name, Rechtsform, Sitz der Gesellschaft)',
    secondColumn: 'Astrid Education AB, Norrlandsgatan 24, 111 43 Stockholm, Sweden',
  },
  {
    firstColumn: 'Verarbeitungsstandort',
    secondColumn: 'EU',
  },
  {
    firstColumn: 'Art der Dienstleistung',
    secondColumn: 'Sprachtransskription, Analyse der Aussprache',
  },
];

interface Props {
  show: boolean;
  closeRequest: () => void;
}

export const AnnouncementModal: React.FC<Props> = ({show, closeRequest}) => {
  const {colors} = useTheme();
  const {isMobile} = useDeviceDetection();
  const modalRef = useRef<HTMLDivElement>(null);
  const maxWidth = useMemo(() => (isMobile ? 700 : 350), [isMobile]);

  const columns = useMemo(
    () => [
      {
        renderItem: (item: Data) => (
          <Container ml={3} maxWidth={maxWidth} style={style.columnItem}>
            <Text fontSize={18} value={item.firstColumn} fontWeight={600} />
          </Container>
        ),
      },
      {
        renderItem: (item: Data) => (
          <Container ml={3} maxWidth={maxWidth} style={style.columnItem}>
            <Text fontSize={18} value={item.secondColumn} />
          </Container>
        ),
      },
    ],
    [maxWidth],
  );

  useEffect(() => {
    if (!show) {
      return;
    }

    const clickHandler = ({target}: any) => {
      if (!modalRef.current) return;

      if (!modalRef.current?.contains(target)) {
        closeRequest();
      }
    };
    modalRef.current?.focus();
    document.addEventListener('mouseup', clickHandler);

    return () => {
      document.removeEventListener('mouseup', clickHandler);
    };
  }, [closeRequest, show]);

  const trapFocus = (event: KeyboardEvent, firstElement: HTMLElement, lastElement: HTMLElement) => {
    if (event.key === 'Tab' && document.activeElement === lastElement) {
      event.preventDefault();
      firstElement.focus();
    }
  };

  useEffect(() => {
    if (!show || !modalRef.current) {
      return;
    }
    const focusable: NodeList = modalRef?.current?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );
    const firstElement = focusable[0] as HTMLElement;
    const lastElement = focusable[focusable.length - 1] as HTMLElement;
    modalRef.current.focus();
    document.addEventListener('keydown', event => trapFocus(event, firstElement, lastElement));
    return () => {
      document.removeEventListener('keydown', event => trapFocus(event, firstElement, lastElement));
    };
  }, [show]);

  return (
    <Container id="modalWrapper">
      <Modal show={show} width="720px" padding="0">
        <ModalInnerContainer ref={modalRef} tabIndex={0}>
          <Container flexDirection="column" style={style.container}>
            <Text
              style={{lineHeight: '28px'}}
              fontSize={isMobile ? 18 : 24}
              fontWeight={600}
              value="Information über die beabsichtigte Ersetzung von Unterauftragnehmern "
            />
            <Container flexDirection="column">
              <Text
                fontSize={isMobile ? 14 : 18}
                value="Gemäß §5 Abs.1 des Vertrages zur Auftragsverarbeitung zur Nutzung des Produkts ChatClass "
              />
              <br />
              <Text
                fontSize={isMobile ? 14 : 18}
                value="Cornelsen plant, den bisherigen Unterauftragnehmer „Microsoft“ zum 25.04.2024 durch „Astrid Education AB“ zu ersetzen."
              />
              <Text
                fontSize={isMobile ? 14 : 18}
                value="Durch diese Änderungen wollen wir sicherzustellen, dass alle Nutzungsdaten von ChatClass ausschließlich innerhalb der EU verarbeitet werden."
              />
              <Text
                fontSize={isMobile ? 14 : 18}
                mb={2}
                value="Hier die aktualisierte Liste der Unterauftragnehmer (Anlage 2 des Auftragsverarbeitungsvertrages):"
              />
            </Container>
            <TableTaskActivityContainer>
              <Table id="tableTaskActivity" columns={columns} data={data} />
            </TableTaskActivityContainer>
          </Container>
          <Container justifyContent="center">
            <Button
              fontSize={20}
              fontWeight={400}
              width={496}
              height={48}
              variant="solid"
              type="button"
              value={'Verstanden'}
              onClick={closeRequest}
            />
          </Container>
        </ModalInnerContainer>
      </Modal>
    </Container>
  );
};
