import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import {MdClose} from 'react-icons/md';
import {useTranslation} from 'react-i18next';
import {Modal, Container, Text, Button} from 'cc-web-components';

import Images from '@/assets/images';

import {Props} from './types';

import '../styles.scss';
import {InfoIcon} from './styles';

const AccessCodeModal = ({
  show,
  closeRequest,
  studentName,
  joinDate,
  joinTime,
  currentCode,
  newCodeRequest,
}: Props) => {
  const {t} = useTranslation('cornelsen');
  const [code, setCode] = useState('');

  useEffect(() => {
    if (code) return;

    setCode(currentCode);
    newCodeRequest(currentCode);
  }, [newCodeRequest, code, currentCode]);

  const submitForm = async (values: any) => {
    const {studentCode} = values;
    newCodeRequest(studentCode);
  };

  const topDescription = `${studentName} ${t('Student.Dialogs.AccessCode.IsJoinedText')}`;
  const topDescriptionEmpty = `${studentName} ${t('Student.Dialogs.AccessCode.IsntJoinedText')}`;

  return (
    <Container id="modalWrapper">
      <Modal show={show} width="650px" padding="20px 0">
        <Container
          justifyContent="center"
          pb={20}
          borderBottom="2px solid #81aac2"
          m={['0 20px', '0 20px', '0 30px', '0 30px']}>
          <Text size={20} fontWeight="600" value={t('Student.Dialogs.AccessCode.Title')} />
        </Container>

        <Formik initialValues={[]} onSubmit={submitForm}>
          {({handleChange, handleBlur, handleSubmit}) => {
            return (
              <>
                <Container justifyContent="space-between" m="12px 30px 30px">
                  <Text
                    value={!!joinDate ? topDescription : topDescriptionEmpty}
                    size={16}
                    fontWeight={600}
                  />
                  <Container>
                    <Text value={joinDate} size={16} mr={30} color="#808080" fontWeight={600} />
                    <Text value={joinTime} size={16} color="#808080" fontWeight={600} />
                  </Container>
                </Container>

                <Container
                  justifyContent="space-between"
                  backgroundColor="#e8f1fb"
                  height={60}
                  alignItems="center"
                  mb={46}>
                  <Text
                    value={t('Student.Dialogs.AccessCode.CodeInfoText')}
                    size={18}
                    ml={30}
                    fontWeight={600}
                    color="#808080"
                  />
                  <Text
                    name="studentCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={'#' + currentCode}
                    size={18}
                    mr={30}
                    color="#00aa66"
                    fontWeight={600}
                  />
                </Container>

                <Container
                  position="relative"
                  flexDirection="column"
                  justifyContent="center"
                  border="1px dashed #999"
                  mb={4}
                  backgroundColor="#f8f8f8">
                  <Container
                    position="absolute"
                    top={-21}
                    left="50%"
                    alignItems="center"
                    justifyContent="center"
                    width={42}
                    height={42}
                    ml={-21}
                    border="1px solid #999"
                    borderRadius={21}
                    bg="#fff">
                    <InfoIcon src={Images.InfoIcon} alt="" />
                  </Container>
                  <p style={{maxWidth: '560px', margin: '40px auto', textAlign: 'center'}}>
                    {t('Student.Dialogs.AccessCode.InformationText')}
                  </p>
                </Container>

                <Container
                  mt={3}
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                  position="absolute"
                  right={70}
                  bottom={-22}>
                  <Button
                    width={44}
                    type="button"
                    variant="solid"
                    value=""
                    height={44}
                    mr={20}
                    leftContent={
                      <Container>
                        <MdClose size={30} color="#1A73E8" />
                      </Container>
                    }
                    style={{backgroundColor: '#fff', border: '1px solid #ddd'}}
                    onClick={closeRequest}
                  />
                  <Button
                    width={220}
                    type="submit"
                    variant="solid"
                    value={t('Student.Dialogs.AccessCode.ButtonSubmitText')}
                    fontSize={18}
                    fontWeight={400}
                    height={44}
                    leftContent={
                      <Container mr={2}>
                        <img src={Images.ReloadWhite} width={24} height={22} alt="" />
                      </Container>
                    }
                    onClick={handleSubmit}
                  />
                </Container>
              </>
            );
          }}
        </Formik>
      </Modal>
    </Container>
  );
};

export default AccessCodeModal;
