import facepaint from 'facepaint';

export const DEVICES = {
  MOBILE: 0,
  TABLET: 1,
  DESKTOP: 2,
  LARGE_DESKTOP: 3,
};

const breakpoints = [576, 768, 992, 1200];
export const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);
export const mqArray = facepaint(mq);
