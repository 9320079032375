import React from 'react';
import {Container} from './styles';
import {Text} from '@/components';

export const HealthPage: React.FC = () => {
  return (
    <Container>
      <Text content="The Application is up and running!" />
    </Container>
  );
};
