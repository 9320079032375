import {EMPTY_STRING} from '@cc/shared';
import Images from '@/assets/images';
import BgBlue from '@/assets/images/bg-sky.jpg';
import BgGreen from '@/assets/images/bg-green.jpg';
import BgOrange from '@/assets/images/bg-orange.jpg';
import BgTeal from '@/assets/images/bg-teal.jpg';

type BackgroundColors = 'green' | 'blue' | 'orange' | 'teal';
const BACKGROUNDS: {[key in BackgroundColors]: string} = {
  green: BgGreen,
  blue: BgBlue,
  orange: BgOrange,
  teal: BgTeal,
};

const getUrl = (image: string) => {
  return 'url(' + image + ')';
};

export const getBackgroundImage = (color: string, isInactive?: boolean | null) => {
  if (isInactive) {
    return getUrl(Images.InactiveColor);
  }

  const imageUrl = BACKGROUNDS[color as BackgroundColors];

  if (!imageUrl) {
    return EMPTY_STRING;
  }

  return getUrl(imageUrl);
};
