import styled from 'styled-components'

export const LicenseBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 65px;
  background: #8896a9;

  & > div {
    display: flex;
    justify-content: space-between;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
`;

export const LicenseBarColumn = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 20px;

  &:nth-child(1) {
      justify-content: flex-start;
  }

  &:nth-child(2) {
    justify-content: center;
    flex-direction: column;

    & > div {
      display: flex;
      justify-content: space-between;
      width: 375px;  
      max-width: 100%;
      margin-bottom: 8px;
    }
  }

  &:last-child {
    flex-direction: row;
    justify-content: flex-end;
    
    & > div {
      width: auto;  
      margin-bottom: 0;
    }
  }
`

export const ExpiredLicenseBarColumn = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`

export const TrialDaysBar = styled.div`
  display: flex;  
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, .1);

`

export const RemainingDaysBar = styled.div<{
  totalDays: number;
	leftDays: number;
}>`
  display: flex;
  width: ${(props) => props.leftDays / props.totalDays * 100}%;
  height: 100%;
  border-radius: 5px;
  background: #fff;
`
