import React, {memo} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {useAndroidFallbackLinking, useAndroidInstallPrompt} from './hooks';

//! Source: https://tools.applemediaservices.com/app/1487598001?country=us
const CHATCLASS_APPLE_APP_ID = '1487598001';

export const SmartBanner: React.FC = memo(() => {
  useAndroidInstallPrompt();
  useAndroidFallbackLinking();

  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="apple-itunes-app"
          content={`app-id=${CHATCLASS_APPLE_APP_ID}, app-argument=${window.location.href}`}
        />
      </Helmet>
    </HelmetProvider>
  );
});
