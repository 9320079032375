import React from 'react';
import {CustomText, IconContainer, QRContainer} from './styles';
import QRCode from 'react-qr-code';
import {
  CHATCLASS_ANDROID_STORE,
  CHATCLASS_APPLE_STORE,
  LERNEN_CORNELSEN_ANDROID_STORE,
  LERNEN_CORNELSEN_APPLE_STORE,
} from '@/shared';
import {ChatclassRounded, CornelsenLernenRounded} from '@/assets';
import {Container} from 'cc-web-components';

interface Props {
  app: 'cornelsen-lernen' | 'chatclass';
  showIcon?: boolean;
}

export const QRCodeWithIcon: React.FC<Props> = ({app, showIcon}) => {
  const isAppChatClass = app === 'chatclass';

  return (
    <Container style={{justifyContent: showIcon ? undefined : 'center'}}>
      {showIcon && (
        <IconContainer>
          {isAppChatClass ? (
            <ChatclassRounded width={80} height={80} />
          ) : (
            <CornelsenLernenRounded width={80} height={80} />
          )}
        </IconContainer>
      )}
      <Container>
        <QRContainer>
          <QRCode
            style={{height: 'auto', maxWidth: '100%', width: '80px'}}
            value={isAppChatClass ? CHATCLASS_APPLE_STORE : LERNEN_CORNELSEN_APPLE_STORE}
          />
          <CustomText content={'Apple App Store'} />
        </QRContainer>
        <QRContainer>
          <QRCode
            style={{height: 'auto', maxWidth: '100%', width: '80px'}}
            value={isAppChatClass ? CHATCLASS_ANDROID_STORE : LERNEN_CORNELSEN_ANDROID_STORE}
          />
          <CustomText content={'Google Play Store'} />
        </QRContainer>
      </Container>
    </Container>
  );
};
