import React from 'react';
import {Container, CustomText} from './styles';
import {useSelector} from 'react-redux';
import {selectUserType} from '@/redux/Rights';
import {ORGANIZATIONS} from '@cc/shared';
import {useTranslation} from 'react-i18next';

const FALLBACK = 'CORNELSEN';

interface Props {
  isVisible?: boolean;
}

export const NoModificationDisclaimer: React.FC<Props> = ({isVisible}) => {
  const {t} = useTranslation('organizations');
  const userType = useSelector(selectUserType);
  const organization = ORGANIZATIONS[userType || FALLBACK];

  if (!isVisible) {
    return null;
  }

  return (
    <Container>
      <CustomText content={t('disclaimer', {organization})} />
    </Container>
  );
};
