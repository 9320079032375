import styled from 'styled-components'

export const TableContainer = styled.div`
  #tableActivities {
    box-shadow: none;

    > div > table {
      border-spacing: 0;

      > thead {
        display: none;
      }

      > tbody > tr {
        td {
          border-top: 1px solid ${(props) => props.theme.colors.gray[5]};

          &:nth-child(1) {
          width: 180px;
          max-width: 180px;
          }
          &:nth-child(2) {
          }
          &:nth-child(3) {
            width: 100px;
            max-width: 100px;
            padding-left: 24px;
          }
          &:nth-child(4) {
            width: 100px;
            max-width: 100px;
          }
          &:nth-child(5) {
            width: 75px;
            max-width: 75px;
          }
        }
      }
    }
  }
`
