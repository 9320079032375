import React from 'react';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Button, Container} from 'cc-web-components';
import {useLocation} from 'react-router-dom';
import {useAssignActivityContext} from '@/redux/AssignActivity';
import {getIsShowTabNewTask} from '@/redux/ClassRoom';
import {selectIsFeatureAvailable} from '@/redux/Rights';
import {Routes} from '@cc/shared';

const {NEW_CLASSROOM, BOOK, CLASSROOM} = Routes;

type TabsProps = {
  indexActived: number;
  isLicenseExpired?: boolean;
  onChange: (indexActived: number) => void;
};

export const TabsTypes = {
  NEW_TASK: 0,
  NEW_CLASS: 1,
  ASSIGN_LICENSE: 2,
};

const Tabs: React.FC<TabsProps> = ({indexActived, onChange, isLicenseExpired}) => {
  const {t} = useTranslation('cornelsen');
  const location = useLocation();
  const {colors} = useTheme();
  const [{playlist, onOpenedAssignLicense, isEditClass}] = useAssignActivityContext();
  const {isShowTabNewTask} = useSelector(getIsShowTabNewTask);
  const isNewTaskTab =
    location.pathname.includes(NEW_CLASSROOM) ||
    location.pathname.includes(BOOK) ||
    location.pathname.includes(CLASSROOM);

  const hideTaskTab =
    location.pathname === '/' ||
    location.pathname === '/c/' ||
    location.pathname.includes('new-books') ||
    location.pathname === '/imprint';
  const canCreateClassroom = useSelector(selectIsFeatureAvailable('CLASSROOM', 'C'));

  const getTabsItems = () => {
    if (isShowTabNewTask && !onOpenedAssignLicense) {
      if (isEditClass || hideTaskTab) {
        return [
          {
            id: TabsTypes.NEW_CLASS,
            label: t('AssignActivity.Tabs.NewClass.Title'),
            disabled: false,
            isVisible: true,
          },
        ];
      }

      if (isNewTaskTab) {
        return [
          {
            id: TabsTypes.NEW_TASK,
            label: t('AssignActivity.Tabs.NewTask.Title'),
            disabled: false,
            isVisible: true,
          },
        ];
      }

      return [
        {
          id: TabsTypes.NEW_TASK,
          label: t('AssignActivity.Tabs.NewTask.Title'),
          disabled: false,
          isVisible: true,
        },
        {
          id: TabsTypes.NEW_CLASS,
          label: t('AssignActivity.Tabs.NewClass.Title'),
          disabled: false,
          isVisible: canCreateClassroom,
        },
      ];
    }

    if (!isShowTabNewTask && !onOpenedAssignLicense) {
      return [
        {
          id: TabsTypes.NEW_CLASS,
          label: t('AssignActivity.Tabs.NewClass.Title'),
          disabled: false,
          isVisible: canCreateClassroom,
        },
      ];
    }

    return [
      {
        id: TabsTypes.ASSIGN_LICENSE,
        label: t('AssignActivity.Tabs.NewClass.StepLicenseSelect.Title'),
        disabled: false,
        isVisible: true,
      },
    ];
  };

  const TABS_ITEMS = getTabsItems();

  const handleChange = (index: number) => {
    onChange(index);
  };

  return (
    <Container pl={3} pr={3} alignItems="center" backgroundColor="#fff">
      {!playlist
        ? TABS_ITEMS.map(
            item =>
              item.isVisible && (
                <Button
                  key={item.id}
                  color={colors.black}
                  value={item.label}
                  onClick={() => handleChange(item.id)}
                  variant={item.id === indexActived ? 'tab-active' : 'tab'}
                  fontSize={14}
                  disabled={item.disabled}
                />
              ),
          )
        : null}
    </Container>
  );
};

export default Tabs;
