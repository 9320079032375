import React, {useState} from 'react';
import {useTheme} from 'styled-components';
import {Card, Container, Text} from 'cc-web-components';
import {useTranslation} from 'react-i18next';
import {Cell, Pie, PieChart, Tooltip} from 'recharts';

import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from 'react-icons/md';
import {FaCircle} from 'react-icons/fa';
import useDeviceDetection from '@/hooks/IsMobile';
import useDeviceDetectionSm from '@/hooks/IsSmall';
import {Competency} from '@/services/ApiCv/types';
import {EmptyMessage, TooltipContainer} from './styles';
import {StyledCard} from '../../atoms';

type Props = {
  competency?: Competency;
};

const COLORS = ['#F94144', '#F3722B', '#F7961E', '#F9C74E', '#91BE6D', '#2C9CDB'];

export const Competences: React.FC<Props> = ({competency}: Props) => {
  const {colors} = useTheme();
  const {t} = useTranslation('cornelsen');
  const {isMobile} = useDeviceDetection();
  const {isSmall} = useDeviceDetectionSm();
  const [isInitialPosition, setIsInitialPosition] = useState(true);

  const renderCardItem = (title: string, value: string | number, img?: any, hideBorder = false) => {
    return !isSmall ? (
      <Container
        flex={1}
        px={2}
        flexDirection="column"
        justifyContent="space-between"
        borderRight={hideBorder ? '' : '1px solid #eee'}>
        <Text color={colors.black} size={14} value={title} />
        <Container flexDirection="row" alignItems="center" justifyContent="space-between">
          <Text color={colors.black} size={24} lineHeight="40px" value={value} />
          {img}
        </Container>
      </Container>
    ) : (
      <Container
        flex={1}
        px={2}
        flexDirection="column"
        justifyContent="space-between"
        borderRight={hideBorder ? '' : '1px solid #eee'}>
        {img}
        <Container className={'boxTextWrapper'}>
          <Text
            color={colors.black}
            size={14}
            value={title}
            style={{width: '86%', minHeight: '36px'}}
          />
        </Container>
        <Text color={colors.black} size={24} lineHeight="40px" value={value} />
      </Container>
    );
  };

  const totalExercices = React.useMemo(() => {
    if (competency) {
      const {
        grammarTotalQuestion,
        listeningTotalQuestion,
        pronunciationTotalQuestion,
        readingTotalQuestion,
        speakingTotalQuestion,
        vocabTotalQuestion,
      } = competency;

      return (
        grammarTotalQuestion +
        listeningTotalQuestion +
        pronunciationTotalQuestion +
        readingTotalQuestion +
        speakingTotalQuestion +
        vocabTotalQuestion
      );
    }

    return 0;
  }, [competency]);

  const totalExercisesCorrectness = React.useMemo(() => {
    if (competency) {
      const {
        grammarCorrectness,
        listeningCorrectness,
        pronunciationCorrectness,
        readingCorrectness,
        speakingCorrectness,
        vocabularyCorrectness,
      } = competency;

      const total =
        (grammarCorrectness +
          listeningCorrectness +
          pronunciationCorrectness * 100 +
          readingCorrectness +
          speakingCorrectness +
          vocabularyCorrectness) /
        6;

      return total ? total.toFixed(0) : 0;
    }

    return 0;
  }, [competency]);

  const pronunciationCorrectness = React.useMemo(() => {
    if (competency && competency.pronunciationCorrectness) {
      const {pronunciationCorrectness} = competency;

      return (pronunciationCorrectness * 100).toFixed(0);
    }

    return 0;
  }, [competency]);

  const scroll = (offset: number) => {
    const cardContainer = document.getElementById('cardContainer');
    if (cardContainer) cardContainer.scrollLeft += offset;
  };

  const onClickLeftButton = () => {
    scroll(-1000);
    setIsInitialPosition(!isInitialPosition);
  };

  const onClickRightButton = () => {
    scroll(1000);
    setIsInitialPosition(!isInitialPosition);
  };

  const FooterCardGraph = () => {
    if (isMobile && totalExercices) {
      return (
        <>
          <Container justifyContent="space-between">
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[0]} />

              <Text ml={2} size={14} color="grey" value="Sprechen" fontWeight={500} />
            </Container>
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[1]} />

              <Text ml={2} size={14} color="grey" value="Aussprache" fontWeight={500} />
            </Container>
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[2]} />

              <Text ml={2} size={14} color="grey" value="Vokabeln" fontWeight={500} />
            </Container>
          </Container>
          <Container mt={2} justifyContent="space-between">
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[3]} />

              <Text ml={2} size={14} color="grey" value="Grammatik" fontWeight={500} />
            </Container>
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[4]} />

              <Text ml={2} size={14} color="grey" value="Hören" fontWeight={500} />
            </Container>
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[5]} />

              <Text ml={2} size={14} color="grey" value="Lesen" fontWeight={500} />
            </Container>
          </Container>
        </>
      );
    }

    return null;
  };

  const CustomTooltip = ({payload, active}: any) => {
    if (active && payload && payload.length) {
      return <TooltipContainer>{`${payload[0].name}: ${payload[0].value}`}</TooltipContainer>;
    }

    return null;
  };

  return (
    <>
      <StyledCard>
        <button
          className={'scrollButton'}
          onClick={() => onClickLeftButton()}
          style={{visibility: !isInitialPosition && isSmall ? 'visible' : 'hidden'}}>
          <MdKeyboardArrowLeft />
        </button>
        <button
          className={'scrollButton'}
          onClick={() => onClickRightButton()}
          style={{visibility: isInitialPosition && isSmall ? 'visible' : 'hidden'}}>
          <MdKeyboardArrowRight />
        </button>
        <Card
          title={t('Home.SectionActivities.CardCompetency.Title')}
          style={{flexDirection: 'column', maxWidth: 'unset', height: '180px'}}>
          <Container id={'cardContainer'} height="100%">
            {renderCardItem(
              t('Home.SectionActivities.CardCompetency.ExercisesTitle'),
              totalExercices || '-',
              <></>,
              false,
            )}
            {renderCardItem(
              t('Home.SectionActivities.CardCompetency.CorrectnessTitle'),
              totalExercisesCorrectness ? `${totalExercisesCorrectness} %` : '-',
              <></>,
            )}
            {renderCardItem(
              t('Home.SectionActivities.CardCompetency.PronunciationCorrectnessTitle'),
              pronunciationCorrectness ? `${pronunciationCorrectness} %` : '-',
              <></>,
              true,
            )}
          </Container>
        </Card>
      </StyledCard>

      <Card
        title={'Anzahl bearbeiteter Aufgaben'}
        width={!isMobile ? '30%' : '100%'}
        height={!isMobile ? '180px' : '250px'}
        justifyContent="center"
        alignItems="center"
        footerContent={FooterCardGraph()}>
        {totalExercices ? (
          <PieChart width={200} height={110} style={{overflow: 'hidden'}}>
            <Pie
              data={competency?.graphValues}
              cx={'50%'}
              cy={100}
              startAngle={180}
              endAngle={0}
              innerRadius={50}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value">
              {competency?.graphValues.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        ) : (
          <EmptyMessage>Keine Aktivität durchgeführt</EmptyMessage>
        )}
      </Card>
    </>
  );
};
