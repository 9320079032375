export type FilterTime = {
  startDate: string
  endDate: string
}

export const FilterTimeValues = {
  ALL: 0,
  TODAY: 1,
  LAST_SEVEN: 2,
  LAST_THIRTY: 3,
}

export const HealthScoreAverageValues = {
  UP: 'UP',
  DOWN: 'DOWN',
  EQUAL: 'EQUAL',
}
