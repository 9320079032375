import Storage from '@/services/Storage';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {logout as logoutAction} from '@/redux/Session';
import {openExternalLink} from '@/utils';

export const useLogout = () => {
  const dispatch = useDispatch();

  const clearLocalData = () => {
    deleteAllCookies();
    Storage.resetSession();
  };

  const logout = useCallback(() => {
    dispatch(logoutAction());
    clearLocalData();
  }, [dispatch]);

  const navigateToLogout = useCallback(() => {
    openExternalLink(`${window._env_.REACT_APP_CORNELSEN_URL}/logout`);
  }, []);

  return {navigateToLogout, logout};
};

const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  cookies.forEach(cookie => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  });
};
