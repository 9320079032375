import {
  Activity,
  ActivityMessageHtml,
  ActivityItemMessage,
  ActivityMessageAudio,
  ActivityMessageTexto,
} from '@/services/ApiCv/types/Activity'
import {
  Chat,
  ChatMessage,
  ChatMessageFromKeys,
  ChatMessageTypeKeys,
} from '@/components/MessageList/types'

function getChatMessage(
  activity: Activity,
  itemMessage: ActivityItemMessage
): ChatMessage | undefined {
  const data = {
    from: itemMessage.from_bot ? ChatMessageFromKeys.BOT : ChatMessageFromKeys.USER,
  } as ChatMessage

  const messageHtml = itemMessage.message as ActivityMessageHtml
  const messageMedia = itemMessage.message as ActivityMessageAudio

  if (!!messageHtml.html) {
    data.type = ChatMessageTypeKeys.HTML
    data.content = messageHtml.html
    data.options = itemMessage.options

    if (data.from === ChatMessageFromKeys.USER) {
      data.metadata = {
        user_name: activity.user_name,
        user_emoji: activity.user_emoji,
      }
    }

    return data
  }
  if (!!messageMedia.media) {
    if (messageMedia.media.type === ChatMessageTypeKeys.AUDIO) {
      data.type = ChatMessageTypeKeys.AUDIO
      data.content = messageMedia.media.source_url
      data.options = itemMessage.options
      data.metadata = {
        duration: messageMedia.media.metadata.duration,
        user_name: activity.user_name,
        user_emoji: activity.user_emoji,
      }

      return data
    }
    if (messageMedia.media.type === ChatMessageTypeKeys.IMAGE) {
      data.type = ChatMessageTypeKeys.IMAGE
      data.content = messageMedia.media.source_url
      data.options = itemMessage.options
      data.metadata = {
        user_name: activity.user_name,
        user_emoji: activity.user_emoji,
      }

      return data
    }
  }
}

export function getMessageListOfTheActivity(activity: Activity): Chat[] {
  const messages = [] as Chat[]

  for (let item of activity.items) {
    const chat = { index: item.index, messages: [] } as Chat

    for (let itemMessage of item.messages) {
      const message = getChatMessage(activity, itemMessage)

      if (!!message) {
        chat.messages.push(message)
      }
    }

    messages.push(chat)
  }

  for (let item of activity.feedbacks) {
    const chat = { messages: [] } as Chat

    if (!!item.feedback_message) {
      const data = { from: ChatMessageFromKeys.FEEDBACK } as ChatMessage

      const [feedbackMessage] = item.feedback_message

      const messageText = feedbackMessage as ActivityMessageTexto
      const messageHtml = feedbackMessage as ActivityMessageHtml
      const messageMedia = feedbackMessage as ActivityMessageAudio

      if (!!messageText.text) {
        data.type = ChatMessageTypeKeys.HTML
        data.content = messageText.text

        if (!!item.sender) {
          data.metadata = {
            user_name: item.sender.name,
          }
        }

        chat.messages.push(data)
      }
      if (!!messageHtml.html) {
        data.type = ChatMessageTypeKeys.HTML
        data.content = messageHtml.html

       if (!!item.sender) {
          data.metadata = {
            user_name: item.sender.name,
          }
        }

        chat.messages.push(data)
      }
      if (!!messageMedia.media) {
        if (messageMedia.media.type === ChatMessageTypeKeys.AUDIO) {
          data.type = ChatMessageTypeKeys.AUDIO
          data.content = messageMedia.media.source_url
          data.metadata = {
            duration: messageMedia.media.metadata.duration,
            user_name: item.sender.name,
          }

          chat.messages.push(data)
        }
        if (messageMedia.media.type === ChatMessageTypeKeys.IMAGE) {
          data.type = ChatMessageTypeKeys.IMAGE
          data.content = messageMedia.media.source_url
          data.metadata = {
            user_name: item.sender.name,
          }

          chat.messages.push(data)
        }
      }
    }

    messages.push(chat)
  }

  return messages
}

export function getMessageListWithoutType(activity: any): Chat[] {
  const messages = [] as Chat[]

  for (let item of activity.items) {
    const chat = { index: item.index, messages: [] } as Chat

    for (let itemMessage of item.messages) {
      const message = getChatMessage(activity, itemMessage)

      if (!!message) {
        chat.messages.push(message)
      }
    }

    messages.push(chat)
  }

  for (let item of activity.feedbacks) {
    const chat = { messages: [] } as Chat

    if (!!item.feedback_message) {
      const data = { from: ChatMessageFromKeys.FEEDBACK } as ChatMessage

      const [feedbackMessage] = item.feedback_message

      const messageText = feedbackMessage as ActivityMessageTexto
      const messageHtml = feedbackMessage as ActivityMessageHtml
      const messageMedia = feedbackMessage as ActivityMessageAudio

      if (!!messageText.text) {
        data.type = ChatMessageTypeKeys.HTML
        data.content = messageText.text

        if (!!item.sender) {
          data.metadata = {
            user_name: item.sender.name,
          }
        }

        chat.messages.push(data)
      }
      if (!!messageHtml.html) {
        data.type = ChatMessageTypeKeys.HTML
        data.content = messageHtml.html

        if (!!item.sender) {
          data.metadata = {
            user_name: item.sender.name,
          }
        }

        chat.messages.push(data)
      }
      if (!!messageMedia.media) {
        if (messageMedia.media.type === ChatMessageTypeKeys.AUDIO) {
          data.type = ChatMessageTypeKeys.AUDIO
          data.content = messageMedia.media.source_url || messageMedia.media.url
          data.metadata = {
            duration: messageMedia.media.metadata.duration,
            user_name: item.sender.name,
          }

          chat.messages.push(data)
        }
        if (messageMedia.media.type === ChatMessageTypeKeys.IMAGE) {
          data.type = ChatMessageTypeKeys.IMAGE
          data.content = messageMedia.media.source_url
          data.metadata = {
            user_name: item.sender.name,
          }

          chat.messages.push(data)
        }
      }
    }

    messages.push(chat)
  }

  return messages
}
