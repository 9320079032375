import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import session from './Session';
import activity from './Activity';
import rights from './Rights';
import classroomState from './ClassRoom';

const reducer = combineReducers({
  session,
  activity,
  classroomState,
  rights,
});

const initalState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initalState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
