import styled from 'styled-components'

export const EmptyMessage = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  background: #f8f8f8;
  border: 1px dashed #bcafa3;  

  p {
    margin: 0;
    font-size: 14px;
    text-align: center;
    line-height: 18px;
    color: #999;
  }
`
