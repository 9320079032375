import styled from 'styled-components'

export const TableContainer = styled.div`
  padding: 0px 200px 0px 120px;

  @media screen and (max-width: 1220px) {
    padding: 0px 90px 0px 80px;
  }

  @media screen and (max-width: 768px) {
    padding: 0px;
  }

  thead {
    display: none;
  }
`

export const UserEmoji = styled.div`
  background-color: #fff;
  width: 26px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -7px;
  cursor: pointer;
  font-size: 14px;

  span {
    color: #333;
    font-size: 14px;
    visibility: hidden;
    position: absolute;
    bottom: -42px;
    z-index: 10;
    background-color: #fff;
    width: 80px;
    height: 30px;
    border-radius: 0.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;

    &:before {
      content: '';
      display: block;
      height: 12px;
      width: 12px;
      background-color: inherit;
      border: 1px solid #ddd;
      position: absolute;
      top: -7px;
      left: calc(50% - 10px);
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
      transform: rotate(135deg);
      border-radius: 0 0 0 0.25em;
    }
  }

  &:hover {
    span {
      visibility: visible;
    }
  }
`
