import React, {useEffect, useState, useMemo, useCallback} from 'react';
import lodashOrdeby from 'lodash.orderby';
import {useTheme} from 'styled-components';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {BsPlus} from 'react-icons/bs';
import {BiCaretUp, BiCaretDown} from 'react-icons/bi';
import {Container, Button, Table, Text} from 'cc-web-components';
import Api from '@/services/ApiCv';
import useQueryParams from '@/hooks/QueryParams';
import useDeviceDetection from '@/hooks/IsMobile';
import {useAssignActivityContext} from '@/redux/AssignActivity';
import {getLicense, startLoading, stopLoading} from '@/redux/Session';
import Helpers, {FilterTime, FilterTimeValues} from '@/helper';
import {ToggleButton} from '@/components/ToggleButton';
import {
  MicroIcon,
  BlueBookIcon,
  BlueEditIcon,
  OpenedBookIcon,
  GroupUsersIcon,
  ChevronUpLined,
  ChevronDownLined,
  TrashOutlinedIcon,
  CalendarOutlinedIcon,
  ArrowLeftOutlinedIcon,
  ArrowRightOutlinedIcon,
} from '@/components/Icons';

import TableTasks from './TableTasks';
import TableStudents from './TableStudents';
import SidebarMessages from './SidebarMessages';
import DeleteModal from './DeleteModal';

import {TableContainer, TableColumnHeaderSortable, Emojies, EmojiItem} from './styles';

import {getPlaylists} from './helpers';
import {TaskProps, PlaylistProps} from './types';

const TabTaskKeys = {
  ACTIVE: 0,
  COMPLETED: 1,
};

enum SortConfigDirectionkeys {
  ASC = 'asc',
  DESC = 'desc',
}

type SortConfigDirection = SortConfigDirectionkeys;

type SortConfig = {
  key: null | keyof PlaylistProps;
  direction: null | SortConfigDirection;
};

type QuantiativoProps = {
  total_active: number;
  total_expired: number;
};

type Props = {
  activedTimeFilter: number;
};

const TabTasks: React.FC<Props> = ({activedTimeFilter}: Props) => {
  const {t} = useTranslation('cornelsen');
  const dispatch = useDispatch();
  const {colors} = useTheme();
  const license = useSelector(getLicense);
  const QueryParams = useQueryParams();
  const {isMobile} = useDeviceDetection();
  const {classroomId} = useParams<{classroomId: string}>();
  const [, {toggleVisibleForm, onLoadPlaylistToEdit}] = useAssignActivityContext();

  const [task, setTask] = useState<TaskProps | null>(null);
  const [playlists, setPlaylists] = useState<PlaylistProps[]>([]);
  const [quantitativos, setQuantitativos] = useState<QuantiativoProps>({
    total_active: 0,
    total_expired: 0,
  });
  const [playlistId, setPlaylistId] = useState('');
  const [isSidebarOpened, setSidebarOpened] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [datesFilterTime, setDatesFilterTime] = useState<FilterTime>();
  const [indexPlaylistDetails, setIndexPlaylistDetails] = useState(-1);
  const [activeIndexTableTasks, setActiveIndexTableTasks] = useState(TabTaskKeys.ACTIVE);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'createdAt',
    direction: SortConfigDirectionkeys.DESC,
  });

  const TABLE_FILTER = [
    <Text
      size={16}
      value={`${t('Classroom.TabTasks.TableFilterActive')} (${quantitativos.total_active})`}
      fontWeight={500}
    />,
    <Text
      size={16}
      value={`${t('Classroom.TabTasks.TableFilterCompleted')} (${quantitativos.total_expired})`}
      fontWeight={500}
    />,
  ];

  const LoadDataPlaylists = useCallback(
    (classroomId: string, expired: boolean, datesFilterTime: FilterTime) => {
      dispatch(startLoading());

      Api.getPlaylistsBy(classroomId, expired, datesFilterTime)
        .then(data => {
          const {total_active, total_expired, content} = data;
          const dataConverted = getPlaylists(content);

          setQuantitativos({total_active, total_expired});
          setPlaylists(dataConverted);

          dispatch(stopLoading());
        })
        .catch(() => {
          dispatch(stopLoading());
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const filter = Helpers.getIntervalDatesByFilterIndex(activedTimeFilter);

    setDatesFilterTime(filter);
  }, [activedTimeFilter]);

  useEffect(() => {
    if (!classroomId || !datesFilterTime) return;

    const expired = activeIndexTableTasks === TabTaskKeys.COMPLETED;

    LoadDataPlaylists(classroomId, expired, datesFilterTime);
  }, [classroomId, datesFilterTime, activeIndexTableTasks, LoadDataPlaylists]);

  useEffect(() => {
    const redirectTo = QueryParams.get('redirectTo');

    if (!redirectTo || !classroomId) return;

    setTimeout(() => {
      LoadDataPlaylists(classroomId, false, {startDate: '', endDate: ''});
    }, 1000);
  }, [QueryParams, classroomId, LoadDataPlaylists]);

  const playlistsSorted = useMemo(() => {
    if (!sortConfig.key || !sortConfig.direction) {
      return playlists;
    }

    return lodashOrdeby(playlists, sortConfig.key, sortConfig.direction);
  }, [sortConfig, playlists]);

  const onOpenDetailsPlaylist = (index: number) => {
    if (index === indexPlaylistDetails) {
      setIndexPlaylistDetails(-1);
    } else {
      setIndexPlaylistDetails(index);
    }
  };

  const onCloseDetailsTask = () => {
    setTask(null);
    setSidebarOpened(false);
  };

  const onOpenDetailsTask = (newTask: TaskProps) => {
    if (!!task && newTask.task_title === task.task_title) {
      onCloseDetailsTask();

      return;
    }

    setTask(newTask);
    setSidebarOpened(true);
  };

  const onDeletePlaylist = () => {
    dispatch(startLoading());

    Api.deletePlaylist(playlistId)
      .then(() => {
        setPlaylists(prev => prev.filter(item => item.id !== playlistId));

        dispatch(stopLoading());
      })
      .catch(() => {
        dispatch(stopLoading());
      });
    setShowDeleteModal(false);
  };

  const onEditPlaylist = (playlist: PlaylistProps) => {
    onLoadPlaylistToEdit(playlist.payload);

    toggleVisibleForm();
  };

  const onOpenFormCreatePlaylist = () => {
    toggleVisibleForm();
  };

  const RenderToggleButton = () => (
    <ToggleButton
      values={TABLE_FILTER}
      onToggle={setActiveIndexTableTasks}
      activeIndex={activeIndexTableTasks}
      style={{
        marginTop: isMobile ? '8px' : '0',
      }}
    />
  );

  const getStudentEmojies = (item: PlaylistProps) => {
    return (
      <Container alignItems="center">
        <Emojies>
          {item.students
            .filter(student => student.name !== 'Jakub Nowak')
            .slice(0, 3)
            .map(item => (
              <EmojiItem>
                {item.emoji}
                <span>{item.name}</span>
              </EmojiItem>
            ))}
        </Emojies>

        {item.students.length > 3 && <Text marginLeft={1} value={`+${item.students.length - 3}`} />}
      </Container>
    );
  };

  const requestSort = (key: keyof PlaylistProps) => {
    let direction: SortConfigDirection = SortConfigDirectionkeys.ASC;

    if (sortConfig.direction === SortConfigDirectionkeys.ASC) {
      direction = SortConfigDirectionkeys.DESC;
    }

    setIndexPlaylistDetails(-1);
    setSortConfig({key, direction});
  };

  const renderColumnSortable = (columnName: keyof PlaylistProps, children: any) => {
    const getIconsOrdered = () => {
      let opacityIconUp = 0.5;
      let opacityIconDown = 0.5;

      if (!!sortConfig.key && sortConfig.key === columnName) {
        opacityIconUp = sortConfig.direction === SortConfigDirectionkeys.ASC ? 1 : 0.5;
        opacityIconDown = sortConfig.direction === SortConfigDirectionkeys.DESC ? 1 : 0.5;
      }

      return (
        <Container flexDirection="column" marginRight={1}>
          <Container marginBottom={-2}>
            <BiCaretUp color={colors.primary} style={{opacity: opacityIconUp}} />
          </Container>

          <BiCaretDown color={colors.primary} style={{opacity: opacityIconDown}} />
        </Container>
      );
    };

    return (
      <TableColumnHeaderSortable onClick={() => requestSort(columnName)}>
        <Container flexDirection="column" marginRight={isMobile ? 0 : 1}>
          {getIconsOrdered()}
        </Container>

        {children}
      </TableColumnHeaderSortable>
    );
  };

  const getIntervalTime = () => {
    if (!datesFilterTime || !datesFilterTime?.startDate) return '';

    const startDate = Helpers.getFormatDate(datesFilterTime.startDate, 'dd.MM.yyyy');

    const endDate = Helpers.getFormatDate(datesFilterTime.endDate, 'dd.MM.yyyy', -1);

    if (activedTimeFilter === FilterTimeValues.TODAY) return startDate;

    return `${startDate} - ${endDate}`;
  };

  const onGetPrevValues = () => {
    if (!datesFilterTime) return;
    onOpenDetailsPlaylist(-1);
    const newIntervalTime = Helpers.getPrevIntervalDates(activedTimeFilter, datesFilterTime);

    setDatesFilterTime(newIntervalTime);
  };

  const onGetNextValues = () => {
    if (!datesFilterTime) return;
    onOpenDetailsPlaylist(-1);
    const newIntervalTime = Helpers.getNextIntervalDates(activedTimeFilter, datesFilterTime);

    setDatesFilterTime(newIntervalTime);
  };

  const TableHeader = () => (
    <Container flexDirection="column">
      <Container
        flex={1}
        minHeight={65}
        padding="0px 6px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          flex={0.3}
          variant="link"
          color="primary"
          onClick={onGetPrevValues}
          leftContent={
            activedTimeFilter !== FilterTimeValues.ALL ? <ArrowLeftOutlinedIcon /> : null
          }
        />

        <Text value={t('Classroom.TabTasks.TableTitle')} size={16} isBold />

        <Button
          flex={0.3}
          variant="link"
          color="primary"
          onClick={onGetNextValues}
          leftContent={
            activedTimeFilter !== FilterTimeValues.ALL ? <ArrowRightOutlinedIcon /> : null
          }
        />
      </Container>
      <Container
        height={49}
        padding="0px 20px"
        alignItems="center"
        justifyContent="center"
        backgroundColor="rgba(26, 115, 232, 0.1)"
      >
        <Text value={getIntervalTime()} color={colors.black} size={14} />
      </Container>
    </Container>
  );

  const formatDate = (date: string) => {
    return date.replace(/\//g, '.');
  };

  const handleDeleteModal = (id: string) => {
    setPlaylistId(id);
    setShowDeleteModal(true);
  };

  const playlist =
    indexPlaylistDetails < 0
      ? {correctness: '0', goal: 0, id: ''}
      : playlistsSorted[indexPlaylistDetails];
  const tasks = indexPlaylistDetails < 0 ? [] : playlistsSorted[indexPlaylistDetails].tasks;
  const students = indexPlaylistDetails < 0 ? [] : playlistsSorted[indexPlaylistDetails].students;

  return (
    <>
      <SidebarMessages
        opened={isSidebarOpened}
        onClose={onCloseDetailsTask}
        activity={task?.payload}
        title={
          <>
            {task?.task_type === 'AI_SPEAKING' ? (
              <MicroIcon width={20} />
            ) : (
              <BlueBookIcon width={20} />
            )}

            <Text value={task?.task_title} marginLeft={2} />
          </>
        }
      />

      <Container flexDirection="column" paddingLeft={2} paddingRight={3}>
        <Container
          alignItems="center"
          flexDirection={isMobile ? 'row' : ''}
          justifyContent="space-between"
        >
          <Text size={20} value={t('Classroom.TabTasks.SectionTitle')} fontWeight={600} />

          <RenderToggleButton />
        </Container>

        <TableContainer>
          <Table
            id="tableTasks"
            flex={1}
            data={playlistsSorted}
            header={<TableHeader />}
            footer={
              <Container
                borderTop={playlistsSorted.length ? `2px solid #1A73E8` : '1px solid #eee'}
                justifyContent="flex-end"
                padding={1}
              >
                {!license?.expired && (
                  <Button
                    value={
                      <Text
                        size={18}
                        marginRight={1}
                        fontWeight={600}
                        color={colors.primary}
                        value={t('Classroom.TabTasks.ButtonNewTaskText')}
                      />
                    }
                    variant="link"
                    rightContent={<BsPlus size={25} />}
                    onClick={onOpenFormCreatePlaylist}
                  />
                )}
              </Container>
            }
            indexOpenContent={indexPlaylistDetails}
            renderExtraContent={() => (
              <>
                <TableTasks
                  tasks={tasks}
                  onClickTask={task => onOpenDetailsTask(task)}
                  taskIdActived={task?.task_title}
                />

                <TableStudents
                  goal={playlist.goal}
                  students={students.filter(student => student.name !== 'Jakub Nowak')}
                  correctness={playlist.correctness}
                  playlistId={playlist.id}
                />
              </>
            )}
            columns={[
              {
                header: renderColumnSortable(
                  'dateTime',
                  <>{t('Classroom.TabTasks.TablePlaylistHeader.Date')}</>,
                ),
                renderItem: (item: PlaylistProps, index: number) => (
                  <Container paddingLeft={3} alignItems="center">
                    <CalendarOutlinedIcon width={15} />

                    <Text
                      size={16}
                      marginLeft={2}
                      marginRight={3}
                      value={formatDate(item.date)}
                      fontWeight={index === indexPlaylistDetails ? 600 : 500}
                    />

                    <Text
                      size={16}
                      marginLeft={1}
                      marginRight={4}
                      value={item.time}
                      fontWeight={index === indexPlaylistDetails ? 600 : 500}
                    />
                  </Container>
                ),
              },
              // {
              //   header: <>{t('Classroom.TabTasks.TablePlaylistHeader.Page')}</>,
              //   renderItem: (item: PlaylistProps, index: number) => (
              //     <>
              //       <Text
              //         size={16}
              //         value={item.seite_number}
              //         fontWeight={index === indexPlaylistDetails ? 600 : 500}
              //       />
              //     </>
              //   ),
              // },
              {
                header: <>{t('Classroom.TabTasks.TablePlaylistHeader.TaskName')}</>,
                renderItem: (item: PlaylistProps, index: number) => (
                  <Container alignItems="center">
                    <OpenedBookIcon width={20} />

                    <Text
                      size={16}
                      marginLeft={2}
                      fontWeight={index === indexPlaylistDetails ? 600 : 500}
                      color={colors.primary}
                      value={item.task_title}
                    />
                  </Container>
                ),
              },
              {
                header: <>{t('Classroom.TabTasks.TablePlaylistHeader.Group')}</>,
                renderItem: (item: PlaylistProps) => <>{getStudentEmojies(item)}</>,
              },
              {
                header: renderColumnSortable(
                  'total_students_ok',
                  <>{t('Classroom.TabTasks.TablePlaylistHeader.Status')}</>,
                ),
                renderItem: (item: PlaylistProps) => (
                  <Container alignItems="center">
                    <GroupUsersIcon width={19} />

                    <Text
                      marginTop={1}
                      marginLeft={2}
                      value={`${item.total_students_ok} / ${item.total_students}`}
                    />
                  </Container>
                ),
              },
              {
                align: 'right',
                header: null,
                renderItem: (item: PlaylistProps, index: number) => (
                  <Container justifyContent="flex-end">
                    {!license?.expired && (
                      <>
                        <Button
                          variant="link"
                          leftContent={<BlueEditIcon width={14} />}
                          onClick={() => onEditPlaylist(item)}
                        />
                        <Button
                          variant="link"
                          leftContent={<TrashOutlinedIcon />}
                          onClick={() => handleDeleteModal(item.id)}
                        />
                      </>
                    )}
                    <Button
                      height="100%"
                      variant="link"
                      leftContent={
                        index === indexPlaylistDetails ? (
                          <ChevronUpLined width={20} />
                        ) : (
                          <ChevronDownLined width={20} />
                        )
                      }
                      onClick={() => onOpenDetailsPlaylist(index)}
                    />
                  </Container>
                ),
              },
            ]}
          />
        </TableContainer>
      </Container>

      <DeleteModal
        show={showDeleteModal}
        closeRequest={() => setShowDeleteModal(false)}
        deleteRequest={onDeletePlaylist}
      />
    </>
  );
};

export default TabTasks;
