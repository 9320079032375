import styled from 'styled-components'

export const TableColumnSortable = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const TableContainer = styled.div`
  #tablePlaylist {
    margin-top: 24px;

    > div > table {
      border-spacing: 0;

      > thead > tr {
        > th {
          &:nth-child(1) {
            padding-left: 20px;
          }
        }
      }

      > tbody > tr {
        > td {
          border-top: 2px solid ${(props) => props.theme.colors.primary};

          &:nth-child(1) {
            width: 180px;
            max-width: 180px;
          }
          &:nth-child(2) {
          }
          &:nth-child(3) {
            width: 100px;
            max-width: 100px;
            padding-left: 24px;
          }
          &:nth-child(4) {
            width: 100px;
            max-width: 100px;
          }
          &:nth-child(5) {
            width: 75px;
            max-width: 75px;
          }
        }
        &.row-actived {
          background-color: rgb(209, 227, 250);
        }
        &.row-actived + tr > td {
          border-top: 0;
        }
      }
    }
  }
`
