import React, {useMemo} from 'react';
import {Container} from 'cc-web-components';
import useDeviceDetection from '@/hooks/IsMobile';
import CustomCards from '../CustomCards';
import {Classrooms} from '../../types';
import {Routes} from '@cc/shared';
import {ClassroomOptions} from '../ClassroomOptions';
import {style} from './styles';

const {NEW_CLASSROOM} = Routes;

interface Props {
  classrooms?: Classrooms;
  onClassroomSelect?: (index: number, classroomId: string, classroomName: string) => void;
}

export const ClassroomCards: React.FC<Props> = ({onClassroomSelect, classrooms}) => {
  const {isMobile} = useDeviceDetection();

  const Cards = useMemo(
    () =>
      classrooms?.map(classroom => {
        return (
          <Container
            width={isMobile ? '100%' : 'initial'}
            style={style.classroomContainer}
            key={classroom.id}>
            <ClassroomOptions
              classroomId={classroom.id}
              onClassroomSelect={onClassroomSelect}
              classroomName={classroom.display_name}
              isVisible={classroom.license?.expired}
            />
            <CustomCards
              id={classroom.id}
              key={classroom.id}
              color={classroom.color}
              name={classroom.display_name}
              link={`${NEW_CLASSROOM}/${classroom.id}`}
              activities_done={classroom.activities_done}
              isLicenseExpired={classroom.license?.expired === true}
              isInactive={classroom.inactive}
            />
          </Container>
        );
      }),
    [classrooms, isMobile, onClassroomSelect],
  );

  return (
    <Container
      margin="16px 0"
      style={style.container}
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap">
      {Cards}
    </Container>
  );
};
