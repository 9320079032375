import { Modal, Container, Text, Button } from 'cc-web-components'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import Images from '@/assets/images'
import useDeviceDetection from '@/hooks/IsMobile'

type Props = {
  show: boolean
  closeRequest: () => void
  deleteRequest: () => void
}

const DeleteModal = ({ show, closeRequest, deleteRequest }: Props) => {
  const { t } = useTranslation('cornelsen')
  const { isMobile } = useDeviceDetection()
  const { colors } = useTheme()

  const renderControls = () => (
    <Container
      mt={32}
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="center"
      alignItems="center"
      style={{ gap: isMobile ? '16px' : '32px' }}
    >
      <Button
        type="button"
        variant="solid"
        value={t('Classroom.TabTasks.DeleteModal.Cancel')}
        fontSize={20}
        fontWeight={400}
        width={isMobile ? '300px' : '150px'}
        height={44}
        paddingRight={20}
        paddingLeft={20}
        style={{
          backgroundColor: colors.white,
          border: '1px solid #ddd',
          color: colors.primary,
          boxShadow: 'none',
        }}
        onClick={closeRequest}
      />
      <Button
        type="button"
        variant="solid"
        value={t('Classroom.TabTasks.DeleteModal.DeleteButton')}
        fontSize={20}
        fontWeight={400}
        height={44}
        width={isMobile ? '300px' : '150px'}
        paddingRight={20}
        paddingLeft={20}
        style={{
          backgroundColor: colors.white,
          border: '1px solid #ddd',
          color: colors.primary,
          boxShadow: 'none',
        }}
        onClick={deleteRequest}
      />
    </Container>
  )

  return (
    <Container id="modalWrapper">
      <Modal
        show={show}
        width="650px"
        backgroundColor={colors.white}
        padding={isMobile ? '0 30px 30px' : '0 60px 30px'}
      >
        <Container justifyContent="center" height={170} alignItems="center">
          <img src={Images.TrashBlue} alt="" width={64} />
        </Container>

        <Container flexDirection="column" alignItems="center">
          <Text
            fontSize={20}
            color={colors.black}
            mb={2}
            value={t('Classroom.TabTasks.DeleteModal.Title')}
          />
          <Text
            fontSize={16}
            fontWeight={400}
            color="#637388"
            textAlign="center"
            value={t('Classroom.TabTasks.DeleteModal.Subtitle')}
          />
        </Container>

        {renderControls()}
      </Modal>
    </Container>
  )
}

export default DeleteModal
