import React, {useCallback, useMemo} from 'react';
import {Container, Text} from 'cc-web-components';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {NumberOfActivities, style} from './styles';
import {useNavigate} from 'react-router-dom';
import useDeviceDetection from '@/hooks/IsMobile';
import {getBackgroundImage} from './getBackgroundImage';

export type ClassroomCard = {
  name: string;
  activities_done: number;
  color: string;
  id: string;
  isLicenseExpired?: boolean;
  link: string;
  isInactive?: boolean | null;
};

const CustomCards: React.FC<ClassroomCard> = ({
  name,
  activities_done,
  color,
  id,
  link,
  isLicenseExpired,
  isInactive,
}) => {
  const {colors} = useTheme();
  const navigate = useNavigate();
  const {t} = useTranslation('cornelsen');
  const {isMobile} = useDeviceDetection();
  const width = useMemo(() => (isMobile ? '100%' : '460px'), [isMobile]);

  const handleOnClick = useCallback(() => {
    navigate(link);
  }, [link, navigate]);

  const backgroundImage = useMemo(() => getBackgroundImage(color, isInactive), [color, isInactive]);

  return (
    <Container
      style={style.container}
      width={width}
      height="224px"
      onClick={handleOnClick}
      alignItems="center"
      flexDirection="column">
      <Container
        alignItems="flex-end"
        backgroundColor={color}
        backgroundImage={backgroundImage}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        width="100%"
        height="70%"
        style={{
          filter: isLicenseExpired ? 'grayscale(1)' : '',
        }}>
        <Text color={colors.black} size={36} value={t(name)} px={4} style={style.text} />
      </Container>
      <NumberOfActivities>
        <Text
          color={colors.black}
          size={20}
          px={4}
          value={activities_done + '  ' + t('Home.CardsDescription')}
        />
      </NumberOfActivities>
    </Container>
  );
};

export default CustomCards;
