/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Container, Dropdown, Page} from 'cc-web-components';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ReactNotifications} from 'react-notifications-component';
import Api from '@/services/ApiCv';
import Footer from '@/components/Footer';
import Images from '@/assets/images';
import Helpers from '@/helper';
import Storage from '@/services/Storage';
import useDeviceDetection from '@/hooks/IsMobile';
import useDeviceDetectionSmall from '@/hooks/IsSmall';
import {Classroom, ClassroomStudentRow, Competency} from '@/services/ApiCv/types';
import {startLoading, stopLoading, getIsHideFeaturesUser, showAlert} from '@/redux/Session';

import useQueryParams from '@/hooks/QueryParams';
import AddStudentPage from '@/pages/NewClassroom/components/AddStudentPage';
import EmptyActivities from '@/pages/NewClassroom/components/EmptyActivities';
import {AddedStudent} from '@/services/ApiCv/types';

import {Competences, HealthScore, DemoBox} from '@cc/components';
import PrintAccessCodes from './components/PrintAccessCodes';
import StudentScoreList from './components/StudentScoreList';
import StudentActivitiesList from './components/StudentActivitiesList';
import DeleteModal from './components/Modals/DeleteModal';
import {WhiteEditIcon} from '@/components/Icons';

import TabTasks from './TabTasks';
import {FilterTime, HealthScoreAverageValues} from './types';
import {BookCover, TextWrapper, DropdownWrapper, TabWrapper} from './styles';
import ApiClassroomService from '@/services/ApiCv/ApiClassroomService';
import {useAssignActivityContext} from '@/redux/AssignActivity';
import {Routes} from '@cc/shared';
import {selectIsFeatureAvailable} from '@/redux/Rights';
import {getHeaderBackground} from '@cc/utils';
import {Snackbar} from '@/components/Snackbar';

const {BOOK} = Routes;

const TabsKeys = {
  OVERVIEW: 0,
  TASKS: 1,
};

export const NewClassroomPage: React.FC = () => {
  const {t} = useTranslation('cornelsen');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {colors} = useTheme();
  const QueryParams = useQueryParams();
  const {isSmall} = useDeviceDetectionSmall();
  const {isMobile} = useDeviceDetection();
  const IsHideFeaturesUser = useSelector(getIsHideFeaturesUser);
  const IsShowFeaturesUser = !IsHideFeaturesUser;
  const [showSnackbar, setShowSnackbar] = useState(false);

  const {classroomId} = useParams<{classroomId: string}>();

  const [tabActived, setTabActived] = useState(TabsKeys.OVERVIEW);
  const [classroom, setClassroom] = useState<Classroom>();
  const [compentecies, setCompetencies] = useState<Competency>();
  const [activeFilter, setActiveFilter] = useState(0);
  const [activeAction, setActiveAction] = useState(0);
  const [datesFilterTime, setDatesFilterTime] = useState<FilterTime>();
  const [classroomStudents, setClassroomStudents] = useState<ClassroomStudentRow[]>();
  const [isStudentAdded, setIsStudentAdded] = useState(0);
  const [isStudentsFormShown, setShowStudentsForm] = useState(false);
  // const [editClassroomModal, setEditClassroomModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [{isEditClass}, {toggleIsEditClass, toggleVisibleForm}] = useAssignActivityContext();
  const isDeleteClassroomVisible = useSelector(selectIsFeatureAvailable('CLASSROOM', 'D'));
  const isPrintCodesVisible = useSelector(selectIsFeatureAvailable('STUDENT_CODE', 'R'));
  const TabDefault = QueryParams.get('tabDefault');
  const RedirectTo = QueryParams.get('redirectTo');
  const isConfigured = !classroom?.metadata.inactive;
  const [numberOfAddedStudents, setNumberOfAddedStudents] = useState(0);

  const FILTERS_TIME = [
    {key: 0, label: t('Globals.FilterTime.All')},
    {key: 1, label: t('Globals.FilterTime.Today')},
    {key: 2, label: t('Globals.FilterTime.Latest7Days')},
    {key: 3, label: t('Globals.FilterTime.Latest30Days')},
  ];

  useEffect(() => {
    if (isConfigured) {
      return;
    }

    setActiveAction(1);
  }, [isConfigured]);

  useEffect(() => {
    if (RedirectTo !== 'tasks') {
      return;
    }

    if (RedirectTo === 'tasks') {
      navigate(window.location.pathname + '?tabDefault=tasks', {replace: true});
    }
  }, [RedirectTo]);

  useEffect(() => {
    if (TabDefault !== 'tasks') {
      return;
    }

    if (TabDefault === 'tasks') {
      setTabActived(TabsKeys.TASKS);
    }
  }, [TabDefault]);

  useEffect(() => {
    const banner = document.getElementById('banner') as HTMLDivElement;
    if (banner) {
      banner.style['display'] = 'flex';
    }
  }, []);

  useEffect(() => {
    if (!isEditClass) setActiveAction(0);
  }, [isEditClass]);

  useEffect(() => {
    if (activeAction === 1) {
      toggleIsEditClass();
      toggleVisibleForm();
    } else if (activeAction === 2) setOpenDeleteModal(true);
  }, [activeAction]);

  const addNewStudents = async (students: AddedStudent[]) => {
    if (classroomId) {
      dispatch(startLoading());
      Api.addStudents(classroomId, students)
        .then(() => {
          setIsStudentAdded(isStudentAdded + 1);
          setShowStudentsForm(false);
          dispatch(stopLoading());
          setNumberOfAddedStudents(students.length);
          setShowSnackbar(true);
        })
        .catch(error => {
          console.log(error);
          dispatch(stopLoading());
        });
    } else console.log('No Classroom');
  };

  const handleDelete = () => {
    if (!classroom?.id) return;

    dispatch(startLoading());
    ApiClassroomService['deleteClassroom'](classroom?.id)
      .then(data => {
        navigate('/', {
          state: {
            alert: {
              message: t('Globals.Notification.Success.ClassDeleted'),
              type: 'info',
            },
          },
        });
      })
      .catch(() => {
        dispatch(
          showAlert({
            value: 'Something went wrong',
            variant: 'danger',
          }),
        );
        dispatch(stopLoading());
      });
  };

  const getClassroom = () => {
    if (classroomId === undefined) {
      return;
    }

    Api.getClassroom(classroomId)
      .then(data => {
        setClassroom(data);
      })
      .catch(() => {
        setClassroom(undefined);
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  useEffect(() => {
    if (classroomId) {
      getClassroom();
    }
  }, [classroomId]);

  useEffect(() => {
    setDatesFilterTime(Helpers.getIntervalDatesByFilterIndex(activeFilter) as FilterTime);
  }, [activeFilter]);

  useEffect(() => {
    if (TabDefault === 'tasks' || !datesFilterTime) return;

    if (classroomId) {
      dispatch(startLoading());

      Api.getClassroomCompetencies(classroomId, datesFilterTime)
        .then(data => {
          setCompetencies(data);
          dispatch(stopLoading());
        })
        .catch(() => {
          dispatch(stopLoading());
        });

      Api.getClassroomStudents(classroomId, datesFilterTime)
        .then(students => {
          setClassroomStudents(students);
          dispatch(stopLoading());
        })
        .catch(() => {
          dispatch(stopLoading());
        });
    }
  }, [dispatch, classroomId, datesFilterTime, isStudentAdded, TabDefault]);

  const classroomHealthScore = useMemo(() => {
    if (!!classroomStudents) {
      const Score = {
        totalStudents: 0,
        activeStudents: 0,
        greenScore: 0,
        yellowScore: 0,
        redScore: 0,
      };

      for (const student of classroomStudents) {
        const {competencies_health_score_average_desc} = student;

        if (competencies_health_score_average_desc === HealthScoreAverageValues.UP) {
          Score.activeStudents += 1;
          Score.greenScore += 1;
        }
        if (competencies_health_score_average_desc === HealthScoreAverageValues.EQUAL) {
          Score.activeStudents += 1;
          Score.yellowScore += 1;
        }
        if (competencies_health_score_average_desc === HealthScoreAverageValues.DOWN) {
          Score.activeStudents += 1;
          Score.redScore += 1;
        }

        Score.totalStudents += 1;
      }

      return Score;
    }
  }, [classroomStudents]);

  const classroomMetaColor = useMemo(() => {
    const data = Helpers.getMetaColors(classroom?.metadata.color || 'blue');

    if (classroom?.license?.expired || classroom?.licence?.expired) {
      data.background = Images.GreyColor;
    }

    return data;
  }, [classroom]);

  const onBackPage = () => {
    window.history.back();
  };

  const onNavigateToBookPage = () => {
    if (!classroom) return;

    Storage.setClassroom({
      book_id: classroom.mainCourseId,
      book_name: classroom.metadata.book_name,
      classroom_id: classroom.id,
      classroom_name: classroom.displayName,
      classroom_color: classroom.metadata.color,
    });

    navigate(`${BOOK}/${classroom.mainCourseId}`);
  };

  const renderTimeFilter = () => (
    <DropdownWrapper>
      <Dropdown
        items={FILTERS_TIME}
        renderItem={(item: any) => (
          <>
            <span />
            {item.label}
          </>
        )}
        handleSelectItem={(_item: any, index: number) => setActiveFilter(index)}
        selectedIndex={activeFilter}
        labelIcon={<img src={Images.SortIcon} alt="" />}
        isInverted={true}
      />
    </DropdownWrapper>
  );

  const renderHeader = () => (
    <Container flex={1} alignItems="center" justifyContent="space-between">
      <TabWrapper>
        <Button
          color={colors.black}
          value={t('Classroom.Tab.Overview')}
          onClick={() => {
            setTabActived(TabsKeys.OVERVIEW);
            navigate(window.location.pathname, {replace: true});
          }}
          variant={tabActived === TabsKeys.OVERVIEW ? 'tab-active' : 'tab'}
        />
        <Button
          color={colors.black}
          value={t('Classroom.Tab.Tasks')}
          onClick={() => {
            setTabActived(TabsKeys.TASKS);
            navigate(window.location.pathname + '?tabDefault=tasks', {replace: true});
          }}
          variant={tabActived === TabsKeys.TASKS ? 'tab-active' : 'tab'}
        />
        <Button
          color={colors.black}
          value={t('Classroom.Tab.Book')}
          onClick={onNavigateToBookPage}
          variant={'tab'}
        />
      </TabWrapper>

      <Container
        alignItems="center"
        position={isMobile ? 'unset' : 'relative'}
        top={isMobile ? 60 : 'auto'}
        right={isMobile ? 0 : 'auto'}>
        {!isSmall && renderTimeFilter()}
      </Container>

      {!classroom?.license?.expired && (
        <Container id="top-dropdown" className="header-dropdown" heigth={50}>
          {isConfigured && (
            <Button
              variant="link"
              alt="Edit classroom"
              leftContent={
                <Container mr={isMobile ? 0 : 2}>
                  <WhiteEditIcon height={20} />
                </Container>
              }
              value={isMobile ? '' : t('Classroom.Edit')}
              onClick={() => setActiveAction(1)}
              style={{color: colors.white}}
            />
          )}
          {isDeleteClassroomVisible && (
            <Button
              variant="link"
              alt="Delete classroom"
              leftContent={
                <Container mr={isMobile ? 0 : 2}>
                  <img src={Images.TrashWhite} alt="Delete classroom" width={20} />
                </Container>
              }
              value={isMobile ? '' : t('Student.FilterActions.Delete')}
              onClick={() => setActiveAction(2)}
              style={{color: colors.white}}
            />
          )}
        </Container>
      )}
    </Container>
  );

  const showStudentsForm = () => {
    setShowStudentsForm(true);
  };

  const hideStudentsForm = () => {
    setShowStudentsForm(false);
  };

  const accessCode = () => {
    if (!classroomStudents || !classroomStudents.length) return null;

    const totalStudents = classroomStudents.length;
    const totalStudentsRegistereds = classroomStudents.filter(item => !!item.student_id).length;

    return (
      <Container mb={4} flexDirection="column">
        <PrintAccessCodes
          classroomId={classroomId}
          totalStudents={totalStudents}
          totalStudentsRegistereds={totalStudentsRegistereds}
        />
      </Container>
    );
  };

  const getAvatarPage = useCallback(() => {
    if (!classroom) return '';

    return classroom.bookCover.image_url;
  }, [classroom]);

  const RenderTabOverView = () => {
    return (
      <>
        <Container
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          mb={5}
          style={{gap: isMobile ? 16 : 8}}>
          {!!compentecies && <Competences competency={compentecies} />}

          <HealthScore score={classroomHealthScore} />
        </Container>
        <StudentScoreList
          color={classroomMetaColor.highlightColor}
          students={classroomStudents ?? []}
          classroomId={classroomId}
          showStudentsForm={showStudentsForm}
          addStudentsRequest={addNewStudents}
          hideButtonAddStudent={classroom?.license?.expired}
        />
        <Container flexDirection="column" mb={4}>
          <StudentActivitiesList
            color={classroomMetaColor.highlightColor}
            bookId={classroom?.mainCourseId}
            filtersOfTime={datesFilterTime}
            filterCallback={(filter: FilterTime) => setDatesFilterTime(filter)}
            activeFilter={activeFilter}
          />
        </Container>

        {isPrintCodesVisible && accessCode()}

        {IsShowFeaturesUser && <DemoBox />}
      </>
    );
  };

  return (
    <>
      <ReactNotifications isMobile={true} breakpoint={425} />

      {isStudentsFormShown ? (
        <Page>
          <AddStudentPage addStudentsRequest={addNewStudents} hideStudentsForm={hideStudentsForm} />
        </Page>
      ) : (
        <Page
          avatar={<BookCover src={getAvatarPage()} />}
          onClickBack={onBackPage}
          title={classroom?.displayName}
          titleSize={34}
          subTitle={classroom?.metadata.book_name}
          backgroundImage={getHeaderBackground(classroomMetaColor, classroom)}
          headerContent={renderHeader()}
          stickyBar={true}
          footerContent={<Footer />}>
          {isSmall && <Container justifyContent="flex-end">{renderTimeFilter()}</Container>}

          {tabActived === TabsKeys.OVERVIEW ? (
            RenderTabOverView()
          ) : (
            <TabTasks activedTimeFilter={activeFilter} />
          )}

          <DeleteModal
            show={openDeleteModal}
            closeRequest={() => {
              setOpenDeleteModal(false);
              setActiveAction(0);
            }}
            className={classroom ? classroom.displayName : '[Name Klasse]'}
            deleteRequest={handleDelete}
          />
        </Page>
      )}
      {showSnackbar && (
        <Snackbar
          hideSnackbar={() => {
            setShowSnackbar(false);
            setNumberOfAddedStudents(0);
          }}
          message={
            numberOfAddedStudents +
            ' ' +
            t('Classroom.TabOverview.SectionCreateStudent.StudentAddedMessage')
          }
        />
      )}
    </>
  );
};
