import React from 'react'
import { MdInfo } from 'react-icons/md'
import { useTheme } from 'styled-components'
import { Container, Text } from 'cc-web-components'

const InfoTitle = 'Wortschatztraining'
const InfoDescription =
  'Mit dem Vokabelquiz können Schüler:innen eigenständig üben, indem sie sich zufällig ausgewählte Aufgaben aus den Formaten Multiple-Choice und schriftliche Vokabelabfrage anzeigen lassen - immer passend zur jeweiligen Doppelseite.'

const ChatEmpty: React.FC = () => {
  const { colors } = useTheme()

  return (
    <Container
      backgroundColor="#f0ece7"
      padding={'15px 38px'}
      justifyContent="center"
      borderLeft="15px solid #fff"
      borderRight="15px solid #fff"
      borderBottom="15px solid #fff"
    >
      <Container maxWidth={530} backgroundColor="#fff" padding={20} borderRadius={4}>
        <Container>
          <MdInfo size={30} color={colors.primary} />
        </Container>

        <Container
          marginLeft={10}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Text
            margin={'5px 0px 10px 0px'}
            fontSize={16}
            fontWeight={600}
            value={`${InfoTitle}: Vokabelquiz`}
          />

          <Text
            value={InfoDescription}
            color="#999999"
            textAlign="left"
            lineHeight={1.5}
            style={{ whiteSpace: 'normal' }}
          />
        </Container>
      </Container>
    </Container>
  )
}

export default ChatEmpty
