import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Table, Container, Text, Button} from 'cc-web-components';

import {
  MicroIcon,
  BlueBookIcon,
  BookPileIcon,
  CrossRedIcon,
  CheckGreenIcon,
  EyeOutlinePrimaryIcon,
  EyeFilledPrimaryIcon,
  WorkSpokenIcon,
} from '@/components/Icons';

import ApiStudentService from '@/services/ApiCv/ApiStudentService';
import MessageList from '@/components/MessageList';
import FormFeedback from '@/components/FormFeedback';
import Helpers from '@/helper';
import {startLoading, stopLoading} from '@/redux/Session';
import {getMessageListWithoutType} from '@/components/MessageList/helpers';

import {TableContainer} from './styles';

type PropsOnSubmitResponse = {
  content: string;
  type: 'TEXT' | 'MEDIA';
};

type ActivityProps = {
  type: 'VOCABULARY_TRAINER' | 'AI_SPEAKING' | 'PRONUNCIATION' | 'GRAMMAR' | 'LISTENING' | 'DIALOG';
  title: string;
  page: string;
  correctness: number;
  total_count: number;
  completed_count: number;
  user_name: string;
  user_emoji: string;
  items?: {total_xp: number}[];
  stat_id: string;
};

type Props = {
  playlistId?: string;
  activities?: ActivityProps[];
  callback?: () => void;
};

const TableActivities: React.FC<Props> = ({playlistId, activities, callback}: Props) => {
  const {t} = useTranslation('cornelsen');
  const dispatch = useDispatch();
  const {studentId, classroomId} = useParams<{studentId: string; classroomId: string}>();

  const [indexDetails, setIndexDetails] = useState(-1);

  const ButtonsWordsSpeedFeedback = [
    {value: t('Globals.ButtonsFeedbacks.Type1.Button1')},
    {value: t('Globals.ButtonsFeedbacks.Type1.Button2')},
    {value: t('Globals.ButtonsFeedbacks.Type1.Button3')},
  ];

  const onOpenDetailsActivity = (index: number) => {
    if (index === indexDetails) {
      setIndexDetails(-1);
    } else {
      setIndexDetails(index);
    }
  };

  const getChatMessages = () => {
    if (!activities || indexDetails < 0) return [];

    const activity = activities[indexDetails];

    const messages = getMessageListWithoutType(activity);

    return messages;
  };

  const getChatXp = () => {
    if (!activities || indexDetails < 0) return 0;

    const activity = activities[indexDetails];

    return !!activity.items?.length ? activity.items[0].total_xp : 0;
  };

  const onSaveFeedbackPlaylist = async (payload: PropsOnSubmitResponse) => {
    if (!activities || !payload.content || !callback || !studentId || !classroomId) return;

    if (payload.type === 'TEXT') {
      dispatch(startLoading());

      ApiStudentService.postAddFeedbackText({
        studentId,
        classroomId,
        payload: {
          statId: activities[indexDetails].stat_id,
          feedback: payload.content,
        },
      })
        .then(() => {
          callback();
          dispatch(stopLoading());

          Helpers.showNotification(
            t('Globals.Notification.Success.Title'),
            t('Globals.Notification.Success.MessageSent'),
            'success',
          );
        })
        .catch(() => {
          dispatch(stopLoading());

          Helpers.showNotification(
            t('Globals.Notification.Error.Title'),
            t('Globals.Notification.Error.SomethingWrong'),
            'danger',
          );
        });
    }

    if (payload.type === 'MEDIA') {
      dispatch(startLoading());

      const formData = new FormData();

      formData.append('statId', activities[indexDetails].stat_id);
      formData.append('audio', payload.content);

      ApiStudentService.postAddFeedbackMedia({
        studentId,
        classroomId,
        payload: formData,
      })
        .then(() => {
          callback();
          dispatch(stopLoading());

          Helpers.showNotification(
            t('Globals.Notification.Success.Title'),
            t('Globals.Notification.Success.MessageSent'),
            'success',
          );
        })
        .catch(() => {
          dispatch(stopLoading());

          Helpers.showNotification(
            t('Globals.Notification.Error.Title'),
            t('Globals.Notification.Error.SomethingWrong'),
            'danger',
          );
        });
    }
  };

  return (
    <TableContainer>
      <Table
        id="tableActivities"
        flex={1}
        data={activities}
        indexOpenContent={indexDetails}
        columns={[
          {
            header: null,
            renderItem: () => <></>,
          },
          // {
          //   header: null,
          //   renderItem: (item: ActivityProps) => (
          //     <Text size={16} value={item.page} fontWeight={500} />
          //   ),
          // },
          {
            header: null,
            renderItem: (item: ActivityProps) => (
              <Container alignItems="center">
                {item.type === 'AI_SPEAKING' && <MicroIcon width={20} />}

                {item.type === 'LISTENING' && <>👂</>}

                {item.type === 'DIALOG' && <>📖</>}

                {item.type === 'PRONUNCIATION' && <WorkSpokenIcon width={20} />}

                {item.type === 'GRAMMAR' && <BookPileIcon width={30} />}

                {item.type === 'VOCABULARY_TRAINER' && <BlueBookIcon width={20} />}

                <Text size={16} value={item.title} fontWeight={500} marginLeft={2} />
              </Container>
            ),
          },
          {
            header: null,
            renderItem: (item: ActivityProps) => (
              <Text size={16} fontWeight={600} value={`${item.correctness} %`} />
            ),
          },
          {
            header: null,
            renderItem: (item: ActivityProps) => {
              const allDone = item.total_count === item.completed_count;

              return (
                <Container alignItems="center" paddingLeft={3}>
                  {allDone === true && <CheckGreenIcon />}
                  {allDone === false && <CrossRedIcon />}

                  <Text
                    size={16}
                    value={`${item.completed_count} / ${item.total_count}`}
                    marginLeft={2}
                  />
                </Container>
              );
            },
          },
          {
            align: 'right',
            header: null,
            renderItem: (_item: ActivityProps, index: number) => (
              <Container justifyContent="flex-end">
                <Button
                  height="100%"
                  variant="link"
                  leftContent={
                    index === indexDetails ? (
                      <EyeFilledPrimaryIcon width={23} />
                    ) : (
                      <EyeOutlinePrimaryIcon width={23} />
                    )
                  }
                  onClick={() => onOpenDetailsActivity(index)}
                />
              </Container>
            ),
          },
        ]}
        renderExtraContent={(item: ActivityProps) => (
          <>
            <MessageList xp_number={getChatXp()} chat={getChatMessages()} />

            <Container padding="5px 18px">
              <FormFeedback
                onSubmit={onSaveFeedbackPlaylist}
                wordsSpeedFeedback={ButtonsWordsSpeedFeedback}
                defaultFeedback="message"
                hasFeedbackError={!item.stat_id}
                feedbackErrorText={t('Globals.Notification.Error.ActivityNotCompleted')}
                clearAfterSend={true}
              />
            </Container>
          </>
        )}
      />
    </TableContainer>
  );
};

export default TableActivities;
