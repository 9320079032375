import { AiOutlineClose } from 'react-icons/ai'
import { Button, Container, Text } from 'cc-web-components'
import { ModalContent, ModalFooter, Image } from '../styles'
import Images from '@/assets/images/product-benefits'

type Props = {
  closeCallback: () => void
}

const Confirmation: React.FC<Props> = ({ closeCallback }: Props) => {
  return (
    <>
      <ModalContent>
        <Container
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Container
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              value="Vielen Dank für Ihr Feedback!"
              fontSize={32}
              fontWeight={600}
              mb={10}
              textAlign="center"
            />
            <Text
              value="Bei Fragen oder Problemen steht unser Support Ihnen zur Verfügung."
              fontSize={20}
              mb={10}
              textAlign="center"
            />
            <Text
              value="Die Demoseite können Sie jederzeit ausprobieren."
              fontSize={20}
              mb={50}
              textAlign="center"
            />
          </Container>
          <Image src={Images.AdaCelebrate} />
        </Container>
      </ModalContent>
      <ModalFooter>
        <Button
          value="SchlieBen"
          variant="solid-min"
          onClick={closeCallback}
          height={38}
          fontSize={16}
          fontWeight={400}
          leftContent={
            <Container mr={1}>
              <AiOutlineClose size={20} />
            </Container>
          }
        />
      </ModalFooter>
    </>
  )
}

export default Confirmation
