import styled from 'styled-components';
import {Container} from 'cc-web-components';

export const StyledTooltip = styled(Container)`
  position: absolute;
  bottom: 100%;
  padding: 8px 12px;
  background: #263238;
  color: #fff;
  width: 359px;
  margin-bottom: 6px;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: 400;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    border-style: solid;
    border-width: 6px 7px 0 7px;
    border-color: #263238 transparent transparent transparent;
  }
`;
