import { useAssignActivityContext } from '@/redux/AssignActivity'
import React from 'react'

import StepOneEdit from './Edit'
import StepOneCreate from './Create'
import StepOneCreateBoook from './CreateBook'

type Props = {
  bookId: string
  setButtonActive: (buttonActive: boolean) => void
}

const StepOne: React.FC<Props> = ({ bookId, setButtonActive }: Props) => {
  const [state, dispatch] = useAssignActivityContext()

  if (!!state.playlistCreate) {
    return (
      <StepOneCreateBoook
        state={state}
        bookId={bookId}
        dispatch={dispatch}
        setButtonActive={setButtonActive}
      />
    )
  }

  if (!!state.playlist) {
    return (
      <StepOneEdit
        state={state}
        bookId={bookId}
        dispatch={dispatch}
        setButtonActive={setButtonActive}
      />
    )
  }

  return (
    <StepOneCreate
      classroomId={state.payload?.classroom_id || ''}
      bookId={bookId}
      dispatch={dispatch}
      setButtonActive={setButtonActive}
    />
  )
}

export default StepOne
