import React, {ReactNode} from 'react';
import {NavigationOption} from './styles';
import {Container, Text} from 'cc-web-components';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';

const FONT_SIZE = 18;
const MARGIN_LEFT = 2;

interface Props {
  onClick?: () => void;
  isVisible?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  translationKey: string;
}

export const NavOption: React.FC<Props> = ({
  translationKey,
  isVisible = true,
  onClick,
  leftIcon,
  rightIcon,
}) => {
  const {colors} = useTheme();
  const {t} = useTranslation('cornelsen');

  if (!isVisible) {
    return null;
  }

  return (
    <NavigationOption onClick={onClick}>
      <Container>
        {leftIcon}
        <Text
          ml={MARGIN_LEFT}
          fontSize={FONT_SIZE}
          color={colors.primary}
          value={t(translationKey)}
        />
      </Container>
      {rightIcon}
    </NavigationOption>
  );
};
