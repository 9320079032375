import React, {useMemo} from 'react';
import {
  ChevronLinedIcon,
  EditLinedIcon,
  EnvelopeLinedIcon,
  HelpCircleIcon,
  LicenseLinedIcon,
  LinkExternLinedIcon,
  ProfileLinedIcon,
} from '../../../Icons';
import {openWindow} from '@cc/utils';
import {NavOption} from '../NavOption';
import {selectNavConfig} from '@/redux/Rights';
import {useSelector} from 'react-redux';

const MY_DATA_URL = 'https://www.cornelsen.de/shop/uebersicht-daten/';
const EDIT_PROFILE_URL = 'https://www.cornelsen.de/shop/daten';
const HELP_URL = 'https://www.cornelsen.de/empfehlungen/chatclass/fuer-lehrer';

interface Props {
  onLicenseClick?: () => void;
  onFeedbackClick?: () => void;
}

export const NavigationOptions: React.FC<Props> = ({onFeedbackClick, onLicenseClick}) => {
  const {
    MY_DATA: isDataHidden,
    EDIT_PROFILE: isEditHidden,
    FEEDBACK: isFeedbackHidden,
    HELP: isHelpHidden,
    LICENSES: areLicensesHidden,
  } = useSelector(selectNavConfig) || {};

  const navOptions = useMemo(
    () => [
      {
        translationKey: 'UserProfile.Navigation.MyData',
        leftIcon: <ProfileLinedIcon />,
        rightIcon: <LinkExternLinedIcon />,
        onClick: () => openWindow(MY_DATA_URL),
        isVisible: !isDataHidden,
      },
      {
        translationKey: 'UserProfile.Navigation.EditProfile',
        leftIcon: <EditLinedIcon />,
        rightIcon: <LinkExternLinedIcon />,
        onClick: () => openWindow(EDIT_PROFILE_URL),
        isVisible: !isEditHidden,
      },
      {
        translationKey: 'UserProfile.Navigation.License',
        leftIcon: <LicenseLinedIcon />,
        rightIcon: <ChevronLinedIcon />,
        onClick: onLicenseClick,
        isVisible: !areLicensesHidden,
      },
      {
        translationKey: 'UserProfile.Navigation.Feedback',
        leftIcon: <EnvelopeLinedIcon />,
        rightIcon: <ChevronLinedIcon />,
        onClick: onFeedbackClick,
        isVisible: !isFeedbackHidden,
      },
      {
        translationKey: 'UserProfile.Navigation.Help',
        leftIcon: <HelpCircleIcon />,
        rightIcon: <LinkExternLinedIcon />,
        onClick: () => openWindow(HELP_URL),
        isVisible: !isHelpHidden,
      },
    ],
    [
      areLicensesHidden,
      isDataHidden,
      isEditHidden,
      isFeedbackHidden,
      isHelpHidden,
      onFeedbackClick,
      onLicenseClick,
    ],
  );

  return (
    <>
      {navOptions.map(option => (
        <NavOption
          onClick={option.onClick}
          leftIcon={option.leftIcon}
          rightIcon={option.rightIcon}
          isVisible={option.isVisible}
          translationKey={option.translationKey}
        />
      ))}
    </>
  );
};
