import styled from 'styled-components';

export const StyledColumnIcon = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 4px;
  }
`;

export const StyledStudentLink = styled.div`
  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    font-weight: 600;

    :hover {
      text-decoration: underline;
    }
  }
`;
export const ClassName = styled.div`
  color: ${props => props.theme.colors.primary};
  font-weight: 400;
`;

export const ActivitiesTableWrapper = styled.div`
  #activitiesClassroomTable {
    th:first-child {
      width: 19%;
      padding-left: 16px;
      @media (max-width: 768px) {
        width: 42%;
      }
      @media (max-width: 425px) {
        width: 27%;
      }
    }
    th:nth-child(2) {
      width: 5%;
    }
    th:nth-child(3) {
      width: 45%;
      @media (max-width: 768px) {
        width: 15%;
      }
      @media (max-width: 425px) {
        width: 8%;
      }
      @media (max-width: 375px) {
        width: 0;
      }
    }
    th:nth-child(4) {
      width: 10%;
      @media (max-width: 425px) {
        width: 20%;
      }
    }
  }

  #activitiesStudentTable {
    th:first-child {
      width: 6%;
    }
    th:nth-child(2) {
      width: 48%;

      @media (max-width: 768px) {
        width: 60%;
      }
      @media (max-width: 425px) {
        width: 20%;
      }
    }
    th:nth-child(3) {
      width: 30%;

      @media (max-width: 768px) {
        width: 11%;
      }
    }
  }

  #feedbackButtons {
    div:first-child button {
      background: transparent;
      cursor: default;
    }
  }
`;
