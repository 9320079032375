import Service from './apiConfig';

export type Avv = {
  value: boolean;
};

const ApiUserService = {
  getIsAvvConfirmed: async (): Promise<Avv> => {
    return (await Service.get<Avv>('/api/user/avv')).data;
  },
  confirmAvv: async (): Promise<Avv> => {
    return (await Service.post<Avv>('/api/user/avv', {value: true})).data;
  },
};

export default ApiUserService;
