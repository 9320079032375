import {Container} from 'cc-web-components';
import styled from 'styled-components';

export const FadeOutContainer = styled(Container)`
  padding: 16px;
  width: 600px;
  background-color: #263238;
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  animation: 0.5s forwards fadeout;
  animation-delay: 4.5s;
  box-sizing: border-box;
  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
