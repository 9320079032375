import React from 'react';
import {Container, Text} from 'cc-web-components';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {BulbSvgStyled, NewsContainer} from './styles';

interface Props {
  onLinkClick?: () => void;
  isNewsLinkHidden?: boolean;
}

export const Heading: React.FC<Props> = ({onLinkClick, isNewsLinkHidden}) => {
  const {colors} = useTheme();
  const {t} = useTranslation('cornelsen');

  return (
    <Container justifyContent="space-between" alignItems="center">
      <Text color={colors.black} size={20} value={t('Home.CardsTitle')} />

      {!isNewsLinkHidden && (
        <NewsContainer
          onClick={onLinkClick}
          tabIndex={0}
          onKeyDown={(key: any) => {
            if (key.code === 'Enter') {
              onLinkClick?.();
            }
          }}>
          <BulbSvgStyled />
          <Text color={'#0163E5'} size={16} value={t('Home.NewsTitle')} />
        </NewsContainer>
      )}
    </Container>
  );
};
