import styled from 'styled-components'

export const Modal = styled.div`
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #fff;
`

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - 69px);
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

export const ModalFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 15px 0;
  background: #fff;
  box-shadow: 0 -3px 7px 0px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  & > div {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    max-width: 600px;
  }

  @media screen and (max-width: 768px) {
    height: 69px;
    padding: 0 18px;

    & > div {
      justify-content: center;
    }
  }
`

export const CloseButton = styled.button`
  z-index: 30;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  border: none;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    top: 30px;
    right: 30px;
  }
`

export const InputRadio = styled.input`
  width: 15px;
  height: 15px;
  margin-right: 10px;

  cursor: pointer;
`

export const Label = styled.label`
  cursor: pointer;
`

export const Image = styled.img`
  width: 300px;
  height: 300px;
`
