import CamelCaseKeys from 'camelcase-keys';
import Service from './apiConfig';
import FeedbackService from './apiFeedbackConfig';

import {
  Classroom,
  StudentCV,
  StudentStats,
  StateType,
  SpeakResponse,
  VocubularyResponse,
  Competency,
  ClassroomStudent,
  ClassroomStudentRow,
  Student,
  AddedStudent,
  UserProfileFeedbackType,
  LicenseFeedbackType,
} from './types';

import {CornelsenTheme} from '@cc/theme';

const Api = {
  getStudent: async (studentId: string): Promise<Student> => {
    const student = (await Service.get<Student>(`/api/student/${studentId}`)).data;
    return {...student, name: student.name === 'Jakub Nowak' ? 'Name gelöscht' : student.name};
  },

  getStudentByCode: async (studentCode: string): Promise<Student> => {
    const student = (await Service.get<Student>(`/api/student/code/${studentCode}`)).data;

    return {...student, name: student.name === 'Jakub Nowak' ? 'Name gelöscht' : student.name};
  },

  getNewStudentCode: async (studentId: string, classroomId: string): Promise<any> => {
    const response = (
      await Service.post<any>(`/api/student/${studentId}/new-code?classroomId=${classroomId}`)
    ).data;

    return {data: response.code};
  },

  refreshCodeStudent: async (studentCode: string, classroomId: string): Promise<any> => {
    const response = (
      await Service.post<any>(
        `/api/student/code/${studentCode}/new-code?classroomId=${classroomId}`,
      )
    ).data;

    return {data: response.code};
  },

  updateStudent: async (
    studentId: string,
    classroomId: string,
    name: string,
    level: string,
  ): Promise<void> => {
    await Service.put<void>(`/api/student/${studentId}?classroomId=${classroomId}`, {name, level});
  },

  updateStudentByCode: async (
    studentCode: string,
    classroomId: string,
    name: string,
    level: string,
  ): Promise<void> => {
    await Service.put<void>(`/api/student/code/${studentCode}?classroomId=${classroomId}`, {
      name,
      level,
    });
  },

  deleteStudent: async (code: string): Promise<void> => {
    await Service.delete<void>(`/api/student/${code}`);
  },

  deleteStudentByCode: async (studentCode: string): Promise<void> => {
    await Service.delete<void>(`/api/student/code/${studentCode}`);
  },

  addStudents: async (classroomId: string, students: AddedStudent[]): Promise<void> => {
    await Service.post<void>(`/api/classroom/${classroomId}/student`, {students});
  },

  getBookInfo: async (id: string): Promise<any> => {
    return (await Service.get(`/api/book/${id}`)).data;
  },

  getClassroom: async (classroomId: string): Promise<Classroom | undefined> => {
    try {
      const data = (await Service.get(`/api/classroom/${classroomId}`)).data;

      if (!!data.licence) {
        data.license = data.licence;
      } else {
        data.licence = {expired: true};
        data.license = data.licence;
      }

      return CamelCaseKeys<Classroom>(data);
    } catch {}
  },

  getCompetences: async (
    classroomId: string,
    studentId: string,
    filter: {},
  ): Promise<StudentCV | undefined> => {
    try {
      const response = await Service.get<StudentCV>(`/api/student/${studentId}/competencies`, {
        params: {classroomId, ...filter},
      });

      return CamelCaseKeys(response.data);
    } catch {
      return;
    }
  },

  getStatsVocab: async (
    studentId: string,
    classroomId: string,
    filter: {},
  ): Promise<StudentStats> => {
    const {content, correctness, total_elements} = (
      await Service.get<VocubularyResponse.Response>(`/api/student/${studentId}/stats`, {
        params: {classroomId, type: StateType.VOCABULARY_TRAINER, ...filter},
      })
    ).data;

    const data = content
      .map(item => {
        const pages = item.course_info.page_number_spec;

        const totalItemsTraining = item?.details.vocabulary_trainer.items.length;
        const totalItemsCorrectly = item?.details.vocabulary_trainer.items.filter(
          item => item.correct,
        ).length;

        const messages = item.details.vocabulary_trainer.items.map(vocabulary => {
          const text = vocabulary.user_response.length ? vocabulary.user_response[0].text : '';

          return {
            text,
            isAudio: false,
            isSent: true,
            payload: vocabulary,
          };
        });

        return {
          pages,
          activity: `Vokabelaufgabe`,
          correct: totalItemsCorrectly === totalItemsTraining,
          correct_text: `${totalItemsCorrectly}/${totalItemsTraining}`,
          messages,
          studentName: item.student_info.name,
          hasFeedback: !!item.feedback.length,
          payload: item,
        };
      })
      .sort((a, b) => b.payload.ended_at - a.payload.ended_at);

    return {
      data,
      correctness,
      total_elements,
    };
  },

  getStatsSpeechTasks: async (
    studentId: string,
    classroomId: string,
    filter: {},
  ): Promise<StudentStats> => {
    try {
      const {content, correctness, total_elements} = (
        await Service.get<SpeakResponse.Response>(`/api/student/${studentId}/stats`, {
          params: {classroomId, type: StateType.AI_SPEAKING, ...filter},
        })
      ).data;

      const data = content
        .map(item => {
          const pages = item.course_info.page_number_spec;
          const activity = `Sprechübung - ${item.activity.header.title}`;
          const words = item.derived.speaking_word_count;

          const messages = item.details.ai_speaking.items.map(speak => {
            const meta = !!speak.user_response.length ? speak.user_response[0] : {};

            return {
              text: 'AUDIO',
              isAudio: true,
              isSent: true,
              meta,
              payload: speak,
            };
          });

          return {
            pages,
            words,
            messages,
            activity,
            hasFeedback: !!item.feedback.length,
            payload: item,
            studentName: item.student_info.name,
          };
        })
        .sort((a, b) => b.payload.ended_at - a.payload.ended_at);

      return {
        data,
        correctness,
        total_elements,
      };
    } catch {
      return {
        total_elements: 0,
        correctness: 0,
        data: [],
      };
    }
  },

  getClassromStatsVocab: async (classroomId: string, filter: {}): Promise<StudentStats> => {
    const {content, correctness, total_elements} = (
      await Service.get<VocubularyResponse.Response>(`/api/classroom/${classroomId}/stats`, {
        params: {type: StateType.VOCABULARY_TRAINER, ...filter},
      })
    ).data;

    const data = content
      .sort((a, b) => b.ended_at - a.ended_at)
      .map(item => {
        const pages = item.course_info.page_number_spec;

        const totalItemsTraining = item?.details.vocabulary_trainer.items.length;
        const totalItemsCorrectly = item?.details.vocabulary_trainer.items.filter(
          item => item.correct,
        ).length;

        const messages = item.details.vocabulary_trainer.items.map(vocabulary => {
          const text = vocabulary.user_response.length ? vocabulary.user_response[0].text : '';

          return {
            text,
            isAudio: false,
            isSent: true,
            payload: vocabulary,
          };
        });

        return {
          pages,
          activity: 'Vokabelaufgabe',
          correct: totalItemsCorrectly === totalItemsTraining,
          correct_text: `${totalItemsCorrectly}/${totalItemsTraining}`,
          messages,
          hasFeedback: false,
          studentName: item.student_info.name,
          payload: item,
        };
      });

    return {
      data,
      correctness,
      total_elements,
    };
  },

  getClassromStatsSpeechTasks: async (classroomId: string, filter: {}): Promise<StudentStats> => {
    try {
      const {content, correctness, total_elements} = (
        await Service.get<SpeakResponse.Response>(`/api/classroom/${classroomId}/stats`, {
          params: {type: StateType.AI_SPEAKING, ...filter},
        })
      ).data;

      const data = content
        .sort((a, b) => b.ended_at - a.ended_at)
        .map(item => {
          const pages = item.course_info.page_number_spec;
          const activity = `Sprechübung - ${item.activity.header.title}`;
          const words = item.derived.speaking_word_count;

          const messages = item.details.ai_speaking.items.map(speak => {
            const meta = !!speak.user_response.length ? speak.user_response[0] : {};

            return {
              text: 'AUDIO',
              isAudio: true,
              isSent: true,
              meta,
              payload: speak,
            };
          });

          return {
            pages,
            words,
            messages,
            activity,
            hasFeedback: !!item.feedback.length,
            studentName: item.student_info.name,
            payload: item,
          };
        });

      return {
        data,
        correctness,
        total_elements,
      };
    } catch {
      return {
        total_elements: 0,
        correctness: 0,
        data: [],
      };
    }
  },

  getClassroomCompetencies: async (
    classroomId: string,
    filters: {},
  ): Promise<Competency | undefined> => {
    try {
      const response = await Service.get<StudentCV>(`/api/classroom/${classroomId}/competencies`, {
        params: {...filters},
      });

      const data = CamelCaseKeys(response.data);
      const graphValues = [
        {name: 'Sprechen', value: data.speakingTotalQuestion},
        {name: 'Aussprache', value: data.pronunciationTotalQuestion},
        {name: 'Vokabeln', value: data.vocabTotalQuestion},
        {name: 'Grammatik', value: data.grammarTotalQuestion},
        {name: 'Hören', value: data.listeningTotalQuestion},
        {name: 'Lesen', value: data.readingTotalQuestion},
      ];

      return {...data, graphValues};
    } catch {
      return;
    }
  },

  getClassroomStudents: async (
    classroomId: string,
    filters: {},
  ): Promise<ClassroomStudentRow[]> => {
    try {
      const servicePath = `/api/classroom/${classroomId}/students`;

      const response = await Service.get<ClassroomStudent[]>(servicePath, {
        params: {...filters},
      });

      const students = response.data.map(item => {
        const level = (level: string) => {
          switch (level) {
            case 'A0':
            case 'A1': {
              return 'Básico';
            }
            case 'A2':
            case 'B1': {
              return 'Intermedio';
            }
            case 'B2':
            case 'C1': {
              return 'Avanzado';
            }
            default: {
              return 'Desconocido';
            }
          }
        };
        const student = {
          student_id: item.student.id,
          student_name: item.student.name === 'Jakub Nowak' ? 'Name gelöscht' : item.student.name,
          student_code: item.student.code,
          student_emoji: item.student.emoji,
          student_classroom_id: item.student.classroom_id,
          language_level: level(item.student.language_level),
        };

        if (!item.competencies) {
          return Object.assign(student, {
            has_compentence: false,
            competencies_health_score_color: '',
            competencies_health_score_text: item.student.code,
            competencies_points: 0,
            competencies_voice_tasks: '-',
            competencies_vocabulary_tasks: '-',
            competencies_vocabulary_tasks_average: 0,
          });
        }

        const {competencies} = item;

        const meta = Api.getMetaHealthScore(competencies.health_score);

        const vocabulary_tasks_average = competencies.vocabulary_tasks_average_correct
          ? (competencies.vocabulary_tasks_average_correct * 100).toFixed(0)
          : 0;

        if (
          !competencies.points &&
          !competencies.health_score &&
          !competencies.voice_tasks &&
          !competencies.vocabulary_tasks
        ) {
          return Object.assign(student, {
            has_compentence: false,
            competencies_health_score_color: '',
            competencies_health_score_text: item.student.code,
            competencies_points: competencies.points,
            competencies_voice_tasks: competencies.voice_tasks,
            competencies_vocabulary_tasks: competencies.vocabulary_tasks,
            competencies_health_score_average_desc: meta.average,
            competencies_vocabulary_tasks_average: `${vocabulary_tasks_average} %`,
          });
        }

        return Object.assign(student, {
          has_compentence: true,
          competencies_health_score_color: meta.color,
          competencies_health_score_text: meta.text,
          competencies_points: competencies.points,
          competencies_voice_tasks: competencies.voice_tasks,
          competencies_vocabulary_tasks: competencies.vocabulary_tasks,
          competencies_health_score_average_desc: meta.average,
          competencies_vocabulary_tasks_average: `${vocabulary_tasks_average} %`,
        });
      }) as ClassroomStudentRow[];

      return students.sort((a, b) => {
        return b.competencies_points > a.competencies_points ? 1 : -1;
      });
    } catch {
      return [];
    }
  },

  getMetaHealthScore: (health_score: number) => {
    switch (true) {
      case health_score >= 8: {
        return {
          text: 'High',
          average: 'UP',
          color: 'green',
        };
      }
      case health_score >= 4 && health_score < 8: {
        return {
          text: 'Mid',
          average: 'EQUAL',
          color: 'yellow',
        };
      }
      case health_score >= 0 && health_score < 4: {
        return {
          text: 'Low',
          average: 'DOWN',
          color: 'red',
        };
      }
      default: {
        return {
          text: ' - ',
          average: '',
          color: CornelsenTheme.colors.black,
        };
      }
    }
  },

  getPlaylistsBy: async (classroomId: string, expired: boolean = false, datesFilterTime = {}) => {
    const params = {
      expired,
      ...datesFilterTime,
    };

    return Service.get(`/api/playlist/classroom/${classroomId}`, {params}).then(res => res.data);
  },

  getStudentPlaylistsBy: async (
    studentId: string,
    expired: boolean = false,
    datesFilterTime = {},
  ) => {
    const params = {
      expired,
      ...datesFilterTime,
    };

    return (await Service.get(`/api/playlist/student/${studentId}`, {params})).data;
  },

  addPlaylist: async (payload: any) => {
    return Service.post(`/api/playlist`, payload);
  },

  updatePlaylist: async (playlistId: string, payload: any) => {
    return Service.put(`/api/playlist/${playlistId}`, payload);
  },

  deletePlaylist: async (playlistId: string) => {
    return Service.delete(`/api/playlist/${playlistId}`, {});
  },

  sendFeedbackTextToPlaylist: async (playlistId: string, payload: any) => {
    return Service.post(`/api/playlist/${playlistId}/textFeedback`, payload);
  },
  //curently not used, should be used later
  // sendFeedbackBroadcastTextToPlaylist: async (playlistId: string, payload: any) => {
  //   return Service.post(`/api/playlist/${playlistId}/broadcast-text-message`, payload);
  // },

  sendFeedbackMediaToPlaylist: async (playlistId: string, audio: any) => {
    const formData = new FormData();
    formData.append('audio', audio);

    return Service.post(`/api/playlist/${playlistId}/mediaFeedback`, formData);
  },
  //curently not used, should be used later
  // sendFeedbackBroadcastMediaToPlaylist: async (playlistId: string, payload: any) => {
  //   const formData = new FormData();
  //   formData.append('audio', payload.audio);

  //   for (const studentId of payload.student_ids) {
  //     formData.append('studentIds', studentId);
  //   }

  //   return Service.post(`/api/playlist/${playlistId}/broadcast-media-message`, formData);
  // },

  sendUserProfileFeedback: async (payload: UserProfileFeedbackType) => {
    return FeedbackService.post('/api/feedbacks/cv', payload);
  },

  sendLicenseFeedback: async (payload: LicenseFeedbackType) => {
    return FeedbackService.post('/api/feedbacks/cv/license', payload);
  },
};

export default Api;
