import styled from 'styled-components';

export const Container = styled.div`
  width: 40%;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%), 0 3px 1px -2px rgb(0 0 0 / 10%),
    0 1px 5px 0 rgb(0 0 0 / 10%);

  @media (max-width: 768px) {
    width: 100%;
  }

  .scrollButton {
    position: absolute;
    right: -48px;
    top: 35%;
    height: 88px;
    width: 88px;
    border: none;
    border-radius: 50%;

    @media (min-width: 768px) {
      display: none;
    }

    svg {
      position: absolute;
      right: 34px;
      top: 24px;
      height: 39px;
      width: 56px;
      color: #999999;
    }
  }

  div[title='Kompetenzen'] {
    box-shadow: none;
    margin-bottom: 0;
  }

  #cardContainer {
    overflow-x: auto;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .boxTextWrapper {
    width: 100%;
    @media (max-width: 425px) {
      width: 168.5px;
    }
    @media (max-width: 414px) {
      width: 163.5px;
    }
    @media (max-width: 375px) {
      width: 143.5px;
    }
  }
`;
