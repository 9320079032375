const StorageKeys = {
  CV_TOKEN: 'cv-token',
  K12_TOKEN: 'k12-token',
  CV_USER: 'cv-user',
  K12_USER: 'k12-user',
  CLASSROOM: 'cc-user-classroom',
  CV_CLASS_STATE: 'k12-user-classroom-state',
  CC_USER: 'cc-user',
};

const Storage = {
  setCvToken: async (token: string): Promise<void> => {
    try {
      await localStorage.setItem(StorageKeys.CV_TOKEN, token);
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`Could not save ${StorageKeys.CV_TOKEN} value on AsyncStorage => ${e}`);
    }
  },

  getCvToken: async (): Promise<string | null> => {
    try {
      const value = await localStorage.getItem(StorageKeys.CV_TOKEN);

      return value;
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`Could extract ${StorageKeys.CV_TOKEN} value on AsyncStorage => ${e}`);
      return null;
    }
  },

  resetSession: (): void => {
    try {
      localStorage.clear();
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`Error on resetSession => ${e}`);
    }
  },

  removeClassroom: async (): Promise<void> => {
    try {
      localStorage.removeItem(StorageKeys.CLASSROOM);
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`Could not save ${StorageKeys.CLASSROOM} value on AsyncStorage => ${e}`);
    }
  },

  getClassroom: async (): Promise<any | null> => {
    try {
      const value = await localStorage.getItem(StorageKeys.CLASSROOM);

      return value ? JSON.parse(value) : undefined;
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`Could extract ${StorageKeys.CLASSROOM} value on AsyncStorage => ${e}`);
      return null;
    }
  },

  setClassroom: async (payload: any): Promise<void> => {
    try {
      await localStorage.setItem(StorageKeys.CLASSROOM, JSON.stringify(payload));
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`Could not save ${StorageKeys.CLASSROOM} value on AsyncStorage => ${e}`);
    }
  },

  setClassState: async (payload: any): Promise<void> => {
    try {
      await localStorage.setItem(StorageKeys.CV_CLASS_STATE, JSON.stringify(payload));
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`Could not save ${StorageKeys.CV_CLASS_STATE} value on AsyncStorage => ${e}`);
    }
  },
  getClassState: async (): Promise<any | null> => {
    try {
      const value = await localStorage.getItem(StorageKeys.CV_CLASS_STATE);

      return value ? JSON.parse(value) : undefined;
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`Could extract ${StorageKeys.CV_CLASS_STATE} value on AsyncStorage => ${e}`);
      return null;
    }
  },
  getCCUser: async (): Promise<any | null> => {
    try {
      const value = localStorage.getItem(StorageKeys.CC_USER);

      return value ? JSON.parse(value) : undefined;
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.warn(`Couldn't extract ${StorageKeys.CC_USER} value on AsyncStorage => ${e}`);
      return null;
    }
  },
};

export default Storage;
