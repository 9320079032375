import {useEffect} from 'react';
import {openExternalLink} from '@cc/utils';
import {getAlternativeLink} from '../utils';
import {isAndroid} from 'react-device-detect';

const TIMEOUT = 2000;

export const useAndroidFallbackLinking = () => {
  useEffect(() => {
    if (!isAndroid) {
      return;
    }

    const alternativeLink = getAlternativeLink(window.location.href);
    setTimeout(() => openExternalLink(alternativeLink), TIMEOUT);
  }, []);
};
