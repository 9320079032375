import {useTheme} from 'styled-components';
import {Modal, Container, Text, Button} from 'cc-web-components';
import {useTranslation} from 'react-i18next';

import Images from '@/assets/images';
import useDeviceDetection from '@/hooks/IsMobile';
import {useDispatch} from 'react-redux';
import {showAlert, startLoading, stopLoading} from '@/redux/Session';
import ApiClassroomService from '@/services/ApiCv/ApiClassroomService';
import {useNavigate} from 'react-router-dom';
import {useCallback} from 'react';

type Props = {
  show: boolean;
  classroomId: string;
  classroomName: string;
  closeRequest: () => void;
  onClassroomDelete: () => void;
};

export const DeleteModal: React.FC<Props> = ({
  show,
  classroomId,
  closeRequest,
  classroomName,
  onClassroomDelete,
}) => {
  const {colors} = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation('cornelsen');
  const {isMobile} = useDeviceDetection();

  const handleDelete = useCallback(() => {
    dispatch(startLoading());
    ApiClassroomService['deleteClassroom'](classroomId)
      .then(data => {
        navigate('/', {
          state: {
            alert: {
              message: 'Classroom has been deleted',
              type: 'info',
            },
          },
        });
      })
      .catch(() => {
        dispatch(
          showAlert({
            value: 'Something went wrong',
            variant: 'danger',
          }),
        );
      })
      .finally(() => dispatch(stopLoading()));
    onClassroomDelete();
  }, [classroomId, dispatch, navigate, onClassroomDelete]);

  const renderControls = () => (
    <Container
      mt={32}
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="center"
      alignItems="center"
      style={{gap: isMobile ? '16px' : '32px'}}>
      <Button
        type="button"
        variant="solid"
        value={t('Home.DeleteModal.Cancel')}
        fontSize={20}
        fontWeight={400}
        width={isMobile ? '300px' : '150px'}
        height={44}
        paddingRight={20}
        paddingLeft={20}
        style={{
          backgroundColor: colors.white,
          border: '1px solid #ddd',
          color: colors.primary,
          boxShadow: 'none',
        }}
        onClick={closeRequest}
      />
      <Button
        type="button"
        variant="solid"
        value={t('Home.DeleteModal.DeleteButton')}
        fontSize={20}
        fontWeight={400}
        height={44}
        width={isMobile ? '300px' : '150px'}
        paddingRight={20}
        paddingLeft={20}
        style={{
          backgroundColor: colors.white,
          border: '1px solid #ddd',
          color: colors.primary,
          boxShadow: 'none',
        }}
        onClick={handleDelete}
      />
    </Container>
  );

  return (
    <Container id="modalWrapper">
      <Modal
        show={show}
        width="650px"
        backgroundColor={colors.white}
        padding={isMobile ? '0 30px 30px' : '0 60px 30px'}>
        <Container justifyContent="center" height={170} alignItems="center">
          <img src={Images.TrashBlue} alt="" width={64} />
        </Container>

        <Container flexDirection="column" alignItems="center">
          <Text
            fontSize={20}
            color={colors.black}
            mb={2}
            value={t('Home.DeleteModal.Title', {classroomName: classroomName})}
          />
          <Text
            fontSize={16}
            fontWeight={400}
            color="#637388"
            textAlign="center"
            value={t('Home.DeleteModal.Subtitle')}
          />
        </Container>

        {renderControls()}
      </Modal>
    </Container>
  );
};
