import {Colors} from 'cc-web-components/dist/components/theme';

import {Theme} from '@emotion/react';
import {color} from '@cornelsen/cvds-tokens/product/react-native/colors';
import {sizes} from '@cornelsen/cvds-tokens/product/react-native/sizes';
import {spacings} from '@cornelsen/cvds-tokens/product/react-native/spacings';
import {typography} from '@cornelsen/cvds-tokens/product/react-native/typography';

declare module 'styled-components' {
  export interface DefaultTheme {
    breakpoints: string[];
    mediaQueries: {
      small: string;
      medium: string;
      large: string;
    };
    colors: Colors;
    elevation: string[];
    spacing: number[];
    fontSizes: number[];
    borderWidths: number[];
    radii: number[];
    zIndex: {
      Modal: number;
      Menu: number;
      Toolbar: number;
      FooterMenu: number;
    };
  }
}

export {CornelsenTheme} from './CornelsenTheme';
export {GlobalStyle} from './GlobalStyle';

export const AppTheme: Theme = {
  colors: color,
  sizes,
  spacings,
  typography,
};

export {mq, mqArray, DEVICES} from './breakpoints';
export * from './utils';
