import React, {useCallback, useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {BiCaretDown, BiCaretUp} from 'react-icons/bi';
import {Container, Table, Button} from 'cc-web-components';
import Helpers from '@/helper';
import {ActivitiesTableWrapper, ClassName} from './styles';

import {
  BookPileIcon,
  BlueBookIcon,
  WorkSpeechIcon,
  WorkSpokenIcon,
  CommentOutlinedGrayIcon,
  ListenOutlinedIcon,
  CommentUpGrayFilled,
  ChevronUpLined,
  ChevronDownLined,
  ArrowDownLined,
} from '@/components/Icons';
import MessageList from '@/components/MessageList';
import FormFeedback from '@/components/FormFeedback';
import {FilterTime} from '@/helper';
import useDeviceDetectionSm from '@/hooks/IsSmall';
import useDeviceDetectionMobile from '@/hooks/IsMobile';
import {Activity, ActivityMessageAudio, ActivityResponse} from '@/services/ApiCv/types/Activity';
import ApiStudentService from '@/services/ApiCv/ApiStudentService';

import TableHeader from './TableHeader';
import {getMessageListOfTheActivity} from './helpers';
import {StyledStudentLink, StyledColumnIcon} from './styles';
import {Classrooms} from '@/pages/Home/types';
import {startLoading, stopLoading} from '@/redux/Session';
import {Routes} from '@cc/shared';

const {CLASSROOM} = Routes;

type SortConfigDirection = 'ASC' | 'DESC' | null;

type SortConfig = {
  key: null | keyof Activity;
  direction: SortConfigDirection;
};

type Props = {
  parent: 'CLASSROOM' | 'STUDENT_DETAILS';
  color: string;
  data: ActivityResponse;
  filterPageActived: number;
  activeTableFilter: number;
  datesFilterTime: FilterTime | undefined;
  onLoadMoreItems?: () => void;
  onNextValues: () => void;
  onPrevValues: () => void;
  showMore?: string;
  classrooms?: Classrooms;
  feedbackCallback?: () => void;
  hideFeedbackForm?: boolean;
};

type PropsOnSubmitResponse = {
  content: string;
  type: 'TEXT' | 'MEDIA';
};

const TableStudentActivities: React.FC<Props> = (props: Props) => {
  const {
    data,
    color,
    parent,
    filterPageActived,
    activeTableFilter,
    datesFilterTime,
    onNextValues,
    onPrevValues,
    onLoadMoreItems,
    showMore,
    classrooms,
    feedbackCallback,
  } = props;

  const {colors} = useTheme();
  const {isSmall} = useDeviceDetectionSm();
  const {isMobile} = useDeviceDetectionMobile();
  const {t} = useTranslation('cornelsen');
  const dispatch = useDispatch();

  const audio = document.createElement('audio');
  const audioRef = useRef<HTMLAudioElement>(audio);
  const [activeContentIndex, setActiveContentIndex] = useState(-1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudio, setCurrentAudio] = useState<ActivityMessageAudio>();
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: null,
    direction: null,
  });

  const resetStateComponent = useCallback(() => {
    setActiveContentIndex(-1);
    setSortConfig({
      key: null,
      direction: null,
    });
  }, []);

  useEffect(() => {
    resetStateComponent();
  }, [activeTableFilter, resetStateComponent]);

  useEffect(() => {
    isPlaying ? audioRef.current?.play() : audioRef.current?.pause();
  }, [isPlaying]);

  const sortedActivities = React.useMemo(() => {
    const activities = [...data.content];

    const sortedActivities = activities.sort((a, b) => {
      if (sortConfig.key) {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'DESC' ? 1 : -1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ASC' ? 1 : -1;
        }
      }

      return 0;
    });

    return sortedActivities;
  }, [data.content, sortConfig]);

  const openExtraContent = (index: number) => {
    if (activeContentIndex === index) {
      setActiveContentIndex(-1);
    } else {
      setActiveContentIndex(index);
    }
  };

  const handleShowMoreItems = () => {
    if (data.content.length < data.total_elements) {
      onLoadMoreItems && onLoadMoreItems();
    }
  };

  const requestSort = (key: keyof Activity) => {
    let direction: SortConfigDirection = 'ASC';

    if (sortConfig.direction === 'ASC') {
      direction = 'DESC';
    }

    setActiveContentIndex(-1);
    setSortConfig({key, direction});
  };

  const getIconsOrdered = (columnName: string) => {
    let opacityIconUp = 0.5;
    let opacityIconDown = 0.5;

    if (!!sortConfig.key && sortConfig.key === columnName) {
      opacityIconUp = sortConfig.direction === 'ASC' ? 1 : 0.5;
      opacityIconDown = sortConfig.direction === 'DESC' ? 1 : 0.5;
    }

    return (
      <Container flexDirection="column" marginRight={1}>
        <Container marginBottom={-2}>
          <BiCaretUp color={colors.primary} style={{opacity: opacityIconUp}} />
        </Container>

        <BiCaretDown color={colors.primary} style={{opacity: opacityIconDown}} />
      </Container>
    );
  };

  const onPlayAudio = (activity: Activity) => {
    try {
      const [lastResponse] = activity.responses.slice(-1);

      if (!lastResponse) return;

      const messageMedia = lastResponse.message as ActivityMessageAudio;

      if (!messageMedia.media) return;

      setCurrentAudio(messageMedia);
      setIsPlaying(!isPlaying);
    } catch (err) {}
  };

  const renderColumnSortable = (columnName: keyof Activity, children: any) => {
    const justifyContent = columnName === 'has_feedback' ? 'flex-end' : '';

    return (
      <Container
        justifyContent={justifyContent}
        style={{cursor: 'pointer'}}
        alignItems="center"
        onClick={() => requestSort(columnName)}>
        <Container flexDirection="column" marginRight={isMobile ? 0 : 1}>
          {getIconsOrdered(columnName)}
        </Container>

        {children}
      </Container>
    );
  };

  const classRoomData = (activity: Activity) => {
    let studentColor = color;
    const studentClass = classrooms?.find(item => item.id === activity.classroom_id);

    if (studentClass?.color) {
      studentColor = Helpers.getMetaColors(studentClass?.color).highlightColor;
    }

    return {backgroundColor: studentColor, classroomName: studentClass?.display_name};
  };

  const GetColumnsClassRoom = (activeContentIndex: number) => {
    return [
      {
        header: renderColumnSortable(
          'user_name',
          <>{t('Home.SectionLastestSpeaking.TableHeader.StudentName')}</>,
        ),
        renderItem: (item: Activity) => {
          const {backgroundColor, classroomName} = classRoomData(item);

          return (
            <Container>
              <Container width={8} height={65} backgroundColor={backgroundColor} />
              <Container flex={1} alignItems={'center'}>
                <Container paddingLeft={2}>
                  {item.user_emoji}
                  <Container flexDirection={'column'} paddingLeft={2}>
                    <StyledStudentLink>
                      <Link to={`${CLASSROOM}/${item.classroom_id}/student/${item.student_id}`}>
                        {item.user_name}
                      </Link>
                    </StyledStudentLink>
                    {classroomName && <ClassName>{classroomName}</ClassName>}
                  </Container>
                </Container>
              </Container>
            </Container>
          );
        },
        // highlightColor: studentColor,
      },
      isMobile
        ? {
            header: null,
            highlightColor: null,
            renderItem: () => {},
          }
        : {
            header: <>{t('Home.SectionLastestSpeaking.TableHeader.PageNumber')}</>,
            renderItem: (item: Activity) => <>{item.page_number_spec}</>,
          },
      {
        header: (
          <Container pl={'12px'}>{t('Home.SectionLastestSpeaking.TableHeader.TaskName')}</Container>
        ),
        renderItem: (item: Activity) => (
          <StyledColumnIcon>
            {item.stat_type === 'AI_SPEAKING' && (
              <>
                <WorkSpeechIcon width={20} />
                <span>
                  {t('Home.SectionLastestSpeaking.TaskTypes.TypeSpeak')} - {item.activity_title}
                </span>
              </>
            )}
            {item.stat_type === 'PRONUNCIATION' && (
              <>
                <WorkSpokenIcon width={20} />
                <span>
                  {t('Home.SectionLastestSpeaking.TaskTypes.TypePronunce')} - {item.activity_title}
                </span>
              </>
            )}
            {item.stat_type === 'LISTENING' && (
              <>
                <>👂</>
                <span>
                  {t('Home.SectionLastestSpeaking.TaskTypes.TypeListening')} - {item.activity_title}
                </span>
              </>
            )}
            {item.stat_type === 'DIALOG' && (
              <>
                <>📖</>
                <span>
                  {t('Home.SectionLastestSpeaking.TaskTypes.TypeDialog')} - {item.activity_title}
                </span>
              </>
            )}
            {item.stat_type === 'GRAMMAR' && (
              <>
                <BookPileIcon />
                <span>
                  {t('Home.SectionLastestSpeaking.TaskTypes.TypeGrammar')} - {item.activity_title}
                </span>
              </>
            )}

            {item.stat_type === 'VOCABULARY_TRAINER' && (
              <>
                <BlueBookIcon />
                <span>
                  {t('Home.SectionLastestSpeaking.TaskTypes.TypeVocab')} - {item.activity_title}
                </span>
              </>
            )}

            {item.stat_type === 'VOCABULARY' && (
              <>
                <BlueBookIcon />
                <span>
                  {t('Home.SectionLastestSpeaking.TaskTypes.TypeVocab')} - {item.activity_title}
                </span>
              </>
            )}
          </StyledColumnIcon>
        ),
      },
      {
        header: t('Home.SectionLastestSpeaking.TableHeader.Korrect'),
        renderItem: (item: Activity) => {
          if (item.percent_right) {
            return <span>{`${item.percent_right} %`}</span>;
          }

          return '-';
        },
      },
      {
        align: isMobile ? 'left' : 'right',
        header: renderColumnSortable(
          'has_feedback',
          <Container pr={[46, 46, 28, 22]}>
            {t('Home.SectionLastestSpeaking.TableHeader.Feedback')}
          </Container>,
        ),
        renderItem: (item: Activity, index: number) => {
          return (
            <Container justifyContent={'flex-end'}>
              <Container id="feedbackButtons">
                <Button
                  variant="link"
                  leftContent={
                    item.has_feedback ? (
                      <CommentUpGrayFilled width={20} />
                    ) : (
                      <CommentOutlinedGrayIcon width={20} />
                    )
                  }
                  onClick={() => {}}
                />

                <Button
                  variant="link"
                  leftContent={
                    item.has_feedback ? (
                      <ListenOutlinedIcon width={21} />
                    ) : (
                      <ListenOutlinedIcon width={21} />
                    )
                  }
                  onClick={() => onPlayAudio(item)}
                />
              </Container>

              <Button
                variant="link"
                leftContent={
                  activeContentIndex === index ? (
                    <Container pt={'3px'}>
                      <ChevronUpLined width={20} />
                    </Container>
                  ) : (
                    <Container pt={'3px'}>
                      <ChevronDownLined width={20} />
                    </Container>
                  )
                }
                onClick={() => openExtraContent(index)}
              />
            </Container>
          );
        },
      },
    ];
  };

  const GetColumnsStudent = (activeContentIndex: number) => {
    return [
      {
        header: <>{t('Student.TabOverView.SectionActivities.TableTasks.Header.Page')}</>,
        highlightColor: color,
        renderItem: (item: Activity) => <>{item.page_number_spec}</>,
      },
      isSmall
        ? {
            header: null,
            highlightColor: null,
            renderItem: () => {},
          }
        : {
            header: (
              <Container pl={'12px'}>
                {t('Student.TabOverView.SectionActivities.TableTasks.Header.Task')}
              </Container>
            ),
            breakLine: isSmall,
            renderItem: (item: Activity) => (
              <StyledColumnIcon>
                {item.stat_type === 'AI_SPEAKING' && (
                  <>
                    <WorkSpeechIcon width={30} />
                    <span>
                      {t('Student.TabOverView.SectionActivities.TableTasks.TypeSpeak')} -{' '}
                      {item.activity_title}
                    </span>
                  </>
                )}
                {item.stat_type === 'PRONUNCIATION' && (
                  <>
                    <WorkSpokenIcon />
                    <span>
                      {t('Student.TabOverView.SectionActivities.TableTasks.TypePronunce')} -{' '}
                      {item.activity_title}
                    </span>
                  </>
                )}
                {item.stat_type === 'LISTENING' && (
                  <>
                    <>👂</>
                    <span>
                      {t('Home.SectionLastestSpeaking.TaskTypes.TypeListening')} -{' '}
                      {item.activity_title}
                    </span>
                  </>
                )}
                {item.stat_type === 'DIALOG' && (
                  <>
                    <>📖</>
                    <span>
                      {t('Home.SectionLastestSpeaking.TaskTypes.TypeDialog')} -{' '}
                      {item.activity_title}
                    </span>
                  </>
                )}
                {item.stat_type === 'GRAMMAR' && (
                  <>
                    <BookPileIcon />
                    <span>
                      {t('Student.TabOverView.SectionActivities.TableTasks.TypeGrammar')} -{' '}
                      {item.activity_title}
                    </span>
                  </>
                )}

                {item.stat_type === 'VOCABULARY_TRAINER' && (
                  <>
                    <BlueBookIcon />
                    <span>
                      {t('Student.TabOverView.SectionActivities.TableTasks.TypeVocab')} -{' '}
                      {item.activity_title}
                    </span>
                  </>
                )}

                {item.stat_type === 'VOCABULARY' && (
                  <>
                    <BlueBookIcon />
                    <span>
                      {t('Student.TabOverView.SectionActivities.TableTasks.TypeVocab')} -{' '}
                      {item.activity_title}
                    </span>
                  </>
                )}
              </StyledColumnIcon>
            ),
          },
      {
        header: t('Student.TabOverView.SectionActivities.TableTasks.Header.Korrect'),
        renderItem: (item: Activity) => {
          if (item.percent_right) {
            return <span>{`${item.percent_right} %`}</span>;
          }

          return '-';
        },
      },
      {
        align: 'right',
        header: renderColumnSortable(
          'has_feedback',
          <Container pr={[46, 46, 28, 22]}>
            {t('Student.TabOverView.SectionActivities.TableTasks.Header.Feedback')}
          </Container>,
        ),
        renderItem: (item: Activity, index: number) => (
          <Container justifyContent="flex-end">
            <Container id="feedbackButtons">
              <Button
                variant="link"
                leftContent={
                  item.has_feedback ? (
                    <CommentUpGrayFilled width={20} />
                  ) : (
                    <CommentOutlinedGrayIcon width={20} />
                  )
                }
                onClick={() => {}}
              />
              <Button
                variant="link"
                leftContent={
                  item.has_feedback ? (
                    <ListenOutlinedIcon width={21} />
                  ) : (
                    <ListenOutlinedIcon width={21} />
                  )
                }
                onClick={() => onPlayAudio(item)}
              />
            </Container>
            <Button
              variant="link"
              leftContent={
                activeContentIndex === index ? (
                  <Container pt={'3px'}>
                    <ChevronUpLined width={20} />
                  </Container>
                ) : (
                  <Container pt={'3px'}>
                    <ChevronDownLined width={20} />
                  </Container>
                )
              }
              onClick={() => openExtraContent(index)}
            />
          </Container>
        ),
      },
    ];
  };

  const GetColumns = () => {
    switch (parent) {
      case 'CLASSROOM': {
        return GetColumnsClassRoom(activeContentIndex);
      }
      case 'STUDENT_DETAILS': {
        return GetColumnsStudent(activeContentIndex);
      }
    }
  };

  const tableName = () => {
    if (parent === 'CLASSROOM') return 'activitiesClassroomTable';
    else return 'activitiesStudentTable';
  };

  const handleFeedback = async (payload: PropsOnSubmitResponse) => {
    if (!payload.content || !feedbackCallback) return;

    dispatch(startLoading());

    if (payload.type === 'TEXT') {
      ApiStudentService.postAddFeedbackText({
        studentId: data.content[activeContentIndex].student_id,
        classroomId: data.content[activeContentIndex].classroom_id,
        payload: {
          statId: data.content[activeContentIndex].stat_id,
          feedback: payload.content,
        },
      })
        .then(() => {
          feedbackCallback();
          dispatch(stopLoading());

          Helpers.showNotification(
            t('Globals.Notification.Success.Title'),
            t('Globals.Notification.Success.MessageSent'),
            'success',
          );
        })
        .catch(() => {
          dispatch(stopLoading());

          Helpers.showNotification(
            t('Globals.Notification.Error.Title'),
            t('Globals.Notification.Error.SomethingWrong'),
            'danger',
          );
        });
    }

    if (payload.type === 'MEDIA') {
      const formData = new FormData();

      formData.append('statId', data.content[activeContentIndex].stat_id);
      formData.append('audio', payload.content);
      ApiStudentService.postAddFeedbackMedia({
        studentId: data.content[activeContentIndex].student_id,
        classroomId: data.content[activeContentIndex].classroom_id,
        payload: formData,
      })
        .then(() => {
          feedbackCallback();
          dispatch(stopLoading());

          Helpers.showNotification(
            t('Globals.Notification.Success.Title'),
            t('Globals.Notification.Success.MessageSent'),
            'success',
          );
        })
        .catch(() => {
          dispatch(stopLoading());

          Helpers.showNotification(
            t('Globals.Notification.Error.Title'),
            t('Globals.Notification.Error.SomethingWrong'),
            'danger',
          );
        });
    }
  };

  const TableExtraContent = () => {
    if (activeContentIndex < 0) {
      return <></>;
    }

    const activity = sortedActivities[activeContentIndex];

    const messages = getMessageListOfTheActivity(activity);

    return (
      <>
        <MessageList xp_number={activity.xp} chat={messages} />

        {!props.hideFeedbackForm && (
          <Container padding="5px 18px">
            <FormFeedback
              onSubmit={handleFeedback}
              wordsSpeedFeedback={[
                {value: t('Globals.ButtonsFeedbacks.Type1.Button1')},
                {value: t('Globals.ButtonsFeedbacks.Type1.Button2')},
                {value: t('Globals.ButtonsFeedbacks.Type1.Button3')},
              ]}
            />
          </Container>
        )}
      </>
    );
  };

  return (
    <ActivitiesTableWrapper>
      <Table
        id={tableName()}
        mt={4}
        flex={1}
        data={sortedActivities}
        indexOpenContent={activeContentIndex}
        columns={GetColumns()}
        renderExtraContent={() => <TableExtraContent />}
        header={
          showMore === 'home' ? (
            <></>
          ) : (
            <TableHeader
              correctness={data.correctness}
              onNextValues={() => {
                resetStateComponent();

                onNextValues();
              }}
              onPrevValues={() => {
                resetStateComponent();

                onPrevValues();
              }}
              datesFilterTime={datesFilterTime}
              total_elements={data.total_elements}
              filterPageActived={filterPageActived}
            />
          )
        }
        footer={
          showMore === 'home' || data.content.length >= data.total_elements ? (
            <></>
          ) : (
            <Container justifyContent="flex-end">
              <Button
                value={t('Classroom.Activities.ButtonFooterText')}
                variant="link"
                onClick={() => handleShowMoreItems()}
                fontSize={18}
                fontFamily={'CV_Source_Sans'}
                fontWeight={600}
                mr={12}
                rightContent={
                  <Container ml={16}>
                    <ArrowDownLined width={16} />
                  </Container>
                }
              />
            </Container>
          )
        }
      />

      {currentAudio && (
        <audio
          ref={audioRef}
          src={currentAudio.media.source_url}
          autoPlay={false}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          preload="false"
        />
      )}
    </ActivitiesTableWrapper>
  );
};

export default TableStudentActivities;
