import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.32);
  height: 100vh;
  width: 100vw;
  z-index: 19;
  display: flex;
  justify-content: flex-end;
`

export const Content = styled.div<{ isMobile: boolean }>`
  background-color: #ffffff;
  height: 100%;
  width: ${(props) => (props.isMobile ? '100%' : '320px')};
  padding-top: 60px;
`
