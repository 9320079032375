import {PayloadAction, createAction, createReducer} from '@reduxjs/toolkit';
import {BLACKLISTED_FEATURES, FEATURES, RIGHTS} from './permissionConfig';
import {FEATURES_BLACKLIST_CONFIG} from './permissionConfig';
import {UserType} from '@cc/shared';
import {BLACKLISTED_NAV_SETTINGS, NAV_BLACKLIST_CONFIG} from './navigationConfig';

/** Action creators */
export const setConfig = createAction<UserType>('SET_CONFIG');

type RightsState = {
  userType?: UserType;
  featuresConfig?: BLACKLISTED_FEATURES;
  navigationConfig?: BLACKLISTED_NAV_SETTINGS;
};

/** Initial state */
export const INITIAL_STATE: RightsState = {
  userType: undefined,
  featuresConfig: undefined,
  navigationConfig: undefined,
};

/** Reducer */
export default createReducer(INITIAL_STATE, {
  [setConfig.type]: (state, {payload}: PayloadAction<UserType>): RightsState => {
    return {
      ...state,
      userType: payload,
      featuresConfig: FEATURES_BLACKLIST_CONFIG[payload],
      navigationConfig: NAV_BLACKLIST_CONFIG[payload],
    };
  },
});

/** Selectors */
const rootSelector = (state: any): RightsState => state.rights;

export const selectFeaturesConfig = (state: RightsState) => rootSelector(state).featuresConfig;

export const selectNavConfig = (state: RightsState) => rootSelector(state).navigationConfig;

export const selectUserType = (state: RightsState) => rootSelector(state).userType;

export const selectIsFeatureAvailable =
  (feature: FEATURES, right: RIGHTS) => (state: RightsState) => {
    const isFeatureBlocked = selectFeaturesConfig(state)?.[feature]?.includes(right);
    return !isFeatureBlocked;
  };
