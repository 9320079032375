import {UserType} from '@cc/shared';

type NAVIGATION_OPTIONS = 'MY_DATA' | 'EDIT_PROFILE' | 'LICENSES' | 'FEEDBACK' | 'HELP';
export type BLACKLISTED_NAV_SETTINGS = {[key in NAVIGATION_OPTIONS]?: boolean};
type BLACKLIST = {[key in UserType]: BLACKLISTED_NAV_SETTINGS | undefined};

const CORNELSEN_BLACKLIST_CONFIG = {};
const BSP_BLACKLIST_CONFIG: BLACKLISTED_NAV_SETTINGS = {
  MY_DATA: true,
  EDIT_PROFILE: true,
  LICENSES: true,
};

export const NAV_BLACKLIST_CONFIG: BLACKLIST = {
  CORNELSEN: CORNELSEN_BLACKLIST_CONFIG,
  BSP: BSP_BLACKLIST_CONFIG,
};
