import React, {useEffect, useMemo, useState} from 'react';
import {Formik} from 'formik';
import {format} from 'date-fns';
import {useTheme} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Button, Container, Page, Dropdown} from 'cc-web-components';
import {MdClose} from 'react-icons/md';
import {useParams, useNavigate} from 'react-router-dom';
import {ReactNotifications, Store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

import Api from '@/services/ApiCv';
import Footer from '@/components/Footer';
import Images from '@/assets/images';
import Avatar from '@/components/Avatar';
import Helpers, {FilterTime} from '@/helper';
import useQueryParams from '@/hooks/QueryParams';
import {Student, Classroom} from '@/services/ApiCv/types';
import useDeviceDetectionSmall from '@/hooks/IsSmall';
import useDeviceDetection from '@/hooks/IsMobile';
import {useTranslationContext} from '@/redux/Translation';
import {LanguageTypesEnum} from '@/redux/Translation/types';
import {startLoading, stopLoading} from '@/redux/Session';
import {WhiteEditIcon} from '@/components/Icons';
import {AiOutlineStar} from 'react-icons/ai';

import DeleteModal from '@/pages/StudentDetails/Modals/DeleteModal';
import AccessCodeModal from '@/pages/StudentDetails/Modals/AccessCodeModal';
import AdjustLevelModal from '@/pages/StudentDetails/Modals/AdjustLevelModal';

import TabTasks from './TabTasks';
import StudyProgress from './StudyProgress';
import LearningActivities from './LearningActivities';

import {TabWrapper, DropdownWrapper} from './styles';
import './styles.scss';
import {Routes} from '@cc/shared';
import {selectIsFeatureAvailable} from '@/redux/Rights';
import {getHeaderBackground} from '@/utils';

const {NEW_CLASSROOM} = Routes;

const TABS = {
  LEARNING_ACTIVITIES: 0,
  STUDY_PROGRESS: 1,
  TASKS: 2,
};

const ActionsModal = {
  CHANGE_LEVEL: 2,
};

type Error = {
  message?: string;
};

export const StudentDetailsPage: React.FC = () => {
  const {t} = useTranslation('cornelsen');
  const [{language}] = useTranslationContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {colors} = useTheme();
  const queryParams = useQueryParams();
  const {isSmall} = useDeviceDetectionSmall();
  const {isMobile} = useDeviceDetection();
  const {classroomId, studentId} = useParams<{classroomId: string; studentId: string}>();

  const [student, setStudent] = useState<Student>();
  const [classroom, setClassroom] = useState<Classroom>();
  const [activeTab, setActiveTab] = useState(TABS.LEARNING_ACTIVITIES);
  const [activeFilter, setActiveFilter] = useState(0);
  const [activeAction, setActiveAction] = useState(0);
  const [datesFilterTime, setDatesFilterTime] = useState<FilterTime>();
  const [openEditName, setOpenEditName] = useState(false);
  const [openLevelModal, setOpenLevelModal] = useState(false);
  const [openCodeModal, setOpenCodeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const isDeleteVisible = useSelector(selectIsFeatureAvailable('STUDENT', 'D'));
  const isUpdateVisible = useSelector(selectIsFeatureAvailable('STUDENT', 'U'));
  const isCodeUpdateVisible = useSelector(selectIsFeatureAvailable('STUDENT_CODE', 'U'));

  const TabDefault = queryParams.get('tabDefault');
  const RedirectTo = queryParams.get('redirectTo');

  const FILTERS_TIME = [
    {key: 0, label: t('Student.FilterTime.All')},
    {key: 1, label: t('Student.FilterTime.Today')},
    {key: 2, label: t('Student.FilterTime.Latest7Days')},
    {key: 3, label: t('Student.FilterTime.Latest30Days')},
  ];

  useEffect(() => {
    const banner = document.getElementById('banner') as HTMLDivElement;
    if (banner) {
      banner.style['display'] = 'flex';
    }
  });

  useEffect(() => {
    if (!classroomId) return;

    Api.getClassroom(classroomId).then(classroom => setClassroom(classroom));
  }, [classroomId]);

  useEffect(() => {
    dispatch(startLoading());

    const studentCode = queryParams.get('code');

    if (!!studentId) {
      Api.getStudent(studentId)
        .then(student => {
          setStudent(student);

          dispatch(stopLoading());
        })
        .catch(() => {
          dispatch(stopLoading());
        });
    } else if (!!studentCode) {
      Api.getStudentByCode(studentCode)
        .then(student => {
          setStudent(student);

          dispatch(stopLoading());
        })
        .catch(() => {
          dispatch(stopLoading());
        });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeAction === 1) setOpenEditName(true);
    else if (activeAction === 2) setOpenLevelModal(true);
    else if (activeAction === 3) setOpenCodeModal(true);
    else if (activeAction === 4) setOpenDeleteModal(true);
  }, [activeAction]);

  useEffect(() => {
    if (RedirectTo !== 'tasks') {
      return;
    }

    if (RedirectTo === 'tasks') {
      navigate(window.location.pathname + '?tabDefault=tasks', {replace: true});
    }
  }, [RedirectTo, navigate]);

  useEffect(() => {
    if (TabDefault === 'tasks') {
      setActiveTab(TABS.TASKS);
    }

    if (TabDefault === 'progress') {
      setActiveTab(TABS.STUDY_PROGRESS);
    }
  }, [TabDefault]);

  const getJoinTime = () => {
    if (!student || !student.member_from) {
      return '';
    }

    let unixTimestamp = student.member_from;
    const date = new Date(unixTimestamp);
    let joinHour;
    let joinMinute;
    if (date.getHours() < 10) joinHour = '0' + date.getHours();
    else joinHour = date.getHours();
    if (date.getMinutes() < 10) joinMinute = '0' + date.getMinutes();
    else joinMinute = date.getMinutes();
    return joinHour + ':' + joinMinute;
  };

  const getJoinDate = () => {
    try {
      if (!student || !student.member_from) {
        return '';
      }

      const formatTemplate = language === LanguageTypesEnum.EN ? 'MM.dd.yyyy' : 'dd.MM.yyyy';

      return format(new Date(student.member_from), formatTemplate);
    } catch (error) {
      return '';
    }
  };

  const showNotification = (title: string, message: string, type: any) => {
    Store.addNotification({
      title,
      message,
      type,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 3000,
        onScreen: true,
        pauseOnHover: true,
      },
    });
  };

  const submitForm = async (values: any) => {
    const {studentName} = values;

    if (!student || !studentName || studentName === student.name) {
      return;
    }

    try {
      const studentCode = queryParams.get('code');

      if (!!studentCode && classroomId) {
        await Api.updateStudentByCode(studentCode, classroomId, studentName, student.level);
      } else if (studentId && classroomId) {
        await Api.updateStudent(studentId, classroomId, studentName, student.level);
      }

      setStudent({
        ...student,
        name: studentName,
      });
      setOpenEditName(false);
      setActiveAction(0);
      showNotification(
        t('Globals.Notification.Success.Title'),
        t('Globals.Notification.Success.StudentName'),
        'success',
      );
    } catch (error) {
      const errorMessage = (error as Error).message;
      if (errorMessage) {
        showNotification(t('Globals.Notification.Error.Title'), errorMessage, 'danger');
      }
    }
  };

  const getNewCode = async (_currentCode: string) => {
    if (!student) {
      return;
    }

    try {
      let response = {data: ''};

      if (!!student.code && classroomId) {
        response = await Api.refreshCodeStudent(student.code, classroomId);
      } else if (studentId && classroomId) {
        response = await Api.getNewStudentCode(studentId, classroomId);
      }

      setStudent({
        ...student,
        code: response.data,
      });

      showNotification(
        t('Globals.Notification.Success.Title'),
        `${student.name}'s ${t('Globals.Notification.Success.CodeUpdated')}`,
        'success',
      );
    } catch (error) {
      const errorMessage = (error as Error).message;
      if (errorMessage) {
        showNotification(t('Globals.Notification.Error.Title'), errorMessage, 'danger');
      }
    }
  };

  const updateStudentLevel = async (studentLevel: string) => {
    if (!student || studentLevel === student.level) {
      return;
    }

    try {
      const studentCode = queryParams.get('code');

      if (!!studentCode && classroomId) {
        await Api.updateStudentByCode(studentCode, classroomId, student.name, studentLevel);
      } else if (studentId && classroomId) {
        await Api.updateStudent(studentId, classroomId, student.name, studentLevel);
      }

      setStudent({
        ...student,
        level: studentLevel,
      });

      setOpenLevelModal(false);

      setActiveAction(0);

      showNotification(
        t('Globals.Notification.Success.Title'),
        `${student.name}'s ${t('Globals.Notification.Success.LevelUpdated')}`,
        'success',
      );
    } catch (error) {
      const errorMessage = (error as Error).message;
      if (errorMessage) {
        showNotification(t('Globals.Notification.Error.Title'), errorMessage, 'danger');
      }
    }
  };

  const deleteStudent = async () => {
    if (!student) return;

    try {
      const studentCode = queryParams.get('code');

      if (!!studentCode) {
        await Api.deleteStudentByCode(studentCode);
      } else {
        await Api.deleteStudent(student.code);
      }

      showNotification(
        t('Globals.Notification.Success.Title'),
        `${student.name}'s ${t('Globals.Notification.Success.StudentDeleted')}`,
        'success',
      );

      navigate(`${NEW_CLASSROOM}/${classroomId}`);
    } catch (error) {
      const errorMessage = (error as Error).message;
      if (errorMessage) {
        showNotification(t('Globals.Notification.Error.Title'), errorMessage, 'danger');
      }
    }
  };

  useEffect(() => {
    setDatesFilterTime(Helpers.getIntervalDatesByFilterIndex(activeFilter) as FilterTime);
  }, [activeFilter]);

  const classroomMetaColor = useMemo(() => {
    return Helpers.getMetaColors(student?.classroom_color || 'blue');
  }, [student]);

  const renderTimeFilter = () => (
    <DropdownWrapper>
      <Dropdown
        items={FILTERS_TIME}
        renderItem={(item: any) => (
          <>
            <span />
            {item.label}
          </>
        )}
        handleSelectItem={(_item: any, index: number) => setActiveFilter(index)}
        selectedIndex={activeFilter}
        labelIcon={<img src={Images.SortIcon} alt="" />}
        isInverted={true}
      />
    </DropdownWrapper>
  );

  const renderHeader = () => (
    <>
      <Container flex={1} alignItems="center" justifyContent="space-between">
        <TabWrapper>
          <Button
            color={colors.black}
            value={t('Student.TabOverView.Title')}
            onClick={() => {
              setActiveTab(TABS.LEARNING_ACTIVITIES);
              navigate(window.location.pathname, {replace: true});
            }}
            variant={activeTab === TABS.LEARNING_ACTIVITIES ? 'tab-active' : 'tab'}
          />
          <Button
            color={colors.black}
            value={t('Student.TabProgress.Title')}
            onClick={() => {
              setActiveTab(TABS.STUDY_PROGRESS);
              navigate(window.location.pathname + '?tabDefault=progress', {replace: true});
            }}
            variant={activeTab === TABS.STUDY_PROGRESS ? 'tab-active' : 'tab'}
          />
          <Button
            color={colors.black}
            value={t('Student.TabTasks.Title')}
            onClick={() => {
              setActiveTab(TABS.TASKS);
              navigate(window.location.pathname + '?tabDefault=tasks', {replace: true});
            }}
            variant={activeTab === TABS.TASKS ? 'tab-active' : 'tab'}
          />
        </TabWrapper>

        <Container
          alignItems="center"
          position={isMobile ? 'unset' : 'relative'}
          top={isMobile ? 60 : 'auto'}
          right={isMobile ? 0 : 'auto'}>
          {!isSmall && renderTimeFilter()}
        </Container>

        {!classroom?.license?.expired && (
          <Container id="top-dropdown" className="header-dropdown" heigth={50}>
            {isUpdateVisible && (
              <Button
                variant="link"
                alt="Edit student name"
                leftContent={
                  <Container mr={isMobile ? 0 : 2}>
                    <WhiteEditIcon height={20} />
                  </Container>
                }
                value={isMobile ? '' : t('Student.FilterActions.EditName')}
                onClick={() => setActiveAction(1)}
                style={{color: colors.white}}
              />
            )}
            <Button
              variant="link"
              alt="Edit student level"
              leftContent={
                <Container mr={isMobile ? 0 : 2}>
                  <AiOutlineStar size={25} />
                </Container>
              }
              value={isMobile ? '' : t('Student.FilterActions.AdjustLevel')}
              onClick={() => setActiveAction(2)}
              style={{color: colors.white}}
            />
            {isCodeUpdateVisible && (
              <Button
                variant="link"
                alt="Edit student code"
                leftContent={
                  <Container mr={isMobile ? 0 : 2}>
                    <img src={Images.ReloadWhite} alt="Edit student code" width={25} />
                  </Container>
                }
                value={isMobile ? '' : t('Student.FilterActions.AccessCode')}
                onClick={() => setActiveAction(3)}
                style={{color: colors.white}}
              />
            )}
            {isDeleteVisible && (
              <Button
                variant="link"
                alt="Delete student"
                leftContent={
                  <Container mr={isMobile ? 0 : 2}>
                    <img src={Images.TrashWhite} alt="Delete student" width={20} />
                  </Container>
                }
                value={isMobile ? '' : t('Student.FilterActions.Delete')}
                onClick={() => setActiveAction(4)}
                style={{color: colors.white}}
              />
            )}
          </Container>
        )}
      </Container>
      {openEditName ? (
        editNameSection()
      ) : (
        <Button
          className="virtualNameButton"
          value={student?.name}
          onClick={() => setOpenEditName(true)}
          disabled={!isUpdateVisible}
        />
      )}
    </>
  );

  const editNameSection = () => (
    <Formik
      initialValues={{studentName: student ? student.name : 'Old Name'}}
      onSubmit={submitForm}>
      {({values, handleSubmit, handleChange, handleBlur}) => {
        return (
          <>
            <Container className="editNameSection" mt={4} mb={4}>
              <Container position="relative" display="flex" flex="1" height={[30, 30, 40, 40]}>
                <input
                  type="input"
                  name="studentName"
                  placeholder={t('Student.FormChangeName.Placeholder')}
                  className="nameInput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.studentName}
                />
                <Button
                  type="submit"
                  className="nameButton saveButton"
                  onClick={handleSubmit}
                  value={t('Student.FormChangeName.ButtonSubmitText')}
                  leftContent={
                    <img
                      className="mobileButtonIcon"
                      src={Images.SaveWhite}
                      width="18px"
                      height="18px"
                      alt=""
                    />
                  }
                />
                <Button
                  type="button"
                  className="nameButton cancelButton"
                  onClick={() => {
                    setOpenEditName(false);
                    setActiveAction(0);
                  }}
                  value={t('Student.FormChangeName.ButtonCancelText')}
                  leftContent={
                    <Container className="mobileButtonIcon">
                      <MdClose size={24} color="1A73E8" />
                    </Container>
                  }
                />
              </Container>
            </Container>
          </>
        );
      }}
    </Formik>
  );

  const onBackPage = () => {
    window.history.back();
  };

  const onChangeLevel = () => {
    setActiveAction(ActionsModal.CHANGE_LEVEL);
  };

  const ContentPage = () => {
    switch (activeTab) {
      case TABS.LEARNING_ACTIVITIES: {
        return (
          <LearningActivities
            color={classroomMetaColor.highlightColor}
            bookId={student?.course_id}
            filterActived={activeFilter}
          />
        );
      }
      case TABS.STUDY_PROGRESS: {
        return (
          <StudyProgress
            activedTimeFilter={activeFilter}
            filterTime={datesFilterTime}
            hasChangedLevel={student?.level}
            onChangeLevel={onChangeLevel}
          />
        );
      }
      case TABS.TASKS: {
        return <TabTasks activedTimeFilter={activeFilter} />;
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      <ReactNotifications isMobile={true} breakpoint={425} />

      <Page
        avatar={<Avatar emoji={student?.emoji} />}
        onClickBack={onBackPage}
        footerContent={<Footer />}
        title={openEditName ? 'Old name' : student?.name}
        headerContent={renderHeader()}
        stickyBar={true}
        backgroundImage={getHeaderBackground(classroomMetaColor, classroom)}
        subTitle={classroom?.displayName}>
        {isSmall && <Container justifyContent="flex-end">{renderTimeFilter()}</Container>}

        {ContentPage()}

        <AdjustLevelModal
          show={openLevelModal}
          closeRequest={() => {
            setOpenLevelModal(false);
            setActiveAction(0);
          }}
          currentStudentLevel={student ? student.level : 'intermediate'}
          updateLevelRequest={updateStudentLevel}
        />

        {openCodeModal && (
          <AccessCodeModal
            show={openCodeModal}
            closeRequest={() => {
              setOpenCodeModal(false);
              setActiveAction(0);
            }}
            studentName={student ? student.name : 'Noell'}
            joinDate={student ? getJoinDate() : '00.00.00'}
            joinTime={student ? getJoinTime() : '00:00'}
            currentCode={student ? student.code : 'Current Code'}
            newCodeRequest={getNewCode}
          />
        )}

        <DeleteModal
          show={openDeleteModal}
          closeRequest={() => {
            setOpenDeleteModal(false);
            setActiveAction(0);
          }}
          className={classroom ? classroom.displayName : '[Name Klasse]'}
          studentName={student ? student.name : '[Name]'}
          deleteRequest={deleteStudent}
        />
      </Page>
    </>
  );
};
