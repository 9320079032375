import React from 'react';
import {useTheme} from 'styled-components';
import {Page, Text} from 'cc-web-components';

import Images from '../../assets/images';
import Footer from '../../components/Footer';
import {CHATCLASS_APPLE_STORE, CHATCLASS_ANDROID_STORE} from '@cc/shared';
import {ButtonLayout, Container, Separator} from './styles';
import useDeviceDetection from '@/hooks/IsMobile';
import {useRedirectionToMobileStore} from '@cc/hooks';

export const DownloadPage: React.FC = () => {
  const {colors} = useTheme();
  useRedirectionToMobileStore();
  const {isMobile} = useDeviceDetection();

  return (
    <Page headerContent={<></>} hideBar footerContent={<Footer />}>
      <Container isMobile={isMobile}>
        <Text value="Download" fontSize={28} color={colors.primary} marginBottom={20} />
        <ButtonLayout isMobile={isMobile}>
          <a href={CHATCLASS_APPLE_STORE}>
            <img src={Images.AppleStore} alt="Apple Store" width={240} />
          </a>
          <Separator />
          <a href={CHATCLASS_ANDROID_STORE}>
            <img src={Images.GooglePlay} alt="Google Play" width={240} />
          </a>
        </ButtonLayout>
      </Container>
    </Page>
  );
};
