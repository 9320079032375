import styled, {css} from 'styled-components';

export const StyledToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
`;

export const StyledToggleButtonContainer = styled.div<{active: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${props =>
    props.active
      ? css`
          div {
            background-color: #e6f1ff;
            color: ${props.theme.colors.primary};
            border: 1px solid #0163e5;

            div {
              border: 0;
            }
          }

          &:first-of-type {
            div {
              border-radius: 4px 0 0 4px;
            }
          }

          &:last-of-type {
            div {
              border-radius: 0 4px 4px 0;
            }
          }
        `
      : css`
          div {
            color: ${props.theme.colors.black};
            border: 1px solid #e1e5ea;
            border-left: 0;

            div {
              border: 0;
            }
          }

          &:first-of-type {
            div {
              border-radius: 4px 0 0 4px;
              border-left: 1px solid #e1e5ea;
              div {
                border: 0;
              }
            }
          }

          &:last-of-type {
            div {
              border-radius: 0 4px 4px 0;
            }
          }
        `}
`;

export const StyledToggleButton = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  transition: all 300ms;
  justify-content: center;
  padding: 11px 16px;
  background: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;
