import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.32);
  height: 100vh;
  width: 100vw;
  z-index: 19;
  display: flex;
  justify-content: flex-end;
`;

export const Content = styled.div<{isMobile: boolean}>`
  background-color: #f6f7f9;
  width: ${props => (props.isMobile ? '100%' : '320px')};
  margin-top: 60px;
`;
export const TopNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 21;
  background: #f6f7f9;

  img {
    width: 25px;
    height: 25px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
`;

export const UserIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: rgb(171, 128, 197);
`;

export const TrialDaysBar = styled.div`
  display: flex;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #eee;
`;

export const RemainingDaysBar = styled.div<{
  totalDays: number;
  leftDays: number;
}>`
  display: flex;
  width: ${props => (props.leftDays / props.totalDays) * 100}%;
  height: 100%;
  border-radius: 5px;
  background: ${props => props.theme.colors.primary};
`;
