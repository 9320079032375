import styled from 'styled-components';

export const StudentListIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

export const InfoIcon = styled.img`
  width: 29px;
  height: 29px;
  filter: brightness(0);
`;

export const DownloadIcon = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 8px;

  ${props => props.theme.mediaQueries.medium} {
    margin-right: 12px;
  }
`;

export const StudentsListLink = styled.a`
  color: #1a73e8;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StudentInput = styled.input<{isRedBorder: boolean}>`
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  border: none;
  outline: ${props =>
    props.isRedBorder
      ? `solid 1px #C62828;
  `
      : `none`};
  :focus {
    outline: 1px solid #0163e5;
  }
  font-family: CV_Source_Sans;
  font-size: 16px;
  color: ${props => props.theme.colors.black};
  line-height: 30px;
  resize: none;
  white-space: nowrap;
  overflow: hidden;
  ::placeholder {
    color: #546e7a;
    opacity: 1;
  }
`;

export const AddStudentButton = styled.button`
  position: absolute;
  right: 20px;
  bottom: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: rgb(26, 115, 232);
  line-height: 12px;
  cursor: pointer;
`;
export const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;

  input {
    position: absolute;
    top: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .check {
      background: #fff;
      border: 1px solid #000;

      &::after {
        display: block;
      }
    }
  }

  .check {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;

    &:after {
      top: 2px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: solid rgb(26, 115, 232);
      border-radius: 1px;
      border-width: 0 3px 3px 0;
      --webkit-transform: rotate(45deg);
      --ms-transform: rotate(45deg);
      transform: rotate(45deg);

      content: '';
      position: absolute;
      display: none;
    }
  }
`;
