import React, {useEffect, useState} from 'react';
import {Page} from 'cc-web-components';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import Api from '../../services/ApiCv';
import Footer from '../../components/Footer';
import TableBooks from '../../components/TableBooks';
import {startLoading, stopLoading} from '../../redux/Session';

import {BookInfo} from './types';
type ParamTypes = {
  id: string;
};

export const BooksContentPage: React.FC = () => {
  const {id} = useParams<ParamTypes>();
  const dispatch = useDispatch();

  const [bookInfo, seBookInfo] = useState<BookInfo>();

  useEffect(() => {
    dispatch(startLoading());
    if (id === undefined) {
      return;
    }

    Api.getBookInfo(id)
      .then(response => {
        seBookInfo(response);
        dispatch(stopLoading());
      })
      .catch(err => {
        console.warn(err);
        console.log('err', err);
        dispatch(stopLoading());
      });
  }, [dispatch, id]);

  const units = bookInfo?.content.modules.map((item: any) => item.units);

  const onBackPage = () => {
    window.history.back();
  };

  return (
    <Page
      headerContent={<></>}
      onClickBack={onBackPage}
      title={bookInfo?.name}
      titleSize={40}
      subTitle={bookInfo?.id}
      backgroundImage={bookInfo?.configuration.app_cover_url}
      footerContent={<Footer />}>
      {units && <TableBooks data={units[0]} />}
    </Page>
  );
};
