import React from 'react'
import { Text, Container } from 'cc-web-components'

import { Image } from './styles'

type Props = {
  src?: string
  alt?: string
  style?: {}
  className?: string
  bookName?: string
  isDefaultBook?: boolean
}

const BookCover: React.FC<Props> = ({ src, alt, isDefaultBook, bookName, ...props }: Props) => {
  return isDefaultBook ? (
    <Container position="relative">
      <Image src={src} alt={alt} />
      <Text
        size={20}
        value={bookName}
        color="#fff"
        fontWeight={800}
        style={{
          position: 'absolute',
          top: '10%',
          left: '10%',
          width: '80%',
          height: '80%',
          overflowWrap: 'break-word',
        }}
      />
    </Container>
  ) : (
    <Image src={src} alt={alt} {...props} />
  )
}

export default BookCover
