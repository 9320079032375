import React, {useCallback, useEffect, useState} from 'react';
import {Container} from 'cc-web-components';
import {Image} from './styles';
import TeaserBubble from '@/assets/images/teaser-bubble.png';
import useDeviceDetection from '@/hooks/IsMobile';
import {getLicense} from '@/redux/Session';
import {useSelector} from 'react-redux';

const PRICES_URL = 'https://www.cornelsen.de/reihen/chatclass-110002200000';
const LICENSES = ['CCWAE', 'TESTLICENSE01'];

export const PriceTeaser: React.FC = () => {
  const license = useSelector(getLicense);
  const {isMobile} = useDeviceDetection();
  const [showPriceTeaser, setShowPriceTeaser] = useState(false);

  const handleOnClick = useCallback(() => (window.location.href = PRICES_URL), []);

  useEffect(() => {
    if (!license) {
      return;
    }

    const showPrice = LICENSES.includes(license?.type);
    setShowPriceTeaser(showPrice);
  }, [license]);

  if (!showPriceTeaser || isMobile) {
    return null;
  }

  return (
    <Container justifyContent="end" onClick={handleOnClick}>
      <Image src={TeaserBubble} alt="" />
    </Container>
  );
};
