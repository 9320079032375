import {unit} from '@cc/utils';
import styled from '@emotion/styled';

export const Container = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: ${({isMobile}) => (isMobile ? 'center' : 'flex-start')};
`;

export const ButtonLayout = styled.div<{isMobile: boolean}>`
  display: flex;
  align-items: center;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
`;

export const Separator = styled.div`
  width: ${unit(20)};
`;
