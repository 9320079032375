import styled from 'styled-components'

import Images from '@/assets/images'

export const BubbleContainer = styled.div`
  justify-content: center;

  padding: 5px 25px;
  margin-top: 20px;
  margin-left: -8px;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(${Images.BubbleBot});

  @media screen and (max-width: 600px) {
  }
`
