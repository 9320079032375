import React, {useMemo} from 'react';
import {useTheme} from 'styled-components';
import {Menu, Text} from 'cc-web-components';
import {MenuItem} from 'cc-web-components/dist/components/Menu/types';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';

import Images from '@/assets/images';
import {getMenu, getIsHideFeaturesUser, toggleShowUserProfile, getUser} from '@/redux/Session';
import AssignActivities from '@/components/AssignActivities';
import {useAssignActivityContext} from '@/redux/AssignActivity';
import {Routes} from '@cc/shared';

import {IconAssignActivity} from './IconAssignActivity';
import UserProfile from '../../../components/UserProfile';
import {UserIcon} from './styles';

const {STUDENT_CODES} = Routes;

interface Props {
  isAuthenticated?: boolean;
}

export const UserMenu: React.FC<Props> = ({isAuthenticated}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {colors} = useTheme();
  const [{onOpened}, {toggleVisibleForm}] = useAssignActivityContext();
  const {t} = useTranslation('Menu');
  const menuItems = useSelector(getMenu);
  const location = useLocation();
  const isHideFeaturesUser = useSelector(getIsHideFeaturesUser);
  const user = useSelector(getUser);

  const activeIndexMenu = useMemo(() => {
    if (location.pathname === '/' || location.pathname === '/c/') {
      return 0;
    }
    if (location.pathname.includes('book')) {
      return 1;
    }

    return -1;
  }, [location]);

  const onOpenAssinActivities = () => {
    toggleVisibleForm();
  };

  const navigateToRoute = (item: MenuItem) => {
    navigate(item.route);
  };

  const onPressLogo = () => {
    navigate('/');
  };

  const onPressExit = () => {
    dispatch(toggleShowUserProfile({visible: true}));
  };

  const renderItem = (item: MenuItem) => {
    return t(item.label);
  };

  const RenderRightContent = () => {
    return <IconAssignActivity onclick={onOpenAssinActivities} isActive={onOpened} />;
  };

  const getTitle = () => {
    if (isHideFeaturesUser) return '';

    return 'ChatClass';
  };

  const getLogo = () => {
    if (isHideFeaturesUser) return <img width={160} src={Images.CCLogo} alt="" />;

    return Images.CVLogo;
  };

  const getUserFormatted = () => {
    return user?.name?.substr(0, 2).toUpperCase();
  };

  if (!isAuthenticated || location.pathname.startsWith(STUDENT_CODES)) {
    return null;
  }

  const isRightContentShown =
    user?.profile !== 'teacher-bsp' || (activeIndexMenu === -1 && location.pathname !== '/imprint');

  return (
    <>
      <Menu
        title={getTitle()}
        items={menuItems}
        onPressItem={navigateToRoute}
        onPressLogo={onPressLogo}
        onPressExit={onPressExit}
        renderItem={renderItem}
        activeIndex={activeIndexMenu}
        exitContent={
          <UserIcon>
            <Text value={getUserFormatted()} fontSize={16} fontWeight={700} color={colors.white} />
          </UserIcon>
        }
        logoUrl={getLogo()}
        menuRightContent={isRightContentShown && <RenderRightContent />}
      />
      <AssignActivities />
      <UserProfile />
    </>
  );
};
