import React from 'react';
import {AppNameText, ComponentContainer, IconWrapper, StoreContainer} from './styles';
import {AppleStoreSvg, ChatclassRounded, CornelsenLernenRounded, PlayStoreSvg} from '@cc/assets';
import {
  CHATCLASS_ANDROID_STORE,
  CHATCLASS_APPLE_STORE,
  LERNEN_CORNELSEN_ANDROID_STORE,
  LERNEN_CORNELSEN_APPLE_STORE,
} from '@cc/shared';
import {isAndroid} from 'react-device-detect';
import {useTranslation} from 'react-i18next';

interface Props {
  app: 'cornelsen-lernen' | 'chatclass';
  showIcon?: boolean;
}

export const MobileAppStoreRedirection: React.FC<Props> = ({app, showIcon = true}) => {
  const {t} = useTranslation('tutorial');
  const isAppChatClass = app === 'chatclass';

  const storeUrl = isAndroid
    ? isAppChatClass
      ? CHATCLASS_ANDROID_STORE
      : LERNEN_CORNELSEN_ANDROID_STORE
    : isAppChatClass
    ? CHATCLASS_APPLE_STORE
    : LERNEN_CORNELSEN_APPLE_STORE;

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}>
      <ComponentContainer href={storeUrl}>
        {showIcon && (
          <IconWrapper>
            {isAppChatClass ? <ChatclassRounded /> : <CornelsenLernenRounded />}
            <AppNameText
              content={isAppChatClass ? t('chatclassAppName') : t('cornelsenLernenAppName')}
            />
          </IconWrapper>
        )}
        <StoreContainer href={storeUrl}>
          {isAndroid ? <PlayStoreSvg /> : <AppleStoreSvg />}
        </StoreContainer>
      </ComponentContainer>
    </div>
  );
};
