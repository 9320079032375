import React from 'react';
import {useTheme} from 'styled-components';
import {Card, Container, Text} from 'cc-web-components';
import {useTranslation} from 'react-i18next';

import Images from '@/assets/images';
import useDeviceDetection from '@/hooks/IsMobile';

import {AppIcon, TextWithLink, style} from './styles';
import QRCode from 'react-qr-code';
import {Routes} from '@cc/shared';

const {DOWNLOAD} = Routes;

export const DemoBox: React.FC = () => {
  const {colors} = useTheme();
  const {t} = useTranslation('cornelsen');
  const {isMobile} = useDeviceDetection();

  return (
    <Container flex={1} bg="#fff">
      <Card
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent="space-between"
        padding={2}
        overflow="visible"
        overflowX="visible">
        <Container flex={1} justifyContent="flex-start" ml={[0, 0, 4, 4]}>
          <Container flexDirection="column" justifyContent="center" maxWidth={550}>
            <Text
              maxWidth={500}
              size={24}
              value={t('Home.BoxQrcode.Title')}
              textAlign={isMobile ? 'center' : 'left'}
              color={colors.primary}
              mb={3}
            />
            <Text
              maxWwidth={500}
              size={16}
              isBold={true}
              value={t('Home.BoxQrcode.Subtitle')}
              textAlign={isMobile ? 'center' : 'left'}
              mb={2}
            />
            <TextWithLink>
              {t('Home.BoxQrcode.Description.Part1')}
              <a href={DOWNLOAD}> {t('Home.BoxQrcode.Description.PartLink')}</a>{' '}
              {t('Home.BoxQrcode.Description.Part3')}
            </TextWithLink>
          </Container>
        </Container>
        <Container justifyContent="center" alignItems="center">
          <AppIcon src={Images.DemoBoxAppIcon} alt="" />
          <QRCode
            viewBox={`0 0 256 256`}
            style={style.qrCode}
            value={'https://app.chatclass.cornelsen.de' + DOWNLOAD}
          />
        </Container>
      </Card>
    </Container>
  );
};
