const setManifest = (manifestURL: string, manifestElementId: string) => {
  document?.querySelector?.(`${manifestElementId}`)?.setAttribute('href', manifestURL);
};

const setManifestFile = (manifest: any, manifestElementId: string) => {
  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], {type: 'application/json'});
  const manifestURL = URL.createObjectURL(blob);
  setManifest(manifestURL, manifestElementId);
};

export const updateManifest = (manifest: string | any, manifestElementId: string) => {
  if (typeof manifest === 'string') {
    setManifest(manifest, manifestElementId);
    return;
  }

  setManifestFile(manifest, manifestElementId);
};
