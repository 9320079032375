import {TooltipPart} from '@/components/Icons';
import {Text} from 'cc-web-components';
import styled from 'styled-components';

const tooltipHeight = 132;
const tipHeight = 6;
const infoBoxIconSize = 20;
const absoluteWrapperMarginTop = -(tooltipHeight + tipHeight + infoBoxIconSize + 8);
const tipWidth = 14;
const tipMarginLeft = 8;
const absoluteWrapperMarginLeft = -(tipMarginLeft + tipWidth / 2 - infoBoxIconSize / 2);
const tooltipWidth = 384;
const middleTipMarginLeft = tooltipWidth / 2 - tipWidth / 2;
const middleWrapperMarginLeft = -(tooltipWidth / 2) + infoBoxIconSize / 2;

export const InfoBoxContainer = styled.div`
  margin-left: 4px;
`;

export const InfoBoxContent = styled.div`
  flex-direction: column;
  justify-content: center;
  width: ${tooltipWidth}px;
  height: ${tooltipHeight}px;
  padding: 12px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
`;

export const InfoBoxIcon = styled.div`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Wrapper = styled.div<{
  isOpen: boolean;
  isBottomTooltip: boolean;
  middleTooltipMobile: boolean;
}>`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  position: absolute;
  flex-direction: column;
  z-index: 20;
  margin-top: ${props => (props.isBottomTooltip ? '8px' : `${absoluteWrapperMarginTop}px`)};
  margin-left: ${props =>
    props.middleTooltipMobile ? `${middleWrapperMarginLeft}px` : `${absoluteWrapperMarginLeft}px`};
`;

export const TooltipPartBottom = styled(TooltipPart)<{middleTooltipMobile: boolean}>`
  margin-left: ${props =>
    props.middleTooltipMobile ? `${middleTipMarginLeft}px` : `${tipMarginLeft}px`};
`;

export const TooltipPartAbove = styled(TooltipPart)<{middleTooltipMobile: boolean}>`
  transform: rotate(180deg);
  margin-left: ${props =>
    props.middleTooltipMobile ? `${middleTipMarginLeft}px` : `${tipMarginLeft}px`};
`;

export const TooltipText = styled(Text)`
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: normal;
  white-space: normal;
  color: #263238;
`;
