import React, {useEffect, useState} from 'react';
import {useNavigate, useMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {TimelineItem} from 'cc-web-components/dist/components/Timeline/types';
import {useTranslation} from 'react-i18next';
import {Container, Timeline} from 'cc-web-components';

import Api from '@/services/ApiCv';
import Storage from '@/services/Storage';
import ApiClassroomService from '@/services/ApiCv/ApiClassroomService';
import {useAssignActivityContext} from '@/redux/AssignActivity';
import ApiLicenseService, {LicenseType} from '@/services/ApiCv/ApiLicenseService';
import {
  startLoading,
  stopLoading,
  showAlert,
  toggleShowModalPricingTable,
  getUser,
} from '@/redux/Session';

import StepOne from './Steps/StepOne';
import StepTwo from './Steps/StepTwo';
import Actions from './Components/Actions';
import StepThree from './Steps/StepThree';
import StepLicenseEmpty from './Steps/LicenseEmpty';
import StepLicenseState from './Steps/LicenseState';
import StepLicenseSelect from './Steps/LicenseSelect';
import {useCreateClassroom} from './Context';
import {Routes} from '@cc/shared';
import {getTeacherName} from './utils';
import {selectUserType} from '@/redux/Rights';

const {NEW_CLASSROOM} = Routes;

type LicenseStatusType = 'EMPTY' | 'STATE' | 'SELECT' | '';

type TabProps = {
  onClose: () => void;
};

const TabNewClass: React.FC<TabProps> = ({onClose}: TabProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation('cornelsen');
  const {createClassroomData, changePayload, setCreateClassroomData} = useCreateClassroom();
  const [{isEditClass, isActivateClass, activateClassLicenseId}] = useAssignActivityContext();
  const user = useSelector(getUser);
  const userType = useSelector(selectUserType);

  const STEPS = {
    Keys: {
      STEP_0: 0,
      STEP_1: 1,
      STEP_2: 2,
    },
    Default: [
      {
        indicator: '1',
        label: t('AssignActivity.Tabs.NewClass.Step1.Title'),
        disabled: false,
      },
      {
        indicator: '2',
        label: t('AssignActivity.Tabs.NewClass.Step2.Title'),
        disabled: true,
      },
      {
        indicator: '3',
        label: t('AssignActivity.Tabs.NewClass.Step3.Title'),
        disabled: true,
      },
    ],
  };

  const [licenses, setLicenses] = useState<LicenseType[]>([]);
  const [stepsList, setStepsList] = useState<TimelineItem[]>(STEPS.Default);
  const [location, setLocation] = useState<string>();
  const [stepActived, setStepActived] = useState(0);
  const [buttonClickable, setButtonClickable] = useState(false);
  const [licensetStatus, setLicensetStatus] = useState<LicenseStatusType>('EMPTY');

  const match = useMatch(`${NEW_CLASSROOM}/:classroomId`);
  const classroomId = match?.params.classroomId;

  useEffect(() => {
    if (licensetStatus !== 'SELECT') return;

    ApiLicenseService.getAvailableLicense().then(licenses => {
      const isAllLicencesExpired = licenses.every(item => item.expired);

      if (!licenses.length || isAllLicencesExpired) {
        setLicensetStatus('EMPTY');

        return;
      }

      const isAnyicencesClassesValids = licenses.some(
        item => (item.type === 'CCPPK' || item.type === 'CCWAL') && !item.expired,
      );

      if (!!isAnyicencesClassesValids) {
        setLicenses(licenses.filter(item => !item.expired));

        setLicensetStatus('SELECT');

        return;
      }

      const licencesSchoolValids = licenses.filter(
        item => (item.type === 'CCPPS' || item.type === 'CCWAS') && !item.expired,
      );

      if (!!licencesSchoolValids.length) {
        const licence = licencesSchoolValids[0];

        changePayload({
          licence_id: licence.licence_id,
        });

        setLicensetStatus('');

        return;
      }

      const licencesTestValids = licenses.filter(
        item => (item.type === 'CCWAE' || item.type === 'TESTLICENSE01') && !item.expired,
      );

      if (!!licencesTestValids.length) {
        const licence = licencesTestValids[0];

        changePayload({
          licence_id: licence.licence_id,
        });

        setLicensetStatus('');

        return;
      }

      setLicensetStatus('EMPTY');
    });

    // eslint-disable-next-line
  }, [licensetStatus]);

  useEffect(() => {
    if (isActivateClass) return;

    Storage.getClassState().then(state => {
      if (!!state) {
        setLocation(state);

        setLicensetStatus('SELECT');
      } else {
        setLicensetStatus('STATE');
      }
    });
  }, [isActivateClass]);

  useEffect(() => {
    if (classroomId && isEditClass && !isActivateClass) {
      Api.getClassroom(classroomId)
        .then(data => {
          if (data) {
            setCreateClassroomData({
              name: data?.displayName,
              color: data?.metadata.color,
              book: data?.metadata.book_name,
              course_id: data?.mainCourseId,
              licence_id: data?.licence.licence_id || '',
              feedback: data?.metadata.feedback_privacy === 'CLASSMATES_ONLY',
              quiz: data?.metadata.competitive_quiz_privacy === 'GLOBAL',
              teacher_name: getTeacherName(userType, data?.metadata.teacher_name, user?.name),
              teacher_title: data?.metadata.teacher_title,
            });
          }
        })
        .catch(() => {
          dispatch(
            showAlert({
              value: 'Something went wrong',
              variant: 'danger',
            }),
          );
        });
    } else {
      setCreateClassroomData({
        name: '',
        color: '',
        book: '',
        course_id: '',
        licence_id: activateClassLicenseId || '',
        feedback: false,
        quiz: false,
        teacher_name: '',
        teacher_title: '',
      });

      setLicensetStatus('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    classroomId,
    isEditClass,
    isActivateClass,
    activateClassLicenseId,
    setCreateClassroomData,
    dispatch,
  ]);

  const onChangeStep = (newIndex: number) => {
    setStepsList(STEPS.Default);
    setStepActived(newIndex);
  };

  const onSubmitForm = () => {
    dispatch(startLoading());
    const {name, book, color, feedback, quiz, licence_id, course_id, teacher_name, teacher_title} =
      createClassroomData;

    if (isEditClass) {
      const payload = {
        name,
        book,
        course_id,
        teacher_name,
        teacher_title,
        quiz_privacy: quiz,
        classroom_color: color,
        feedback_privacy: feedback,
      };

      ApiClassroomService['updateClassroom']({classroomId: classroomId, payload})
        .then(data => {
          onClose();
          window.location.href = `${NEW_CLASSROOM}/${classroomId}`;
        })
        .catch(() => {
          dispatch(
            showAlert({
              value: 'Something went wrong',
              variant: 'danger',
            }),
          );
        })
        .finally(() => {
          dispatch(stopLoading());
        });
    } else {
      const payload = {
        name,
        book,
        course_id,
        licence_id,
        teacher_name,
        teacher_title,
        location: location,
        quiz_privacy: quiz,
        classroom_color: color,
        feedback_privacy: feedback,
      };

      ApiClassroomService.createClassroom({payload: payload})
        .then(data => {
          onClose();
          navigate(`${NEW_CLASSROOM}/${data.id}`, {
            state: {
              alert: {
                message: 'Classroom has been created',
                type: 'info',
              },
            },
          });
        })
        .catch(() => {
          dispatch(
            showAlert({
              value: 'Something went wrong',
              variant: 'danger',
            }),
          );
        })
        .finally(() => {
          dispatch(stopLoading());
        });
    }
  };

  const onNextStepForm = () => {
    const nextStepIndex = stepActived + 1;

    if (nextStepIndex === stepsList.length) {
      onSubmitForm();

      return;
    }

    const steps = [...stepsList];

    steps[nextStepIndex].disabled = false;

    setStepsList(steps);

    setStepActived(nextStepIndex);
  };

  const clickedEmptyLicenseButtonNext = () => {
    dispatch(toggleShowModalPricingTable({visible: true}));
  };

  const clickedSelectLicenseButtonNext = () => {
    setLicensetStatus('');
  };

  const clickedStateLicenseButtonNext = () => {
    Storage.setClassState(createClassroomData.location);

    setLocation(createClassroomData.location);

    isActivateClass ? setLicensetStatus('') : setLicensetStatus('SELECT');
  };

  const setButtonActive1 = (buttonActive: boolean) => {
    if (stepActived === STEPS.Keys.STEP_0) setButtonClickable(buttonActive);
  };

  const setButtonActive2 = (buttonActive: boolean) => {
    if (stepActived === STEPS.Keys.STEP_1) setButtonClickable(buttonActive);
  };

  const setButtonActive3 = (buttonActive: boolean) => {
    if (stepActived === STEPS.Keys.STEP_2) setButtonClickable(buttonActive);
  };

  const buttonText = (() => {
    if (stepActived === STEPS.Keys.STEP_0) {
      return t('AssignActivity.Tabs.NewClass.Step1.ButtonSubmitText');
    }

    if (stepActived === STEPS.Keys.STEP_1) {
      return t('AssignActivity.Tabs.NewClass.Step2.ButtonSubmitText');
    }

    return isEditClass
      ? t('AssignActivity.Tabs.NewClass.Step3.ButtonEditSubmitText')
      : t('AssignActivity.Tabs.NewClass.Step3.ButtonSubmitText');
  })();

  if (licensetStatus === 'EMPTY' && !isEditClass) {
    return (
      <Container height={'calc(100vh - 118px - 20px)'} overflowY="auto" flexDirection="column">
        <StepLicenseEmpty />

        <Actions
          showIconNext={false}
          buttonNextValue={t('AssignActivity.Tabs.NewClass.StepLicenseEmpty.ButtonSubmitText')}
          buttonBackValue={t('AssignActivity.Tabs.NewClass.ButtonCancelText')}
          onClickButtonNext={clickedEmptyLicenseButtonNext}
          onClickButtonBack={onClose}
          buttonNextDisabled={false}
        />
      </Container>
    );
  }

  if (licensetStatus === 'STATE' && !isEditClass) {
    return (
      <Container height={'calc(100vh - 118px - 20px)'} overflowY="auto" flexDirection="column">
        <StepLicenseState setButtonActive={setButtonClickable} />

        <Actions
          buttonNextValue={t('AssignActivity.Tabs.NewClass.StepLicenseState.ButtonSubmitText')}
          buttonBackValue={t('AssignActivity.Tabs.NewClass.ButtonCancelText')}
          onClickButtonNext={clickedStateLicenseButtonNext}
          onClickButtonBack={onClose}
          buttonNextDisabled={!buttonClickable}
        />
      </Container>
    );
  }

  if (licensetStatus === 'SELECT' && !isEditClass) {
    return (
      <Container height={'calc(100vh - 118px - 20px)'} overflowY="auto" flexDirection="column">
        <StepLicenseSelect licenses={licenses} setButtonActive={setButtonClickable} />

        <Actions
          buttonNextValue={t('AssignActivity.Tabs.NewClass.StepLicenseSelect.ButtonSubmitText')}
          buttonBackValue={t('AssignActivity.Tabs.NewClass.ButtonCancelText')}
          onClickButtonNext={clickedSelectLicenseButtonNext}
          onClickButtonBack={onClose}
          buttonNextDisabled={!buttonClickable}
        />
      </Container>
    );
  }

  return (
    <Container height={'calc(100vh - 118px - 20px)'} overflowY="auto" flexDirection="column">
      <Container backgroundColor="#f7f7f7" pl="23px" pb={2} pr="23px">
        <Timeline data={stepsList} defaultIndex={stepActived} onChange={onChangeStep} />
      </Container>

      <Container flex={1} overflowY="auto" background="#f8f8f8">
        <Container flex={1} display={stepActived === STEPS.Keys.STEP_0 ? 'block' : 'none'}>
          <StepOne setButtonActive={setButtonActive1} />
        </Container>
        <Container flex={1} display={stepActived === STEPS.Keys.STEP_1 ? 'block' : 'none'}>
          <StepTwo isEditClass={isEditClass} setButtonActive={setButtonActive2} />
        </Container>
        <Container flex={1} display={stepActived === STEPS.Keys.STEP_2 ? 'block' : 'none'}>
          <StepThree setButtonActive={setButtonActive3} />
        </Container>
      </Container>

      <Actions
        buttonNextValue={buttonText}
        buttonBackValue={t('AssignActivity.Tabs.NewClass.ButtonCancelText')}
        onClickButtonNext={onNextStepForm}
        onClickButtonBack={onClose}
        buttonNextDisabled={!buttonClickable}
      />
    </Container>
  );
};

export default TabNewClass;
