import {Text} from 'cc-web-components';
import {useEffect} from 'react';
import {FadeOutContainer} from './styles';
import {Checkmark} from '@/assets';

interface Props {
  message: string;
  hideSnackbar: () => void;
  size?: 'small' | 'medium' | 'large';
  appearance?: 'emphasized' | 'subtle';
  actionLabel?: string;
  duration?: number;
}

export const Snackbar = ({
  message,
  hideSnackbar,
  size,
  appearance,
  actionLabel,
  duration,
}: Props) => {
  useEffect(() => {
    setTimeout(() => hideSnackbar(), 5500);
  }, [hideSnackbar]);

  return (
    <FadeOutContainer>
      <Checkmark />
      <Text value={message} color="#fff" marginLeft={'8px'} size={'18px'}></Text>
    </FadeOutContainer>
  );
};
