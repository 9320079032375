import styled from 'styled-components'
import { Text as BaseText } from 'cc-web-components'

export const Text = styled(BaseText)`
  font-weight: 600;
  font-size: 16px;
`

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  span {
    margin-right: 8px;
  }
`

export const ActivityAndFrequencyContainer = styled.div<{ color: string }>`
  div > div:last-child > div {
    background-color: ${(props) => props.color === 'green' && '#007a3b'};
  }
`
