import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {AddFilledIcon as FilledIcon, AddOutlinedIcon as OutlinedIcon} from '@/components/Icons';

type Props = {
  onclick: () => void;
  isActive: boolean;
};

const Container = styled.div`
  cursor: pointer;
  margin-top: 5px;

  svg {
    display: block;
  }
`;

export const IconAssignActivity: React.FC<Props> = ({isActive, onclick}: Props) => {
  const [isHover, setIsHover] = useState(false);
  const changeIconTrue = useCallback(() => setIsHover(true), []);
  const changeIconFalse = useCallback(() => setIsHover(false), []);

  return (
    <Container onClick={onclick} onMouseEnter={changeIconTrue} onMouseLeave={changeIconFalse}>
      {isHover || isActive ? <FilledIcon width={56} /> : <OutlinedIcon width={56} />}
    </Container>
  );
};
