import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  background: ${(props) => props.theme.colors.white};
  width: 100%;

  @media (max-width: 768px) {
    > div:first-child {
      align-items: flex-start;
    }

    > div:last-child {
      align-items: flex-end;
    }

  @media (max-width: 425px) {

    > div:last-child {
      align-items: flex-start;
      padding-right: 32px;
    }

    > div + div {
      flex-direction: column;

      > div + div {
        margin-top: 16px;
        margin-left: 0;
      }
      }
    }

    
  }
`

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  @media (max-width: 768px) {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`
