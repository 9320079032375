import React from 'react'
import { useTheme } from 'styled-components'

import { DiamondIcon } from '@/components/Icons'
import { Container, Text } from 'cc-web-components'

type Props = {
  value: number
}

const Xp: React.FC<Props> = (props: Props) => {
  const { colors } = useTheme()

  return (
    <Container paddingRight={2} position="relative">
      {props.value ? (
        <>
          <Container
            position={'absolute'}
            width={65}
            height={'18px'}
            background={colors.primary}
            borderRadius={'6px'}
            opacity={0.2}
            marginLeft={1}
          />

          <DiamondIcon width={20} />

          <Text
            color={colors.primary}
            marginLeft={1}
            fontWeight={600}
            value={`${props.value} Pt`}
          />
        </>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default Xp
