import React, { useEffect, useState } from 'react'
import { Button, Container, Text } from 'cc-web-components'

import { ToggleButton } from '@/components/ToggleButton'
import { CartIcon, CloseIcon, SmileyIcon } from '@/components/Icons'
import {
  Modal,
  ModalContent,
  SubTitle,
  PricingTableContainer,
  PricingTable,
  PricingTableHeader,
  TextContainer,
  IconCircle,
  // Separator,
  Benefits,
  AppBox,
  Adaillu,
  CloseButton,
} from './styles'
import Images from '@/assets/images/product-benefits'

type Props = {
  closeCallback: () => void
}

const ModalPricingTable: React.FC<Props> = ({ closeCallback }: Props) => {
  const [switchIndex, setSwitchIndex] = useState(0)

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const handleSchollLicenseNavigation = () => {
    const url =
      switchIndex === 0
        ? 'https://www.cornelsen.de/produkte/chatclass-schullizenz-9783060362141'
        : 'https://www.cornelsen.de/produkte/chatclass-printplus-schullizenz-1100027883'

    window.location.href = url
  }

  const handleClassLicenseNavigation = () => {
    const url =
      switchIndex === 0
        ? 'https://www.cornelsen.de/produkte/chatclass-klassenlizenz-9783060362134'
        : 'https://www.cornelsen.de/produkte/chatclass-printplus-klassenlizenz-1100027882'

    window.location.href = url
  }

  const handleTestLicenseNavigation = () => {
    window.location.href =
      'https://www.cornelsen.de/produkte/chatclass-lehrer-testzugang-9783060362127'
  }

  // const handlePrintPlusNavigation = () => {
  //   window.open('https://www.cornelsen.de/empfehlungen/e-books/printplus-klassenlizenz')
  // }

  return (
    <Modal>
      <CloseButton onClick={closeCallback}>
        <CloseIcon />
      </CloseButton>

      <ModalContent>
        <Container>
          <Text
            value="Wählen Sie Ihre ChatClass Lizenz"
            mb={35}
            fontSize={32}
            lineHeight="32px"
            fontWeight={700}
            textAlign="center"
            color="#2B323B"
          />

          <ToggleButton
            values={['Ohne PrintPlus', 'Mit PrintPlus']}
            activeIndex={switchIndex}
            onToggle={(index: number) => setSwitchIndex(index)}
            style={{
              marginBottom: '25px',
            }}
          />

          {switchIndex ? (
            <SubTitle>
              <strong>Cornelsen PrintPlus</strong> – Alle Schulen, die bereits unsere gedruckten
              Arbeitshefte* nutzen, erhalten Vorzüge bei ChatClass.
            </SubTitle>
          ) : (
            <SubTitle>
              <strong>Ohne PrintPlus </strong> – Sollten Sie kein Arbeitsheft* benutzen, können Sie
              trotzdem ChatClass erwerben.
            </SubTitle>
          )}

          <PricingTableContainer>
            <PricingTable>
              <div>
                <label>Empfohlen ab 4 Lehrkräften</label>
                <PricingTableHeader>
                  <Text
                    value="Schullizenz"
                    mb={[10, 10, 0, 10]}
                    fontSize={24}
                    lineHeight="22px"
                    fontWeight={700}
                  />
                  <Text
                    value="Ideal für alle Lehrkräfte einer Schule"
                    mb={[30, 30, 0, 30]}
                    fontSize={14}
                    fontWeight={400}
                    color="#999"
                  />
                  <Container display="flex" alignItems="flex-end" mb={[10, 10, 0, 10]}>
                    <Text
                      value={switchIndex ? 129 : 299}
                      mb={0}
                      mr={1}
                      fontSize={45}
                      lineHeight="35px"
                      fontWeight={700}
                    />
                    <Text
                      value="€ /"
                      mb={0}
                      mr={1}
                      fontSize={24}
                      lineHeight="22px"
                      fontWeight={700}
                    />
                    <Text
                      value="Jahr"
                      mb={0}
                      fontSize={24}
                      lineHeight="22px"
                      fontWeight={600}
                      color="#999"
                    />
                  </Container>
                  <Text
                    value={switchIndex ? '10,75 € im Monat' : '24,91 € im Monat'}
                    mb={[54, 54, 0, 54]}
                    mr={1}
                    fontSize={14}
                    fontWeight={600}
                  />
                </PricingTableHeader>
                <section>
                  <IconCircle>
                    <img src={Images.School} alt="" />
                  </IconCircle>
                  <ul>
                    <li>Passend zu allen Cornelsen Englischlehrwerken</li>
                    <li>Unbegrenzte Anzahl von Klassen</li>
                    <li>Unbegrenzte Lehrkräfteanzahl</li>
                  </ul>
                </section>
                <section>
                  <TextContainer>
                    <Text
                      value="Inklusive aller Features & Funktionalitäten der Klassenlizenz."
                      fontSize={14}
                      fontWeight={600}
                      textAlign="center"
                      color="#fff"
                    />
                  </TextContainer>
                </section>
                <section>
                  <Container mb="10px">
                    <img src={Images.Info} alt="" />
                  </Container>

                  <TextContainer>
                    <Text
                      value="Einfacher Wechsel mit all Ihren Daten zur Klassenlizenz möglich"
                      fontSize={12}
                      fontWeight={400}
                      textAlign="center"
                      color="#999"
                    />
                  </TextContainer>

                  <Button
                    width={210}
                    height={40}
                    variant="solid"
                    value={
                      <Text
                        value="Schullizenz kaufen"
                        color="#fff"
                        fontSize={20}
                        marginLeft={1}
                        fontWeight={300}
                        mb={1}
                      />
                    }
                    leftContent={
                      <Container mr={1}>
                        <CartIcon />
                      </Container>
                    }
                    onClick={handleSchollLicenseNavigation}
                  />
                </section>
              </div>
            </PricingTable>

            <PricingTable>
              <div>
                <label>Beliebteste Auswahl</label>
                <PricingTableHeader>
                  <Text
                    value="Klassenlizenz"
                    mb={[10, 10, 0, 10]}
                    fontSize={24}
                    lineHeight="22px"
                    fontWeight={700}
                  />
                  <Text
                    value="Ideal für einzelne Lehrkräfte"
                    mb={[30, 30, 0, 30]}
                    fontSize={14}
                    fontWeight={400}
                    color="#999"
                  />
                  <Container display="flex" alignItems="flex-end" mb={[10, 10, 0, 10]}>
                    <Text
                      value={switchIndex ? 39 : 99}
                      mb={0}
                      mr={1}
                      fontSize={45}
                      lineHeight="35px"
                      fontWeight={700}
                    />
                    <Text
                      value="€ /"
                      mb={0}
                      mr={1}
                      fontSize={24}
                      lineHeight="22px"
                      fontWeight={700}
                    />
                    <Text
                      value="Jahr"
                      mb={0}
                      fontSize={24}
                      lineHeight="22px"
                      fontWeight={600}
                      color="#999"
                    />
                  </Container>
                  <Text
                    value={switchIndex ? '3,25 € im Monat' : '8,25 € im Monat'}
                    mb={[54, 54, 0, 54]}
                    mr={1}
                    fontSize={14}
                    fontWeight={600}
                  />
                </PricingTableHeader>
                <section>
                  <IconCircle>
                    <img src={Images.Class} alt="" />
                  </IconCircle>
                  <ul>
                    <li>Passend zu allen Cornelsen Englischlehrwerken</li>
                    <li>Für eine Klasse</li>
                    <li>Zugang für eine Lehrkraft</li>
                  </ul>
                </section>
                <section>
                  <label>Features</label>
                  <ul>
                    <li>
                      Aufgaben zuweisen <span>Neu</span>
                    </li>
                    <li>Lernfortschritt einsehen</li>
                    <li>Lernkompetenzen auf einem Blick</li>
                    <li>Individuelles Feedback senden</li>
                    <li>Units freigeben oder blockieren</li>
                  </ul>
                </section>
                <section>
                  <label>Coming soon</label>
                  <ul>
                    <li>Individuelle Förderempfehlungen</li>
                    <li>Lernfortschritt exportieren</li>
                    <li>Analyse der Aussprache</li>
                    <li>Belohnungssystem für Schüler/-innen</li>
                  </ul>

                  <Button
                    width={225}
                    height={40}
                    variant="solid"
                    value={
                      <Text
                        value="Klassenlizenz kaufen"
                        color="#fff"
                        fontSize={20}
                        marginLeft={1}
                        fontWeight={300}
                        mb={1}
                      />
                    }
                    leftContent={
                      <Container mr={1}>
                        <CartIcon />
                      </Container>
                    }
                    onClick={handleClassLicenseNavigation}
                  />
                </section>
              </div>
            </PricingTable>

            <PricingTable>
              <div>
                <label>Jetzt testen</label>
                <PricingTableHeader>
                  <Text
                    value="Testlizenz"
                    mb={[10, 10, 0, 10]}
                    fontSize={24}
                    lineHeight="22px"
                    fontWeight={700}
                  />
                  <Text
                    value="Ideal zum Testen"
                    mb={[30, 30, 0, 30]}
                    fontSize={14}
                    fontWeight={400}
                    color="#999"
                  />
                  <Container display="flex" alignItems="flex-end" mb={[10, 10, 0, 10]}>
                    <Text
                      value="0"
                      mb={0}
                      mr={1}
                      fontSize={45}
                      lineHeight="35px"
                      fontWeight={700}
                    />
                    <Text
                      value="€ /"
                      mb={0}
                      mr={1}
                      fontSize={24}
                      lineHeight="22px"
                      fontWeight={700}
                    />
                    <Text
                      value="Jahr"
                      mb={0}
                      fontSize={24}
                      lineHeight="22px"
                      fontWeight={600}
                      color="#999"
                    />
                  </Container>
                  <Text
                    value="0 € im Monat"
                    mb={[54, 54, 0, 54]}
                    mr={1}
                    fontSize={14}
                    fontWeight={600}
                  />
                </PricingTableHeader>
                <section>
                  <IconCircle>
                    <img src={Images.Test} alt="" />
                  </IconCircle>
                  <ul>
                    <li>Passend zu allen Cornelsen Englischlehrwerken</li>
                    <li>Testen Sie mit einer Klasse</li>
                    <li>Zugang für eine Lehrkraft</li>
                  </ul>
                </section>
                <section>
                  <TextContainer>
                    <Text
                      value="Alle Features & Funktionalitäten der Klassenlizenz unverbindlich testen."
                      fontSize={14}
                      fontWeight={600}
                      textAlign="center"
                      color="#637388"
                    />
                  </TextContainer>
                </section>
                <section>
                  <Container mb="10px">
                    <img src={Images.Info} alt="" />
                  </Container>

                  <TextContainer>
                    <Text
                      value="Einfacher Wechsel mit all Ihren Daten in eine Klassenlizenz oder Schullizenz."
                      fontSize={12}
                      fontWeight={400}
                      textAlign="center"
                      color="#999"
                    />
                  </TextContainer>

                  <Button
                    width={250}
                    height={40}
                    variant="solid"
                    value={
                      <Text
                        value="Testlizenz ausprobieren"
                        color="#fff"
                        fontSize={20}
                        fontWeight={300}
                        mb={1}
                      />
                    }
                    leftContent={
                      <Container mr={2}>
                        <SmileyIcon />
                      </Container>
                    }
                    onClick={handleTestLicenseNavigation}
                  />
                </section>
              </div>
            </PricingTable>
          </PricingTableContainer>

          <Container display="flex" flexDirection="column" pr={3} pl={3}>
            <Container display="flex" flexDirection="column" maxWidth={615}>
              <Text
                value="* Gilt für aktuelle Cornelsen Arbeitshefte zu den Schulbüchern der aktuellen Frontlist-Produktmarken:"
                fontSize={14}
                lineHeight="24px"
                textAlign="center"
                fontWeight={400}
                color="#000"
              />
              <Text
                value=" EG Lighthouse / Headlight / Highlight / Access (alle Ausgaben) / Focus on Success PLUS sowie Go Ahead"
                mb={35}
                fontSize={14}
                lineHeight="24px"
                textAlign="center"
                fontWeight={400}
                color="#000"
              />
            </Container>

            {/* <Separator />

            <Container flexDirection="column" maxWidth={615}>
              <Text
                value="Über Cornelsen PrintPlus"
                mb={1}
                fontSize={16}
                lineHeight="24px"
                textAlign="center"
                fontWeight={700}
                color="#000"
              />
              <Text
                value="Die perfekte Ergänzung zurn Schülerbuch Schulen, die bereits unsere Schulbücher eingeführt haben, aber dennoch nicht auf die Vorzüge des neuen E-Books verzichten wollen."
                mb={1}
                fontSize={16}
                lineHeight="24px"
                textAlign="center"
                fontWeight={400}
                color="#000"
              />
              <Text
                value="Mehr Informationen zu PrintPlus"
                mb={35}
                fontSize={16}
                lineHeight="24px"
                textAlign="center"
                fontWeight={400}
                color="#1A73E8"
                onClick={handlePrintPlusNavigation}
                style={{ cursor: 'pointer' }}
              />
            </Container> */}
          </Container>
        </Container>

        <Benefits>
          <Text
            value="Vorteile für"
            mt="50px"
            mb={1}
            fontSize={28}
            fontWeight={700}
            lineHeight="18px"
            color="#000"
          />
          <Text
            value="Ihre Schüler-/innen in der ChatClass App"
            mb={60}
            fontSize={28}
            fontWeight={700}
            textAlign="center"
            color="#000"
          />
          <ul>
            <li>Angstfrei sprechen üben</li>
            <li>Wissen über Quizbattles testen</li>
            <li>Hörverständnis trainieren</li>
            <li>Wortschatztraining</li>
            <li>Feedback geben & erhalten</li>
            <li>Privater Übungsmodus (Ninja)</li>
          </ul>
          <AppBox>
            <img src={Images.AppIconShadow} alt="" />
            <Text
              value="Die ChatClass App für Schüler/-innen ist kostenlos."
              fontSize={22}
              fontWeight={600}
              color="#000"
            />
          </AppBox>
          <Adaillu src={Images.Adaillu} alt="" />
        </Benefits>
      </ModalContent>
    </Modal>
  )
}

export default ModalPricingTable
