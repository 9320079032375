import React, {useCallback, useEffect, useState} from 'react';
import {Alert} from 'cc-web-components';

import LicenseBar from '@/components/LicenseBar';
import ModalLicenseExpired from '@/components/ModalLicenseExpired';
import ModalFeedbackLicense from '@/components/ModalFeedbackLicense';
import ModalProductBenefits from '@/components/ModalProductBenefits';
import ModalPricingTable from '@/components/ModalPricingTable';
import {Loader} from '@/components';
import {useDispatch, useSelector} from 'react-redux';

import useDeviceDetection from '@/hooks/IsMobile';
import {
  getAlertValue,
  getIsHideFeaturesUser,
  getIsShowModalPricingTable,
  getLicense,
  getLoading,
  getUser,
  hiderAlert,
  setLicense,
  toggleShowModalPricingTable,
} from '@/redux/Session';
import {Survey} from '@/services/ApiCv/types/Survey';
import useQueryParams from '@/hooks/QueryParams';
import ApiLicenseService, {LicenseAllType} from '@/services/ApiCv/ApiLicenseService';
import {setIsShowTabNewTask} from '@/redux/ClassRoom';
import lodashOrderBy from 'lodash.orderby';
import ApiStudentService from '@/services/ApiCv/ApiStudentService';
import Storage from '../../../services/Storage';

export const Additions: React.FC = () => {
  const dispatch = useDispatch();
  const QueryParams = useQueryParams();
  const alertValue = useSelector(getAlertValue);
  const license = useSelector(getLicense);
  const user = useSelector(getUser);
  const {isMobile} = useDeviceDetection();
  const [showLicenseBar, setShowLicenseBar] = useState(false);
  const [showModalExpiredLicense, setShowModalExpiredLicense] = useState(false);
  const [showModalProductBenefits, setShowModalProductBenefits] = useState(false);
  const [showModalFeedbackLicense, setShowModalFeedbackLicense] = useState(false);
  const isLoading = useSelector(getLoading);
  const IsShowFeaturesUser = !useSelector(getIsHideFeaturesUser);
  const isShowModalPricingTable = useSelector(getIsShowModalPricingTable);
  const isShowLicenseBarMock = (function () {
    if (QueryParams.get('license') === 'expired') {
      return true;
    }
    if (QueryParams.get('license') === 'expire_in') {
      return true;
    }

    return false;
  })();

  useEffect(() => {
    if (!license || !user) return;
    const isLicenseFeedback = localStorage.getItem('hideFeedback') === 'true';
    setFeedbackLicense(isLicenseFeedback);
  }, [user, license]);

  const handleExpiredLicenseNext30Days = useCallback(
    async (licenses: LicenseAllType[]) => {
      const activeLicenses = licenses.filter(license =>
        license.classrooms.some((classroom: any) => !classroom.code.includes('deleted')),
      );

      if (!!activeLicenses.length) {
        const [license] = lodashOrderBy(activeLicenses, ['days_to_expire'], 'asc');

        dispatch(
          setLicense({
            license,
          }),
        );

        setShowLicenseBar(true);
        setShowModalExpiredLicense(false);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!isShowLicenseBarMock) return;

    dispatch(
      setLicense({
        license: {
          test: true,
          days_to_expire: 87,
          expired: true,
          type: '',
        },
      }),
    );

    setShowLicenseBar(true);
  }, [dispatch, isShowLicenseBarMock]);

  const handleExpiredLicense = useCallback(
    async (licenses: LicenseAllType[]) => {
      const activeLicenses = licenses.filter(license =>
        license.classrooms.some((classroom: any) => !classroom.code.includes('deleted')),
      );

      if (!!activeLicenses.length) {
        dispatch(
          setLicense({
            license: activeLicenses[0],
          }),
        );

        setShowLicenseBar(true);
        ApiStudentService.getSurveys().then(data => setSurvey(data));
        setShowModalExpiredLicense(true);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    async function loadLicense() {
      const token = await Storage.getCvToken();

      if (!token) return;

      ApiLicenseService.getAllLicenses().then(licenses => {
        const isShowTabNewTask = licenses.some(license => !license?.expired);

        dispatch(setIsShowTabNewTask({isShowTabNewTask}));

        dispatch(
          setLicense({
            license: licenses.filter(license => !license.expired)[0],
          }),
        );

        handleExpiredLicenseNext30Days(
          licenses.filter(license => !license?.expired && license.days_to_expire <= 30),
        );

        handleExpiredLicense(licenses.filter(license => license?.expired));
      });
    }

    loadLicense();
  }, [dispatch, handleExpiredLicense, handleExpiredLicenseNext30Days]);

  useEffect(() => {
    if (alertValue) {
      setTimeout(() => dispatch(hiderAlert()), 3000);
    }
  }, [alertValue, dispatch]);

  // const [showBanner, setShowBanner] = useState(true)
  const [feedbackLicense, setFeedbackLicense] = useState(false);
  const [survey, setSurvey] = useState({} as Survey);

  const isShowLicenseBar =
    isShowLicenseBarMock || (!isMobile && showLicenseBar && IsShowFeaturesUser);

  const handleCloseLicenseBar = () => {
    setShowLicenseBar(false);
  };

  const openExpiredLicenseModal = () => {
    setShowModalExpiredLicense(true);
  };

  const openModalProductBenefts = () => {
    setShowModalExpiredLicense(false);
    setShowModalProductBenefits(true);
  };

  const openModalPricingTable = () => {
    setShowModalExpiredLicense(false);
    dispatch(toggleShowModalPricingTable({visible: true}));
  };

  const closeModalPricingTable = () => {
    dispatch(toggleShowModalPricingTable({visible: false}));
  };

  const handleToggleModalFeedbackLicense = () => {
    setShowModalFeedbackLicense(!showModalFeedbackLicense);
  };

  const closeAllModalsToExpiredLicense = () => {
    setShowModalFeedbackLicense(false);
    setShowModalProductBenefits(false);
  };

  return (
    <>
      {!!license && isShowLicenseBar && (
        <LicenseBar
          license={license}
          leftDays={license.days_to_expire}
          expiredLicense={license.expired}
          testLicense={license.test}
          closeCallback={handleCloseLicenseBar}
          shopCallback={openModalPricingTable}
          moreInfoCallback={openExpiredLicenseModal}
        />
      )}
      {showModalExpiredLicense && license && survey && (
        <ModalLicenseExpired
          okayCallback={openModalProductBenefts}
          shopCallback={openModalPricingTable}
          license={license}
          survey={survey}
        />
      )}
      {showModalProductBenefits && (
        <ModalProductBenefits
          shopCallback={openModalPricingTable}
          closeCallback={closeAllModalsToExpiredLicense}
          feedbackCallback={
            !feedbackLicense ? handleToggleModalFeedbackLicense : closeAllModalsToExpiredLicense
          }
        />
      )}
      {showModalFeedbackLicense && !feedbackLicense && (
        <ModalFeedbackLicense closeCallback={closeAllModalsToExpiredLicense} />
      )}
      {isShowModalPricingTable && <ModalPricingTable closeCallback={closeModalPricingTable} />}
      <Loader isVisible={isLoading} />
      {alertValue && <Alert value={alertValue.value} variant={alertValue.variant} />}
    </>
  );
};
