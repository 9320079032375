import {DefaultTheme} from 'styled-components';

const breakpoints = ['425px', '768px', '1024px'];

export const CornelsenTheme: DefaultTheme = {
  breakpoints,
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints[0]})`,
    medium: `@media screen and (min-width: ${breakpoints[1]})`,
    large: `@media screen and (min-width: ${breakpoints[2]})`,
  },
  colors: {
    red: '#e53935',
    green: '#00c967',
    primary: '#1A73E8',
    blue: ['#1A73E8'],
    secondary: '#FF8037',
    yellow: '#ffc107',
    gray: ['#333', '#666', '#999', '#c2c2c2', '#ddd', '#eee', '#f8f8f8', '#F9FAFE'],
    black: '#000',
    white: '#fffffe',
    transparent: 'transparent',
  },
  elevation: [
    'box-shadow: none',
    'box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1), 0 3px 1px -2px rgba(0,0,0,0.1), 0 1px 5px 0 rgba(0,0,0,0.1);',
    'box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);',
    'box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);',
    'box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);',
    'box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);',
  ],
  spacing: [0, 4, 8, 12, 16, 24, 32, 40, 44],
  fontSizes: [11, 12, 14, 16, 18, 20, 24, 32, 36],
  borderWidths: [0, 0.5, 1, 2],
  radii: [2, 4, 5, 8, 10, 20],
  zIndex: {
    Modal: 30,
    Menu: 20,
    Toolbar: 18,
    FooterMenu: 10,
  },
};
