import styled from 'styled-components'

export const Emojies = styled.div`
  background-color: transparent;

  display: flex;
  align-items: center;
  flex-direction: row;
`

export const EmojiItem = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #ccc;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  cursor: pointer;
  font-size: 14px;

  &:not(:first-child) {
    margin-left: -10px;
  }
  span {
    color: #333;
    font-size: 14px;
    position: absolute;
    bottom: -47px;
    z-index: 10;
    background-color: #fff;
    width: fit-content;
    height: 30px;
    padding: 0 5px;
    border-radius: 0.25em;
    display: none;
    border: 1px solid #ddd;
    &:before {
      content: '';
      display: block;
      height: 12px;
      width: 12px;
      background-color: inherit;
      border: 1px solid #ddd;
      position: absolute;
      top: -7px;
      left: calc(50% - 10px);
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
      transform: rotate(135deg);
      border-radius: 0 0 0 0.25em;
    }
  }
  &:hover {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

export const TableContainer = styled.div`
  #tableTasks {
    margin-top: 30px;
    > div + div {
      overflow-x: visible;
    }

    > div > table {
      border-spacing: 0;

      > thead > tr > th {
        &:nth-child(1) {
          padding-left: 13px;
        }
      }

      > tbody > tr {
        &.row-actived {
          background-color: rgb(209, 227, 250);
        }

        &.row-actived + tr > td {
          border-top: 0;
        }
      }

      > tbody > tr > td {
        border-top: 2px solid ${(props) => props.theme.colors.primary};

        &:nth-child(1) {
          width: 180px;
          max-width: 180px;
        }
        &:nth-child(2) {
        }
        &:nth-child(3) {
          width: 150px;
          max-width: 150px;
        }
        &:nth-child(4) {
          width: 100px;
          max-width: 100px;
        }
        &:nth-child(5) {
          width: 375px;
          max-width: 375px;
        }
      }
    }
  }

  #tableTaskActivity {
    > div > table {
      border-spacing: 0;

      > tbody > tr > td {
        &:nth-child(1) {
          width: 180px;
        }
        &:nth-child(2) {
          width: 375px;
        }
        &:nth-child(3) {
          width: 150px;
        }
        &:nth-child(4) {
          width: 100px;
        }
        &:nth-child(5) {
        }
      }
    }
  }

  #tableTaskStudents {
    box-shadow: none;

    > div > table {
      border-spacing: 0;

      > thead > tr > th {
        background-color: ${(props) => props.theme.colors.gray[6]};

        &:nth-child(1) {
          padding-left: 13px;
        }
      }
      > tbody > tr > td {
        &:nth-child(1) {
          width: 250px;
          padding-right: 16px;
        }
        &:nth-child(2) {
          width: 300px;
        }
        &:nth-child(3) {
          width: 150px;
        }
        &:nth-child(4) {
          width: 90px;
        }
        &:nth-child(5) {
        }
      }
    }
  }
`

export const TableColumnHeaderSortable = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
