export enum ActivityType {
  AI_SPEAKING = 'AI_SPEAKING',
  VOCABULARY_TRAINER = 'VOCABULARY_TRAINER',
}

export type ActivityMediaType = 'AUDIO' | 'IMAGE' | 'TEXT'

export type ActivityFeedbackType = 'TEACHER'

export type ActivityMessageHtml = {
  html: string
}

export type ActivityMessageTexto = {
  text: string
}

export type ActivityMessageAudio = {
  media: {
    id: string
    type: ActivityMediaType
    url: string
    source_url: string
    metadata: {
      duration: number
    }
  }
}

export type ActivityOption = {
  text: string
  correct: boolean
}

export type ActivityMessage = ActivityMessageAudio | ActivityMessageTexto | ActivityMessageHtml

export type ActivityItemMessage = {
  from_bot: boolean
  correct: boolean
  message: ActivityMessage
  options: ActivityOption[]
  orig_message: ActivityMessage
  chosen_options?: number[]
}

export type ActivityItemUser = {
  from_bot: boolean
  correct: boolean
  message: ActivityMessage
  orig_message: ActivityMessage
}

export type FeedbackMessage = {
  stat_id: string
  type: ActivityFeedbackType
  feedback_message: ActivityMessage[]
  sender: { name: string }
}

export type ActivityItemMessages = {
  correct_text?: string
  index: number
  messages: ActivityItemMessage[]
}

export type ActivityBookDetail = {
  activity_id: string
  xp: number
  items: ActivityItemMessages[]
  stat_type: string
}

export type Activity = {
  user_name: string
  user_emoji: string
  date: string
  activity_id: string
  activity_title: string
  page_number_spec: number
  percent_right: number
  speaking_word_count: number
  classroom_id: string
  student_id: string
  stat_id: string
  stat_type: string
  xp: number
  responses: {
    from_bot: boolean
    message: ActivityMessage
    orig_message: ActivityMessage
  }[]
  items: {
    index: number
    correct: boolean
    messages: ActivityItemMessage[]
  }[]
  feedbacks: FeedbackMessage[]
  correct: boolean
  number_items: number
  number_items_correct: number
  has_feedback: boolean
}

export type ActivityVocab = Activity & {}

export type ActivitySpeech = Activity & {}

export type ActivityResponse = {
  correctness: number
  total_elements: number
  content: Activity[]
  pageable?: {
    offset: number
    page_number: number
  }
  number_of_elements?: number
  total_pages?: number
}

export type ActivityBook = {
  id: string
  type: string
  title: string
  xp: number
  randomized: boolean
  vocabulary_task: boolean
  speaking_task: boolean
}

export type TaskActivityResponse = {
  activity_id: string
  items: ActivityItemMessages[]
  xp: number
}[]
