import styled, {css} from 'styled-components';
import {Text} from 'cc-web-components';

export const SmilyWrapper = styled.div<{color: string}>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: ${({color}) =>
    color === 'green' ? '#E8F5E9' : color === 'red' ? '#FFEBEE' : '#FFF8E1'};
  ${css`
    :first-of-type {
      border-radius: 8px 0 0 8px;
    }

    :last-of-type {
      border-radius: 0 8px 8px 0;
    }
  `};
`;

export const StyledScoreIconExample = styled.div`
  flex: 1;
  display: flex;
`;

export const ScoreText = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
`;
