import React from 'react'

import { StyledContainer, StyledNumberText as Text } from '../styles'

type SeparatorsNumbersProps = {
  numbers: string[]
  rotation: number
  circleRatio: number
}

const SeparatorsNumbers: React.FC<SeparatorsNumbersProps> = ({
  numbers,
  rotation,
  circleRatio,
}: SeparatorsNumbersProps) => {
  const getRotate = React.useCallback(
    (index: number) => {
      const delta = index === 2 ? 1.26 : 1.25
      const parentRote = (circleRatio / 5) * (index * delta) + rotation
      let childrenRotate = 0

      switch (index) {
        case 0: {
          childrenRotate = 135
          break
        }
        case 1: {
          childrenRotate = 68
          break
        }
        case 2: {
          childrenRotate = 0
          break
        }
        case 3: {
          childrenRotate = -68
          break
        }
        case 4: {
          childrenRotate = -135
          break
        }
      }

      return {
        parentRote,
        childrenRotate,
      }
    },
    [circleRatio, rotation]
  )

  return (
    <>
      {numbers.map((value, index) => {
        const { parentRote, childrenRotate } = getRotate(index)

        return (
          <StyledContainer key={index} rotate={parentRote}>
            <Text
              size={17}
              value={value}
              marginTop={-45}
              fontWeight={600}
              rotate={childrenRotate}
            />
          </StyledContainer>
        )
      })}
    </>
  )
}

export default SeparatorsNumbers
