import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, Text } from 'cc-web-components'

import useRecorder from '@/hooks/recorder'
import Helpers from '@/helper'
import AudioPlayer from '@/components/AudioPlayer'
import ButtonPulse from '@/components/ButtonPulse'
import useDeviceDetection from '@/hooks/IsMobile'
import { RefreshIcon, SendOutlinedIcon, SpeechOutlinedIcon, UserIcon } from '@/components/Icons'

type Props = {
  onSubmit: (audio: string) => void
  hasFeedbackError?: boolean
  feedbackErrorText?: string
}

const Form: React.FC<Props> = ({ onSubmit, hasFeedbackError, feedbackErrorText }: Props) => {
  const { t } = useTranslation('cornelsen')
  const { isMobile } = useDeviceDetection()

  const [
    { audioURL, isRecording, audioData },
    { startRecording, stopRecording, cleanRecording, counter },
  ] = useRecorder()

  const handleSubmit = async () => {
    if (hasFeedbackError && feedbackErrorText) {
      Helpers.showNotification(t('Globals.Notification.Error.Title'), feedbackErrorText, 'danger')

      return
    }

    onSubmit(audioData)
  }

  if (audioURL) {
    return (
      <Container
        flex={1}
        py={[3, 3, 0, 0]}
        pr={3}
        pl={3}
        minHeight={150}
        borderTop="1px solid #eee"
        alignItems="center"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <Container flex={1} alignItems="center">
          <Container marginBottom={2}>
            <UserIcon />
          </Container>

          <Text marginLeft={3} fontSize={16} value={t('Globals.FormFeedback.Audio.ToSendText')} />
        </Container>

        <Container
          flex={1}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mb={[2, 2, 0, 0]}
        >
          <Button
            color="primary"
            width={58}
            height={58}
            variant="shadow"
            onClick={() => cleanRecording()}
            leftContent={<RefreshIcon />}
            marginBottom={3}
          />

          <AudioPlayer src={audioURL} />
        </Container>

        <Container flex={1} alignItems="center" justifyContent="flex-end">
          <Button
            value={t('Globals.FormFeedback.ButtonSendText')}
            variant="solid-min"
            onClick={handleSubmit}
            height={44}
            fontSize={16}
            fontWeight={400}
            leftContent={
              <Container mr={2}>
                <SendOutlinedIcon width={20} />
              </Container>
            }
          />
        </Container>
      </Container>
    )
  }

  if (isRecording) {
    return (
      <Container
        flex={1}
        py={[3, 3, 0, 0]}
        pr={3}
        pl={3}
        minHeight={150}
        borderTop="1px solid #eee"
        alignItems="center"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <Container flex={0.3} alignItems="center">
          <Container marginBottom={2}>
            <UserIcon />
          </Container>

          <Text
            marginLeft={3}
            fontSize={16}
            value={t('Globals.FormFeedback.Audio.ToSpeakingText')}
          />
        </Container>

        <Container
          flex={0.3}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mb={[2, 2, 0, 0]}
        >
          <ButtonPulse onClick={() => stopRecording()} />

          <Text value={counter} mt={2} />
        </Container>

        <Container flex={0.3} alignItems="center" justifyContent="flex-end">
          <Button
            value={t('Globals.FormFeedback.ButtonSendText')}
            variant="solid-min"
            onClick={() => {}}
            height={44}
            fontSize={16}
            fontWeight={400}
            leftContent={
              <Container mr={2}>
                <SendOutlinedIcon width={20} />
              </Container>
            }
          />
        </Container>
      </Container>
    )
  }

  return (
    <Container
      flex={1}
      flexDirection={isMobile ? 'column' : 'row'}
      py={[3, 3, 0, 0]}
      alignItems="center"
      justifyContent="space-between"
      minHeight={150}
      borderTop="1px solid #eee"
      marginTop={2}
    >
      <Container flex={0.3} alignItems="center">
        <Container marginBottom={2}>
          <UserIcon />
        </Container>

        <Text marginLeft={3} fontSize={16} value={t('Globals.FormFeedback.Audio.ToStartText')} />
      </Container>

      <Container flex={0.3} alignItems="center" justifyContent="center" mb={[2, 2, 0, 0]}>
        <Button
          color="primary"
          variant="shadow"
          width={58}
          height={58}
          onClick={() => startRecording()}
          leftContent={<SpeechOutlinedIcon width={24} />}
        />
      </Container>

      <Container flex={0.3} alignItems="center" justifyContent="flex-end">
        <Button
          value={t('Globals.FormFeedback.ButtonSendText')}
          variant="solid-min"
          onClick={() => {}}
          height={44}
          fontSize={16}
          fontWeight={400}
          leftContent={
            <Container mr={2}>
              <SendOutlinedIcon width={20} />
            </Container>
          }
        />
      </Container>
    </Container>
  )
}

export default Form
