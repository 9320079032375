import jwt_decode from 'jwt-decode';
import {JWT} from '../types';

export const getIdFromToken = (token?: string) => {
  if (!token) {
    return undefined;
  }
  const {sub: id} = jwt_decode<JWT>(token);
  return id;
};
