import styled from 'styled-components';

export const NavigationOption = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 1;
  padding: 20px;
  background: ${props => props.theme.colors.white};
  cursor: pointer;
  border: 0;
  border-bottom: 1px solid #eee;
  transition: 0.2s;

  &:hover {
    background: rgb(232, 241, 252);
  }

  &:first-child {
    border-top: 1px solid #eee;
  }

  & div {
    margin-right: auto;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;
