import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid rgb(229 229 229);
  background-color: #fff;
  padding: 0px 210px 0px 130px;

  @media screen and (max-width: 1220px) {
    border-left: 0px;
    padding: 0px 100px 0px 100px;
  }

  @media screen and (max-width: 768px) {
    border-left: 0px;
    padding: 0px 10px;
  }

  @media screen and (max-width: 425px) {
    border-top: 1px solid rgb(229 229 229);
  }
`
