import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Text, Button, Container } from 'cc-web-components'
import { IoArrowBack, IoArrowForward } from 'react-icons/io5'

import Helpers, { FilterTime, FilterTimeValues } from '../../../helper'

type Props = {
  correctness: number
  total_elements: number
  filterPageActived: number
  datesFilterTime: FilterTime | undefined
  onNextValues: () => void
  onPrevValues: () => void
}

const TableHeader: React.FC<Props> = ({
  correctness,
  total_elements,
  filterPageActived,
  datesFilterTime,
  onNextValues,
  onPrevValues,
}: Props) => {
  const { t } = useTranslation('cornelsen')
  const { colors } = useTheme()

  const correctnessPercent = useMemo(() => Helpers.getValuePercent(correctness), [correctness])

  const canInterateValues = useMemo(
    () => filterPageActived !== FilterTimeValues.ALL,
    [filterPageActived]
  )

  const getIntervalTime = useMemo(() => {
    if (!canInterateValues || !datesFilterTime || !datesFilterTime.startDate) return ''

    const startDate = Helpers.getFormatDate(datesFilterTime.startDate, 'dd.MM.yyyy')

    const endDate = Helpers.getFormatDate(datesFilterTime.endDate, 'dd.MM.yyyy', -1)

    if (filterPageActived === FilterTimeValues.TODAY) {
      return startDate
    }

    return `${startDate} - ${endDate}`
  }, [datesFilterTime, filterPageActived, canInterateValues])

  return (
    <Container flexDirection="column">
      <Container
        flex={1}
        minHeight={65}
        padding="0px 6px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          flex={0.3}
          variant="link"
          color="primary"
          onClick={onPrevValues}
          leftContent={canInterateValues ? <IoArrowBack fontSize={25} /> : null}
        />

        <Text value={t('Classroom.Activities.TablePeriodTitle')} size={16} isBold />

        <Button
          flex={0.3}
          variant="link"
          color="primary"
          onClick={onNextValues}
          leftContent={canInterateValues ? <IoArrowForward fontSize={25} /> : null}
        />
      </Container>
      <Container
        height={49}
        padding="0px 20px"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="rgba(26, 115, 232, 0.1)"
      >
        <Text
          value={`${total_elements} ${t('Classroom.Activities.TableTotalTasks')}`}
          size={14}
          flex={0.3}
        />

        <Text value={getIntervalTime} color={colors.black} size={14} />

        <Container flex={0.3} display="flex" justifyContent="flex-end">
          <Text
            value={`${t('Classroom.Activities.TableCorrectness')} ${correctnessPercent} %`}
            size={14}
          />
        </Container>
      </Container>
    </Container>
  )
}

export default TableHeader
