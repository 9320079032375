import styled from 'styled-components';

export const NumberOfActivities = styled.div`
  background-color: #fff;
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
`;

export const style = {
  container: {cursor: 'pointer '},
  text: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
