/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {Container, Text} from 'cc-web-components';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {useCreateClassroom} from '../../Context';

import {ColorSelector} from '../../Components';
import {CustomInput, NoModificationDisclaimer, TeacherStatus} from './components';
import {selectIsFeatureAvailable} from '@/redux/Rights';
import Document from '@/assets/documents/auftragsverarbeitungsvertrag-avv-als-pdf-herunterladen.pdf';
import {CheckboxContainer, DownloadButton, DownloadIcon, LinkButton, LinkIcon} from './styles';
import ApiUserService from '@/services/ApiCv/ApiUserService';
import {getIsAvvConfirmed, setIsAvvConfirmed} from '@/redux/Session';

type Props = {
  setButtonActive: (buttonActive: boolean) => void;
};

const StepOne = ({setButtonActive}: Props) => {
  const {t} = useTranslation('cornelsen');
  const dispatch = useDispatch();
  const canModify = useSelector(selectIsFeatureAvailable('CLASSROOM', 'U'));
  const {createClassroomData, setCreateClassroomData} = useCreateClassroom();
  const isAvvConfirmed = useSelector(getIsAvvConfirmed).value;
  const [avvChecked, setAvvChecked] = useState(false);

  useEffect(() => {
    setButtonActive(
      !!createClassroomData.name &&
        !!createClassroomData.teacher_name &&
        !!createClassroomData.teacher_title &&
        !!createClassroomData.color &&
        isAvvConfirmed,
    );
  }, [createClassroomData, isAvvConfirmed]);

  const handleTeacherName = (event: any) => {
    setCreateClassroomData({
      ...createClassroomData,
      teacher_name: event.target.value,
    });
    localStorage.setItem('teacherName', event.target.value);
  };

  const handleClassname = (event: any) => {
    setCreateClassroomData({
      ...createClassroomData,
      name: event.target.value,
    });
  };

  const handleSelectColor = (color: string) => {
    setCreateClassroomData({
      ...createClassroomData,
      color,
    });
  };

  const confirmAvv = () => {
    ApiUserService.confirmAvv()
      .then(() => {
        dispatch(setIsAvvConfirmed({value: true}));
      })
      .catch(() => {});
  };

  const onChangeAvv = (event: any) => {
    const checked = event.target.checked;
    if (checked) {
      setAvvChecked(checked);
      confirmAvv();
    }
  };

  const InputComponent = ({field, ...props}: any) => {
    return (
      <CheckboxContainer>
        <input
          type="checkbox"
          id="checkboxcontainer"
          onChange={onChangeAvv}
          disabled={avvChecked}
          checked={avvChecked}
        />
        <span className="check"></span>
      </CheckboxContainer>
    );
  };

  return (
    <Container flexDirection="column">
      <Container padding={3} flexDirection="column" background="#fff" pt={4} pb={4}>
        <Container flexDirection="column">
          <Text
            size={16}
            fontWeight={600}
            value={t('AssignActivity.Tabs.NewClass.Step1.ClassNameTitle')}
          />
          <CustomInput
            onChange={handleClassname}
            value={createClassroomData.name}
            translationKey="AssignActivity.Tabs.NewClass.Step1.ClassNamePlaceholder"
          />
        </Container>

        <Container flexDirection="column" mt={2}>
          <Text
            size={16}
            fontWeight={600}
            value={t('AssignActivity.Tabs.NewClass.Step1.TeacherTitle.Title')}
          />
          <TeacherStatus />
        </Container>

        <Container flexDirection="column" mt={2}>
          <Text
            mb={2}
            size={16}
            fontWeight={600}
            value={t('AssignActivity.Tabs.NewClass.Step1.TeacherName.Title')}
          />
          <CustomInput onChange={handleTeacherName} value={createClassroomData.teacher_name} />
        </Container>
        {!isAvvConfirmed && (
          <>
            <Container mt={4}>
              <InputComponent />
              <Text
                marginLeft={16}
                value={t('AssignActivity.Tabs.NewClass.Step1.AvvLabel')}
                size={16}
                color="#999999"
              />
            </Container>

            <Text
              margin={'12px 0px 12px 0px'}
              size={16}
              color="#666666"
              value={t('AssignActivity.Tabs.NewClass.Step1.AvvDescription')}
            />

            <DownloadButton href={Document} download={true}>
              <DownloadIcon />
              {t('AssignActivity.Tabs.NewClass.Step1.AvvDownload')}
            </DownloadButton>

            <LinkButton
              target="_blank"
              href="https://www.cornelsen.de/service/kundenservice/rechtliche-hinweise/avv"
              rel="noreferrer">
              <LinkIcon />
              Mehr Details
            </LinkButton>
          </>
        )}

        <Container paddingTop={'16px'} flexDirection="column" background="#fff">
          <Text
            mb={2}
            size={16}
            fontWeight={600}
            value={t('AssignActivity.Tabs.NewClass.Step1.ColorTitle')}
          />
          <ColorSelector activeColor={createClassroomData.color} callback={handleSelectColor} />
        </Container>

        {isAvvConfirmed && (
          <Text
            marginTop={32}
            value={t('AssignActivity.Tabs.NewClass.Step1.AvvConfirmed')}
            size={16}
            color="#666666"
          />
        )}
      </Container>

      <NoModificationDisclaimer isVisible={!canModify} />
    </Container>
  );
};

export default StepOne;
