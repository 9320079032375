import React, {useEffect, useState} from 'react';
import {Container, Text} from 'cc-web-components';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {LearningActivities} from './styles';
import useDeviceDetection from '@/hooks/IsMobile';
import {Competences, HealthScore} from '@cc/components';
import {Competency} from '@/services/ApiCv/types';
import {useDispatch} from 'react-redux';
import {startLoading, stopLoading} from '@/redux/Session';
import ApiHomePageService from '@/services/ApiCv/ApiHomePageService';

interface Props {
  getClassrooms: () => void;
}

export const StudentsProgress: React.FC<Props> = ({getClassrooms}) => {
  const {colors} = useTheme();
  const dispatch = useDispatch();
  const {t} = useTranslation('cornelsen');
  const {isMobile} = useDeviceDetection();
  const [competency, setCompetency] = useState<Competency>();

  useEffect(() => {
    dispatch(startLoading());

    getClassrooms();

    ApiHomePageService['getAllCompetencies']()
      .then(data => {
        setCompetency(data);
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  }, [dispatch, getClassrooms]);

  return (
    <Container
      alignItems="flex-start"
      justifyContent="space-between"
      flexDirection="column"
      marginTop="30px">
      <Text
        color={colors.black}
        size={20}
        value={t('Home.SectionActivities.Title')}
        fontWeight={600}
      />
      <LearningActivities className={`learningActivities ${isMobile ? `competencesMobile` : ``}`}>
        <Competences competency={competency} />
        <HealthScore />
      </LearningActivities>
    </Container>
  );
};
