import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Text, Select } from 'cc-web-components'
import { useCreateClassroom } from '../../Context'

type StateProps = {
  id: number
  text: string
}

type Props = {
  setButtonActive: (active: boolean) => void
}

const SELECT_ITEMS = [
  { id: 1, text: 'Baden-Württemberg' },
  { id: 2, text: 'Bayern' },
  { id: 3, text: 'Berlin' },
  { id: 4, text: 'Brandenburg' },
  { id: 5, text: 'Bremen' },
  { id: 6, text: 'Hamburg' },
  { id: 7, text: 'Hessen' },
  { id: 8, text: 'Mecklenburg-Vorpommern' },
  { id: 9, text: 'Niedersachsen' },
  { id: 10, text: 'Nordrhein-Westfalen' },
  { id: 11, text: 'Rheinland-Pfalz' },
  { id: 12, text: 'Saarland' },
  { id: 13, text: 'Sachsen' },
  { id: 14, text: 'Sachsen-Anhalt' },
  { id: 15, text: 'Schleswig-Holstein' },
  { id: 16, text: 'Thüringen' },
] as StateProps[]

const LicenseSelect: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation('cornelsen')
  const { createClassroomData, setCreateClassroomData } = useCreateClassroom()

  const [indexLicense, setIndexLicense] = useState(-1)

  function handleSelectLicense(item: StateProps, index: number) {
    setIndexLicense(index)

    setCreateClassroomData({
      ...createClassroomData,
      location: item.text,
    })

    props.setButtonActive(index >= 0)
  }

  return (
    <Container flex={1} flexDirection="column" backgroundColor="#fff">
      <Container
        minHeight={120}
        alignItems="center"
        justifyContent="center"
        backgroundColor="rgb(248 248 248)"
      >
        <Text
          value={t('AssignActivity.Tabs.NewClass.StepLicenseState.Title')}
          fontSize={24}
          fontWeight={400}
        />
      </Container>

      <Container marginTop={20} paddingLeft={20} paddingRight={15} flexDirection="column">
        <Text
          value={t('AssignActivity.Tabs.NewClass.StepLicenseState.SubTitle')}
          fontSize={16}
          marginTop={15}
          fontWeight={600}
        />

        <Text
          value={t('AssignActivity.Tabs.NewClass.StepLicenseState.Description')}
          fontSize={16}
          marginTop={15}
        />
      </Container>

      <Container flex={1} padding={20} marginTop={20}>
        <Select
          items={SELECT_ITEMS}
          renderItem={(item: StateProps) => <Text value={item.text} />}
          onSelectItem={(item: StateProps, index: number) => handleSelectLicense(item, index)}
          selectedIndex={indexLicense}
          placeholder={t('AssignActivity.Tabs.NewClass.StepLicenseState.PlaceholderSelect')}
        />
      </Container>

      <Container height={25} backgroundColor="rgb(248 248 248)" />
    </Container>
  )
}

export default LicenseSelect
