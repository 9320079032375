import styled from '@emotion/styled';
import {Text} from '../../atoms';
import {Container} from 'cc-web-components';
import {getFontStyle} from '@cc/theme';

export const StoreContainer = styled.a``;

export const AppNameText = styled(Text)`
  text-align: center;
  margin-top: 4px;
  ${({theme}) => getFontStyle(theme, 'mediumBodyStrong200')};
`;

export const ComponentContainer = styled.a`
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 120px;
  text-decoration: none;
  color: black;
`;
export const IconWrapper = styled(Container)`
  height: 120px;
  flex-direction: column;
  align-items: center;
`;
