import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Text } from 'cc-web-components'

import Helpers from '@/helper'
import useDeviceDetection from '@/hooks/IsMobile'
import { ActivityResponse } from '@/services/ApiCv/types/Activity'
import ApiHomePageService from '@/services/ApiCv/ApiHomePageService'
import TableStudentActivities from '@/components/TableStudentActivities'
import { startLoading, stopLoading } from '@/redux/Session'
import { useTranslation } from 'react-i18next'

import { FilterTime } from '../../types'
import { Classrooms } from '@/pages/Home/types'

type Props = {
  color: string
  activeFilter: number
  filtersOfTime: FilterTime
  showMore?: string
  classrooms?: Classrooms
}

const VocabularyProblems: React.FC<Props> = ({
  color,
  activeFilter,
  filtersOfTime,
  showMore,
  classrooms,
}: Props) => {
  const dispatch = useDispatch()
  const { isMobile } = useDeviceDetection()
  const { t } = useTranslation('cornelsen')

  const [activitiesData, setActivitiesData] = useState<ActivityResponse>()
  const [datesFilterTime, setDatesFilterTime] = useState<FilterTime>(filtersOfTime)
  const [activeTableFilter] = useState(1)

  useEffect(() => {
    setDatesFilterTime(filtersOfTime)
  }, [filtersOfTime])

  useEffect(() => {
    dispatch(startLoading())

    ApiHomePageService['getAllVocabularyProblems']()
      .then((data) => {
        setActivitiesData(data)
        dispatch(stopLoading())
      })
      .catch(() => {
        dispatch(stopLoading())
      })
  }, [dispatch])

  const onGetPrevValues = () => {
    const newIntervalTime = Helpers.getPrevIntervalDates(activeFilter, datesFilterTime)

    setDatesFilterTime(newIntervalTime)
  }

  const onGetNextValues = () => {
    const newIntervalTime = Helpers.getNextIntervalDates(activeFilter, datesFilterTime)

    setDatesFilterTime(newIntervalTime)
  }
  return (
    <Container flexDirection="column" width="100%">
      <Container
        mt={5}
        flexDirection={isMobile ? 'column' : ''}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text size={20} value={t('Home.SectionLastestProblems.Title')} fontWeight={600} />
      </Container>

      {!!activitiesData && (
        <TableStudentActivities
          parent="CLASSROOM"
          showMore={showMore}
          color={color}
          data={activitiesData}
          filterPageActived={activeFilter}
          activeTableFilter={activeTableFilter}
          datesFilterTime={datesFilterTime}
          classrooms={classrooms}
          onNextValues={onGetNextValues}
          onPrevValues={onGetPrevValues}
        />
      )}
    </Container>
  )
}

export default VocabularyProblems
