import styled from 'styled-components'

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;

  div:first-child {
    padding-left: 0;
    button {
      padding-left: 0;
    }
  }
`
export const DropdownWrapper = styled.div`
  label {
    height: 65px;
    padding-right: 0;
  }

  ul {
    top: 60px;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);

    li {
      font-size: 16px;
      color: #999999;
    }
  }
`
