import {MobileAppStoreRedirection} from '@cc/components';
import React from 'react';
import {Container} from './styles';
import {isTablet, isMobile} from 'react-device-detect';
import {QRCodeWithIcon} from '../molecules/QRCodeWithIcon';

interface Props {
  showIcon: boolean;
  app: 'chatclass' | 'cornelsen-lernen';
}

export const StoreInfo: React.FC<Props> = ({showIcon, app}) => {
  const redirectionComponent =
    isMobile || isTablet ? (
      <MobileAppStoreRedirection app={app} showIcon={showIcon} />
    ) : (
      <QRCodeWithIcon app={app} showIcon={showIcon} />
    );

  return <Container isQRCode={!isMobile}>{redirectionComponent}</Container>;
};
