export enum ChatMessageTypeKeys {
  HTML = 'HTML',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

export enum ChatMessageFromKeys {
  BOT = 'BOT',
  USER = 'USER',
  FEEDBACK = 'FEEDBACK',
}

export type ChatMessageFrom =
  | ChatMessageFromKeys.BOT
  | ChatMessageFromKeys.USER
  | ChatMessageFromKeys.FEEDBACK

export type ChatMessageType =
  | ChatMessageTypeKeys.HTML
  | ChatMessageTypeKeys.AUDIO
  | ChatMessageTypeKeys.IMAGE

export type ChatMessageMetadata = {
  duration?: number
  user_name?: string
  user_emoji?: string
}

export type ChatMessageOptions = {
  text: string
  correct: boolean
}

export type ChatMessage = {
  from: ChatMessageFrom
  type: ChatMessageType
  content: string
  metadata?: ChatMessageMetadata
  options?: ChatMessageOptions[]
}

export type Chat = {
  correct_text?: string
  index?: number
  colorIndex?: string
  messages: ChatMessage[]
}
