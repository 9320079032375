import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {VscChevronDown, VscChevronUp} from 'react-icons/vsc';
import {Button, Container, Table, Text} from 'cc-web-components';

import MessageList from '@/components/MessageList';
import ApiBookService from '@/services/ApiCv/ApiBookService';
import useDeviceDetection from '@/hooks/IsMobile';
import {useTranslation} from 'react-i18next';
import {useAssignActivityContext} from '@/redux/AssignActivity';
import {ActivityBook, ActivityBookDetail} from '@/services/ApiCv/types/Activity';
import {BookDetailUnit, BookDetailSection} from '@/services/ApiCv/types/BookDetail';
import {startLoading, stopLoading, showAlert} from '@/redux/Session';
import {
  MicroIcon,
  ReviewQuizIcon,
  BlueBookIcon,
  BookPileIcon,
  AssignTaskLinedIcon,
  WorkSpokenIcon,
} from '@/components/Icons';

import ChatEmpty from './ChatEmpty';
import {TableContainer} from './styles';
import {convertActivityBookItemsToMessageList} from './helpers';
import {useTheme} from 'styled-components';

const doNothing = () => {};

type ClassroomStorageProps = {
  book_id: string;
  book_name: string;
  classroom_id: string;
  classroom_name: string;
};

type Props = {
  bookUnit: BookDetailUnit | undefined;
  activities: ActivityBook[];
  bookSection: BookDetailSection | undefined;
  classroomStorage: ClassroomStorageProps | undefined;
  isOriginRedirectBooks: boolean;
  isLicenseExpired?: boolean;
};

const Activities: React.FC<Props> = ({activities, ...props}: Props) => {
  const dispatch = useDispatch();
  const {colors} = useTheme();
  const {isMobile} = useDeviceDetection();
  const {t} = useTranslation('cornelsen');
  const [, {toggleVisibleForm, ...dispatchPlaylist}] = useAssignActivityContext();

  const [showInfoBox, setShowInfoBox] = useState(false);
  const [activityBook, setActivityBook] = useState<ActivityBookDetail>();
  const [indexChatOpen, setIndexChatOpen] = useState(-1);

  useEffect(() => {
    setShowInfoBox(false);
    setActivityBook(undefined);
    setIndexChatOpen(-1);
  }, [activities]);

  const getTasksOfActivity = (activity: ActivityBook) => {
    const {id, type} = activity;

    dispatch(startLoading());

    ApiBookService.getBookTasksByPage({id, type})
      .then(response => {
        setActivityBook(response);
        dispatch(stopLoading());
      })
      .catch(() => {
        dispatch(stopLoading());
      });
  };

  const onOpenDetailsActivity = (index: number) => {
    const newIndex = index === indexChatOpen ? -1 : index;

    setShowInfoBox(false);
    setActivityBook(undefined);
    setIndexChatOpen(newIndex);

    if (newIndex < 0) {
      return;
    }

    const activity = activities[newIndex];

    if (activity.randomized) {
      setShowInfoBox(true);

      return;
    }

    getTasksOfActivity(activity);
  };

  const onOpenAssinActivities = (item: ActivityBook) => {
    if (!props.classroomStorage || !props.bookUnit || !props.bookSection) return;

    if (props.bookUnit.locked) {
      dispatch(showAlert({variant: 'danger', value: t('Book.UnitLockedError')}));

      return;
    }

    const {book_id, book_name, classroom_id, classroom_name} = props.classroomStorage;

    dispatchPlaylist.updatePlaylistBookId(book_id);
    dispatchPlaylist.onLoadPlaylistToCreate({
      classroom_id,
      classroom_name,
      bookId: book_id,
      bookName: book_name,
      unit: {
        id: props.bookUnit.id,
        name: props.bookUnit.name,
      },
      section: {
        section_name: props.bookSection.section_name,
      },
      activity_id: item.id,
    });

    toggleVisibleForm();
  };

  const RenderColumnTitle = (index: number, item: ActivityBook) => {
    const getTitle = () => {
      if (item.randomized) return t('Book.TaskTypes.VocabQuiz');
      if (item.type === 'AI_SPEAKING') return t('Book.TaskTypes.Speak');
      if (item.type === 'LISTENING') return t('Book.TaskTypes.Listening');
      if (item.type === 'DIALOG') return t('Book.TaskTypes.Dialog');
      if (item.type === 'PRONUNCIATION') return t('Book.TaskTypes.Pronunciation');
      if (item.type === 'GRAMMAR') return t('Book.TaskTypes.Grammar');
      if (item.type === 'VOCABULARY_TRAINER') return t('Book.TaskTypes.VocabTraining');
      if (item.type === 'VOCABULARY') return t('Book.TaskTypes.VocabTraining');

      return t('Book.TaskTypes.VocabTraining');
    };

    const getIcon = () => {
      if (item.type === 'AI_SPEAKING') return <MicroIcon />;
      if (item.type === 'PRONUNCIATION') return <WorkSpokenIcon />;
      if (item.type === 'GRAMMAR') return <BookPileIcon />;
      if (item.type === 'LISTENING') return <>👂</>;
      if (item.type === 'DIALOG') return <>📖</>;
      if (item.type === 'VOCABULARY_TRAINER') return <BlueBookIcon />;
      if (item.type === 'VOCABULARY') return <BlueBookIcon />;

      return item.randomized ? <ReviewQuizIcon /> : <BlueBookIcon />;
    };

    return (
      <Container
        padding={isMobile ? '0 5px 0 20px' : '0 20px'}
        alignItems="center"
        onClick={() => onOpenDetailsActivity(index)}
        style={{cursor: 'pointer'}}>
        {getIcon()}

        <Container flexDirection="column" ml={2}>
          <Text size={16} fontWeight={600} value={item.title} />
          <Text size={16} value={getTitle()} />
        </Container>
      </Container>
    );
  };

  const RenderColumnAction = (index: number, item: ActivityBook) => {
    const Icon = index === indexChatOpen ? VscChevronUp : VscChevronDown;

    return (
      <Container
        alignItems="center"
        justifyContent="flex-end"
        onClick={() => onOpenDetailsActivity(index)}
        style={{cursor: 'pointer'}}>
        {!props.isOriginRedirectBooks && !item.randomized && !props.isLicenseExpired && (
          <Button
            height={40}
            width={50}
            variant="link"
            onClick={() => onOpenAssinActivities(item)}
            leftContent={
              <AssignTaskLinedIcon color={props.bookUnit?.locked ? '#c2c2c2' : colors.primary} />
            }
          />
        )}
        <Button
          height={40}
          width={50}
          variant="link"
          leftContent={<Icon size={25} />}
          onClick={doNothing}
        />
      </Container>
    );
  };

  const RenderExtraContent = () => {
    if (!!showInfoBox) {
      return <ChatEmpty />;
    }

    if (!!activityBook) {
      const messages = convertActivityBookItemsToMessageList(activityBook.items);

      return <MessageList xp_number={activityBook.xp} chat={messages} />;
    }

    return <></>;
  };

  // const Footer = () => (
  //   <Container
  //     height={40}
  //     marginTop={-1}
  //     paddingLeft={20}
  //     alignItems="center"
  //     backgroundColor="rgb(232,241,252)"
  //   >
  //     <Container marginRight={15}>
  //       {STUDENTS.map((student) => (
  //         <UserEmoji>
  //           {student.emoji}

  //           <span>{student.name}</span>
  //         </UserEmoji>
  //       ))}
  //     </Container>

  //     <Text color="gray" value={t('Book.StudentsEmojiText')} />
  //   </Container>
  // )

  return (
    <TableContainer>
      <Table
        data={activities}
        indexOpenContent={indexChatOpen}
        // footer={!props.isOriginRedirectBooks && <Footer />}
        columns={[
          {
            align: 'left',
            header: null,
            renderItem: (item: ActivityBook, index: number) => RenderColumnTitle(index, item),
          },
          {
            header: null,
            renderItem: (item: ActivityBook, index: number) => RenderColumnAction(index, item),
          },
        ]}
        renderExtraContent={RenderExtraContent}
      />
    </TableContainer>
  );
};

export default Activities;
