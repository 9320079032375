import React from 'react'
import { Container } from 'cc-web-components'
import {
  buildStyles,
  CircularProgressbarWithChildren as CircularProgress,
} from 'react-circular-progressbar'

import { HealthScoreResponse } from '../../types'
 
import SeparatorNumber from './SeparatorNumber'
import SeparatorInterval from './SeparatorInterval'
import ContentInformations from './ContentInfos'

import 'react-circular-progressbar/dist/styles.css'

const strokeWidth = 10
const rotation = 0.625
const circleRatio = 0.75

type Props = {
  healthScore: HealthScoreResponse
}

const ProgressCircle: React.FC<Props> = ({ healthScore }: Props) => {
  return (
    <Container flex={1} mt="40px" alignItems="flex-end" justifyContent="center">
      <CircularProgress
        value={healthScore.health_score.current * 10}
        strokeWidth={strokeWidth}
        circleRatio={circleRatio}
        styles={buildStyles({
          rotation,
          backgroundColor: '#ccc',
          pathColor:
            healthScore.meta.indicator.color === 'green'
              ? '#73C16A'
              : healthScore.meta.indicator.color,
        })}
        background={false}
      >
        <SeparatorInterval rotation={rotation} circleRatio={circleRatio} numberOfSeparators={20} />

        <SeparatorNumber
          rotation={rotation}
          circleRatio={circleRatio}
          numbers={['0', '2.5', '5.0', '7.5', '10.0']}
        />

        <ContentInformations
          healthScore={healthScore}
          scoreValue={`${healthScore.health_score.current.toFixed(1)}`}
          scoreUpOrDownValue={`${healthScore.health_score.diff > 0 ? '+' : ''}${healthScore.health_score.diff}`}
        />
      </CircularProgress>
    </Container>
  )
}

export default ProgressCircle
