import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StudentLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;

  @media (max-width: 321px) {
    white-space: break-spaces;
  }

  @media (max-width: 425px) {
    white-space: break-spaces;
  }

  :hover {
    text-decoration: underline;
  }

  span {
    margin-right: 5px;
  }
`
