import React from 'react';
import {MdClose} from 'react-icons/md';
import {Formik, Field} from 'formik';
import {useTranslation} from 'react-i18next';
import {Modal, Container, Text, Button} from 'cc-web-components';

import Images from '@/assets/images';

import {Props} from './types';

import '../styles.scss';
import {InfoIcon} from './styles';

const CLOSE_ICON_COLOR = '#1A73E8';

const AdjustLevelModal = ({show, closeRequest, currentStudentLevel, updateLevelRequest}: Props) => {
  const {t} = useTranslation('cornelsen');

  const submitForm = (values: any) => {
    const {studentLevel} = values;
    updateLevelRequest(studentLevel);
  };

  return (
    <Container id="modalWrapper">
      <Modal show={show} width="650px" padding="20px 0">
        <Container justifyContent="center" pb={20} borderBottom="2px solid #81aac2" m="0 30px">
          <Text size={20} fontWeight="600" value={t('Student.Dialogs.LevelAdjust.Title')} />
        </Container>

        <Formik
          initialValues={{
            studentLevel: currentStudentLevel,
          }}
          onSubmit={submitForm}>
          {({handleChange, handleBlur, handleSubmit}) => {
            return (
              <>
                <Container justifyContent="space-around" maxWidth={450} m="12px auto 30px">
                  <Text
                    value={t('Globals.Types.StudentLevel.beginner')}
                    size={16}
                    fontWeight={600}
                  />
                  <Text
                    value={t('Globals.Types.StudentLevel.intermediate')}
                    size={16}
                    fontWeight={600}
                  />
                  <Text
                    value={t('Globals.Types.StudentLevel.advanced')}
                    size={16}
                    fontWeight={600}
                  />
                </Container>

                <Container
                  justifyContent="center"
                  backgroundColor="#e8f1fb"
                  height={60}
                  alignItems="center"
                  mb="46px">
                  <Container width={450} justifyContent="space-around">
                    <Field
                      type="radio"
                      name="studentLevel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={t('Student.Dialogs.LevelAdjust.Level1')}
                    />
                    <Field
                      type="radio"
                      name="studentLevel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={t('Student.Dialogs.LevelAdjust.Level2')}
                    />
                    <Field
                      type="radio"
                      name="studentLevel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={t('Student.Dialogs.LevelAdjust.Level3')}
                    />
                  </Container>
                </Container>

                <Container
                  position="relative"
                  flexDirection="column"
                  justifyContent="center"
                  border="1px dashed #999"
                  mb={4}
                  backgroundColor="#f8f8f8">
                  <Container
                    position="absolute"
                    top={-21}
                    left="50%"
                    alignItems="center"
                    justifyContent="center"
                    width={42}
                    height={42}
                    ml={-21}
                    border="1px solid #999"
                    borderRadius={21}
                    bg="#fff">
                    <InfoIcon src={Images.InfoIcon} alt="" />
                  </Container>
                  <p
                    style={{maxWidth: '560px', margin: '40px auto', textAlign: 'center'}}
                    dangerouslySetInnerHTML={{
                      __html: t('Student.Dialogs.LevelAdjust.Informationtext'),
                    }}></p>
                </Container>

                <Container
                  mt={3}
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                  position="absolute"
                  right={70}
                  bottom={-22}>
                  <Button
                    width={44}
                    type="button"
                    variant="solid"
                    value=""
                    height={44}
                    mr="20px"
                    leftContent={
                      <Container>
                        <MdClose size={30} color={CLOSE_ICON_COLOR} />
                      </Container>
                    }
                    style={{
                      backgroundColor: '#fff',
                      border: '1px solid #ddd',
                    }}
                    onClick={closeRequest}
                  />
                  <Button
                    width={154}
                    type="button"
                    variant="solid"
                    value={t('Student.Dialogs.LevelAdjust.ButtonSubmitText')}
                    fontSize={18}
                    fontWeight={400}
                    height={44}
                    leftContent={
                      <img
                        src={Images.SaveWhite}
                        width="18px"
                        height="18px"
                        style={{marginRight: '8px'}}
                        alt=""
                      />
                    }
                    onClick={handleSubmit}
                  />
                </Container>
              </>
            );
          }}
        </Formik>
      </Modal>
    </Container>
  );
};

export default AdjustLevelModal;
