import styled from 'styled-components'

export const BookCover = styled.img`
  width: 58px;
  margin: 5px 0 0 -5px;
  border: 1px solid ${(props) => props.theme.colors.white};

  ${(props) => props.theme.mediaQueries.medium} {
    width: 111px;
  }

  @media screen and (min-width: 1300px) {
    width: 133px;
    margin: 0;
  }
`

export const StyledTooltipContent = styled.div`
  white-space: normal;
`

export const StyledStudentLink = styled.div`
  a {
    color: #1a73e8;
    text-decoration: none;
    font-weight: 600;

    :hover {
      text-decoration: underline;
    }
  }
`

export const TextWrapper = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;

  @media (max-width: 425px) {
    font-size: 16px;
    margin-left: 20px;
  }
`

export const DropdownWrapper = styled.div`
  label {
    height: 65px;
    padding-right: 0;
  }

  ul {
    top: 60px;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);

    li {
      font-size: 16px;
      color: #999999;
    }
  }
`
export const TabWrapper = styled.div`
  display: flex;
  align-items: center;

  div:first-child {
    padding-left: 0;
    button {
      padding-left: 0;
    }
  }
`
