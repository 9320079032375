export enum StateType {
  AI_SPEAKING = 'AI_SPEAKING',
  VOCABULARY_TRAINER = 'VOCABULARY_TRAINER',
}

export type License = {
  active_until: string;
  days_to_expire: number;
  expired: boolean;
  licence_id: string;
  test: boolean;
  type: string;
};

export interface Classroom {
  id: string;
  code: string;
  mainCourseId: string;
  instanceName: string;
  displayName: string;
  bookCover: {
    slug: string;
    image_url: string;
    name: string;
  };
  metadata: {
    title: string;
    book_name: string;
    color: string;
    feedback_privacy: string;
    competitive_quiz_privacy: string;
    location: string;
    teacher_title?: string;
    teacher_name?: string;
    inactive?: boolean;
  };
  license: License;
  licence: License;
}

export interface Competency {
  grammarCorrectness: number;
  grammarTotalQuestion: number;
  healthScore: number;
  listeningCorrectness: number;
  listeningTotalQuestion: number;
  points: number;
  pronunciationCorrectness: number;
  pronunciationTotalQuestion: number;
  readingCorrectness: number;
  readingTotalQuestion: number;
  speakingCorrectness: number;
  speakingPoints: number;
  speakingTotalQuestion: number;
  speakingTotalScore: number;
  vocabTotalQuestion: number;
  vocabularyCorrectness: number;
  vocabularyTasks: number;
  vocabularyTasksAverageCorrect: number;
  voiceTasks: number;
  wordsSpoken: number;
  graphValues: Array<{name: string; value: number}>;
}

export interface Student {
  emoji: string;
  classroom_color: string;
  classroom_id: string;
  code: string;
  id: string;
  level: string;
  locale: string;
  name: string;
  member_from: string;
  course_id: string;
}

export interface StudentCV {
  grammarCorrectness: number;
  grammarTotalQuestion: number;
  healthScore: number;
  listeningCorrectness: number;
  listeningTotalQuestion: number;
  points: number;
  pronunciationCorrectness: number;
  pronunciationTotalQuestion: number;
  readingCorrectness: number;
  readingTotalQuestion: number;
  speakingCorrectness: number;
  speakingPoints: number;
  speakingTotalQuestion: number;
  speakingTotalScore: number;
  vocabTotalQuestion: number;
  vocabularyCorrectness: number;
  vocabularyTasks: number;
  vocabularyTasksAverageCorrect: number;
  voiceTasks: number;
  wordsSpoken: number;
}

export interface StudentStatsItem {
  pages: string;
  activity: string;
  words?: number;
  correctly?: string;
  correct?: boolean;
  correct_text?: string;
  studentName: string;
  hasFeedback: boolean;
  messages: {
    text: string;
    isAudio: boolean;
    isSent: boolean;
    payload: {};
  }[];
  payload?: SpeakResponse.Content | VocubularyResponse.Content;
}

export type ContentMessageType = 'AI_SPEAKING' | 'VOCABULARY_TRAINER';

export interface StudentStats {
  total_elements: number;
  correctness: number;
  data: StudentStatsItem[];
}

export interface StudentSummary {
  schuler: string;
  healthScore: string;
  punkte: number;
  sprechen: number;
  vokabeln: number;
  korrekt: string;
}

export module SpeakResponse {
  export interface StudentInfo {
    id: string;
    classroom_id: string;
    name: string;
    user_status: string;
    emoji: string;
  }

  export interface Header {
    id: string;
    type: string;
    title: string;
    current: boolean;
  }

  export interface Metadata {
    duration: number;
    mime_type: string;
  }

  export interface Media {
    id: string;
    type: string;
    url: string;
    context: string;
    metadata: Metadata;
    source_url: string;
    entity: boolean;
  }

  export interface Message {
    media: Media;
    text: string;
  }

  export interface Metadata2 {
    duration: number;
    mime_type: string;
  }

  export interface Media2 {
    id: string;
    type: string;
    url: string;
    context: string;
    metadata: Metadata2;
    source_url: string;
    entity: boolean;
  }

  export interface SampleAnswer {
    media: Media2;
  }

  export interface Item {
    messages: Message[];
    options: any[];
    accept_input_types: string[];
    allow_multi_option: boolean;
    randomize_options: boolean;
    allow_other_option: boolean;
    confirm_before_proceed: boolean;
    item_type: string;
    required_expressions: string[];
    suggested_expressions: string[];
    required_display: string;
    suggested_display: string;
    min_answer_word_count: number;
    sample_answer: SampleAnswer[];
    more_suggested_expressions: string;
    prompt_translation: string;
    prompt_transcription: string;
  }

  export interface Content2 {
    items: Item[];
  }

  export interface ContentTag {
    name: string;
    type: string;
  }

  export interface Activity {
    header: Header;
    content: Content2;
    content_tags: ContentTag[];
  }

  export interface Metadata3 {
    mime_type: string;
  }

  export interface Media3 {
    id: string;
    type: string;
    url: string;
    context: string;
    metadata: Metadata3;
    source_url: string;
    entity: boolean;
  }

  export interface UserResponse {
    media: Media3;
  }

  export interface AiSpeakingAnalysisResult {
    present_required_expressions: string[];
    missing_required_expressions: any[];
    present_suggested_expressions: string[];
    word_count: number;
    distinct_present_required_expressions_count: number;
    total_required_expressions_count: number;
  }

  export interface TranscriptionInfo {
    transcription: string;
    duration: number;
    config: string;
    response: string;
    sent: any;
    request_time_millis: number;
    long_running_api: boolean;
    error_audio_too_long: boolean;
    unrecognized: boolean;
    provider: string;
  }

  export interface Item2 {
    user_response: UserResponse[];
    correct: boolean;
    index: number;
    tags: any[];
    score: number;
    ai_speaking_analysis_result: AiSpeakingAnalysisResult;
    transcription_info: TranscriptionInfo;
    status: string;
    advance_item: boolean;
    timestamp: any;
    used_hints: any[];
    unused_hints: string[];
    bonus_xp: number;
  }

  export interface AiSpeaking {
    items: Item2[];
  }

  export interface Metadata4 {
    mime_type: string;
  }

  export interface Media4 {
    id: string;
    type: string;
    url: string;
    context: string;
    metadata: Metadata4;
    source_url: string;
    entity: boolean;
  }

  export interface UserResponse2 {
    media: Media4;
  }

  export interface AiSpeakingAnalysisResult2 {
    present_required_expressions: string[];
    missing_required_expressions: any[];
    present_suggested_expressions: string[];
    word_count: number;
    distinct_present_required_expressions_count: number;
    total_required_expressions_count: number;
  }

  export interface TranscriptionInfo2 {
    transcription: string;
    duration: number;
    config: string;
    response: string;
    sent: any;
    request_time_millis: number;
    long_running_api: boolean;
    error_audio_too_long: boolean;
    unrecognized: boolean;
    provider: string;
  }

  export interface Attempt {
    user_response: UserResponse2[];
    correct: boolean;
    index: number;
    tags: any[];
    score: number;
    ai_speaking_analysis_result: AiSpeakingAnalysisResult2;
    transcription_info: TranscriptionInfo2;
    status: string;
    advance_item: boolean;
    timestamp: any;
    used_hints: any[];
    unused_hints: string[];
    bonus_xp: number;
  }

  export interface AttemptDetails {
    attempts: Attempt[];
  }

  export interface Details {
    ai_speaking: AiSpeaking;
    attempt_details: AttemptDetails;
  }

  export interface Derived {
    xp: number;
    skill: number;
    speaking_time: number;
    speaking_word_count: number;
    cheater_score: number;
    listening_total_score: number;
    listening_total_question: number;
    speaking_total_score: number;
    speaking_total_question: number;
    vocab_total_score: number;
    vocab_total_question: number;
  }

  export interface CourseInfo {
    course_id: string;
    unit_id: string;
    page_number_spec: string;
  }

  export interface Content {
    id: string;
    student_info: StudentInfo;
    activity: Activity;
    type: ContentMessageType | string;
    started_at: any;
    ended_at: any;
    created_at: any;
    details: Details;
    derived: Derived;
    course_info: CourseInfo;
    feedback: any[];
  }

  export interface Sort {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  }

  export interface Pageable {
    sort: Sort;
    page_size: number;
    page_number: number;
    offset: number;
    unpaged: boolean;
    paged: boolean;
  }

  export interface Sort2 {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  }

  export interface Response {
    correctness: number;
    content: Content[];
    pageable: Pageable;
    last: boolean;
    total_pages: number;
    total_elements: number;
    size: number;
    number: number;
    sort: Sort2;
    first: boolean;
    number_of_elements: number;
    empty: boolean;
  }
}

export module VocubularyResponse {
  export interface PrivacySettings {
    olympics_participant: boolean;
    requested_deletion: boolean;
  }

  export interface Info {
    id: string;
    name: string;
    name_or_fallback: string;
    mobile_id: string;
    roles: any[];
    locale: string;
    last_contact: any;
    status: string;
    privacy_settings: PrivacySettings;
  }

  export interface User {
    info: Info;
  }

  export interface Header {
    id: string;
    type: string;
    title: string;
    randomize_options: boolean;
    randomize_question_order: boolean;
    current: boolean;
  }

  export interface ContentTag {
    name: string;
    type: string;
  }

  export interface Activity {
    header: Header;
    content: {
      items: Item[];
    };
    content_tags: ContentTag[];
  }

  export interface Config {
    course_id: string;
    unit_id: string;
    page_number_spec: string;
    num_questions: number;
  }

  export interface UserResponse {
    text: string;
  }

  export interface Item {
    user_response: UserResponse[];
    correct: boolean;
    index: number;
    tags: any[];
    not_understood: boolean;
    status: string;
    advance_item: boolean;
    timestamp: any;
    message_id: string;
    attempt_index: number;
    chosen_options: number[];
    score?: number;

    messages: {text: string}[];
    options: any[];
    accept_input_types: any[];
    allow_multi_option: boolean;
    randomize_options: boolean;
    allow_other_option: boolean;
    confirm_before_proceed: boolean;
    item_type: string;
  }

  export interface QuestionId {
    id: string;
    updated_at: any;
  }

  export interface VocabularyTrainer {
    config: Config;
    items: Item[];
    question_ids: QuestionId[];
    cancelled: boolean;
    randomized: boolean;
  }

  export interface UserResponse2 {
    text: string;
  }

  export interface Attempt {
    user_response: UserResponse2[];
    correct: boolean;
    index: number;
    tags: any[];
    not_understood: boolean;
    status: string;
    advance_item: boolean;
    timestamp: any;
    message_id: string;
    attempt_index: number;
    chosen_options: number[];
    score?: number;
  }

  export interface AttemptDetails {
    attempts: Attempt[];
  }

  export interface Details {
    vocabulary_trainer: VocabularyTrainer;
    attempt_details: AttemptDetails;
  }

  export interface Derived {
    xp: number;
    skill: number;
    speaking_time: number;
    speaking_word_count: number;
    cheater_score: number;
  }

  export interface CourseInfo {
    course_id: string;
    unit_id: string;
    page_number_spec: string;
  }

  export interface Content {
    id: string;
    user: User;
    student_info: ClassroomStudentInfo;
    activity: Activity;
    type: ContentMessageType | string;
    started_at: any;
    ended_at: any;
    created_at: any;
    details: Details;
    derived: Derived;
    course_info: CourseInfo;
    feedback: any[];
  }

  export interface Sort {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  }

  export interface Pageable {
    sort: Sort;
    page_size: number;
    page_number: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  }

  export interface Sort2 {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  }

  export interface Response {
    correctness: number;
    content: Content[];
    pageable: Pageable;
    last: boolean;
    total_pages: number;
    total_elements: number;
    sort: Sort2;
    first: boolean;
    number_of_elements: number;
    size: number;
    number: number;
    empty: boolean;
  }
}

export interface PrivacySettings {
  olympics_participant: boolean;
  requested_deletion: boolean;
}

export interface ClassroomStudentInfo {
  id: string;
  classroom_id: string;
  locale: string;
  name: string;
  code: string;
  emoji: string;
  language_level: string;
}

export interface Competencies {
  vocabulary_tasks: number;
  vocabulary_tasks_average_correct: number;
  vocabulary_tasks_average: string;
  voice_tasks: number;
  words_spoken: number;
  points: number;
  health_score: number;
  health_score_average_desc: 'UP' | 'EQUAL' | 'DOWN' | '' | string;
  health_score_text: string;
  health_score_color: string;
}

export interface ClassroomStudent {
  student: ClassroomStudentInfo;
  competencies: Competencies;
}

export type ClassroomStudentRow = {
  has_compentence: boolean;
  student_id: string;
  student_name: string;
  student_code: string;
  student_emoji: string;
  student_classroom_id: string;
  competencies_health_score_color: string;
  competencies_health_score_text: string;
  competencies_health_score_average_desc: string;
  competencies_points: string | number;
  competencies_tasks: number;
  competencies_voice_tasks: string | number;
  competencies_vocabulary_tasks: string | number;
  competencies_vocabulary_tasks_average: string | number;
  language_level: string;
};

export interface AddedStudent {
  name: string;
  level: string;
}

export interface SubjectFeedbackType {
  label: string;
  value: string;
}

export interface UserProfileFeedbackType {
  bookName?: string;
  classroomId?: string;
  classroomName?: string;
  description: string;
  files?: any;
  logFiles: boolean;
  state?: string;
  subject: SubjectFeedbackType;
  userName: string;
  environment?: string;
  device?: string;
  os?: string;
}

export interface LicenseFeedbackType {
  user_id: string | undefined;
  user_name: string | undefined;
  refusal_reason: string;
  refusal_reason_other: string;
  accept_share: boolean;
  improvements_message: string;
}
