import styled from 'styled-components'

export const Image = styled.img`
  width: 58px;
  margin: 5px 0 0 -5px;
  border: 1px solid ${(props) => props.theme.colors.white};

  ${(props) => props.theme.mediaQueries.medium} {
    width: 111px;
  }

  @media screen and (min-width: 1300px) {
    /* width: 125px; */
    margin: 0;
  }
`
