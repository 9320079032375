import styled from 'styled-components';
import {CornelsenTheme as Theme} from '@cc/theme';

export const AppIcon = styled.img`
  width: 106px;
  height: 106px;
  margin-right: ${props => props.theme.spacing[0]};
  margin-bottom: ${props => props.theme.spacing[3]}px;

  ${props => props.theme.mediaQueries.medium} {
    order: 1;
    margin-right: 12px;
  }
`;

export const QrCode = styled.img`
  width: 96px;
  height: 96px;

  ${props => props.theme.mediaQueries.medium} {
    order: 2;
  }
`;

export const TextWithLink = styled.p`
  margin: 0 0 ${props => props.theme.spacing[5]}px;
  text-align: center;

  a {
    color: ${Theme.colors.primary};
  }

  ${props => props.theme.mediaQueries.medium} {
    margin-bottom: ${props => props.theme.spacing[0]};
    text-align: left;
  }
`;

export const style = {
  qrCode: {height: '96px', maxWidth: '100%', width: '96px'},
};
