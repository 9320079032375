import React from 'react';
import {useSelector} from 'react-redux';
import {Container} from 'cc-web-components';

import Images from '../../assets/images';
import {FooterLink, FooterReactLink} from './styles';
import {getIsHideFeaturesUser} from '@/redux/Session';
import {Routes} from '@cc/shared';

const {IMPRINT} = Routes;

const Footer = () => {
  const IsHideFeaturesUser = useSelector(getIsHideFeaturesUser);

  if (IsHideFeaturesUser) {
    return <></>;
  }

  return (
    <Container pb={2} flexDirection="column" justifyContent="center" alignItems="center">
      <Container flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center">
        <FooterReactLink to={IMPRINT}>Impressum</FooterReactLink>
        <FooterLink
          href="https://www.cornelsen.de/empfehlungen/chatclass/datenschutz"
          target="_blank"
          rel="noreferrer">
          Datenschutz
        </FooterLink>
        <FooterLink
          href="https://www.cornelsen.de/empfehlungen/chatclass/fuer-lehrer"
          target="_blank"
          rel="noreferrer">
          Hilfe
        </FooterLink>
        <FooterLink
          href="https://www.cornelsen.de/empfehlungen/chatclass"
          target="_blank"
          rel="noreferrer">
          Über uns
        </FooterLink>
        <FooterLink
          href="https://www.cornelsen.de/empfehlungen/chatclass/faq"
          target="_blank"
          rel="noreferrer">
          FAQ
        </FooterLink>
      </Container>
      <Container mt={4}>
        <img alt="ChatClass" src={Images.CVLogoHorizontal} height="auto" width="120px" />
      </Container>
    </Container>
  );
};

export default Footer;
