import React, { ChangeEvent, useState, useEffect } from 'react'

import Calendar from 'react-calendar'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Container, Text, InputMask } from 'cc-web-components'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import { format, parseISO, isValid, addHours } from 'date-fns'
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai'

import Helpers from '@/helper'
import { useTranslationContext } from '@/redux/Translation'
import { useAssignActivityContext } from '@/redux/AssignActivity'

import 'react-calendar/dist/Calendar.css'
import './styles.sass'

type Props = {
  setButtonActive: (buttonActive: boolean) => void
}

const TabThree: React.FC<Props> = ({ setButtonActive }: Props) => {
  const { t } = useTranslation('cornelsen')
  const { colors } = useTheme()
  const [
    { playlist, hasErrorStepThree, hasErrorStepThree1 },
    { updatePayloadForm, toggleErrorStepThree, toggleErrorStepThree1 },
  ] = useAssignActivityContext()
  const [{ language }] = useTranslationContext()

  const [sendTime, setSendTime] = useState(() => {
    if (!!playlist) {
      return format(playlist.info.due_date, 'HH:mm')
    }

    const date = new Date()
    const time = format(addHours(date, 2), 'HH:mm')

    return time
  })
  const [dateCalendar, setDateCalendar] = useState<Date | undefined>(() => {
    if (!!playlist) {
      return new Date(playlist.info.due_date)
    }

    return new Date()
  })
  const [dateSendInput, setDateSendInput] = useState(() => {
    if (!!playlist) {
      return format(playlist.info.due_date, 'dd.MM.yyyy')
    }

    return format(new Date(), 'dd.MM.yyyy')
  })

  useEffect(() => {
    if (!dateCalendar || !sendTime) return

    const sendTimeSanitized = sendTime.replaceAll('_', '')

    if (sendTimeSanitized.length < 5) {
      return
    }

    const sendTimeFormated = format(dateCalendar, 'yyyy-MM-dd')

    const due_date = Helpers.getDatetimeWithOffset(sendTimeFormated, sendTime)

    const selectedDatetime = new Date(due_date)

    const hasOneMinuteDifference = selectedDatetime.getTime() - new Date().getTime() < 1

    toggleErrorStepThree(hasOneMinuteDifference)

    updatePayloadForm({ due_date })
  }, [dateCalendar, sendTime, updatePayloadForm, toggleErrorStepThree])

  const onChangeDateSend = (event: ChangeEvent<HTMLInputElement>) => {
    setDateSendInput(event.target.value)

    const inputDate = event.target.value.replaceAll('_', '')

    if (inputDate.length !== 10) return

    const dateParsed = inputDate.split('.').reverse().join('-')

    const dateObject = parseISO(dateParsed)

    const dateIsInvalid = !isValid(dateObject)

    if (dateIsInvalid) {
      setDateCalendar(undefined)

      toggleErrorStepThree(true)

      return
    }

    setDateCalendar(dateObject)
  }

  const onChangeDateCalendar = (date: Date) => {
    const dateObject = format(date, 'dd.MM.yyyy')

    setDateSendInput(dateObject)

    setDateCalendar(date)
  }

  const onChangeTime = (event: ChangeEvent<HTMLInputElement>) => {
    setSendTime(event.target.value)
  }

  useEffect(() => {
    const dateSendInputFormated = dateSendInput.split('.').reverse().join('-')
    const due_date = Helpers.getDatetimeWithOffset(dateSendInputFormated, sendTime)
    const dateObject = new Date(due_date)
    const isDateValid = isValid(dateObject)
    const isDateBeforeOneMinute = dateObject.getTime() - new Date().getTime() < 1
    const hour = sendTime.split(':')[0]
    const min = sendTime.split(':')[1]
    const isTimeValid =
      Number(hour) < 25 && Number(min) < 60 && sendTime !== '' && !sendTime.includes('_')
    if (isDateValid && !isDateBeforeOneMinute && isTimeValid) setButtonActive(true)
    else setButtonActive(false)
    if (sendTime.includes('_') || sendTime === '') return
    // toggleErrorStepThree1(!isTimeValid)
  }, [dateSendInput, sendTime, setButtonActive, toggleErrorStepThree1])

  return (
    <Container flexDirection="column" paddingTop={3}>
      <Text
        size={16}
        marginLeft={2}
        fontWeight={600}
        value={t('AssignActivity.Tabs.NewTask.Step3.SectionDateTitle')}
      />

      <Container marginTop={2} padding={2} flexDirection="column">
        <Text value={t('AssignActivity.Tabs.NewTask.Step3.FieldDate')} />

        <InputMask
          mask="99.99.9999"
          onChange={onChangeDateSend}
          marginTop={2}
          placeholder={t('AssignActivity.Tabs.NewTask.Step3.FieldDatePlaceholder')}
          value={dateSendInput}
          style={{ borderRadius: 4 }}
          hasError={hasErrorStepThree}
          sideContent={<AiOutlineCalendar fontSize={20} color={colors.gray[2]} />}
        />
      </Container>

      <Container marginTop={2} marginBottom={2}>
        <Calendar
          locale={language}
          view="month"
          value={dateCalendar}
          next2Label={null}
          prev2Label={null}
          onClickDay={onChangeDateCalendar}
          nextLabel={<IoIosArrowForward size={25} />}
          prevLabel={<IoIosArrowBack size={25} />}
        />
      </Container>

      <Container height={24} backgroundColor="#f7f7f7" />

      <Container padding={2} marginTop={2} flexDirection="column">
        <Text value={t('AssignActivity.Tabs.NewTask.Step3.FieldTime')} />

        <InputMask
          mask="99:99"
          value={sendTime}
          onChange={onChangeTime}
          marginTop={2}
          style={{ borderRadius: 4 }}
          hasError={hasErrorStepThree1}
          sideContent={<AiOutlineClockCircle fontSize={20} color={colors.gray[2]} />}
        />
      </Container>

      <Container height={24} backgroundColor="#f7f7f7" />
    </Container>
  )
}

export default TabThree
