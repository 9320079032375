import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import Helpers from '@/helper';
import {Compentence} from '@/services/ApiCv/types/Competence';
import ApiStudentService from '@/services/ApiCv/ApiStudentService';

import CardsCompentencies from './CardsCompentencies';
import StudentActivitiesList from './StudentActivitiesList';

import {FilterTime} from '../types';

import './styles.css';

interface Props {
  color: string;
  bookId?: string;
  filterActived: number;
}

const LearningActivities: React.FC<Props> = ({color, bookId, filterActived}: Props) => {
  const dispatch = useDispatch();
  const [competences, setCompetences] = useState<Compentence>();
  const {classroomId, studentId} = useParams<{classroomId: string; studentId: string}>();
  const [datesFilterTime, setDatesFilterTime] = useState<FilterTime>();

  useEffect(() => {
    setDatesFilterTime(Helpers.getIntervalDatesByFilterIndex(filterActived) as FilterTime);
  }, [filterActived]);

  useEffect(() => {
    if (!studentId || !classroomId || !datesFilterTime) return;

    const params = {classroomId, studentId, filter: datesFilterTime};

    ApiStudentService.getCompetences(params).then(response => {
      setCompetences(response.content);
    });
  }, [dispatch, classroomId, studentId, datesFilterTime]);

  return (
    <>
      {!!competences && <CardsCompentencies competences={competences} />}

      {!!studentId && (
        <StudentActivitiesList
          color={color}
          bookId={bookId}
          activeFilter={filterActived}
          filtersOfTime={datesFilterTime}
          filterCallback={(filter: FilterTime) => setDatesFilterTime(filter)}
        />
      )}
    </>
  );
};

export default LearningActivities;
