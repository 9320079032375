import React from 'react'
import { useTheme } from 'styled-components'
import { Button, Container, Text } from 'cc-web-components'

import { InfoIconBlue, CloseBlueIcon } from '../Icons'

import { CloseButton, Wrapper } from './styles'

type Props = {
  title?: string
  description?: string
  cta?: string
  action?: () => void
  closeCallback: () => void
}

const Banner = ({ title, description, cta, action, closeCallback }: Props) => {
  const { colors } = useTheme()

  // const handleNavigation = () => {
  //   window.open(
  //     'https://outlook.office365.com/owa/calendar/CornelsenChatClassInterviewTerminbuchung@Cornelsen.onmicrosoft.com/bookings/'
  //   )
  // }

  return (
    <Wrapper id="banner">
      <Container p={2} backgroundColor={colors.primary} justifyContent="center" alignItems="center">
        <InfoIconBlue width={50} />
      </Container>
      <Container p={3} alignItems="center" width={'100%'} justifyContent={'space-between'}>
        <Container flexDirection="column">
          {title && <Text fontSize={16} fontWeight={600} value={title} />}
          {description && <Text fontSize={16} value={description} />}
        </Container>
        <Container ml={3} justifyContent="center" alignItems="center">
          {cta && action && (
            <Button
              width={180}
              height={50}
              mr={3}
              variant="solid"
              type="button"
              value={<Text fontSize={18} fontWeight={400} color={colors.white} value={cta} />}
              onClick={action}
            />
          )}

          <CloseButton onClick={closeCallback}>
            <CloseBlueIcon width={30} />
          </CloseButton>
        </Container>
      </Container>
    </Wrapper>
  )
}

export default Banner
