import { useEffect, useState } from 'react'

const useDeviceDetection = () => {
  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isMobile }
}

export default useDeviceDetection
