import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card, Container, Text} from 'cc-web-components';

import useDeviceDetection from '@/hooks/IsMobile';
import ScoreIconCount from '@/components/ScoreIconCount';

import {Props} from './types';
import {EmptyMessage} from './styles';

export const HealthScore: React.FC<Props> = ({score}) => {
  const {isMobile} = useDeviceDetection();
  const {t} = useTranslation('cornelsen');

  const bodyContent = score ? (
    <Container alignItems="center" justifyContent="center" flex={1}>
      <Text
        value={`${score.activeStudents}`}
        fontFamily="CV_Source_Sans"
        size={16}
        color="#0064AA"
        mr={1}
      />
      <Text
        value={` / ${score.totalStudents}`}
        size={16}
        fontFamily={'CV_Source_Sans'}
        color="#0064AA"
      />
      <Text
        value={t('Classroom.BoxHealthScoreText')}
        ml={2}
        fontFamily={'CV_Source_Sans'}
        size={16}
        color="#0064AA"
      />
    </Container>
  ) : (
    <EmptyMessage>
      <Container pl={'10px'} pr={'10px'}>
        <p>{t('Home.SectionActivities.CardHealthScore.EmptyContent')}</p>
      </Container>
    </EmptyMessage>
  );

  const footerContent = score ? (
    <Container flexDirection="row" justifyContent="center" padding={11} style={{gap: '16px'}}>
      <ScoreIconCount color="green" score={score.greenScore} />
      <ScoreIconCount color="yellow" score={score.yellowScore} />
      <ScoreIconCount color="red" score={score.redScore} />
    </Container>
  ) : null;

  return (
    <Card
      title={t('Home.SectionActivities.CardHealthScore.Title')}
      flex={1}
      width={!isMobile ? '30%' : '100%'}
      height="180px"
      footerContent={footerContent}>
      {bodyContent}
    </Card>
  );
};
