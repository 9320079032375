import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Text} from 'cc-web-components';

import Helpers from '@/helper';
import useDeviceDetection from '@/hooks/IsMobile';
import {ActivityResponse} from '@/services/ApiCv/types/Activity';
import ApiHomePageService from '@/services/ApiCv/ApiHomePageService';
import TableStudentActivities from '@/components/TableStudentActivities';
import {getLicense, startLoading, stopLoading} from '@/redux/Session';
import {useTranslation} from 'react-i18next';

import {FilterTime} from '../../types';
import {Classrooms} from '@/pages/Home/types';

type Props = {
  color: string;
  activeFilter: number;
  filtersOfTime: FilterTime;
  showMore?: string;
  classrooms?: Classrooms;
};

const SpeakingAssignments: React.FC<Props> = ({
  color,
  activeFilter,
  filtersOfTime,
  showMore,
  classrooms,
}: Props) => {
  const dispatch = useDispatch();
  const {isMobile} = useDeviceDetection();
  const license = useSelector(getLicense);
  const {t} = useTranslation('cornelsen');

  const [activitiesData, setActivitiesData] = useState<ActivityResponse>();
  const [datesFilterTime, setDatesFilterTime] = useState<FilterTime>(filtersOfTime);
  const [reload, setReload] = useState(false);
  const [activeTableFilter] = useState(0);

  console.log(activitiesData);

  useEffect(() => {
    setDatesFilterTime(filtersOfTime);
  }, [filtersOfTime]);

  useEffect(() => {
    dispatch(startLoading());

    ApiHomePageService['getAllSpeakingAssignments']()
      .then(data => {
        setActivitiesData(data);
        dispatch(stopLoading());
      })
      .catch(() => {
        dispatch(stopLoading());
      });
  }, [dispatch, reload]);

  const onGetPrevValues = () => {
    const newIntervalTime = Helpers.getPrevIntervalDates(activeFilter, datesFilterTime);

    setDatesFilterTime(newIntervalTime);
  };

  const onGetNextValues = () => {
    const newIntervalTime = Helpers.getNextIntervalDates(activeFilter, datesFilterTime);

    setDatesFilterTime(newIntervalTime);
  };

  return (
    <Container flexDirection="column" width="100%">
      <Container
        mt={5}
        flexDirection={isMobile ? 'column' : ''}
        alignItems="center"
        justifyContent="space-between">
        <Text size={20} value={t('Home.SectionLastestSpeaking.Title')} fontWeight={600} />
      </Container>

      {!!activitiesData && (
        <TableStudentActivities
          parent="CLASSROOM"
          showMore={showMore}
          color={color}
          data={activitiesData}
          filterPageActived={activeFilter}
          activeTableFilter={activeTableFilter}
          datesFilterTime={datesFilterTime}
          onNextValues={onGetNextValues}
          onPrevValues={onGetPrevValues}
          classrooms={classrooms}
          feedbackCallback={() => setReload(prev => !prev)}
          hideFeedbackForm={license?.expired === true}
        />
      )}
    </Container>
  );
};

export default SpeakingAssignments;
