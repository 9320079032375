import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, Container, ProgressBar, Text } from 'cc-web-components'

import { HealthScoreResponse } from '../../types'
import PronunciationScoreInfoBox from '@/components/InfoBox/PronunciationScore'
import { ChevronDownLined, ChevronUpLined } from '@/components/Icons'
import { ProgressBarContainer, ActivityAndFrequencyContainer } from './styles'

type Props = {
  healthScore: HealthScoreResponse
}

const ProgressBarComponent: React.FC<Props> = ({ healthScore }: Props) => {
  const { t } = useTranslation('cornelsen')
  const { colors } = useTheme()
  const [showPronunciationScore, setShowPronunciationScore] = useState(false)

  const TextWithValue = ({ title = '', description = '', up = true }) => {
    const color = up ? colors.green : colors.red

    return (
      <Container>
        <Text size={16} fontWeight={600} value={title} />

        {description && (
          <Text size={16} marginLeft={3} value={`${up ? '+' : '-'}${description}`} color={color} />
        )}
      </Container>
    )
  }

  const getColorActivity = () => {
    if (healthScore.activities.current < 0.5) {
      return 'red'
    }
    if (healthScore.activities.current < 1.5) {
      return 'yellow'
    }
    return 'green'
  }

  const getColorFrequency = () => {
    if (healthScore.frequency.current < 0.5) {
      return 'red'
    }
    if (healthScore.frequency.current < 1.5) {
      return 'yellow'
    }
    return 'green'
  }

  return (
    <ProgressBarContainer>
      <ProgressBar
        mt={0}
        flex={1}
        value={healthScore.meta.speaking_total_score}
        color="primary"
        title={
          <Container alignItems="center">
            <Text
              size={16}
              fontWeight={600}
              value={t('Student.TabProgress.SectionHealthScore.GraphBars.SpeakTitle')}
              onClick={() => setShowPronunciationScore(!showPronunciationScore)}
              style={{ cursor: 'pointer' }}
            />
            <Button
              height="100%"
              variant="link"
              leftContent={
                showPronunciationScore ? (
                  <ChevronUpLined width={20} />
                ) : (
                  <ChevronDownLined width={20} />
                )
              }
              onClick={() => setShowPronunciationScore(!showPronunciationScore)}
            />
          </Container>
        }
      />

      {showPronunciationScore && (
        <Container flex={1} display="flex" flexDirection="column" backgroundColor="#eee" pb={3}>
          <ProgressBar
            ml={4}
            mt={2}
            flex={1}
            value={healthScore.meta.pronunciation_score}
            color="primary"
            title={
              <Container>
                <Text size={16} fontWeight={600} value={'Aussprachetraining'} mr={2} />
                <PronunciationScoreInfoBox />
              </Container>
            }
            style={{ justifyContent: 'space-around' }}
          />

          <Container justifyContent="space-between" ml={4} mt={2}>
            <Text size={16} value={'Word Accuracy'} />
            <Text size={16} value={`${healthScore.meta.pronunciation_accuracy_score} %`} mr={2} />
          </Container>

          <Container justifyContent="space-between" ml={4} mt={2}>
            <Text size={16} value={'Fluency'} />
            <Text size={16} value={`${healthScore.meta.pronunciation_fluency_score} %`} mr={2} />
          </Container>

          <Container justifyContent="space-between" ml={4} mt={2}>
            <Text size={16} value={'Completeness'} />
            <Text
              size={16}
              value={`${healthScore.meta.pronunciation_completeness_score} %`}
              mr={2}
            />
          </Container>
        </Container>
      )}

      <ProgressBar
        mt={30}
        flex={1}
        value={healthScore.meta.listening_total_score}
        color="primary"
        title={
          <Text
            size={16}
            fontWeight={600}
            value={t('Student.TabProgress.SectionHealthScore.GraphBars.ListenTitle')}
          />
        }
      />

      <ProgressBar
        mt={30}
        flex={1}
        value={healthScore.meta.vocab_total_score}
        color="primary"
        title={
          <Text
            size={16}
            fontWeight={600}
            value={t('Student.TabProgress.SectionHealthScore.GraphBars.VocabTitle')}
          />
        }
      />

      <ActivityAndFrequencyContainer color={getColorActivity()}>
        <ProgressBar
          mt={50}
          flex={1}
          value={healthScore.meta.activities_score}
          color={getColorActivity()}
          title={
            <TextWithValue
              up={healthScore.meta.activities_diff_score_up}
              title={t('Student.TabProgress.SectionHealthScore.GraphBars.ActivityTitle')}
            />
          }
        />
      </ActivityAndFrequencyContainer>

      <ActivityAndFrequencyContainer color={getColorFrequency()}>
        <ProgressBar
          mt={30}
          flex={1}
          value={healthScore.meta.frequency_score}
          color={getColorFrequency()}
          title={
            <TextWithValue
              up={healthScore.meta.frequency_diff_score_up}
              title={t('Student.TabProgress.SectionHealthScore.GraphBars.RegularityTitle')}
            />
          }
        />
      </ActivityAndFrequencyContainer>
    </ProgressBarContainer>
  )
}

export default ProgressBarComponent
