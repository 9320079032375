import styled from 'styled-components'
import { Text } from 'cc-web-components'

export const StyledContainer = styled.div<{ rotate: number }>`
  position: absolute;
  height: 100%;
  transform: ${(props) => `rotate(${props.rotate}turn)`};
`

export const StyledSeparator = styled.div`
  background: #d5d5d5;
  width: 2px;
  height: 2%;
  margin-top: -15px;
`

export const StyledNumber = styled.div`
  margin-top: -45px;
  font-weight: bold;
`

export const StyledNumberText = styled(Text)<{ rotate: number }>`
  transform: ${(props) => (props.rotate ? `rotate(${props.rotate}deg)` : '')};
`
