import styled from 'styled-components';

export const CloseButton = styled.button`
  z-index: 30;
  position: absolute;
  top: 0px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const ModalInnerContainer = styled.div`
  flex-direction: column;
  outline: none;
  padding: 32px 48px 32px 48px;
  align-items: center;
  box-sizing: border-box;
`;

export const Carousel = styled.div<{isActive: boolean}>`
  width: 12px;
  height: 12px;
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: 9999px;
  background: ${props => (props.isActive ? props.theme.colors.black : props.theme.colors.white)};
`;

export const TableTaskActivityContainer = styled.div`
  #tableTaskActivity {
    table {
      thead {
        display: none;
      }
    }
  }
`;

export const style = {
  container: {
    gap: '8px',
    maxHeight: '90vh',
    overflowY: 'auto',
    padding: '0px 72px 24px 72px',
    boxSizing: 'border-box',
  },
  columnHeader: {
    textWrap: 'wrap',
    whiteSpace: 'pre-wrap',
  },
  columnItem: {
    textWrap: 'wrap',
    whiteSpace: 'pre-wrap',
  },
};
