import React, {createContext, useEffect, useContext, useState, useCallback} from 'react';
import i18nextInstance from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './languages/en.json';
import de from './languages/de.json';

import {
  LanguageTypesEnum,
  TranslationStateType,
  TranslationContextType,
  TranslationProviderType,
  TranslationDispatchType,
} from './types';

i18nextInstance.use(initReactI18next).init({
  resources: {
    de,
    en,
  },
  lng: 'de',
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false,
  },
});

const TranslationContext = createContext({} as TranslationContextType);

export const TranslationProvider: React.FC<TranslationProviderType> = ({children}) => {
  const [language, setLanguage] = useState<LanguageTypesEnum>();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let Language = params.get('language');

    if (!!Language) {
      setLanguage(Language as LanguageTypesEnum);
      return;
    }

    setLanguage(LanguageTypesEnum.DE);
  }, []);

  useEffect(() => {
    i18nextInstance.changeLanguage(language);
  }, [language]);

  const changeLanguage = useCallback((language: LanguageTypesEnum) => {
    setLanguage(language);
  }, []);

  const state = {language} as TranslationStateType;

  const dispatch = {changeLanguage} as TranslationDispatchType;

  return (
    <TranslationContext.Provider value={[state, dispatch]}>{children}</TranslationContext.Provider>
  );
};

export function useTranslationContext(): TranslationContextType {
  return useContext(TranslationContext);
}
