import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Text, Select } from 'cc-web-components'

import Images from '@/assets/images'
import { LicenseType } from '@/services/ApiCv/ApiLicenseService'

type Props = {
  licenses: LicenseType[]
  setButtonActive: (active: boolean) => void
  updateSelectedLicense: (licenseId: string) => void
}

const LicenseSelect: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation('cornelsen')

  const [indexLicense, setIndexLicense] = useState(-1)

  function handleSelectLicense(license: LicenseType, index: number) {
    props.updateSelectedLicense(license.licence_id)
    setIndexLicense(index)

    props.setButtonActive(index >= 0)
  }

  function renderSelectItem(item: LicenseType) {
    let date = ''

    try {
      date = item.active_until.slice(0, 10).slice(0, 10).split('-').reverse().join('.')
    } catch (error) {
      date = ''
    }

    if (item.type === 'CCPPK' || item.type === 'CCWAL') {
      return <Text value={`Klassenlizenz - gültig bis  ${date}`} />
    }

    return <Text value={`Schullizenz - gültig bis ${date}`} />
  }

  return (
    <Container flex={1} flexDirection="column" backgroundColor="#fff">
      <Container
        minHeight={120}
        alignItems="center"
        justifyContent="center"
        backgroundColor="rgb(248 248 248)"
      >
        <Text
          value={t('AssignActivity.Tabs.NewClass.StepLicenseSelect.Title')}
          fontSize={24}
          fontWeight={400}
        />
      </Container>

      <Container padding={20} flexDirection="column" flex={1}>
        <Text
          value={t('AssignActivity.Tabs.NewClass.StepLicenseSelect.SubTitle')}
          fontSize={16}
          marginTop={15}
          fontWeight={600}
        />

        <Text
          value={t('AssignActivity.Tabs.NewClass.StepLicenseSelect.Description')}
          fontSize={16}
          marginTop={15}
        />

        <Container marginTop={50}>
          <Select
            items={props.licenses}
            renderItem={(item: LicenseType) => renderSelectItem(item)}
            onSelectItem={(item: LicenseType, index: number) => handleSelectLicense(item, index)}
            selectedIndex={indexLicense}
            placeholder={t('AssignActivity.Tabs.NewClass.StepLicenseSelect.PlaceholderSelect')}
          />
        </Container>
      </Container>

      <Container
        padding={20}
        minHeight={204}
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        backgroundColor="rgb(248 248 248)"
      >
        <img src={Images.IdeaBlue} alt="" height={45} width={45} />

        <Text
          color="#888"
          value={t('AssignActivity.Tabs.NewClass.StepLicenseEmpty.Description')}
          fontSize={16}
          textAlign="center"
          marginTop={16}
          fontWeight={400}
        />
      </Container>
    </Container>
  )
}

export default LicenseSelect
