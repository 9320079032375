import {Text} from '@cc/components';
import styled from '@emotion/styled';

export const Container = styled.div`
  padding-top: ${({theme}) => theme.spacings.spacing075};
  padding-left: ${({theme}) => theme.spacings.spacing100};
  padding-right: ${({theme}) => theme.spacings.spacing100};
  padding-bottom: ${({theme}) => theme.spacings.spacing075};
  background-color: ${({theme}) => theme.colors.backgroundSignalWarning};
  margin-bottom: ${({theme}) => theme.spacings.spacing100};
`;

export const CustomText = styled(Text)`
  ${({theme}) => theme.typography.mediumBody050};
  color: ${({theme}) => theme.colors.foregroundOnSignalWarning};
`;
