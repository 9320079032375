import { format } from 'date-fns'
import { PlaylistProps } from './types'

function getColorStatus(status: 'DONE' | 'PENDING' | 'STARTED' | 'FINISHED') {
  switch (status) {
    case 'DONE': {
      return '#00AA64'
    }
    case 'FINISHED': {
      return '#00AA64'
    }
    case 'STARTED': {
      return '#000'
    }
    case 'PENDING': {
      return '#e53935'
    }
    default: {
      return '#000'
    }
  }
}

function getStatusTranslated(status: 'DONE' | 'PENDING' | 'STARTED' | 'FINISHED') {
  switch (status) {
    case 'DONE': {
      return 'Completed'
    }
    case 'FINISHED': {
      return 'Completed'
    }
    case 'STARTED': {
      return 'Progress'
    }
    case 'PENDING': {
      return 'Opened'
    }
    default: {
      return 'Opened'
    }
  }
}

export function getPlaylists(data: any) {
  const playlists = []

  for (let item of data) {
    const datetimeTarget = new Date(item.info.due_date)

    const total_students = item.students.length
    const total_students_ok = item.students.filter(
      (student: any) => student.status === 'FINISHED'
    ).length
    const total_activities = item.activities.length

    const students = item.students.map((student: any) => {
      const status_color = getColorStatus(student.status)
      const status_translated = getStatusTranslated(student.status)

      return {
        ok: student.status === 'FINISHED',
        id: student.user.id,
        emoji: student.user.emoji,
        name: student.user.name,
        classroom_id: student.user.classroom_id,
        status: student.status,
        status_translated: status_translated,
        status_number: (student.correctness * 100).toFixed(0),
        status_color,
        all_activities_ok: total_activities === student.completed_activities,
        total_activities: total_activities,
        total_activities_ok: student.completed_activities,
        playlist_id: student.playlist_id,
        has_feedback: student.has_feedback,
        selected: false,
      }
    })

    const tasks = item.activities.map((activity: any) => {
      activity.feedbacks = []

      activity.total_xp = !!activity.items.length ? activity.items[0].total_xp : 0

      return {
        task_type: activity.type,
        task_title: activity.title,
        seite_number: activity.page,
        group_percent: (activity.correctness * 100).toFixed(0),
        total_students: total_students,
        total_students_ok: activity.completed_count,
        payload: activity,
      }
    })

    const playlist = {
      id: item.info.id,
      createdAt: item.info.created,
      dateTime: datetimeTarget,
      date: format(datetimeTarget, 'dd/MM/yyyy'),
      time: format(datetimeTarget, 'HH:mm'),
      seite_number: item.info.pages,
      task_title: item.info.name,
      correctness: (item.correctness * 100).toFixed(0),
      goal: item.info.goal,
      total_students,
      total_students_ok,
      students,
      tasks,
      payload: item,
    } as PlaylistProps

    playlists.push(playlist)
  }

  return playlists
}
