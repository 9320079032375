import React from 'react';

import Images from '@/assets/images';

import {ScoreText, SmilyWrapper, StyledScoreIconExample} from './styles';

const ScoreIconExample: React.FC = () => {
  return (
    <StyledScoreIconExample>
      <SmilyWrapper color={'green'}>
        <img src={Images.HighScoreSmileyIcon} alt="" />
        <ScoreText value={'10 - 8'} />
      </SmilyWrapper>

      <SmilyWrapper color={'yellow'}>
        <img src={Images.MidHighScoreSmileyIcon} alt="" />
        <ScoreText value={'7,9 - 6'} />
      </SmilyWrapper>
      <SmilyWrapper color={'yellow'}>
        <img src={Images.MiddleScoreSmileyIcon} alt="" />
        <ScoreText value={'5,9 - 4'} />
      </SmilyWrapper>
      <SmilyWrapper color={'red'}>
        <img src={Images.BadScoreSmileyIcon} alt="" />
        <ScoreText value={'3,9 - 2'} />
      </SmilyWrapper>
      <SmilyWrapper color={'red'}>
        <img src={Images.VeryBadScoreSmileyIcon} alt="" />
        <ScoreText value={'1,9 - 0'} />
      </SmilyWrapper>
    </StyledScoreIconExample>
  );
};

export default ScoreIconExample;
