import React, { createContext, useCallback, useContext, useState } from 'react'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'

import Service from '@/services/ApiCv'

import Helpers from '@/helper'
import {
  PlaylistProps,
  StepErrorType,
  CreateClassProps,
  PlaylistCreateProps,
  AssignActivityState,
  PlaylistPayloadProps,
  AssignActivityDispatch,
  AssignActivityContextData,
  AssignActivityProviderProps,
} from './types'
import { getContentPlaylist, getContentPlaylistCreate } from './helpers'

import { getUser } from '../Session'

const AssignActivityContext = createContext({} as AssignActivityContextData)

export const AssignActivityProvider: React.FC<AssignActivityProviderProps> = ({ children }) => {
  const [payload, setPayload] = useState<PlaylistPayloadProps>()
  const [playlist, setPlaylist] = useState<PlaylistProps>()
  const [onOpened, setOnOpened] = useState(false)
  const [onOpenedAssignLicense, setOnOpenedAssignLicense] = useState(false)
  const [stepError, setStepError] = useState<StepErrorType>()
  const [playlistCreate, setPlaylistCreate] = useState<PlaylistCreateProps>()
  const [hasErrorStepThree, setHasErrorStepThree] = useState(false)
  const [hasErrorStepThree1, setHasErrorStepThree1] = useState(false)
  const [isEditClass, setIsEditClass] = useState(false)
  const [isActivateClass, setIsActivateClass] = useState(false)
  const [isOnboarding, setIsOnboarding] = useState(false)
  const [onboardingStepIndex, setOnboardingStepIndex] = useState(0)
  const [activateClassLicenseId, setActivateClassLicenseId] = useState('')
  const [classroomId, setClassroomId] = useState('')
  const [playlistBookId, setPlaylistBookId] = useState('')
  const [classroomCreation, setClassroomCreation] = useState<CreateClassProps>()

  const user = useSelector(getUser)

  const savePlaylist = useCallback(async () => {
    if (!payload || !user || !user.id) return

    const content = getContentPlaylistCreate(payload, user.id)

    await Service.addPlaylist(content)
    setPlaylist(undefined)
    setPayload(undefined)
    setPlaylistCreate(undefined)
  }, [payload, user])

  const toggleVisibleForm = useCallback(() => {
    setOnOpened((prev) => !prev)
    setOnOpenedAssignLicense(false)
  }, [])

  const toggleAssignLicense = useCallback(() => {
    setOnOpened((prev) => !prev)
    setOnOpenedAssignLicense(true)
  }, [])

  const toggleIsEditClass = useCallback(() => {
    setIsEditClass((prev) => !prev)
  }, [])

  const toggleIsActivateClass = useCallback(() => {
    setIsActivateClass((prev) => !prev)
  }, [])

  const toggleIsOnboarding = useCallback(() => {
    setIsOnboarding((prev) => !prev)
  }, [])

  const handleOnboardingStepIndex = useCallback((index: number) => {
    setOnboardingStepIndex(index)
  }, [])

  const toggleErrorStepThree = useCallback((value = true) => {
    setHasErrorStepThree(value)
  }, [])

  const toggleErrorStepThree1 = useCallback((value = false) => {
    setHasErrorStepThree1(value)
  }, [])

  const updatePayloadForm = useCallback((data: any) => {
    setPayload((prev: any) => ({ ...prev, ...data }))
  }, [])

  const clearPayloadForm = useCallback(() => {
    setPayload(undefined)
  }, [])

  const clearPlaylistForm = useCallback(() => {
    setPlaylist(undefined)
    setPlaylistCreate(undefined)
  }, [])

  const onLoadPlaylistToEdit = useCallback((data: any) => {
    const dueDate = format(data.info.due_date, 'yyyy-MM-dd')
    const dueTime = format(data.info.due_date, 'HH:mm')

    const due_date = Helpers.getDatetimeWithOffset(dueDate, dueTime)

    const student_ids = data.students.map((item: any) => item.user.id)

    const activities = data.activities.map((activity: any) => {
      return {
        id: activity.id,
      }
    })

    const [unit] = data.info.content.modules[0].units

    const payload = {
      due_date,
      name: data.info.name,
      bookId: data.info.course_id,
      classroom_id: data.info.classroom_id,
      teacher_message: data.info.teacher_message,
      goal: data.info.goal,
      section: {
        section_name: `Seite ${data.info.pages}`,
      },
      unit: {
        id: unit.id,
        name: unit.name,
      },
      activities,
      student_ids,
    }

    setPayload((prev: any) => ({ ...prev, ...payload }))

    setPlaylist(data)
  }, [])

  const updatePlaylist = useCallback(async () => {
    if (!playlist || !payload) return

    const content = getContentPlaylist(payload)

    const data = {
      content,
      name: payload.name,
      due_date: payload.due_date,
      student_ids: payload.student_ids,
      teacher_message: payload.teacher_message,
      goal: payload.goal,
    }

    await Service.updatePlaylist(playlist?.info.id, data)
    setPlaylist(undefined)
    setPayload(undefined)
    setPlaylistCreate(undefined)
  }, [playlist, payload])

  const onLoadPlaylistToCreate = useCallback((data: PlaylistProps) => {
    setPayload((prev: any) => ({ ...prev, ...data }))
    setPlaylistCreate((prev: any) => ({ ...prev, ...data }))
  }, [])

  const toggleErrorSteps = useCallback((payload: StepErrorType | undefined) => {
    setStepError(payload)
  }, [])

  const updateClassroomIdToReactiveLicense = (classroomId: string) => {
    setClassroomId(classroomId)
  }

  const updateLicenseIdToActiveClass = useCallback((licenseId: string) => {
    setActivateClassLicenseId(licenseId)
  }, [])

  const updatePlaylistBookId = useCallback((courseId: string) => {
    setPlaylistBookId(courseId)
  }, [])

  const state = {
    payload,
    classroomCreation,
    onOpened,
    onOpenedAssignLicense,
    isEditClass,
    isActivateClass,
    isOnboarding,
    onboardingStepIndex,
    playlist,
    playlistCreate,
    hasErrorStepThree,
    hasErrorStepThree1,
    stepError,
    classroomId,
    playlistBookId,
    activateClassLicenseId,
  } as AssignActivityState

  const dispatch = {
    savePlaylist,
    setClassroomCreation,
    updatePlaylist,
    clearPayloadForm,
    clearPlaylistForm,
    updatePayloadForm,
    toggleVisibleForm,
    toggleAssignLicense,
    toggleIsEditClass,
    toggleIsActivateClass,
    toggleIsOnboarding,
    handleOnboardingStepIndex,
    onLoadPlaylistToEdit,
    toggleErrorStepThree,
    toggleErrorStepThree1,
    onLoadPlaylistToCreate,
    toggleErrorSteps,
    updateClassroomIdToReactiveLicense,
    updatePlaylistBookId,
    updateLicenseIdToActiveClass,
  } as AssignActivityDispatch

  return (
    <AssignActivityContext.Provider value={[state, dispatch]}>
      {children}
    </AssignActivityContext.Provider>
  )
}

export function useAssignActivityContext(): AssignActivityContextData {
  return useContext(AssignActivityContext)
}
