import styled from 'styled-components'

import Images from '../../assets/images/product-benefits'

export const Modal = styled.div`
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #fff;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - 69px);
  overflow-y: auto;
`;

export const SubTitle = styled.h2`
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 300;
  text-align: center;

  strong {
    font-weight: 700;
  }
`;

export const ModalFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 15px 0;
  background: #fff;
  box-shadow: 0 -3px 7px 0px rgba(0, 0, 0, .1);
  box-sizing: border-box;

  & > div {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    max-width: 930px;
  }

  @media screen and (min-width: 768px) {
    height: 69px;
    padding: 0 18px;

    & > div {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`

export const TeacherGrid = styled.div`
  display: grid;
  gap: 10px;
  width: 100%;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    margin-bottom: 66px;
  }

  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  grid-template-areas:
    "a a"
    "c d"
    "b b"
    "e e"
  ;

  div {
    display: flex;
    flex-direction: column;    
    background: #fafafa;
    cursor: pointer;
    transition: all .4s ease;

    &:hover {
      transform: translate3D(0,-1px,0) scale(1.03);      
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      color: #000;
    }

    p {
      font-size: 16px;
      color: #2B323B;
    }
  }

  div:nth-child(1) {
    grid-area: a;
    height: 307px;      
    background: url(${Images.HealthScore}) no-repeat left bottom #fafafa;
    background-size: 245px auto;
    align-items: flex-end;

    h3 {
      max-width: 300px;
      margin: 30px 30px 10px 0;
      font-size: 20px;
      text-align: right;
    }

    p {
      max-width: 200px;
      margin-right: 30px;
      text-align: right;
    }
  }

  div:nth-child(2) {
    grid-area: b;
    height: 222px;
    background: url(${Images.Feedback}) no-repeat right bottom 52px #fafafa;
    background-size: 154px auto;    

    h3 {
      max-width: 180px;
      margin: 30px 0 10px 30px;
    }

    p {
      max-width: 180px;
      margin-left: 30px;
    }
  }

  div:nth-child(3) {
    grid-area: c;
    align-items: center;
    height: 239px;

    img {
      margin: 20px 0 15px;
    }

    h3 {
      text-align: center;
      margin: 0 25px 10px;
    }

    p {
      text-align: center;
      margin: 0 25px 10px;
    }
  }

  div:nth-child(4) {
    grid-area: d;
    align-items: center;

    img {
      margin: 20px 0 5px;
    }

    h3 {
      text-align: center;
      margin: 0 25px 10px;
    }

    p {
      text-align: center;
      margin: 0 25px 15px;
    }

    span {
      display: flex;
      padding: 2px 10px;
      border-radius: 10px;
      color: #6cdd00;
      background: rgba(0, 0, 0, .05);
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  div:nth-child(5) {
    grid-area: e;
    height: 288px;
    background: url(${Images.Tasks}) no-repeat right 20px bottom #fafafa;
    background-position: right -50px bottom;

    h3 {
      max-width: 170px;
      margin: 40px 0 10px 30px;
    }

    p {
      max-width: 200px;
      margin-left: 30px;
    }
  }

  @media screen and (min-width: 768px) {
    gap: 20px;
    grid-template-columns: calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    grid-template-areas:
      "a a c d"
      "a a e e"
      "b b e e"
    ;

    div:nth-child(1) {
      height: 307px;      
    }

    div:nth-child(2) {
      background-position-y: 55px;
    }

    div:nth-child(3) {}

    div:nth-child(4) {
      h3 {
        margin: 0 5px 10px;
      }
    }

    div:nth-child(5) {
      height: auto;  
      
      p {
        max-width: 170px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    gap: 30px;
    grid-template-columns: 210px 210px 210px 210px;
    grid-template-areas:
      "a a c d"
      "a a e e"
      "b b e e"
    ;

    div:nth-child(1) {
      height: 307px;
      background-size: auto auto;
    }

    div:nth-child(2) {
      height: 255px;
      background-size: auto auto;
      background-position: right bottom 52px;
    }

    div:nth-child(3) {
      height: 239px;
    }

    div:nth-child(4) {}

    div:nth-child(5) {}
  }
`

export const StudentGrid = styled.div`
  display: grid;
  gap: 10px;
  width: 100%;
  padding-bottom: 26px;

  @media screen and (min-width: 768px) {
    padding-bottom: 100px;
  }

  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  grid-template-areas:
    "a b"
    "d d"
    "c c"
    "e e"
  ;

  div {
    display: flex;
    flex-direction: column;    
    background: #fafafa;
    cursor: pointer;
    transition: all .4s ease;

    &:hover {
      transform: translate3D(0,-1px,0) scale(1.03);      
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      color: #000;
    }

    p {
      font-size: 16px;
      color: #2B323B;
    }
  }

  div:nth-child(1) {
    grid-area: a;
    align-items: center;
    height: 239px;

    img {
      margin: 20px 0 15px;
    }

    h3 {
      text-align: center;
      margin: 0 25px 10px;
    }

    p {
      text-align: center;
      margin: 0 25px 10px;
    }
  }

  div:nth-child(2) {
    grid-area: b;
    align-items: center;

    img {
      margin: 20px 0 15px;
    }

    h3 {
      text-align: center;
      margin: 0 25px 10px;
    }

    p {
      text-align: center;
      margin: 0 25px 10px;
    }
  }

  div:nth-child(3) {
    grid-area: c;
    background: url(${Images.PrivateMode}) no-repeat center bottom #fafafa;
    align-items: center;
    height: 300px;

    h3 {
      text-align: center;
      margin: 30px 25px 15px;
    }

    p {
      max-width: 270px;
      text-align: center;
      margin: 0 25px 15px;
    }
  }

  div:nth-child(4) {
    grid-area: d;
    background: url(${Images.Quiz}) no-repeat right bottom #fafafa;
    align-items: flex-start;
    justify-content: center;
    height: 325px;

    h3 {
      text-align: left;
      margin: 30px 25px 15px;
    }

    p {
      max-width: 240px;
      text-align: left;
      margin: 0 25px 15px;
    }
  }

  div:nth-child(5) {
    grid-area: e;
    background: url(${Images.PeerFeedback}) no-repeat left bottom #fafafa;
    background-size: 130px auto;
    background-position: left center;
    align-items: flex-end;
    height: 213px;

    h3 {
      max-width: 250px;
      text-align: right;
      margin: 30px 25px 15px;
    }

    p {
      max-width: 220px;
      text-align: right;
      margin: 0 25px 15px;
    }
  }

  @media screen and (min-width: 768px) {
    gap: 20px;
    grid-template-columns: calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    grid-template-areas:
      "a b d d"
      "c c d d"
      "c c e e"
    ;

    div:nth-child(1) {}

    div:nth-child(3) {      
      background-size: 253px auto;
    }

    div:nth-child(4) {
      height: auto;

      p {
        max-width: 190px;
      }
    }

    div:nth-child(5) {
      height: 213px;      

      p {
        max-width: 213px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: 210px 210px 210px 210px;

    div:nth-child(1) {}

    div:nth-child(2) {}

    div:nth-child(3) {
      height: 299px;
    }

    div:nth-child(4) {}

    div:nth-child(5) {}
  }
`

export const CloseButton = styled.button`
  z-index: 30;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  border: none;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    top: 30px;
    right: 30px;
  }
`

export const LinkButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: 400;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;

  img {
    width: 26px;
    margin-right: 5px;
  }
`
