import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;

  div {
    overflow: visible;
  }

  div > div > div {
    overflow: visible;
  }
`