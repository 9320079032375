import { PlaylistCreateRequestProps, PlaylistPayloadProps } from './types'

export function getContentPlaylistCreate(payload: any, teacher_id: string) {
  const {
    name,
    due_date,
    bookId: course_id,
    classroom_id,
    student_ids,
    teacher_message,
    goal,
    ...props
  } = payload as PlaylistPayloadProps

  const todos = props.activities.map((activity) => {
    const pages = activity.section_name.replace(/Teil |Seite /g, '')

    return {
      type: 'SELF_PACED_ACTIVITY', // activity.type,
      pages,
      title: activity.title,
      activity_id: activity.id,
    }
  })

  const units = [
    {
      id: props.unit.id,
      name: props.unit.name,
      todos,
    },
  ]

  const content = {
    modules: [
      {
        id: 'module1',
        name: 'Module 1',
        units,
      },
    ],
  }

  return {
    teacher_id,
    goal: goal === undefined ? 85 : goal,
    name: name || 'Vokabeltest',
    content,
    due_date,
    course_id,
    student_ids,
    classroom_id,
    teacher_message: teacher_message || 'Viel Erfolg!',
  } as PlaylistCreateRequestProps
}

export function getContentPlaylist(props: any) {
  
  const todos = props.activities.map((activity: any) => {
    const pages = activity.section_name.replace(/Teil |Seite /g, '')

    return {
      type: 'SELF_PACED_ACTIVITY',
      pages,
      title: activity.title,
      activity_id: activity.id,
    }
  })

  const units = [
    {
      id: props.unit.id,
      name: props.unit.name,
      todos,
    },
  ]

  return {
    modules: [
      {
        id: 'module1',
        name: 'Module 1',
        units,
      },
    ],
  }
}
