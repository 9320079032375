import React, { useEffect } from 'react'
import { Button, Container, Text } from 'cc-web-components'

import Images from '@/assets/images/product-benefits'
import { CartIcon } from '@/components/Icons'
import { CloseIcon } from '@/components/Icons'
import {
  Modal,
  ModalContent,
  ModalFooter,
  SubTitle,
  StudentGrid,
  TeacherGrid,
  CloseButton,
  LinkButton,
} from './styles'

type Props = {
  shopCallback: () => void
  closeCallback: () => void
  feedbackCallback: () => void
}

const ModalProductBenefits: React.FC<Props> = ({
  shopCallback,
  closeCallback,
  feedbackCallback,
}: Props) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <Modal>
      <CloseButton onClick={closeCallback}>
        <CloseIcon />
      </CloseButton>

      <ModalContent>
        <Container display="flex" flexDirection="column" alignItems="center" width={930} mt={45}>
          <Text
            value="Diese ChatClass Vorteile entgehen …"
            mb={23}
            fontSize={20}
            fontWeight={600}
          />
          <SubTitle>
            … Ihnen als <strong>Lehrkraft im Dashboard</strong>
          </SubTitle>

          <TeacherGrid>
            <div>
              <h3>Lernfortschritt verfolgen & Student Health Score</h3>
              <p>
                Verfolgen Sie die Entwicklung ihrer Schüler/-innen in den verschiedenen
                Kompetenzbereichen
              </p>
            </div>
            <div>
              <h3>Feedback versenden</h3>
              <p>
                Senden Sie Ihren Schüler/-innen Rückmeldung zu Sprachaufnahmen aus dem Dashboard als
                Text oder Sprachnachricht.
              </p>
            </div>
            <div>
              <img src={Images.UnitLock} alt="" />
              <h3>Unit Lock</h3>
              <p>Schalten Sie Units nach und nach frei, um eng an Ihrem Lehrwerk zu arbeiten.</p>
            </div>
            <div>
              <img src={Images.Reports} alt="" />
              <h3>Lernfortschritt-bericht für Eltern</h3>
              <p>Geben Sie Eltern Rückmeldung zur Entwicklung.</p>
              <span>Demsäscht</span>
            </div>
            <div>
              <h3>Aufgaben Zuweisen</h3>
              <p>Entscheiden Sie, welche Aufgaben Ihre Schüler/-innen bearbeiten sollen.</p>
            </div>
          </TeacherGrid>

          <SubTitle>
            … Ihnen als <strong>Schüler/i-nnen in der App</strong>
          </SubTitle>

          <StudentGrid>
            <div>
              <img src={Images.Speaking} alt="" />
              <h3>Sprechaufgaben</h3>
              <p>Bilder beschreiben, Fragen beantworten und vieles mehr.</p>
            </div>
            <div>
              <img src={Images.Vocabulary} alt="" />
              <h3>Vokabelaufgaben</h3>
              <p>Multiple-Choice-Formate, schriftliche und mündliche Vokabelabfragen.</p>
            </div>
            <div>
              <h3>Privater Modus</h3>
              <p>Schüler/-innen können im privaten Modus trainieren.</p>
            </div>
            <div>
              <h3>Quizbattles</h3>
              <p>
                Beim Quiz üben die Schüler/-innen in Wettbewerb miteinander.
                Multiple-Choice-Formate, schriftliche und mündliche Vokabeltests sorgen für den
                gewünschten Übungseffekt.
              </p>
            </div>
            <div>
              <h3>Peer-Feedback versenden</h3>
              <p>
                Schüler/-innen haben die Möglichkeit, sich andere Antworten anzuhören und darauf
                konstruktives Feedback zu geben.{' '}
              </p>
            </div>
          </StudentGrid>
        </Container>
      </ModalContent>

      <ModalFooter>
        <div>
          <LinkButton onClick={feedbackCallback}>
            <img src={Images.CloseBlue} alt="" />
            Nein danke, ich verzichte auf die Vorteile.
          </LinkButton>

          <Button
            minWidth={189}
            variant="solid"
            value={
              <Text
                value="Lizenz kaufen"
                color="#fff"
                fontSize={20}
                marginLeft={1}
                fontWeight={300}
                mb={1}
              />
            }
            leftContent={
              <Container mr={1}>
                <CartIcon />
              </Container>
            }
            onClick={shopCallback}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalProductBenefits
