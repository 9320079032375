import React, {ReactElement} from 'react';
import {Container, CustomText, IconContainer, PreText, ShadowContainer} from './styles';

interface Props {
  icon?: ReactElement;
  preText?: string;
  text: string;
  bottomContent?: ReactElement;
  iconWithoutWrapper?: boolean;
  addShadow?: boolean;
}

export const Instruction: React.FC<Props> = ({
  icon,
  text,
  preText,
  bottomContent,
  iconWithoutWrapper,
  addShadow,
}) => {
  return (
    <Container>
      {icon && !iconWithoutWrapper && <IconContainer>{icon}</IconContainer>}
      {icon && iconWithoutWrapper && addShadow && <ShadowContainer>{icon}</ShadowContainer>}
      {icon && iconWithoutWrapper && !addShadow && icon}
      <div style={{flexDirection: 'row', display: 'flex'}}>
        <PreText content={preText} />
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <CustomText content={text} />
          {bottomContent}
        </div>
      </div>
    </Container>
  );
};
