import {Text} from '@cc/components';
import styled from '@emotion/styled';

export const CustomText = styled(Text)`
  ${({theme}) => theme.typography.mediumCaption025};
  margin-top: ${({theme}) => theme.spacings.spacing050};
  margin-bottom: ${({theme}) => theme.spacings.spacing050};
`;

export const style = {borderRadius: 4, borderColor: '#ccc', borderWidth: 1};
