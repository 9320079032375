import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FooterLink = styled.a`
  padding: 5px 25px;
  font-size: 16px;
  text-decoration: none;
  color: #999;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 767px) {
    padding: 5px;
    font-size: 14px;
  }
`

export const FooterReactLink = styled(Link)`
  padding: 5px 25px;
  font-size: 16px;
  text-decoration: none;
  color: #999;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 767px) {
    padding: 5px;
    font-size: 14px;
  }
`
