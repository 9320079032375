import React from 'react'
import { SpeechOutlinedIcon } from '@/components/Icons'
import { Button } from 'cc-web-components'
import styled, { keyframes } from 'styled-components'

type Props = { onClick: () => void }

const pulseAnimation = keyframes`
  0% {transform: scale(1, 1); opacity: 0.75;}
  50% {opacity: .5;}
  100% {transform: scale(1.6, 1.6); opacity: 0.0;}                
`

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 58px;
  border-radius: 50%;
  box-shadow: 0 0 0 0 ${(props) => props.theme.colors.red};

  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    border: 1px solid #ccc;
    box-shadow: 0 0 1px #ccc;
    border-radius: 50%;
    height: 58px;
    width: 58px;
    opacity: 0;
    animation: ${pulseAnimation} 1s ease-out;
    animation-iteration-count: infinite;
  }

  button {
    z-index: 2;
    position: relative;

    img {
      filter: brightness(10);
    }
  }
`

const ButtonPulse: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <StyledContainer>
      <Button
        color="red"
        variant="solid-min"
        width={58}
        height={58}
        onClick={onClick}
        leftContent={<SpeechOutlinedIcon width={24} />}
      />
    </StyledContainer>
  )
}

export default ButtonPulse
