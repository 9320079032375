import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'cc-web-components'

import FormText from './FormText'
import FormAudio from './FormAudio'
import { ToggleButton } from '../ToggleButton'

const FEEDBACK = {
  HIDE: '',
  AUDIO: 'audio',
  MESSAGE: 'message',
}

export type PropsOnSubmitResponse = {
  content: any
  type: 'TEXT' | 'MEDIA'
}

type Props = {
  children?: any
  clearAfterSend?: boolean
  onSubmit?: (data: PropsOnSubmitResponse) => void
  defaultFeedback?: '' | 'audio' | 'message'
  wordsSpeedFeedback?: { value: string }[]
  hasFeedbackError?: boolean
  feedbackErrorText?: string
}

const TableChat: React.FC<Props> = ({
  children,
  clearAfterSend,
  onSubmit,
  defaultFeedback,
  wordsSpeedFeedback,
  hasFeedbackError,
  feedbackErrorText,
}: Props) => {
  const { t } = useTranslation('cornelsen')
  const [activeTableFilter, setActiveTableFilter] = useState(0)
  const [sendFeedback, setSendFeedback] = useState(FEEDBACK.AUDIO)

  useEffect(() => {
    if (!!defaultFeedback) {
      setSendFeedback(defaultFeedback)
    }
  }, [defaultFeedback])

  const showFeedback = (type: string) => {
    if (sendFeedback === type) {
      setSendFeedback(FEEDBACK.HIDE)
    } else {
      setSendFeedback(type)
    }
  }

  const onSubmitText = (content: string) => {
    if (onSubmit) {
      onSubmit({
        content,
        type: 'TEXT',
      })
    }
  }

  const onSubmitMedia = (content: any) => {
    if (onSubmit) {
      onSubmit({
        content,
        type: 'MEDIA',
      })
    }
  }

  const FEEDBACKS = [t('Globals.FormFeedback.Audio.ButtonStart'), t('Globals.FormFeedback.Comment.ButtonStart')]

  return (
    <Container flex={1} flexDirection="column">
      <Container flex={1}  py={2}>
        <Container flex={1} alignItems="center">
          {children}
        </Container>
        <Container justifyContent="flex-end">
          <ToggleButton
            values={FEEDBACKS}
            activeIndex={activeTableFilter}
            onToggle={(index: number) => {
              setActiveTableFilter(index)
              showFeedback(index === 0 ? FEEDBACK.AUDIO : FEEDBACK.MESSAGE)
            }}
          />
        </Container>
      </Container>

      {sendFeedback === FEEDBACK.AUDIO && (
        <FormAudio
          onSubmit={(audio: any) => onSubmitMedia(audio)}
          hasFeedbackError={hasFeedbackError}
          feedbackErrorText={feedbackErrorText}
        />
      )}

      {sendFeedback === FEEDBACK.MESSAGE && (
        <FormText
          clearAfterSend={clearAfterSend}
          onSubmit={(text) => onSubmitText(text)}
          buttonsWords={wordsSpeedFeedback}
          hasFeedbackError={hasFeedbackError}
          feedbackErrorText={feedbackErrorText}
        />
      )}
    </Container>
  )
}

export default TableChat
