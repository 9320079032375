import { useEffect, useState } from 'react'
import getBlobDuration from 'get-blob-duration'

const useRecorder = (): any[] => {
  const [audioURL, setAudioURL] = useState('')
  const [audioData, setAudioData] = useState('')
  const [isRecording, setIsRecording] = useState(false)
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null)

  const [counter, setCounter] = useState<string>('00:00')
  const [secCounter, setSecCounter] = useState(0)
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error)
      }
      return
    } else {
      if (isRecording) {
        recorder?.start()
      } else {
        recorder?.stop()
      }

      const handleData = async (e: any) => {
        const url = URL.createObjectURL(e.data)

        setAudioData(e.data)
        setAudioURL(url)
        setDuration(await getBlobDuration(url))
      }

      recorder?.addEventListener('dataavailable', handleData)

      return () => recorder?.removeEventListener('dataavailable', handleData)
    }
  }, [recorder, isRecording])

  useEffect(() => {
    if (isRecording) {
      setTimeout(() => {
        setSecCounter(secCounter + 1)
      }, 1000)
    }

    setCounter(`
      ${Math.floor(secCounter / 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}:
      ${Math.floor(secCounter % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}
    `)
  }, [isRecording, secCounter])

  const startRecording = () => {
    setSecCounter(0)
    setIsRecording(true)
  }

  const stopRecording = () => {
    setIsRecording(false)
  }

  const cleanRecording = () => setAudioURL('')

  return [
    { audioURL, isRecording, audioData },
    { startRecording, stopRecording, cleanRecording, counter, duration },
  ]
}

const requestRecorder = async (): Promise<MediaRecorder> => {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
  return new MediaRecorder(stream)
}
export default useRecorder
