import { BookSelectorList, BookSelectorItem } from './styles'

import { BookSelectorProps } from './types'

import Images from '@/assets/images'

const COLLECTIONS_COVERS: { [key: string]: string } = {
  access: Images.Access22Book,
  ahead: Images.GoAheadBook,
  headlight: Images.HeadlightBook,
  highlight: Images.HighlightBook,
  lighthouse: Images.LighthouseGEBook,
  focus: Images.FocusOnSuccessPlusBook,
  neutral_content: Images.NeutralBook
}

interface Book {
  name: string
}

interface Collection {
  name: string
  books: Book[]
}

export const BookSelector: React.FC<BookSelectorProps> = ({
  collections,
  activeCollection,
  callback,
  isDisabled,
}) => {
  return (
    <BookSelectorList>
      {collections?.map((collection: Collection, index: number) => (
        <BookSelectorItem
          key={index}
          onClick={() => callback(collection.books[0], index)}
          active={activeCollection === index}
          isDisabled={isDisabled}
        >
          <img src={COLLECTIONS_COVERS[collection.name]} alt="" />
        </BookSelectorItem>
      ))}
    </BookSelectorList>
  )
}
