import styled, {css} from 'styled-components';
import {Container} from 'cc-web-components';

type HeaderProps = {
  opened: boolean;
  sticky?: boolean;
};

export const Wrapper = styled.div`
  ${props =>
    props.theme.mediaQueries.small &&
    `
    border-right: 1px solid rgb(229 229 229);
  `}

  @media screen and (min-width: 425px) {
    overflow-y: auto;
    max-height: calc(100vh - 221px);
  }
`;

export const Item = styled(Container)`
  width: 100%;
`;

export const ItemHeader = styled(Container)<HeaderProps>`
  align-items: center;
  background-color: #fff;
  border-top: 1px solid rgb(229 229 229);
  border-bottom: ${props => (props.opened ? '1px solid rgb(229 229 229)' : '0px')};
  cursor: pointer;
  height: 66px;
  padding: 0 23px;

  top: 0px;
  position: sticky;
  position: -webkit-sticky;

  @media screen and (max-width: 425px) {
    top: 110px;
    transition: all 0.3s;
  }

  @media screen and (min-width: 425px) {
    ${props =>
      props.sticky &&
      css`
        top: 110px;
        transition: top 0.3s;
      `}
  }
`;

export const ItemContent = styled(Container)`
  flex-direction: column;
`;

export const ItemSection = styled.div<{opened: boolean}>`
  display: flex;
  align-items: center;
  border-top: 1px solid rgb(229 229 229);
  cursor: pointer;
  height: 65px;
  padding: 0 23px;
  justify-content: space-between;
  background-color: ${props => (props.opened ? '#E6F1FF' : '#fff')};

  &:hover {
    > div {
      color: ${props => props.theme.colors.primary};
    }
  }

  &:first-child {
    border-top: 0px solid rgb(229 229 229);
  }
`;

export const ItemSectionCount = styled.div<{opened: boolean}>`
  background-color: ${props => (props.opened ? '#fff' : 'rgb(232, 241, 252)')};
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
