import { useState } from 'react'
import { CloseIcon, SendOutlinedIcon } from '@/components/Icons'
import { Button, Container, Input, InputCheckbox, Text } from 'cc-web-components'
import { Modal, CloseButton, ModalContent, InputRadio, Label, ModalFooter } from './styles'
import Confirmation from './Confirmation'
import Api from '@/services/ApiCv'
import { useSelector } from 'react-redux'
import { getUser } from '@/redux/Session'

type Props = {
  closeCallback: () => void
}

type OptionProps = {
  id: number
  name: string
}

const feedbackOptions = [
  { id: 1, name: 'Schüler/-innen nutzen das Produkt nicht' },
  { id: 2, name: 'Ich habe das Lehrwerk/Klasse gewechselt' },
  { id: 3, name: 'Technische Schwierigkeiten' },
  { id: 4, name: 'Ich bin nicht der/die Entscheidungsträger/-in' },
  { id: 5, name: 'Zu teuer' },
  { id: 6, name: 'Kein Budget/bereits ausgeschöpft' },
  { id: 7, name: 'Ich fand das Produkt zu kompliziert' },
  { id: 8, name: 'Testzeitraum zu kurz' },
  { id: 9, name: 'Anderer Grund' },
]

const ModalFeedbackLicense: React.FC<Props> = ({ closeCallback }: Props) => {
  const user = useSelector(getUser)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showOtherReasonInput, setShowOtherReasonInput] = useState(false)
  const [feedbackText, setFeedbackText] = useState('')
  const [otherReasonText, setOtherReasonText] = useState('')
  const [improvementText, setImprovementText] = useState('')
  const [contactPermission, setContactPermission] = useState(false)

  const handleFeedback = async () => {
    await Api.sendLicenseFeedback({
      user_id: user?.id,
      user_name: user?.name,
      refusal_reason: feedbackText,
      refusal_reason_other: otherReasonText,
      accept_share: contactPermission,
      improvements_message: improvementText,
    }).then(() => {
      localStorage.setItem('hideFeedback', 'true')
    })
  }

  const handleOption = (option: OptionProps) => {
    if (option.id === 9) {
      setShowOtherReasonInput(true)
    } else {
      setShowOtherReasonInput(false)
      setOtherReasonText('')
    }
    setFeedbackText(option.name)
  }

  const handleSubmit = () => {
    if (feedbackText) {
      handleFeedback()
      setShowConfirmation(true)
    }
  }

  const toogleContactPermissionInput = () => {
    setContactPermission(!contactPermission)
  }

  return (
    <Modal>
      <CloseButton onClick={closeCallback}>
        <CloseIcon />
      </CloseButton>

      {!showConfirmation ? (
        <>
          <ModalContent>
            <Container width={600} mt={45} display="flex" flexDirection="column">
              <Text
                value="Bevor Sie ChatClass den Rücken kehren …"
                fontSize={32}
                fontWeight={600}
                mb={10}
              />
              <Text
                value="Erzählen Sie uns gerne, warum ChatClass Sie nicht weiter überzeugen konnte. Ihr Feedback wird uns helfen, das Produkt zu verbessern."
                fontSize={20}
              />
              <Container display="flex" flexDirection="column" mt={20} pb={30}>
                {feedbackOptions.map((option, index) => {
                  return (
                    <Container key={index} display="flex" alignItems="center" mt={1} mb={1}>
                      <InputRadio
                        type="radio"
                        name="feedback"
                        id={`${option.id}`}
                        onChange={() => {
                          handleOption(option)
                        }}
                      />
                      <Label htmlFor={`${option.id}`}>
                        <Text value={option.name} fontSize={20} />
                      </Label>
                    </Container>
                  )
                })}

                {showOtherReasonInput && (
                  <Container flexDirection="column" ml={25}>
                    <Text value="Bitte Grund angeben" fontSize={16} />
                    <Input
                      type="textarea"
                      rows={3}
                      style={{ flex: 1, resize: 'none', fontSize: 16, fontWeight: 600 }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setOtherReasonText(event.target.value)
                      }}
                      value={otherReasonText}
                    />
                  </Container>
                )}

                <Container flexDirection="column">
                  <Text value="Was sollten wir verbessern?" fontSize={16} />
                  <Input
                    type="textarea"
                    rows={3}
                    style={{ flex: 1, resize: 'none', fontSize: 16, fontWeight: 600 }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setImprovementText(event.target.value)
                    }}
                    value={improvementText}
                    mb={10}
                  />
                  <InputCheckbox
                    labelRight={
                      <Text
                        size={16}
                        marginLeft={10}
                        value="Cornelsen darf mich hinsichtlich meines Feedbacks kontaktieren"
                      />
                    }
                    checked={contactPermission}
                    disabled={false}
                    onChange={toogleContactPermissionInput}
                  />
                </Container>
              </Container>
            </Container>
          </ModalContent>
          <ModalFooter>
            <Container>
              <Button
                value="Feedback Senden"
                variant="solid-min"
                onClick={() => {
                  handleSubmit()
                }}
                height={38}
                fontSize={16}
                fontWeight={400}
                leftContent={
                  <Container mx={2}>
                    <SendOutlinedIcon width={24} />
                  </Container>
                }
              />
            </Container>
          </ModalFooter>
        </>
      ) : (
        <Confirmation closeCallback={closeCallback} />
      )}
    </Modal>
  )
}

export default ModalFeedbackLicense
