export enum LanguageTypesEnum {
  EN = 'en',
  DE = 'de',
}

export type TranslationProviderType = {
  children: React.ReactNode;
};

export type TranslationStateType = {
  language: LanguageTypesEnum;
};

export type TranslationDispatchType = {
  changeLanguage: (language: LanguageTypesEnum) => void;
};

export type TranslationContextType = [TranslationStateType, TranslationDispatchType];
