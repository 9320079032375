import React from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Container, Text } from 'cc-web-components'

import { HealthScoreResponse } from '../../../types'
import { StyledScoreIconCount } from './styles'

type Props = {
  healthScore: HealthScoreResponse
  scoreValue: string
  scoreUpOrDownValue: string
}

const ContentInfos: React.FC<Props> = ({ healthScore, scoreValue, scoreUpOrDownValue }: Props) => {
  const { colors } = useTheme()
  const { t } = useTranslation('cornelsen')

  return (
    <Container flexDirection="column" alignItems="center">
      <Text
        size={12.5}
        color={colors.gray[2]}
        marginTop={3}
        value={t('Student.TabProgress.SectionHealthScore.GraphCircle.Title').toUpperCase()}
      />

      <Text size={30} color={colors.black} marginTop={1} value={scoreValue} />

      <Container marginTop={4} alignItems="center">
        <Container justifyContent="center" alignItems="center" style={{ gap: '4px' }}>
          <StyledScoreIconCount color={healthScore.meta.indicator.text}>
            {healthScore.meta.indicator.text === ' - ' ? 'Low' : healthScore.meta.indicator.text}
          </StyledScoreIconCount>

        <Text
          color="#003B0A"
          value={scoreUpOrDownValue}
        />
        </Container>

      </Container>
    </Container>
  )
}

export default ContentInfos
