import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Container, Text} from 'cc-web-components';

import Helpers from '@/helper';
import useDeviceDetection from '@/hooks/IsMobile';
import {ActivityResponse} from '@/services/ApiCv/types/Activity';
import ApiClassroomService from '@/services/ApiCv/ApiClassroomService';
import ApiBookService from '@/services/ApiCv/ApiBookService';
import {ToggleButton} from '@/components/ToggleButton';
import TableStudentActivities from '@/components/TableStudentActivities';
import {getLicense, startLoading, stopLoading} from '@/redux/Session';

import {FilterTime} from '../../types';
import {BlueBookIcon, BookPileIcon, MicroIcon, WorkSpokenIcon} from '@/components/Icons';

type Props = {
  color: string;
  bookId?: string;
  activeFilter: number;
  filtersOfTime: FilterTime | undefined;
  filterCallback: (filter: FilterTime) => void;
};

const allFilters = [
  {
    type: 'ALL',
    name: 'Classroom.Activities.FilterTypes.All',
    icon: null,
    filter:
      '?type=AI_SPEAKING&type=PRONUNCIATION&type=LISTENING&type=DIALOG&type=GRAMMAR&type=VOCABULARY_TRAINER&type=VOCABULARY',
  },
  {
    type: 'SPEAKING',
    name: 'Classroom.Activities.FilterTypes.Speaking',
    icon: <MicroIcon />,
    filter: '?type=AI_SPEAKING',
  },
  {
    type: 'VOCABULARY',
    name: 'Classroom.Activities.FilterTypes.Vocabulary',
    icon: <BlueBookIcon />,
    filter: '?type=VOCABULARY_TRAINER&type=VOCABULARY',
  },
  {
    type: 'PRONUNCIATION',
    name: 'Classroom.Activities.FilterTypes.Pronunciation',
    icon: <WorkSpokenIcon />,
    filter: '?type=PRONUNCIATION',
  },
  {
    type: 'LISTENING',
    name: 'Classroom.Activities.FilterTypes.Listening',
    icon: <>👂</>,
    filter: '?type=LISTENING',
  },
  {
    type: 'GRAMMAR',
    name: 'Classroom.Activities.FilterTypes.Grammar',
    icon: <BookPileIcon />,
    filter: '?type=GRAMMAR',
  },
  {
    type: 'READING',
    name: 'Classroom.Activities.FilterTypes.Dialog',
    icon: <>📖</>,
    filter: '?type=DIALOG',
  },
];

const StudentList: React.FC<Props> = ({
  color,
  bookId,
  activeFilter,
  filtersOfTime,
  filterCallback,
}: Props) => {
  const dispatch = useDispatch();
  const {t} = useTranslation('cornelsen');
  const {isMobile} = useDeviceDetection();
  const {classroomId} = useParams<{classroomId: string}>();
  const license = useSelector(getLicense);

  const [activitiesData, setActivitiesData] = useState<ActivityResponse>();
  const [datesFilterTime, setDatesFilterTime] = useState<FilterTime>();
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState(allFilters);
  const [activeTableFilter, setActiveTableFilter] = useState(0);

  useEffect(() => {
    setDatesFilterTime(filtersOfTime);
  }, [filtersOfTime]);

  useEffect(() => {
    if (bookId) {
      ApiBookService.getBookById({id: bookId}).then(data => {
        const classroomFilters = allFilters.filter(
          item => data.competencies.includes(item.type) || item.type === 'ALL',
        );
        setFilters(classroomFilters);
      });
    }
  }, [bookId]);

  const getRequest = useCallback(
    (page: number) => {
      const requestData = {
        classroomId,
        filter: {...datesFilterTime, page},
        types: filters[activeTableFilter].filter,
      };

      return ApiClassroomService['getActivities'](requestData);
    },
    [classroomId, datesFilterTime, filters, activeTableFilter],
  );

  useEffect(() => {
    if (!datesFilterTime) return;

    dispatch(startLoading());

    getRequest(0)
      .then(data => {
        setActivitiesData(data);
        dispatch(stopLoading());
      })
      .catch(() => {
        dispatch(stopLoading());
      });
  }, [dispatch, getRequest, reload, datesFilterTime]);

  const onGetPrevValues = () => {
    if (!datesFilterTime) return;

    const newIntervalTime = Helpers.getPrevIntervalDates(activeFilter, datesFilterTime);

    filterCallback(newIntervalTime);
  };

  const onGetNextValues = () => {
    if (!datesFilterTime) return;

    const newIntervalTime = Helpers.getNextIntervalDates(activeFilter, datesFilterTime);

    filterCallback(newIntervalTime);
  };

  const loadMoreItems = () => {
    if (!activitiesData || !activitiesData.pageable || !activitiesData.total_pages) return;

    const newPageNumber = activitiesData.pageable.page_number + 1;

    if (newPageNumber <= activitiesData.total_pages) {
      getRequest(newPageNumber).then(data => {
        data.content = [...activitiesData.content, ...data.content];

        setActivitiesData(data);
      });
    }
  };

  const toggleButton = () => {
    return (
      <ToggleButton
        values={TABLE_FILTER}
        activeIndex={activeTableFilter}
        onToggle={(index: number) => setActiveTableFilter(index)}
        style={{
          marginTop: isMobile ? '8px' : '0',
        }}
      />
    );
  };

  const TABLE_FILTER = filters.map(item => {
    if (item.type === 'ALL') return t(item.name);

    return isMobile ? item.icon : t(item.name);
  });

  return (
    <>
      <Container
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text size={20} value={t('Classroom.Activities.TableTitle')} fontWeight={600} />

        {toggleButton()}
      </Container>

      {!!activitiesData && (
        <TableStudentActivities
          parent="CLASSROOM"
          color={color}
          data={activitiesData}
          filterPageActived={activeFilter}
          activeTableFilter={activeTableFilter}
          datesFilterTime={datesFilterTime}
          onNextValues={onGetNextValues}
          onPrevValues={onGetPrevValues}
          onLoadMoreItems={() => loadMoreItems()}
          feedbackCallback={() => setReload(prev => !prev)}
          hideFeedbackForm={license?.expired === true}
        />
      )}
    </>
  );
};

export default StudentList;
