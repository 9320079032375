import {openExternalLink} from '@cc/utils';
import {Loader} from '@cc/components';
import React, {useEffect} from 'react';

const BSP_LOGIN_ENDPOINT = 'bsp';

export const LoginBspScreen: React.FC = () => {
  useEffect(() => {
    //! Redirects to Backend BSP oAuth implementation that will do the redirection accordingly.
    openExternalLink(`${window._env_.REACT_APP_CORNELSEN_URL}/${BSP_LOGIN_ENDPOINT}`);
  }, []);

  return <Loader isVisible />;
};
