import React, {useCallback, useMemo, useState} from 'react';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Card, Container, Text} from 'cc-web-components';
import {Cell, Pie, PieChart, Tooltip} from 'recharts';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {FaCircle} from 'react-icons/fa';
import Images from '@/assets/images';
import Helpers from '@/helper';
import {StyledCard} from '@cc/components';
import {Compentence} from '@/services/ApiCv/types/Competence';
import useDeviceDetection from '@/hooks/IsMobile';
import useDeviceDetectionSm from '@/hooks/IsSmall';

import '../styles.css';
import {EmptyMessage, StyledScoreIconCount, TooltipContainer} from './styles';

type Props = {
  competences: Compentence;
};

const COLORS = ['#F94144', '#F3722B', '#F7961E', '#F9C74E', '#91BE6D', '#2C9CDB'];

const CardsCompentencies: React.FC<Props> = ({competences}: Props) => {
  const {t} = useTranslation('cornelsen');
  const {colors} = useTheme();
  const {isMobile} = useDeviceDetection();
  const {isSmall} = useDeviceDetectionSm();
  const [isInitialPosition, setIsInitialPosition] = useState(true);

  const MetaHealthScore = useMemo(() => {
    return Helpers.getMetaHealthScore(competences.health_score);
  }, [competences]);

  const renderCardItem = useCallback(
    (title: string, value: string | number, img: any, hideBorder = false) => {
      return !isSmall ? (
        <Container
          flex={1}
          px={2}
          flexDirection="column"
          justifyContent="space-between"
          borderRight={hideBorder ? '' : '1px solid #eee'}>
          <Text color={colors.black} size={14} value={title} />

          <Container flexDirection="row" alignItems="center" justifyContent="space-between">
            <Text color={colors.black} size={24} lineHeight="40px" value={value} />
            {img}
          </Container>
        </Container>
      ) : (
        <Container
          flex={1}
          px={2}
          flexDirection="column"
          justifyContent="space-between"
          borderRight={hideBorder ? '' : '1px solid #eee'}>
          {img}
          <Container className={'boxTextWrapper'}>
            <Text
              color={colors.black}
              size={14}
              value={title}
              style={{width: '86%', minHeight: '36px'}}
            />
          </Container>

          <Text color={colors.black} size={24} lineHeight="40px" value={value} />
        </Container>
      );
    },
    [colors, isSmall],
  );

  const totalExercices = React.useMemo(() => {
    if (competences) {
      const {
        grammar_total_question,
        listening_total_question,
        pronunciation_total_question,
        reading_total_question,
        speaking_total_question,
        vocab_total_question,
      } = competences;

      return (
        grammar_total_question +
        listening_total_question +
        pronunciation_total_question +
        reading_total_question +
        speaking_total_question +
        vocab_total_question
      );
    }

    return 0;
  }, [competences]);

  const totalExercisesCorrectness = React.useMemo(() => {
    if (competences) {
      const {
        grammar_correctness,
        listening_correctness,
        pronunciation_correctness,
        reading_correctness,
        speaking_correctness,
        vocabulary_correctness,
      } = competences;

      const total =
        (grammar_correctness +
          listening_correctness +
          pronunciation_correctness * 100 +
          reading_correctness +
          speaking_correctness +
          vocabulary_correctness) /
        6;

      return total ? total.toFixed(0) : 0;
    }

    return 0;
  }, [competences]);

  const pronunciationCorrectness = React.useMemo(() => {
    if (competences && competences.pronunciation_correctness) {
      const {pronunciation_correctness} = competences;

      return (pronunciation_correctness * 100).toFixed(0);
    }

    return 0;
  }, [competences]);

  const scroll = (offset: number) => {
    const cardContainer = document.getElementById('cardContainer');
    if (cardContainer) cardContainer.scrollLeft += offset;
  };

  const onClickLeftButton = () => {
    scroll(-430);
    setIsInitialPosition(!isInitialPosition);
  };

  const onClickRightButton = () => {
    scroll(430);
    setIsInitialPosition(!isInitialPosition);
  };

  const FooterCardGraph = () => {
    if (isMobile && totalExercices) {
      return (
        <>
          <Container justifyContent="space-between">
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[0]} />

              <Text ml={2} size={14} color="grey" value="Sprechen" fontWeight={500} />
            </Container>
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[1]} />

              <Text ml={2} size={14} color="grey" value="Aussprache" fontWeight={500} />
            </Container>
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[2]} />

              <Text ml={2} size={14} color="grey" value="Vokabeln" fontWeight={500} />
            </Container>
          </Container>
          <Container mt={2} justifyContent="space-between">
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[3]} />

              <Text ml={2} size={14} color="grey" value="Grammatik" fontWeight={500} />
            </Container>
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[4]} />

              <Text ml={2} size={14} color="grey" value="Hören" fontWeight={500} />
            </Container>
            <Container alignItems="center">
              <FaCircle fontSize={8} color={COLORS[5]} />

              <Text ml={2} size={14} color="grey" value="Lesen" fontWeight={500} />
            </Container>
          </Container>
        </>
      );
    }

    return null;
  };

  const CustomTooltip = ({payload, active}: any) => {
    if (active && payload && payload.length) {
      return <TooltipContainer>{`${payload[0].name}: ${payload[0].value}`}</TooltipContainer>;
    }

    return null;
  };

  return (
    <Container
      flexDirection={isMobile ? 'column' : 'row'}
      height={isMobile ? '100%' : '180px'}
      mt={isMobile && 30}
      style={{gap: '8px'}}>
      <StyledCard>
        <button
          className={'scrollButton'}
          onClick={() => onClickLeftButton()}
          style={{visibility: !isInitialPosition && isSmall ? 'visible' : 'hidden'}}>
          <MdKeyboardArrowLeft />
        </button>
        <button
          className={'scrollButton'}
          onClick={() => onClickRightButton()}
          style={{visibility: isInitialPosition && isSmall ? 'visible' : 'hidden'}}>
          <MdKeyboardArrowRight />
        </button>

        <Card
          title={t('Student.TabOverView.SectionScores.CardTitle')}
          style={{flexDirection: 'column', maxWidth: 'unset', height: '180px'}}>
          <Container id={'cardContainer'} height="100%">
            {renderCardItem(
              t('Student.TabOverView.SectionScores.ExercisesTitle'),
              totalExercices || '-',
              <></>,
              false,
            )}
            {renderCardItem(
              t('Student.TabOverView.SectionScores.CorrectnessTitle'),
              totalExercisesCorrectness ? `${totalExercisesCorrectness} %` : '-',
              <></>,
            )}
            {renderCardItem(
              t('Student.TabOverView.SectionScores.PronunciationCorrectnessTitle'),
              pronunciationCorrectness ? `${pronunciationCorrectness} %` : '-',
              <></>,
              true,
            )}
          </Container>
        </Card>
      </StyledCard>

      <Card
        title={'Anzahl bearbeiteter Aufgaben'}
        width={!isMobile ? '30%' : '100%'}
        height={!isMobile ? '180px' : '250px'}
        justifyContent="center"
        alignItems="center"
        footerContent={FooterCardGraph()}>
        {competences && totalExercices ? (
          <PieChart width={200} height={110} style={{overflow: 'hidden'}}>
            <Pie
              data={competences.graph_values}
              cx={'50%'}
              cy={100}
              startAngle={180}
              endAngle={0}
              innerRadius={50}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value">
              {competences.graph_values.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        ) : (
          <EmptyMessage>Keine Aktivität durchgeführt</EmptyMessage>
        )}
      </Card>

      <Card
        flex={1}
        width={!isMobile ? '30%' : '100%'}
        height="auto"
        footerContent={
          <Container flexDirection="row" height={40}>
            <Container
              flex={1}
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              borderRight="1px solid #eee"
              pr="20px">
              <StyledScoreIconCount color={MetaHealthScore.text}>
                {MetaHealthScore.text === ' - ' ? 'Low' : MetaHealthScore.text}
              </StyledScoreIconCount>
            </Container>
            <Container
              flex={1}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pl="20px">
              <img src={Images.DiamondIcon} alt="" width={24} />
            </Container>
          </Container>
        }>
        <Container
          flex={1}
          height="100%"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          borderRight="1px solid #eee"
          pl={2}
          pr="20px">
          <Text size={16} mb={2} value={t('Student.TabOverView.SectionScores.ScoreTitle')} />

          <Text size={24} value={competences.health_score.toFixed(1)} />
        </Container>
        <Container
          flex={1}
          height="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          pr={2}
          pl="20px">
          <Text size={16} mb={2} value={t('Student.TabOverView.SectionScores.PointsTitle')} />

          <Text size={24} value={competences.points} />
        </Container>
      </Card>
    </Container>
  );
};

export default CardsCompentencies;
