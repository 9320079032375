import {Text} from '../../atoms';
import styled from '@emotion/styled';

export const QR_CODE_SIZE = 120;

export const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-right: 20px;
`;

export const CustomText = styled(Text)`
  text-align: center;
  ${({theme}) => theme.typography.mediumBodyStrong200};
  width: 120px;
`;

export const AppNameText = styled(Text)`
  text-align: center;
  ${({theme}) => theme.typography.mediumBodyStrong100Base};
  margin-top: 4px;
`;

export const Separator = styled.div`
  width: ${({theme}) => theme.spacings.spacing125};
`;
