import jwt_decode from 'jwt-decode';

const ONE_MILLISECOND = 1000;
type JWT = {
  exp: number;
};

export const getIsTokenExpired = (token: string | null) => {
  if (!token) {
    return true;
  }

  const expirationTimeInSeconds = jwt_decode<JWT>(token).exp;
  const currentTimeInSeconds = new Date().getTime() / ONE_MILLISECOND;

  if (expirationTimeInSeconds > currentTimeInSeconds) {
    return false;
  }

  return true;
};
