/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Container, Select, Text } from 'cc-web-components'
import { useTranslation } from 'react-i18next'

import { BookSelector } from '../../Components/BookSelector'
import { Book } from '../../types'
import { useCreateClassroom } from '../../Context'

type Props = {
  isEditClass: boolean
  setButtonActive: (buttonActive: boolean) => void
}

const StepTwo = ({ isEditClass, setButtonActive }: Props) => {
  const { t } = useTranslation('cornelsen')
  const {
    createClassroomData,
    setCreateClassroomData,
    collections,
    activeCollection,
    setActiveCollection,
    books,
    activeBook,
    setActiveBook,
  } = useCreateClassroom()
  const [hasFindedIndexes, setHasFindedIndexes] = useState(false)

  useEffect(() => {
    if (activeCollection >= 0) setButtonActive(true)
    else setButtonActive(false)
  })

  useEffect(() => {
    if (!isEditClass || !collections.length || hasFindedIndexes) return

    const collectionIndex = collections.findIndex((collection) =>
      collection.books.find((book) => book.name === createClassroomData.book)
    )

    const bookIndex =
      collectionIndex > -1
        ? collections[collectionIndex].books.findIndex(
            (book) => book.name === createClassroomData.book
          )
        : 0

    setActiveCollection(collectionIndex)
    setActiveBook(bookIndex)
    setCreateClassroomData({
      ...createClassroomData,
      course_id: collectionIndex > -1 ? collections[collectionIndex].books[bookIndex].id : '',
    })
    setHasFindedIndexes(true)
  }, [collections])

  const handleActiveCollection = (item: Book, index: number) => {
    setActiveCollection(index)
    setActiveBook(0)
    setCreateClassroomData({
      ...createClassroomData,
      book: item.name,
      course_id: item.id,
    })
  }

  const handleActiveBook = (item: Book, index: number) => {
    setActiveBook(index)
    setCreateClassroomData({
      ...createClassroomData,
      book: item.name,
      course_id: item.id,
    })
  }

  return (
    <Container flexDirection="column">
      <Container padding={3} flexDirection="column" background="#fff" pt={4} pb={4}>
        <Text
          mb={2}
          size={16}
          fontWeight={600}
          value={t('AssignActivity.Tabs.NewClass.Step2.BooksTitle')}
        />
        <Container flexDirection="column" mb={4}>
          {collections && (
            <BookSelector
              collections={collections}
              activeCollection={activeCollection}
              callback={handleActiveCollection}
            />
          )}
        </Container>
        <Text
          fontSize={16}
          fontWeight={400}
          mb={2}
          color="#666"
          value={t('AssignActivity.Tabs.NewClass.Step2.BookTitle')}
        />
        <Select
          items={
            !!books.length
              ? books
              : [{ name: t('AssignActivity.Tabs.NewClass.Step2.BookPlaceholder') }]
          }
          renderItem={(item: Book) => item?.name}
          onSelectItem={handleActiveBook}
          selectedIndex={activeBook}
          isDisabled={!books.length}
        />
      </Container>
    </Container>
  )
}

export default StepTwo
