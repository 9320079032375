import { format } from 'date-fns'
import { PlaylistProps } from './types'

function getStatusTranslated(status: 'PENDING' | 'STARTED' | 'FINISHED') {
  switch (status) {
    case 'FINISHED': {
      return 'Completed'
    }
    case 'STARTED': {
      return 'Progress'
    }
    case 'PENDING': {
      return 'Opened'
    }
    default: {
      return 'Opened'
    }
  }
}

export function parsePlaylistData(data: any) {
  const playlists: PlaylistProps[] = []

  for (let item of data) {
    const datetimeDue = new Date(item.playlist.playlist_info.due_date)

    item.activities.forEach((activity: any) => {
      activity.user_name = item.playlist.user.name
      activity.user_emoji = item.playlist.user.emoji
      activity.correctness = (activity.correctness * 100).toFixed(0)

      if (typeof activity.feedbacks === 'undefined') {
        activity.feedbacks = []
      }
    })

    const playlist = {
      id: item.playlist.id,
      dateTime: datetimeDue,
      date: format(datetimeDue, 'dd/MM/yyyy'),
      time: format(datetimeDue, 'HH:mm'),
      task_title: item.playlist.playlist_info.name,
      seite_number: item.playlist.playlist_info.pages,
      correct_percent: (item.correctness * 100),
      status: item.playlist.status,
      status_translated: getStatusTranslated(item.playlist.status),
      payload: item,
    } as PlaylistProps

    playlists.push(playlist)
  }

  return playlists
}
