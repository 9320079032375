export enum StatusEnum {
  OPENED = 'Opened',
  PROGRESS = 'Progress',
  COMPLETED = 'Completed',
}

export type PlaylistProps = {
  id: string
  date: string
  time: string
  dateTime: Date
  task_type: 'VOCAB' | 'SPEAK'
  task_title: string
  seite_number: string
  correct_percent: number
  status: StatusEnum
  status_translated: string
  payload: any
}

export enum SortConfigDirectionKeys {
  ASC = 'asc',
  DESC = 'desc',
}

export type SortConfigDirection = SortConfigDirectionKeys

export type SortConfig = {
  key: null | keyof PlaylistProps
  direction: null | SortConfigDirection
}
