import React from 'react'
import { Text, Button } from 'cc-web-components'

import { ArrowLeftOutlinedIcon, ArrowRightOutlinedIcon } from '@/components/Icons'

import { Wrapper } from './styles'

type Props = {
  title: string
  prevDisabled: boolean
  nextDisabled: boolean
  onNext: () => void
  onPrev: () => void
}

const Header: React.FC<Props> = (props: Props) => {
  const { title, prevDisabled, nextDisabled } = props

  return (
    <Wrapper>
      <Button
        variant="link"
        onClick={props.onPrev}
        disabled={prevDisabled}
        style={{ opacity: prevDisabled ? '0.25' : 1 }}
        leftContent={<ArrowLeftOutlinedIcon />}
      />

      <Text size={16} fontWeight={600} value={title} />

      <Button
        variant="link"
        color="primary"
        onClick={props.onNext}
        disabled={nextDisabled}
        style={{ opacity: nextDisabled ? '0.25' : 1 }}
        leftContent={<ArrowRightOutlinedIcon />}
      />
    </Wrapper>
  )
}

export default Header
