const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
const androidPlatforms = ['Android'];

type ReturnType = 'ios' | 'android' | 'desktop';

export const detectPlatform = (): ReturnType => {
  const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';

  if (iosPlatforms.indexOf(platform) !== -1) {
    return 'ios';
  }

  if (androidPlatforms.indexOf(platform) !== -1) {
    return 'android';
  }

  return 'desktop';
};
