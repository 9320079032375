import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;

  @media screen and (max-width: 1220px) {
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`

export const HeaderFixedMobile = styled.div<{ bgImage: string }>`
  height: 36px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-image: ${(props) => `url(${props.bgImage})`};
  background-size: cover;

  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 10;
`

export const SidebarContainer = styled.div`
  background-color: #fff;

  @media screen and (min-width: 425px) {
    max-width: 400px;
    flex: 0.4;
  }

  @media screen and (max-width: 425px) {
    margin-top: 50px;    
  }
`

export const Content = styled.div<{ isHidden: boolean }>`
  flex: 1;
  display: ${(props) => (props.isHidden ? 'none' : 'unset')};
`

export const HeaderContainer = styled.div<{ sticky: boolean }>`
  height: 67px;
  top: 0;

  @media screen and (min-width: 425px) {
    ${(props) =>
    props.sticky &&
    css`
        z-index: 2;
        position: sticky;
        top: 110px;
        transition: top 0.3s;
      `}
  }

  @media screen and (max-width: 425px) {
    position: fixed;
    width: 100%;
    top: 175px;
    z-index: 10;
  }
`

export const ContentContainer = styled.div`
  padding-top: 2px;
  position: relative;

  @media screen and (max-width: 425px) {
    margin-top: calc(36px + 67px + 30px);
  }

  @media screen and (min-width: 425px) {
    overflow-y: auto;
    height: calc(100vh - 290px);
  }
`

export const PageWrapper = styled.div<{ isNeutralContent: boolean }>`
  ${({ isNeutralContent }) => isNeutralContent && `
    div {
      div:nth-child(1n) {
        div:nth-child(1n) {
          div {
            div:nth-child(2n) {
              div {
                color: #263238;
                svg {
                  color: #263238;
                }
              }
            }
          }
        }
      }
    }
  }
  `
}
`
