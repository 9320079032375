export const getToken = (name: string) => {
  const prefix = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const splitCookie = decodedCookie.split(';');

  for (let i = 0; i < splitCookie.length; i++) {
    let cookie = splitCookie[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(prefix) === 0) {
      const token = cookie.substring(prefix.length, cookie.length);
      return token;
    }
  }
  return '';
};
