import React, {useEffect, useMemo, useState, useCallback} from 'react';
import lodashOrdeby from 'lodash.orderby';
import {useTheme} from 'styled-components';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {BiCaretUp, BiCaretDown} from 'react-icons/bi';
import {Container, Text, Table, Button} from 'cc-web-components';

import {TableContainer, TableColumnSortable} from './styles';

import {ToggleButton} from '@/components/ToggleButton';
import {
  CalendarOutlinedIcon,
  ChevronDownLined,
  ChevronUpLined,
  OpenedBookIcon,
  CrossRedIcon,
  MinusBlueIcon,
  CheckGreenIcon,
  ArrowLeftOutlinedIcon,
  ArrowRightOutlinedIcon,
  PlusBlueIcon,
} from '@/components/Icons';
import Api from '@/services/ApiCv';
import useDeviceDetection from '@/hooks/IsMobile';
import {useAssignActivityContext} from '@/redux/AssignActivity';
import Helpers, {FilterTime, FilterTimeValues} from '@/helper';
import {getLicense, startLoading, stopLoading} from '@/redux/Session';

import TableActivities from './TableActivities';

import {
  SortConfig,
  StatusEnum,
  PlaylistProps,
  SortConfigDirection,
  SortConfigDirectionKeys,
} from './types';

import {parsePlaylistData} from './helpers';

const TabTaskKeys = {
  ACTIVE: 0,
  COMPLETED: 1,
};

type QuantiativoProps = {
  total_active: number;
  total_expired: number;
};

type Props = {
  activedTimeFilter: number;
};

const TabTasks: React.FC<Props> = ({activedTimeFilter}: Props) => {
  const {t} = useTranslation('cornelsen');
  const dispatch = useDispatch();
  const {colors} = useTheme();
  const license = useSelector(getLicense);
  const {isMobile} = useDeviceDetection();
  const {studentId} = useParams<{studentId: string}>();
  const [, dispatchPlaylist] = useAssignActivityContext();

  const [quantitativos, setQuantitativos] = useState<QuantiativoProps>({
    total_active: 0,
    total_expired: 0,
  });
  const [playlists, setPlaylists] = useState<PlaylistProps[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: null,
    direction: null,
  });
  const [datesFilterTime, setDatesFilterTime] = useState<FilterTime>();
  const [indexPlaylistDetails, setIndexPlaylistDetails] = useState(-1);
  const [activeIndexTableTasks, setActiveIndexTableTasks] = useState(0);

  const TABLE_FILTER = [
    <Text
      size={16}
      value={`${t('Student.TabTasks.SectionTasks.Buttons.Active')} (${quantitativos.total_active})`}
      color={activeIndexTableTasks === TabTaskKeys.ACTIVE ? '#FDFEFF' : '#1A73E8'}
      fontWeight={500}
    />,
    <Text
      size={16}
      value={`${t('Student.TabTasks.SectionTasks.Buttons.Done')} (${quantitativos.total_expired})`}
      color={activeIndexTableTasks === TabTaskKeys.COMPLETED ? '#FDFEFF' : '#1A73E8'}
      fontWeight={500}
    />,
  ];

  const LoadDataPlaylists = useCallback(
    (studentId: string, expired: boolean, datesFilterTime: FilterTime) => {
      dispatch(startLoading());

      Api.getStudentPlaylistsBy(studentId, expired, datesFilterTime)
        .then(({total_active, total_expired, content}) => {
          setQuantitativos({total_active, total_expired});
          setPlaylists(parsePlaylistData(content));
          dispatch(stopLoading());
        })
        .catch(error => {
          console.log(error);

          setPlaylists([]);

          dispatch(stopLoading());
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const filter = Helpers.getIntervalDatesByFilterIndex(activedTimeFilter);

    setDatesFilterTime(filter);
  }, [activedTimeFilter]);

  useEffect(() => {
    if (!studentId || !datesFilterTime) return;

    const hasExpired = activeIndexTableTasks === TabTaskKeys.COMPLETED;

    LoadDataPlaylists(studentId, hasExpired, datesFilterTime);
  }, [studentId, datesFilterTime, activeIndexTableTasks, LoadDataPlaylists]);

  const playslistsOrdered = useMemo(() => {
    if (!sortConfig.key || !sortConfig.direction) {
      return playlists;
    }

    return lodashOrdeby(playlists, sortConfig.key, sortConfig.direction);
  }, [playlists, sortConfig]);

  const RenderToggleButton = () => (
    <ToggleButton
      values={TABLE_FILTER}
      onToggle={setActiveIndexTableTasks}
      activeIndex={activeIndexTableTasks}
      style={{
        marginTop: '0',
      }}
    />
  );

  const onOpenDetailsTask = (index: number) => {
    if (index === indexPlaylistDetails) {
      setIndexPlaylistDetails(-1);
    } else {
      setIndexPlaylistDetails(index);
    }
  };

  const getIntervalTime = () => {
    if (!datesFilterTime || !datesFilterTime.startDate) return '';

    const startDate = Helpers.getFormatDate(datesFilterTime.startDate, 'dd.MM.yyyy');

    const endDate = Helpers.getFormatDate(datesFilterTime.endDate, 'dd.MM.yyyy', -1);

    if (activedTimeFilter === FilterTimeValues.TODAY) return startDate;

    return `${startDate} - ${endDate}`;
  };

  const onGetPrevValues = () => {
    if (!datesFilterTime) return;

    const newIntervalTime = Helpers.getPrevIntervalDates(activedTimeFilter, datesFilterTime);

    setDatesFilterTime(newIntervalTime);
  };

  const onGetNextValues = () => {
    if (!datesFilterTime) return;

    const newIntervalTime = Helpers.getNextIntervalDates(activedTimeFilter, datesFilterTime);

    setDatesFilterTime(newIntervalTime);
  };

  const TableHeader = () => (
    <Container flexDirection="column">
      <Container
        flex={1}
        minHeight={65}
        padding="0px 6px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          flex={0.3}
          variant="link"
          color="primary"
          onClick={onGetPrevValues}
          leftContent={
            activedTimeFilter !== FilterTimeValues.ALL ? <ArrowLeftOutlinedIcon /> : null
          }
        />

        <Text value={t('Student.TabTasks.SectionTasks.Table.Title')} size={16} fontWeight={600} />

        <Button
          flex={0.3}
          variant="link"
          color="primary"
          onClick={onGetNextValues}
          leftContent={
            activedTimeFilter !== FilterTimeValues.ALL ? <ArrowRightOutlinedIcon /> : null
          }
        />
      </Container>
      <Container
        height={49}
        padding="0px 20px"
        alignItems="center"
        justifyContent="center"
        backgroundColor="rgba(26, 115, 232, 0.1)"
      >
        <Text value={getIntervalTime()} color={colors.black} size={14} />
      </Container>
    </Container>
  );

  const TableFooter = () => (
    <Container
      borderTop={playslistsOrdered.length ? `2px solid #1A73E8` : '1px solid #eee'}
      justifyContent="flex-end"
      padding={1}
    >
      {!license?.expired && (
        <Button
          value={
            <Text
              size={18}
              marginRight={1}
              fontWeight={600}
              color={colors.primary}
              value={t('Student.TabTasks.SectionTasks.Buttons.NewTask')}
            />
          }
          variant="link"
          rightContent={<PlusBlueIcon />}
          onClick={() => dispatchPlaylist.toggleVisibleForm()}
        />
      )}
    </Container>
  );

  const requestSort = (key: keyof PlaylistProps) => {
    let direction: SortConfigDirection = SortConfigDirectionKeys.ASC;

    if (sortConfig.direction === SortConfigDirectionKeys.ASC) {
      direction = SortConfigDirectionKeys.DESC;
    }

    setIndexPlaylistDetails(-1);
    setSortConfig({key, direction});
  };

  const renderColumnSortable = (columnName: keyof PlaylistProps, children: any) => {
    const getIconsOrdered = () => {
      let opacityIconUp = 0.5;
      let opacityIconDown = 0.5;

      if (!!sortConfig.key && sortConfig.key === columnName) {
        opacityIconUp = sortConfig.direction === SortConfigDirectionKeys.ASC ? 1 : 0.5;
        opacityIconDown = sortConfig.direction === SortConfigDirectionKeys.DESC ? 1 : 0.5;
      }

      return (
        <Container flexDirection="column" marginRight={1}>
          <Container marginBottom={-2}>
            <BiCaretUp color={colors.primary} style={{opacity: opacityIconUp}} />
          </Container>

          <BiCaretDown color={colors.primary} style={{opacity: opacityIconDown}} />
        </Container>
      );
    };

    return (
      <TableColumnSortable onClick={() => requestSort(columnName)}>
        <Container flexDirection="column" marginRight={isMobile ? 0 : 1}>
          {getIconsOrdered()}
        </Container>

        {children}
      </TableColumnSortable>
    );
  };

  const formatDate = (date: string) => {
    return date.replace(/\//g, '.');
  };

  const feedbackCallback = () => {
    if (!studentId) return;

    const hasExpired = activeIndexTableTasks === TabTaskKeys.COMPLETED;

    dispatch(startLoading());

    Api.getStudentPlaylistsBy(studentId, hasExpired)
      .then(({content}) => {
        setPlaylists(parsePlaylistData(content));

        dispatch(stopLoading());
      })
      .catch(error => {
        console.log(error);
        dispatch(stopLoading());
      });
  };

  return (
    <Container flexDirection="column">
      <Container alignItems="center" justifyContent="space-between">
        <Text size={20} value={t('Student.TabTasks.SectionTasks.Title')} fontWeight={600} />

        <RenderToggleButton />
      </Container>

      <TableContainer>
        <Table
          id="tablePlaylist"
          flex={1}
          data={playslistsOrdered}
          header={<TableHeader />}
          footer={<TableFooter />}
          columns={[
            {
              header: renderColumnSortable(
                'date',
                <>{t('Student.TabTasks.SectionTasks.Table.Header.Date')}</>,
              ),
              renderItem: (item: PlaylistProps, index: number) => (
                <Container alignItems="center" paddingLeft="20px">
                  <CalendarOutlinedIcon width={15} />

                  <Container>
                    <Text
                      size={16}
                      marginLeft={2}
                      value={formatDate(item.date)}
                      fontWeight={index === indexPlaylistDetails ? 600 : 500}
                    />
                    <Text
                      size={16}
                      marginLeft={3}
                      value={item.time}
                      fontWeight={index === indexPlaylistDetails ? 600 : 500}
                    />
                  </Container>
                </Container>
              ),
            },
            // {
            //   header: <>{t('Student.TabTasks.SectionTasks.Table.Header.Page')}</>,
            //   renderItem: (item: PlaylistProps, index: number) => (
            //     <Text
            //       size={16}
            //       value={item.seite_number}
            //       fontWeight={index === indexPlaylistDetails ? 600 : 500}
            //     />
            //   ),
            // },
            {
              header: <>{t('Student.TabTasks.SectionTasks.Table.Header.Task')}</>,
              renderItem: (item: PlaylistProps, index: number) => (
                <Container alignItems="center">
                  <OpenedBookIcon width={20} />

                  <Text
                    size={16}
                    color={colors.primary}
                    value={item.task_title}
                    marginLeft={2}
                    fontWeight={index === indexPlaylistDetails ? 600 : 500}
                  />
                </Container>
              ),
            },
            {
              header: renderColumnSortable(
                'correct_percent',
                <>{t('Student.TabTasks.SectionTasks.Table.Header.Correctly')}</>,
              ),
              renderItem: (item: PlaylistProps) => (
                <Text size={16} fontWeight={600} value={`${item.correct_percent.toFixed(0)} %`} />
              ),
            },
            {
              header: renderColumnSortable(
                'status',
                <>{t('Student.TabTasks.SectionTasks.Table.Header.Status')}</>,
              ),
              renderItem: (item: PlaylistProps) => {
                let Icon = <MinusBlueIcon width={17} />;

                if (item.status_translated === StatusEnum.COMPLETED) Icon = <CheckGreenIcon />;
                if (item.status_translated === StatusEnum.OPENED) Icon = <CrossRedIcon />;
                if (item.status_translated === StatusEnum.PROGRESS)
                  Icon = <MinusBlueIcon width={15} />;

                return (
                  <Container alignItems="center" paddingLeft={3}>
                    {Icon}

                    <Text
                      size={16}
                      value={t(`Globals.Types.Status.${item.status_translated}`)}
                      marginLeft={2}
                    />
                  </Container>
                );
              },
            },
            {
              align: 'right',
              header: null,
              renderItem: (_item: PlaylistProps, index: number) => (
                <Container justifyContent="flex-end">
                  <Button
                    height="100%"
                    variant="link"
                    leftContent={
                      index === indexPlaylistDetails ? (
                        <ChevronUpLined width={20} />
                      ) : (
                        <ChevronDownLined width={20} />
                      )
                    }
                    onClick={() => onOpenDetailsTask(index)}
                  />
                </Container>
              ),
            },
          ]}
          indexOpenContent={indexPlaylistDetails}
          renderExtraContent={() => {
            if (indexPlaylistDetails < 0) return <></>;

            const playlist = playslistsOrdered[indexPlaylistDetails];

            return (
              <TableActivities
                callback={feedbackCallback}
                playlistId={playlist.id}
                activities={playlist.payload.activities}
              />
            );
          }}
        />
      </TableContainer>
    </Container>
  );
};

export default TabTasks;
