import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Button, Container, Input, Text} from 'cc-web-components';
import {useFormik} from 'formik';
import {useMatch} from 'react-router-dom';

import {StudentInput} from './styles';
import {ArrowLeftOutlinedIcon, PlusBlueIcon, TrashOutlinedIcon} from '@/components/Icons';
import Tooltip from '@/components/Tooltip';
import {Routes} from '@/shared';
import {Loader} from '@/components';
import {getIsHideFeaturesUser, getLoading} from '@/redux/Session';

const {NEW_CLASSROOM} = Routes;
const studentsEmptyGroup = Array(5).fill({name: '', level: 'intermediate'});

export interface Props {
  addStudentsRequest: (addedStudents: any[]) => Promise<void>;
  hideStudentsForm: () => void;
}

const AddStudentPage: React.FC<Props> = ({addStudentsRequest, hideStudentsForm}) => {
  const {t} = useTranslation('cornelsen');
  const IsShowFeaturesUser = !useSelector(getIsHideFeaturesUser);
  const [nextTextAreaIndex, setNextTextAreaIndex] = useState(0);
  const isLevelDescriptionShown = localStorage.getItem('levelDescriptionShown') === 'true';
  const match = useMatch(`${NEW_CLASSROOM}/:classroomId`);
  const classroomId = match?.params.classroomId;
  const studentsString = classroomId ? sessionStorage.getItem(classroomId) : undefined;
  const isLoading = useSelector(getLoading);
  const [isRedBorder, setIsRedBorder] = useState(false);

  const submitForm = () => {
    if (filledStudents.length === 0) {
      setIsRedBorder(true);
      return;
    }
    addStudentsRequest(filledStudents).then(() => {
      classroomId && sessionStorage.removeItem(classroomId);
    });
  };

  const {setValues, values, handleChange, handleBlur} = useFormik({
    initialValues: {
      students: studentsString ? JSON.parse(studentsString) : [...studentsEmptyGroup],
    },
    onSubmit: submitForm,
  });
  const filledStudents = values.students?.filter((student: any) => {
    return student.name.trim() !== '';
  });

  const focusNextTextArea = useCallback(() => {
    const nextTextArea = document.getElementById(`students[${nextTextAreaIndex}].id`);

    if (nextTextArea) {
      nextTextArea.focus();
    }
  }, [nextTextAreaIndex]);

  useEffect(() => {
    focusNextTextArea();
  }, [focusNextTextArea]);

  const addStudentInputRows = () => {
    setValues({...values, students: [...values.students, ...studentsEmptyGroup]});
  };

  const handleLevelChange = (e: React.ChangeEvent<any>) => {
    handleChange(e);
    localStorage.setItem('levelDescriptionShown', 'true');
  };

  useEffect(() => {
    classroomId && sessionStorage.setItem(classroomId, JSON.stringify(values.students));
  }, [values.students, classroomId]);

  const handleNameChange = (e: React.ChangeEvent<any>) => {
    handleChange(e);
  };

  return (
    <Container flexDirection="column">
      <Button
        marginBottom="20px"
        padding={'10px 16px 10px 12px'}
        fontSize={16}
        fontWeight={400}
        type="button"
        variant="link"
        value={t('Classroom.TabOverview.SectionCreateStudent.Back')}
        onClick={hideStudentsForm}
        leftContent={
          <Container marginRight={'4px'}>
            <ArrowLeftOutlinedIcon height={24} width={24} />
          </Container>
        }
      />

      <Text
        value={t('Classroom.StudentsScore.ButtonFooterText')}
        size={20}
        fontWeight={600}
        marginBottom="8px"
      />
      {isRedBorder && (
        <Text
          value={t('Classroom.TabOverview.SectionCreateStudent.EmptyFieldError')}
          size={18}
          fontWeight={400}
          marginBottom="8px"
          color="#C62828"
        />
      )}
      <Container flex={1} height={61} bg="#fff" borderBottom="1px solid #e5e5e5">
        <Container flex={1} alignItems="center" height={61} pl={[14, 14, 40, 40]}>
          <Text
            value={`${t('Classroom.TabOverview.SectionCreateStudent.Form.StudentDesc')} (${
              filledStudents.length
            })`}
            size={18}
            fontWeight={700}
          />
        </Container>
        {IsShowFeaturesUser && (
          <Container
            flex={1}
            alignItems="center"
            justifyContent="space-around"
            height={61}
            pr={[40, 40, 82, 82]}>
            <Container position="relative" height="100%" alignItems="center">
              <Text
                value={t('Classroom.TabOverview.SectionCreateStudent.Form.Level1')}
                fontSize={[12, 12, 12, 16]}
              />
              {!isLevelDescriptionShown && (
                <Tooltip
                  text={t('Classroom.TabOverview.SectionCreateStudent.CreateStudentDescription')}
                />
              )}
            </Container>
            <Text
              value={t('Classroom.TabOverview.SectionCreateStudent.Form.Level2')}
              fontSize={[12, 12, 12, 16]}
            />
            <Text
              value={t('Classroom.TabOverview.SectionCreateStudent.Form.Level3')}
              fontSize={[12, 12, 12, 16]}
            />
          </Container>
        )}
      </Container>

      <Container flexDirection="column">
        {values.students.map((student: any, index: number) => (
          <Container
            key={`student-${index}`}
            flex={1}
            height={61}
            bg="#fff"
            borderBottom="1px solid #e5e5e5">
            <Container flex={1} alignItems="center" height={61} ml={[14, 14, 40, 40]}>
              <StudentInput
                isRedBorder={isRedBorder && index === 0}
                name={`students[${index}].name`}
                id={`students[${index}].id`}
                placeholder={t('Classroom.TabOverview.SectionCreateStudent.Form.InputPlaceholder')}
                onChange={handleNameChange}
                onBlur={handleBlur}
                onFocus={() => setIsRedBorder(false)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    setNextTextAreaIndex(index + 1);
                  }
                }}
                value={student.name}
              />
            </Container>
            {IsShowFeaturesUser && (
              <Container
                flex={1}
                alignItems="center"
                justifyContent="space-around"
                height={60}
                marginRight="20px">
                <Input
                  type="radio"
                  name={`students[${index}].level`}
                  onChange={handleLevelChange}
                  onBlur={handleBlur}
                  value={t('Globals.Types.StudentLevel.beginner').toLowerCase()}
                  checked={student.level === t('Globals.Types.StudentLevel.beginner').toLowerCase()}
                />
                <Input
                  type="radio"
                  name={`students[${index}].level`}
                  onChange={handleLevelChange}
                  onBlur={handleBlur}
                  value={t('Globals.Types.StudentLevel.intermediate').toLowerCase()}
                  checked={
                    student.level === t('Globals.Types.StudentLevel.intermediate').toLowerCase()
                  }
                />
                <Input
                  type="radio"
                  name={`students[${index}].level`}
                  onChange={handleLevelChange}
                  onBlur={handleBlur}
                  value={t('Globals.Types.StudentLevel.advanced').toLowerCase()}
                  checked={student.level === t('Globals.Types.StudentLevel.advanced').toLowerCase()}
                />
              </Container>
            )}
            <Button
              margin={2}
              padding={10}
              variant="link"
              leftContent={<TrashOutlinedIcon width={24} height={24} />}
              onClick={() => {
                setValues(prevState => ({
                  ...prevState,
                  students: prevState.students.filter((_: any, i: number) => index !== i),
                }));
              }}
            />
          </Container>
        ))}
      </Container>
      <Container justifyContent={'space-between'} marginTop={'20px'} marginLeft={'20px'}>
        <Button
          padding={'10px 16px 10px 12px'}
          fontSize={16}
          fontWeight={400}
          type="button"
          variant="link"
          value={t('Classroom.TabOverview.SectionCreateStudent.AddStudentPlaceholders')}
          onClick={addStudentInputRows}
          leftContent={
            <Container marginRight={'4px'}>
              <PlusBlueIcon height={24} width={24} />
            </Container>
          }
        />
        <Button
          padding={'12px 16px'}
          fontSize={16}
          fontWeight={400}
          borderRadius={'22px'}
          type="button"
          variant="solid"
          value={t('Classroom.TabOverview.SectionCreateStudent.CreateStudentButtonText')}
          onClick={submitForm}
          style={{backgroundColor: '#0163E5'}}
        />
      </Container>
      <Loader isVisible={isLoading} />
    </Container>
  );
};

export default AddStudentPage;
