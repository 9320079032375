import React, {useEffect, useState} from 'react';
import {TimelineItem} from 'cc-web-components/dist/components/Timeline/types';
import {VscArrowRight} from 'react-icons/vsc';
import {useTranslation} from 'react-i18next';
import {useMatch} from 'react-router-dom';
import {Button, Container, Text, Timeline} from 'cc-web-components';

import Api from '@/services/ApiCv';
import {WarningRedIcon} from '@/components/Icons';
import {useAssignActivityContext} from '@/redux/AssignActivity';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import {startLoading, stopLoading} from '@/redux/Session';
import {useDispatch} from 'react-redux';
import {Routes} from '@cc/shared';

const {CLASSROOM, NEW_CLASSROOM, BOOK} = Routes;

type TabProps = {
  onClose: () => void;
};

const ErrorStep = (props: any) => {
  return (
    <Container style={{minHeight: 120}} alignItems="center" backgroundColor="#f6d6d3" pl={3} pr={3}>
      <WarningRedIcon />

      <Text marginLeft={16} color={'rgb(211, 0, 33)'} value={props.value} />
    </Container>
  );
};

const TabNewTask: React.FC<TabProps> = ({onClose}: TabProps) => {
  const dispatch = useDispatch();
  const {t} = useTranslation('cornelsen');
  const [
    {playlist, payload, playlistCreate, hasErrorStepThree, stepError},
    {updatePayloadForm, ...dispatchPlaylist},
  ] = useAssignActivityContext();

  const STEPS = {
    Keys: {
      STEP_0: 0,
      STEP_1: 1,
      STEP_2: 2,
    },
    Default: [
      {
        indicator: '1',
        label: t('AssignActivity.Tabs.NewTask.Step1.Title'),
        disabled: false,
      },
      {
        indicator: '2',
        label: t('AssignActivity.Tabs.NewTask.Step2.Title'),
        disabled: true,
      },
      {
        indicator: '3',
        label: t('AssignActivity.Tabs.NewTask.Step3.Title'),
        disabled: true,
      },
    ],
  };

  const [stepsList, setStepsList] = useState<TimelineItem[]>(() => {
    if (!playlist) return STEPS.Default;

    return STEPS.Default.map(item => ({...item, disabled: false}));
  });
  const [stepActived, setStepActived] = useState(0);
  const [buttonClickable, setButtonClickable] = useState(false);
  const [bookId, setBookId] = useState('');
  const [classId, setClassId] = useState('');

  const match = useMatch(`${NEW_CLASSROOM}/:classroomId`);
  const matchStudentsDetails = useMatch(`${CLASSROOM}/:classroomId/student/:studentId`);
  const matchBook = useMatch(`${BOOK}/:id`);
  const classroomId = match?.params.classroomId;
  const classroomIdStudentsDetails = matchStudentsDetails?.params.classroomId;
  const studentIdStudentsDetails = matchStudentsDetails?.params.studentId;
  const bookParamId = matchBook?.params.id;

  useEffect(() => {
    if (!playlistCreate) return;

    setBookId(playlistCreate.bookId);
    setClassId(playlistCreate.classroom_id);
  }, [playlistCreate]);

  useEffect(() => {
    if (!classroomId) return;

    updatePayloadForm({classroom_id: classroomId});
  }, [classroomId, updatePayloadForm]);

  useEffect(() => {
    if (!bookId) return;

    updatePayloadForm({bookId});
  }, [bookId, updatePayloadForm]);

  useEffect(() => {
    if (!classroomIdStudentsDetails) return;

    updatePayloadForm({classroom_id: classroomIdStudentsDetails});
  }, [classroomIdStudentsDetails, updatePayloadForm]);

  useEffect(() => {
    const getClassroom = (id: string) => {
      Api.getClassroom(id)
        .then(data => {
          if (data) {
            setBookId(data.mainCourseId);
            setClassId(data.id);
          }
        })
        .catch(error => {
          console.log(error.message);
        });
    };

    if (classroomId) {
      getClassroom(classroomId);
    }

    if (classroomIdStudentsDetails) {
      getClassroom(classroomIdStudentsDetails);
    }
    if (bookParamId) {
      setBookId(bookParamId);
    }
  }, [classroomId, classroomIdStudentsDetails, bookParamId]);

  useEffect(() => {
    return () => {
      setStepActived(0);
    };
  }, []);

  const onSubmitCreate = async () => {
    dispatch(startLoading());
    try {
      dispatchPlaylist.savePlaylist().then(() => {
        dispatchPlaylist.clearPayloadForm();
        onClose();

        if (payload.student_ids.length === 1) {
          window.location.href = `${CLASSROOM}/${payload.classroom_id}/student/${payload.student_ids[0]}?redirectTo=tasks`;
        } else {
          window.location.href = `${NEW_CLASSROOM}/${payload.classroom_id}?redirectTo=tasks`;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmitUpdate = async () => {
    dispatch(stopLoading());
    try {
      dispatchPlaylist.updatePlaylist().then(() => {
        dispatchPlaylist.clearPayloadForm();
        onClose();

        if (payload.student_ids.length === 1) {
          window.location.href = `${CLASSROOM}/${payload.classroom_id}/student/${payload.student_ids[0]}?redirectTo=tasks`;
        } else {
          window.location.href = `${NEW_CLASSROOM}/${payload.classroom_id}?redirectTo=tasks`;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeStep = (newIndex: number) => {
    setStepsList(STEPS.Default);
    setStepActived(newIndex);
  };

  const onNext = () => {
    const nextStepIndex = stepActived + 1;

    if (nextStepIndex === stepsList.length) {
      if (!!playlist) {
        onSubmitUpdate();

        return;
      }

      onSubmitCreate();

      return;
    }

    const steps = [...stepsList];

    steps[nextStepIndex].disabled = false;

    setStepsList(steps);

    setStepActived(nextStepIndex);
  };

  const onPrev = () => {
    dispatchPlaylist.clearPayloadForm();

    onClose();
  };

  const getButtonText = () => {
    switch (stepActived) {
      case STEPS.Keys.STEP_0: {
        return t('AssignActivity.Tabs.NewTask.Step1.ButtonSubmitText');
      }
      case STEPS.Keys.STEP_1: {
        return t('AssignActivity.Tabs.NewTask.Step2.ButtonSubmitText');
      }
      case STEPS.Keys.STEP_2: {
        return !playlist
          ? t('AssignActivity.Tabs.NewTask.Step3.ButtonSubmitText')
          : t('AssignActivity.Tabs.NewTask.Step3.ButtonEditSubmitText');
      }
      default: {
        return t('AssignActivity.Tabs.NewTask.Step1.ButtonSubmitText');
      }
    }
  };

  const setButtonActive1 = (buttonActive: boolean) => {
    if (stepActived === STEPS.Keys.STEP_0) setButtonClickable(buttonActive);
  };

  const setButtonActive2 = (buttonActive: boolean) => {
    if (stepActived === STEPS.Keys.STEP_1) {
      setButtonClickable(buttonActive);
    }
  };

  const setButtonActive3 = (buttonActive: boolean) => {
    if (stepActived === STEPS.Keys.STEP_2) setButtonClickable(buttonActive);
  };

  return (
    <Container height={'calc(100vh - 118px)'} overflowY="auto" flexDirection="column">
      {(hasErrorStepThree && stepActived === STEPS.Keys.STEP_2) || !!stepError ? (
        <ErrorStep
          value={!!stepError ? stepError.text : t('AssignActivity.Tabs.NewTask.FormErrorFields')}
        />
      ) : (
        <Container backgroundColor="#f7f7f7" pl="23px" pb={2} pr="23px">
          <Timeline data={stepsList} defaultIndex={stepActived} onChange={onChangeStep} />
        </Container>
      )}

      <Container flex={1} overflowY="auto">
        <Container flex={1} display={stepActived === STEPS.Keys.STEP_0 ? 'block' : 'none'}>
          <StepOne bookId={bookId} setButtonActive={setButtonActive1} />
        </Container>

        <Container flex={1} display={stepActived === STEPS.Keys.STEP_1 ? 'block' : 'none'}>
          <StepTwo
            studentId={studentIdStudentsDetails}
            classId={classId}
            setButtonActive={setButtonActive2}
          />
        </Container>

        <Container flex={1} display={stepActived === STEPS.Keys.STEP_2 ? 'block' : 'none'}>
          <StepThree setButtonActive={setButtonActive3} />
        </Container>
      </Container>

      <Container
        paddingTop={4}
        paddingBottom={3}
        alignItems="center"
        flexDirection="column"
        justifyContent="center">
        <Button
          fontSize={16}
          fontWeight={500}
          minWidth={170}
          type="button"
          variant="solid"
          value={getButtonText()}
          onClick={onNext}
          leftContent={
            <Container marginRight={2}>
              <VscArrowRight size={19} />
            </Container>
          }
          disabled={!buttonClickable}
          style={{opacity: buttonClickable ? 1 : 0.5}}
        />

        <Button
          type="button"
          value={t('AssignActivity.Tabs.NewTask.ButtonCancelText')}
          minWidth={170}
          variant="link"
          onClick={onPrev}
          fontSize={16}
          marginTop={3}
          fontWeight={500}
          style={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 0 1px rgba(0,0,0,0.05), 0 3px 7px 0 rgba(0,0,0,0.10)',
            borderRadius: '20px',
          }}
        />
      </Container>
    </Container>
  );
};

export default TabNewTask;
