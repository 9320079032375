import styled from 'styled-components'

export const AudioPlaerControllerWrapper = styled.div`
  svg {
    color: #1a73e8;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .audioplayer__time {
    margin-left: 12px;
  }

  progress {
    display: none;
  }
`

export const InputRangeWrapper = styled.div`
  width: 190px;
  margin-left: 8px;

  [data-reach-slider-input][data-orientation='horizontal'] {
    width: calc(100% - 6px);
    height: 5px;
  }
  .hqtksP [data-reach-slider-handle] {
    width: 6px;
    height: 6px;
  }
`
export const AudioPlayerWrapper = styled.div`
  width: 310px;

  .rhap_container {
    padding: 4px 0;
    background-color: transparent;
    box-shadow: none;
  }

  .rhap_controls-section {
    flex: 0;
  }

  .rhap_play-pause-button {
    font-size: 24px;
    width: 24px;
    height: 24px;
    margin: 0;
  }

  .rhap_progress-indicator {
    background: #1a73e8;
    box-shadow: none;
    width: 18px;
    height: 18px;
    top: -7px;
  }

  .rhap_button-clear {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rhap_progress-bar {
    background-color: rgba(26, 115, 232, 0.2);
  }

  .rhap_progress-filled {
    background-color: #1a73e8;
  }

  .rhap_time {
    color: #000;
  }

  svg {
    color: #1a73e8;
    cursor: pointer;
  }
`
