import styled from 'styled-components'

export const BookCover = styled.img`
  width: 32px;
  border: 6px solid #a9dbf5;
  background: white;
  padding: 14px;
  border-radius: 50%;
  margin-bottom: -5px;

  @media screen and (min-width: 768px) {
    width: 48px;
    border: 12px solid #a9dbf5;
    padding: 30px;
  }

  @media screen and (min-width: 1300px) {
    margin-bottom: 47px;
  }
`
