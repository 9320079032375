import {EMPTY_STRING} from '@cc/shared';

const BY_DOT = '.';
const envPrefix = 'app.chatclass';
const getEnvFromPath = (path: string) => {
  if (!path) {
    return EMPTY_STRING;
  }

  const potentialEnv = path.split(envPrefix)[1]?.split(BY_DOT)[1];

  if (potentialEnv === 'dev') {
    return '.development';
  }

  if (potentialEnv === 'stage') {
    return '.staging';
  }

  return EMPTY_STRING;
};

const HTTPS = 'https://';
const prefix = 'intent://';
const getSuffix = (env: string) => {
  return `#Intent;scheme=https;package=de.cornelsen.chatclass${env};end`;
};

export const getAlternativeLink = (pathName: string) => {
  const baseLink = pathName.split(HTTPS)[1];
  const env = getEnvFromPath(baseLink);
  return prefix + baseLink + getSuffix(env);
};
