import { useEffect, useState } from 'react'

import { ColorSelectorList, ColorSelectorItem } from './styles'
import { ColorSelectorProps } from './types'

import Images from '@/assets/images'

const COLORS = [
  { name: 'blue', image: Images.SkyColor },
  { name: 'teal', image: Images.TealColor },
  { name: 'green', image: Images.GreenColor },
  { name: 'orange', image: Images.OrangeColor },
]

export const ColorSelector: React.FC<ColorSelectorProps> = ({ activeColor, callback }) => {
  const [activeColorIndex, setActiveColorIndex] = useState<number | null>(null)

  useEffect(() => {
    setActiveColorIndex(COLORS.findIndex(color => color.name === activeColor))
  }, [activeColor])

  return (
    <ColorSelectorList>
      {
        COLORS.map((color, index) => (
          <ColorSelectorItem
            key={index}
            onClick={() => callback(color.name)}
            active={activeColorIndex === index}
          >            
            <img src={color.image} alt="" />
          </ColorSelectorItem>
        ))
      }
    </ColorSelectorList>
  );
}
    