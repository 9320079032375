import React, {useCallback, useLayoutEffect, useRef, useState} from 'react';
import {useTheme} from 'styled-components';
import {IoInformationCircleOutline} from 'react-icons/io5';
import {
  InfoBoxContainer,
  InfoBoxContent,
  InfoBoxIcon,
  TooltipPartBottom,
  Wrapper,
  TooltipPartAbove,
  TooltipText,
} from './styles';
import ScoreIconExample from '@/components/ScoreIconExample';
import {useTranslation} from 'react-i18next';
import useDeviceDetection from '@/hooks/IsMobile';

export interface Props {
  middleTooltipMobile?: boolean;
}

const HealthScore: React.FC<Props> = ({middleTooltipMobile = false}) => {
  const [isOpen, setOpen] = useState(false);
  const {colors} = useTheme();
  const infoIconRef = useRef<any>();
  const [isBottomTooltip, setIsBottomTooltip] = useState(true);
  const {t} = useTranslation('cornelsen');
  const {isMobile} = useDeviceDetection();
  const middleStyle = isMobile && middleTooltipMobile;

  useLayoutEffect(() => {
    function updateSize() {
      const measures = infoIconRef && infoIconRef.current.getBoundingClientRect();
      const y = measures && measures.y ? measures.y : 0;
      const height = measures && measures.height ? measures.height : 0;
      const yOfBottomTooltip = y + height + 8;
      const bottomTooltip = window.innerHeight - yOfBottomTooltip > 138;
      setIsBottomTooltip(bottomTooltip);
    }
    window.addEventListener('resize', updateSize);
    window.addEventListener('scroll', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('scroll', updateSize);
    };
  }, []);

  const onMouseEnter = useCallback(() => {
    setOpen(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <InfoBoxContainer>
        <InfoBoxIcon
          ref={ref => {
            infoIconRef.current = ref;
          }}>
          <IoInformationCircleOutline
            onClick={event => {
              event.stopPropagation();
              setOpen(true);
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            color={colors.primary}
            size={20}
            tabIndex={0}
          />
        </InfoBoxIcon>
        <Wrapper
          isOpen={isOpen}
          isBottomTooltip={isBottomTooltip}
          middleTooltipMobile={middleStyle}>
          {isBottomTooltip && <TooltipPartAbove middleTooltipMobile={middleStyle} />}
          <InfoBoxContent>
            <TooltipText value={t('Classroom.StudentsScore.InfoHealthScore.Part1')} />
            <ScoreIconExample />
          </InfoBoxContent>
          {!isBottomTooltip && <TooltipPartBottom middleTooltipMobile={middleStyle} />}
        </Wrapper>
      </InfoBoxContainer>
    </>
  );
};

export default HealthScore;
