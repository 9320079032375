import { useCallback, useEffect, useState } from 'react'

const useDeviceDetectionSm = () => {
  const [isSmall, setMobile] = useState(false)

  const handleResize = useCallback(() => {
    setMobile(window.innerWidth <= 425)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [isSmall, handleResize])

  return { isSmall }
}

export default useDeviceDetectionSm
