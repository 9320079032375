import React, { useEffect, useState } from 'react'
// import debounce from 'lodash.debounce'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Container, Text, Select, InputCheckbox, Button } from 'cc-web-components'

import { AssignActivityState, AssignActivityDispatch } from '@/redux/AssignActivity/types'
import {
  Book,
  BookDetail,
  BookDetailUnit,
  BookDetailSection,
  BookDetailActivity,
} from '@/services/ApiCv/types/Book'
import ApiBook from '@/services/ApiCv/ApiBooksService'
import {
  BookPileIcon,
  BlueBookIcon,
  ChevronUpLined,
  ChevronDownLined,
  WorkSpeechIcon,
  WorkSpokenIcon,
} from '@/components/Icons'
import { BookSelector } from '../../TabNewClass/Components'
import { useCreateClassroom } from '../../TabNewClass/Context'

type Props = {
  state: AssignActivityState
  dispatch: AssignActivityDispatch
  bookId: string
  setButtonActive: (buttonActive: boolean) => void
}

// const INITIAL_TARGET_SCORE = 85

const StepOneEdit: React.FC<Props> = ({ dispatch, ...props }: Props) => {
  const { t } = useTranslation('cornelsen')
  const { colors } = useTheme()
  const { updatePayloadForm, updatePlaylistBookId } = dispatch
  const { collections, activeCollection, setActiveCollection, books } = useCreateClassroom()
  const { setButtonActive, bookId } = props
  const { playlist } = props.state

  const [bookFamily, setBookFamily] = useState<string>()
  const [units, setUnits] = useState<BookDetailUnit[]>([])
  const [sections, setSections] = useState<BookDetailSection[]>([])
  const [allActivitiesSelected, setAllActivitiesSelected] = useState<boolean[]>([])
  const [selectedActivities, setSelectedActivities] = useState<BookDetailActivity[]>([])

  const [bookIndex, setBookIndex] = useState(-1)
  const [unitIndex, setUnitIndex] = useState(-1)
  const [sectionIndex, setSectionIndex] = useState(-1)

  // const [targetScore, setTargetScore] = useState(() => {
  //   if (!playlist.info.goal) return INITIAL_TARGET_SCORE

  //   return playlist.info.goal
  // })
  // const [targetScoreEnable, setTargetScoreEnable] = useState(() => {
  //   if (!playlist.info.goal) {
  //     return {
  //       sim: false,
  //       nao: false,
  //     }
  //   }

  //   return {
  //     sim: true,
  //     nao: false,
  //   }
  // })

  // const debouceChangePayload = useRef(
  //   debounce((payload: any) => updatePayloadForm(payload), 1000)
  // ).current

  useEffect(() => {
    if (!collections.length) return

    const { course_id } = playlist.info

    const collectionIndex = collections.findIndex((collection) =>
      collection.books.find((book) => book.id === course_id)
    )

    setActiveCollection(collectionIndex)

    const bookIndex =
      collectionIndex > -1
        ? collections[collectionIndex].books.findIndex((book) => book.id === course_id)
        : 0

    updatePlaylistBookId(course_id)
    setBookIndex(bookIndex)

    ApiBook.getBookById(course_id).then((bookDetail) => {
      if (!bookDetail.units.length) return

      setBookFamily(bookDetail.book_family)
      setUnits(bookDetail.units)

      if (!!playlist.activities.length) {
        const unitIndex = bookDetail.units.findIndex((item) => item.id === playlist.info.unit_id)
        const bookSections = bookDetail.units[unitIndex].sections
        const activities = [] as BookDetailActivity[]

        playlist.info.content.modules[0].units[0].todos.forEach((playlistActivity) => {
          const sectionIndex = bookSections.findIndex((section) =>
            section.section_name.includes(playlistActivity.pages)
          )

          bookSections[sectionIndex].activities.forEach((activity) => {
            if (activity.id === playlistActivity.activity_id) {
              activity.selected = true
              activity.section_name = playlistActivity.pages
              activities.push(activity)
            }
          })
        })

        const sectionsFiltered = bookSections.filter((section) => section.activities.length)
        const isAllSelected = sectionsFiltered.map((section) =>
          section.activities.every((activity) => activity.selected === true)
        )

        setAllActivitiesSelected(isAllSelected)
        setSections(bookSections.filter((section) => !!section.activities.length))
        setSelectedActivities(activities)
      }
    })
  }, [books, collections, playlist, setActiveCollection, updatePayloadForm, updatePlaylistBookId])

  useEffect(() => {
    const isAnyActivitySelected = selectedActivities.length

    if (isAnyActivitySelected > 0) {
      setButtonActive(true)
    }
    if (isAnyActivitySelected === 0) {
      setButtonActive(false)
    }
  }, [selectedActivities, setButtonActive])

  useEffect(() => {
    if (!!selectedActivities.length) {
      updatePayloadForm({ activities: selectedActivities })
    }
  }, [updatePayloadForm, selectedActivities])

  useEffect(() => {
    if (!units.length) return

    const unitIndex = units.findIndex((item) => item.id === playlist.info.unit_id)

    setUnitIndex(unitIndex)
  }, [playlist, units])

  const onSelectBook = (_index: number, book: Book) => {
    const findedIndex = books.findIndex((item) => item.id === book.id)

    updatePlaylistBookId(book.id)
    setBookIndex(findedIndex)

    updatePayloadForm({ bookId: book.id })

    ApiBook.getBookById(book.id).then((bookDetail) => {
      setBookFamily(bookDetail.book_family)
      setUnits(bookDetail.units)
      setUnitIndex(-1)
      setSectionIndex(-1)
    })
  }

  const onSelectUnit = (_index: number, bookUnit: BookDetailUnit) => {
    const { id, name } = bookUnit

    // if (!!bookUnit.locked) {
    //   setButtonActive(false)

    //   toggleErrorSteps({
    //     type: 'STEPS',
    //     text: t('Book.UnitLockedError'),
    //   })
    // } else {
    //   toggleErrorSteps(undefined)
    // }

    const findedIndex = units.findIndex((item) => item.id === bookUnit.id)

    setUnitIndex(findedIndex)
    setSectionIndex(-1)

    setSections(bookUnit.sections.filter((section) => !!section.activities.length))

    updatePayloadForm({
      unit: { id, name },
    })
  }

  const onSelectActivity = (index: number, checked: boolean) => {
    const activitiesLocal = sections[sectionIndex].activities.map((activity, activityIndex) =>
      activityIndex === index
        ? { ...activity, selected: checked, section_name: sections[sectionIndex].section_name }
        : { ...activity }
    )

    const sectionsLocal = sections.map((item, itemIndex) =>
      itemIndex === sectionIndex ? { ...item, activities: activitiesLocal } : { ...item }
    )

    const isAllChecked = activitiesLocal.every((activity) => activity.selected === true)
    const activity = activitiesLocal[index]
    const activityIndex = selectedActivities.findIndex((item) => item.id === activity.id)

    if (activityIndex === -1) {
      setSelectedActivities([...selectedActivities, activity])
    } else {
      setSelectedActivities(selectedActivities.filter((item) => item.id !== activity.id))
    }

    allActivitiesSelected[sectionIndex] = isAllChecked
    setAllActivitiesSelected(allActivitiesSelected)
    setSections(sectionsLocal)
  }

  const onSelectAllActivities = (checked: boolean, index: number) => {
    const selectedSection = sections[index]

    selectedSection.activities.forEach((activity) => (activity.selected = checked))

    sections.splice(index, 1, selectedSection)
    allActivitiesSelected[index] = checked

    if (checked) {
      const activities = selectedSection.activities.filter(
        (activity) => !selectedActivities.some((item) => activity.id === item.id)
      )
      activities.forEach((activity) => (activity.section_name = selectedSection.section_name))
      setSelectedActivities(selectedActivities.concat(activities))
    } else {
      const activities = selectedActivities.filter(
        (activity) => !selectedSection.activities.some((item) => activity.id === item.id)
      )
      activities.forEach((activity) => (activity.section_name = selectedSection.section_name))
      setSelectedActivities(activities)
    }

    setSections(sections)
    setAllActivitiesSelected(allActivitiesSelected)
  }

  const getTypeText = (item: BookDetailActivity) => {
    if (item.type === 'AI_SPEAKING') return t('Globals.Types.SpeakTraining')

    if (item.type === 'LISTENING') return t('Globals.Types.Listening')

    if (item.type === 'DIALOG') return t('Globals.Types.Dialog')

    if (item.type === 'PRONUNCIATION') return t('Globals.Types.PronunceTraining')

    if (item.type === 'VOCABULARY_TRAINER') return t('Globals.Types.VocabTraining')

    if (item.type === 'GRAMMAR') return t('Globals.Types.GrammarTraining')

    return ''
  }

  const openSection = (index: number) => {
    if (sectionIndex === index) {
      setSectionIndex(-1)
    } else {
      setSectionIndex(index)
    }
  }

  const handleActiveCollection = (item: Book, index: number) => {
    setActiveCollection(index)
    setBookIndex(-1)
    setUnitIndex(-1)
    setSectionIndex(-1)
    setUnits([])
    setSections([])
    setSelectedActivities([])
    setAllActivitiesSelected([])
  }

  const RenderSections = () => {
    return (
      <Container flexDirection="column" style={{ borderTop: '1px solid #ddd' }}>
        {sections.map((item, index) => (
          <Container key={`${item.section_name}-${index}`} flexDirection="column">
            <Container
              key={`${item.section_name}-${index}`}
              pl={3}
              minHeight={48}
              backgroundColor="#f7f7f7"
              alignItems="center"
              justifyContent="space-between"
              style={{ borderBottom: '1px solid #ddd' }}
            >
              <Text size={14} fontWeight={600} value={item.section_name} />

              <Container>
                <InputCheckbox
                  labelLeft={
                    <Text
                      size={12}
                      marginRight={10}
                      value={t('AssignActivity.Tabs.NewTask.Step1.FieldSelectAllText')}
                    />
                  }
                  checked={allActivitiesSelected[index]}
                  disabled={!item.activities.length}
                  onChange={(checked) => onSelectAllActivities(checked, index)}
                />

                <Button
                  variant="link"
                  leftContent={
                    sectionIndex === index ? (
                      <Container pt={'3px'}>
                        <ChevronUpLined width={20} />
                      </Container>
                    ) : (
                      <Container pt={'3px'}>
                        <ChevronDownLined width={20} />
                      </Container>
                    )
                  }
                  onClick={() => openSection(index)}
                />
              </Container>
            </Container>

            {!!item.activities.length &&
              sectionIndex === index &&
              RenderActivities(item.activities, item.section_name)}
          </Container>
        ))}
      </Container>
    )
  }

  const RenderActivities = (activities: BookDetailActivity[], sectionName: string) => {
    return (
      <Container paddingLeft={3} paddingRight={2} flexDirection="column">
        {activities.map((item, index) => {
          return (
            <Container
              key={index}
              alignItems="center"
              flexDirection="row"
              borderBottom={'1px solid #c5c5c5'}
              paddingTop={2}
              paddingBottom={2}
            >
              {item.type === 'AI_SPEAKING' && <WorkSpeechIcon width={20} />}

              {item.type === 'LISTENING' && <>👂</>}

              {item.type === 'DIALOG' && <>📖</>}

              {item.type === 'PRONUNCIATION' && <WorkSpokenIcon width={20} />}

              {item.type === 'GRAMMAR' && <BookPileIcon width={30} />}

              {item.type === 'VOCABULARY_TRAINER' && <BlueBookIcon width={20} />}

              <Container flex={1} marginLeft={2} flexDirection="column">
                <Text value={getTypeText(item)} />

                <Text size={12} color={colors.gray[2]} value={item.title} />
              </Container>

              <InputCheckbox
                checked={item.selected}
                disabled={false}
                onChange={(checked) => onSelectActivity(index, checked)}
              />
            </Container>
          )
        })}
      </Container>
    )
  }

  // const RenderTruOrFalse = () => {
  //   return (
  //     <>
  //       <Container>
  //         <RadioContainer>
  //           <InputElement
  //             type="radio"
  //             name="trueOrFalse"
  //             checked={targetScoreEnable.sim}
  //             onChange={(value) => {
  //               setTargetScoreEnable({ sim: value.target.checked, nao: !value.target.checked })
  //             }}
  //           />
  //           <Check className="check">
  //             <div />
  //           </Check>
  //           <Text marginLeft={10} value={t('AssignActivity.Tabs.NewTask.Step1.FieldCheckYes')} />
  //         </RadioContainer>
  //       </Container>
  //       <Container marginLeft={4}>
  //         <RadioContainer>
  //           <InputElement
  //             type="radio"
  //             name="trueOrFalse"
  //             checked={targetScoreEnable.nao}
  //             onChange={(value) => {
  //               setTargetScoreEnable({ sim: !value.target.checked, nao: value.target.checked })
  //               debouceChangePayload({ goal: 0 })
  //             }}
  //           />
  //           <Check className="check">
  //             <div />
  //           </Check>
  //           <Text marginLeft={10} value={t('AssignActivity.Tabs.NewTask.Step1.FieldCheckNo')} />
  //         </RadioContainer>
  //       </Container>
  //     </>
  //   )
  // }

  // const onChangeTargetScore = (value: number) => {
  //   setTargetScore(value)

  //   debouceChangePayload({ goal: value })
  // }

  return (
    <Container flexDirection="column">
      <Container padding={3} flexDirection="column">
        <Text
          mb={2}
          size={16}
          value={t('AssignActivity.Tabs.NewTask.Step1.SectionBookTitle')}
          fontWeight={600}
        />

        <Container mb={3} flexDirection="column">
          <Container flexDirection="column" mb={4}>
            {collections && (
              <BookSelector
                collections={collections}
                activeCollection={activeCollection}
                callback={handleActiveCollection}
                isDisabled={!!bookId}
              />
            )}
          </Container>

          <Text
            mt={2}
            mb={2}
            size={14}
            value={
              bookFamily === 'neutral_content'
                ? t('AssignActivity.Tabs.NewTask.Step1.NeutralContentFieldBook')
                : t('AssignActivity.Tabs.NewTask.Step1.FieldBook')
            }
            color={colors.gray[1]}
          />

          <Select
            items={books}
            renderItem={(item: Book) => item?.name}
            onSelectItem={(book: Book, index: number) => onSelectBook(index, book)}
            selectedIndex={bookIndex}
            isDisabled={!books.length || !!bookId}
            placeholder={t('AssignActivity.Tabs.NewTask.Step1.FieldBookPlaceholder')}
          />
        </Container>

        <Container mb={3} flexDirection="column">
          <Text
            mt={1}
            mb={2}
            size={14}
            value={
              bookFamily === 'neutral_content'
                ? t('AssignActivity.Tabs.NewTask.Step1.NeutralContentFieldChapter')
                : t('AssignActivity.Tabs.NewTask.Step1.FieldChapter')
            }
            color={colors.gray[1]}
          />

          <Select
            items={units}
            renderItem={(item: BookDetail) => item?.name}
            onSelectItem={(bookUnit: BookDetailUnit, index: number) =>
              onSelectUnit(index, bookUnit)
            }
            selectedIndex={unitIndex}
            isDisabled={!units.length}
            placeholder={t('AssignActivity.Tabs.NewTask.Step1.FieldChapterPlaceholder')}
          />
        </Container>

        {/* <Container pl={1} flexDirection="column">
          <Text
            mt={1}
            mb={2}
            size={14}
            value={t('AssignActivity.Tabs.NewTask.Step1.FieldPage')}
            color={colors.gray[1]}
          />

          <Select
            items={sections}
            renderItem={(item: BookDetailSection) => item?.section_name}
            onSelectItem={(bookSection: BookDetailSection, index: number) =>
              onSelectBookSection(index, bookSection)
            }
            selectedIndex={sectionIndex}
            isDisabled={!sections.length}
            placeholder={t('AssignActivity.Tabs.NewTask.Step1.FieldPagePlaceholder')}
          />
        </Container> */}
      </Container>

      {/* <Container
        pl={3}
        pr={3}
        minHeight={48}
        backgroundColor="#f7f7f7"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          size={16}
          fontWeight={600}
          value={t('AssignActivity.Tabs.NewTask.Step1.SectionTaskTitle')}
        />

        <InputCheckbox
          labelLeft={
            <Text
              size={12}
              marginRight={10}
              value={t('AssignActivity.Tabs.NewTask.Step1.FieldSelectAllText')}
            />
          }
          checked={allActivitiesSelected}
          disabled={!activities.length}
          onChange={onSelectAllActivities}
        />
      </Container> */}

      {!!sections.length && <RenderSections />}

      {/* {!!activities.length && <RenderActivities />} */}

      {/* <Container pt={3} pr={3} pl={4} flexDirection="column">
        <Text
          mb={2}
          size={16}
          fontWeight={600}
          value={t('AssignActivity.Tabs.NewTask.Step1.SectionTargetTitle')}
        />

        <Text
          mb={2}
          size={12}
          color={colors.gray[2]}
          value={t('AssignActivity.Tabs.NewTask.Step1.FooterText')}
          style={{ opacity: targetScoreEnable.nao ? '45%' : 'initial' }}
        />

        <Container mb={4} flex={1} flexDirection="column">
          <Container flex={1} marginBottom={3} justifyContent="flex-end">
            <Text
              size={14}
              value={`${targetScore} %`}
              color={colors.primary}
              style={{ opacity: targetScoreEnable.nao ? '45%' : 'initial' }}
            />
          </Container>

          <InputRange
            onChange={onChangeTargetScore}
            disabled={targetScoreEnable.nao}
            defaultValue={INITIAL_TARGET_SCORE}
          />

          <Container flex={1} marginTop={3}>
            <Text
              size={12}
              value={`${t('AssignActivity.Tabs.NewTask.Step1.TargetRecommendedText')}: 85 %`}
              style={{ opacity: targetScoreEnable.nao ? '45%' : 'initial' }}
            />
          </Container>
        </Container>

        <Container flex={1} flexDirection="row">
          <RenderTruOrFalse />
        </Container>
      </Container> */}

      <Container height={48} marginTop={3} backgroundColor="#f7f7f7" />
    </Container>
  )
}

export default StepOneEdit
