import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Container, Button} from 'cc-web-components';
import {VscArrowLeft, VscArrowRight} from 'react-icons/vsc';
import {useTranslation} from 'react-i18next';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';

import Helper from '@/helper';
import {startLoading, stopLoading} from '@/redux/Session';

import ApiStudent from '../../service';
import {FilterTime, HealthScoreGraphResponse} from '../../types';
import {CustomText} from './styles';

const DataKeys = {
  key: 'date',
  VOCABULARY: 'vocab_total_score',
  SPEAK: 'speaking_total_score',
  LISTEN: 'listening_total_score',
  vocab_total_score: 'Wortschatz',
  speaking_total_score: 'Sprechkompetenz',
  listening_total_score: 'Hörverstehen',
};

type LabelKey = 'vocab_total_score' | 'speaking_total_score' | 'listening_total_score';

type Props = {
  activedTimeFilter: number;
  studentId?: string;
  classroomId?: string;
  filterDate: FilterTime | undefined;
};

const AREA_CHART_TYPE = 'step';
const GRAPH_HEADER_HEIGHT = 49;

const GraphArea: React.FC<Props> = ({
  studentId,
  classroomId,
  filterDate,
  activedTimeFilter,
}: Props) => {
  const {t} = useTranslation('cornelsen');
  const dispatch = useDispatch();

  const [healthScoreGraph, setHealthScoreGraph] = useState<HealthScoreGraphResponse[]>([]);
  const [filterTime, setFilterTime] = useState<FilterTime | undefined>(filterDate);
  const isFilterSet = !!filterTime?.startDate && !!filterTime?.endDate;

  useEffect(() => {
    setFilterTime(filterDate);
  }, [filterDate]);

  useEffect(() => {
    dispatch(startLoading());
    if (!filterTime || !studentId || !classroomId) return;

    ApiStudent.getStudentHealtScoreGraph({studentId, classroomId, filter: filterTime})
      .then(response => {
        setHealthScoreGraph(response);
        dispatch(stopLoading());
      })
      .catch(() => {
        setHealthScoreGraph([]);
        dispatch(stopLoading());
      });
  }, [dispatch, studentId, filterTime, classroomId]);

  const handlePrev = useCallback(() => {
    if (!filterTime) return;
    const time = Helper.getPrevIntervalDates(activedTimeFilter, filterTime);

    setFilterTime(time);
  }, [filterTime, activedTimeFilter]);

  const handleNext = useCallback(() => {
    if (!filterTime) return;
    const time = Helper.getNextIntervalDates(activedTimeFilter, filterTime);

    setFilterTime(time);
  }, [filterTime, activedTimeFilter]);

  return (
    <Container flex={1} flexDirection="column" overflow="hidden">
      <Container
        alignItems="center"
        marginBottom={'-5px'}
        height={GRAPH_HEADER_HEIGHT}
        borderBottom="1px solid #eee"
        paddingBottom={1}
        justifyContent="space-between">
        {isFilterSet && (
          <Button
            variant="link"
            leftContent={<VscArrowLeft fontSize={25} />}
            onClick={handlePrev}
          />
        )}
        <CustomText content={t('Student.TabProgress.SectionCompetency.GraphTitle')} />

        {isFilterSet && (
          <Button
            variant="link"
            leftContent={<VscArrowRight fontSize={25} />}
            onClick={handleNext}
          />
        )}
      </Container>
      <Container>
        <ResponsiveContainer height={325}>
          <ScatterChart data={healthScoreGraph}>
            <CartesianGrid strokeDasharray="0 0" strokeWidth={0.4} />

            <XAxis
              dataKey={DataKeys.key}
              fontSize={11}
              height={15}
              tickFormatter={value => {
                return value;
              }}
            />

            <YAxis
              fontSize={11}
              width={40}
              ticks={[20, 40, 60, 80, 100]}
              tickFormatter={(value: string) => `${value} %`}
            />

            <Tooltip
              formatter={(value: any, name: LabelKey) => {
                let translationKey = 'Vocab';

                if (name === 'speaking_total_score') {
                  translationKey = 'Speak';
                }
                if (name === 'listening_total_score') {
                  translationKey = 'Listen';
                }

                return [
                  `${value} %`,
                  t(`Student.TabProgress.SectionCompetency.GraphLegend.${translationKey}`),
                ];
              }}
            />

            <Scatter type={AREA_CHART_TYPE} dataKey={DataKeys.SPEAK}>
              {healthScoreGraph.map((entry, index) => {
                const speakingValue = entry.speaking_total_score;
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={speakingValue === 0 ? 'transparent' : 'rgba(171, 128, 197, 0.5)'}
                    stroke={speakingValue === 0 ? 'transparent' : 'rgb(171 128 197)'}
                    strokeWidth={speakingValue === 0 ? 0 : 3}
                  />
                );
              })}
            </Scatter>

            <Scatter type={AREA_CHART_TYPE} dataKey={DataKeys.LISTEN}>
              {healthScoreGraph.map((entry, index) => {
                const listeningValue = entry.listening_total_score;
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={listeningValue === 0 ? 'transparent' : 'rgba(240, 110, 0, 0.5)'}
                    stroke={listeningValue === 0 ? 'transparent' : 'rgb(240 110 0)'}
                    strokeWidth={listeningValue === 0 ? 0 : 3}
                  />
                );
              })}
            </Scatter>

            <Scatter type={AREA_CHART_TYPE} dataKey={DataKeys.VOCABULARY}>
              {healthScoreGraph.map((entry, index) => {
                const vocabValue = entry.vocab_total_score;
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={vocabValue === 0 ? 'transparent' : 'rgba(0, 170, 1, 0.5)'}
                    stroke={vocabValue === 0 ? 'transparent' : 'rgb(0 170 170)'}
                    strokeWidth={vocabValue === 0 ? 0 : 3}
                  />
                );
              })}
            </Scatter>
          </ScatterChart>
        </ResponsiveContainer>
      </Container>
    </Container>
  );
};

export default GraphArea;
