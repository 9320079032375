import styled, { css } from 'styled-components'
import { Props } from './types'

export const StyledScoreIconCount = styled.div<Props>`
  width: 36px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 9999px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;

  ${(props) =>
    props.color === 'green' &&
    css`
      background: #a5d6a7;
      color: #003b0a;
    `}

  ${(props) =>
    props.color === 'yellow' &&
    css`
      background: #ffe082;
      color: #3d2e00;
    `}

    ${(props) =>
    props.color === 'red' &&
    css`
      background: #ef9a9a;
      color: #660003;
    `}
`
